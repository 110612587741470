<div *ngIf="showBatchCandidateList" class="main-div-assessment container-fluid bg-white  pt-1 pb-5">
    <div class="row ">
        <div class="col">
            <div class="card border-left-0 border-right-0">
                <div class="card-body card-body-slim">
                    <div class="row GR-14">
                        <div class="col-1" style="max-width:50px;"></div>
                        <div class="col">Name</div>
                        <div class="col">Access Code</div>
                        <div class="col">Test Status</div>
                        <div class="col-1"></div>
                    </div>
                </div>
            </div>
            <div class="card card__two mtop15 border-0" *ngFor="let candidate of batchCandidates;let i=index;">
                <div class="card-body card-bg card-body-slim">
                    <div class="row">
                        <div class="col-1" *ngIf="candidate.imageUrl == ''" style="max-width:50px;">
                            <span class="dot" *ngIf="linkType == 'PUBLIC'"
                                style="padding-top:3px;"><b>{{candidate.candidateName | uppercase | slice:0:
                                    1}}</b></span>
                            <span class="dot" *ngIf="linkType == 'PRIVATE'"
                                style="padding-top:3px; cursor: pointer !important;"><b>{{candidate.candidateName |
                                    uppercase | slice:0: 1}}</b></span>
                        </div>
                        <div class="col-1" *ngIf="candidate.imageUrl != ''" style="max-width:50px;">
                            <img src="{{candidate.imageUrl}}" alt="image" class="dot" *ngIf="linkType == 'PRIVATE'"
                                style="cursor: pointer;" />
                            <img src="{{candidate.imageUrl}}" alt="image" class="dot" *ngIf="linkType == 'PUBLIC'"
                                style="cursor: not-allowed;" />
                        </div>
                        <div *ngIf="candidate.status!='Not Taken'" class="col text-truncate qHeading dect"
                            style="cursor: pointer;" (click)="fetchCandidateDetails(candidate);">
                            {{ candidate.candidateName }}
                        </div>
                        <div *ngIf="candidate.status=='Not Taken'" class="col text-truncate qHeading dect">
                            {{candidate.candidateName}}
                        </div>

                        <div class="col text-truncate qRest">{{candidate.accessCode}}</div>

                        <div class="col text-truncate qRest">{{candidate.status}}</div>

                        <div class="col-1">

                            <div  *ngIf="isDescriptive && linkType == 'PRIVATE'  && candidate.updatedStatus !== 'In-Progress' && isLinkOpenForEvaluation">
                                <button type="button" class="btn float-right btnMore" role="button"
                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="material-icons">more_vert</i>
                                </button>
                                <i *ngIf="candidate.candidateReviewAssessmentDetails && candidate.candidateReviewAssessmentDetails[0].isReviewAnswer === 'true' && (candidate.updatedStatus !=='In-Progress' && candidate.updatedStatus !=='Not Taken')"
                                    popover="Assessment Evaluation Pending" [popoverOnHover]="true"
                                    [popoverCloseOnMouseOutside]="true" popoverPlacement="left"
                                    class="material-icons blue-dot"> fiber_manual_record </i>
                                <div  class="can-drop-btn dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuLink" x-placement="bottom-end">
                                    <a *ngIf="(candidate.updatedStatus =='Not Taken' || candidate.updatedStatus =='Reset' || candidate.updatedStatus == 'Resume' || candidate.updatedStatus =='Expired')&& roleId!=='external'" class="dropdown-item" (click)="scheduleTime(candidate)">Schedule Time</a>
                                    <a *ngIf="(candidate.updatedStatus =='Not Taken' || candidate.updatedStatus =='Reset' || candidate.updatedStatus == 'Resume')&& roleId!=='external'" class="dropdown-item" (click)="imageUploadAlert(candidate,i,'menuUpload');">Upload Image</a>

                                    <a *ngIf=" (candidate.updatedStatus =='Submitted' || candidate.updatedStatus== 'In-Complete' || candidate.updatedStatus =='Auto-Submitted' || candidate.updatedStatus =='Terminated' || candidate.updatedStatus =='Auto-Terminated' )"
                                        class="dropdown-item" (click)="initiateReviewProcess(candidate,false)">
                                        Evaluate Assessment
                                    </a>
                                </div>
                            </div>
                            <div  *ngIf="!isDescriptive && linkType == 'PRIVATE' && candidate.updatedStatus !== 'Expired' && candidate.updatedStatus !== 'In-Progress' && candidate.updatedStatus !=='Expired' && candidate.updatedStatus !=='In-Complete' && candidate.updatedStatus !=='Submitted'&& candidate.updatedStatus !=='Auto-Submitted' && candidate.updatedStatus !=='Terminated' && candidate.updatedStatus !=='Auto-Terminated' && isLinkOpenForEvaluation">
                                <button type="button" class="btn float-right btnMore" role="button"
                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="material-icons">more_vert</i>
                                </button>
                                <i *ngIf="candidate.candidateReviewAssessmentDetails && candidate.candidateReviewAssessmentDetails[0].isReviewAnswer === 'true' && (candidate.updatedStatus !=='In-Progress' && candidate.updatedStatus !=='Not Taken' )"
                                    popover="Assessment Evaluation Pending" [popoverOnHover]="true"
                                    [popoverCloseOnMouseOutside]="true" popoverPlacement="left"
                                    class="material-icons blue-dot"> fiber_manual_record </i>
                                <div  class="can-drop-btn dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuLink" x-placement="bottom-end">
                                    <a *ngIf="(candidate.updatedStatus =='Not Taken' || candidate.updatedStatus =='Reset' || candidate.updatedStatus == 'Resume' || candidate.updatedStatus =='Expired')&& roleId!=='external'" class="dropdown-item" (click)="scheduleTime(candidate)">Schedule Time</a>
                                    <a *ngIf="(candidate.updatedStatus =='Not Taken' || candidate.updatedStatus =='Reset' || candidate.updatedStatus == 'Resume')&& roleId!=='external'" class="dropdown-item" (click)="imageUploadAlert(candidate,i,'menuUpload');">Upload Image</a>

                                </div>
                            </div>


                            <div  *ngIf="isDescriptive && linkType != 'PRIVATE' && candidate.candidateReviewAssessmentDetails && candidate.candidateReviewAssessmentDetails[0].assessmentId !== '' && (candidate.updatedStatus !=='In-Progress' && candidate.updatedStatus !=='Not Taken' && (candidate.updatedStatus =='Submitted' || candidate.updatedStatus== 'In-Complete' || candidate.updatedStatus =='Auto-Submitted' || candidate.updatedStatus =='Terminated' || candidate.updatedStatus =='Auto-Terminated' )) && isLinkOpenForEvaluation">
                                <button type="button" class="btn float-right btnMore" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="material-icons">more_vert</i>
                                </button>
                                <i *ngIf="candidate.candidateReviewAssessmentDetails && candidate.candidateReviewAssessmentDetails[0].isReviewAnswer === 'true' && (candidate.updatedStatus !=='In-Progress' && candidate.updatedStatus !=='Not Taken')"
                                    popover="Assessment Evaluation Pending" [popoverOnHover]="true"
                                    [popoverCloseOnMouseOutside]="true" popoverPlacement="left"
                                    class="material-icons blue-dot"> fiber_manual_record </i>
                                <div  class="can-drop-btn dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuLink" x-placement="bottom-end">

                                    <a  class="dropdown-item" (click)="initiateReviewProcess(candidate,false)">
                                        Evaluate Assessment
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div class="container-fluid text-center" *ngIf="batchCandidates.length == 0">
                <!-- start for main entry Landing Page-->
                <h3 class="text-center mt-5" *ngIf="!isFetchingCandidateList && noDataFoundFlag">{{candidateErr}}</h3>
                <h3 class="text-center mt-5" *ngIf="isFetchingCandidateList">Fetching candidate list...</h3>

            </div>
        </div>

    </div>

    <div *ngIf="batchCandidates.length != 0 " class="row mtop30">
        <div class="col text-center">
            <button type="button" [hidden]="!hasMoreCandidate" class="btn btn-more mtop30 "
                (click)="fetchBatchCandidates(false,$event);">
                Load more
            </button>
        </div>
    </div>
</div>

<!-- Candidate details View -->
<div *ngIf="showCandidateAssessmentDetails" class="main-div-assessment container-fluid bg-white  pt-1 pb-5">
    <div class="row mt-3">
        <nav class="col" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <i class="material-icons green align-middle" style="cursor: pointer"
                        (click)="showBatchCandidateListView();">keyboard_arrow_left</i>
                    <a>{{selectedCandidate.candidateName}}</a>
                </li>
            </ol>
        </nav>
    </div>

    <div class="row">
        <div class="col">
            Assessment Name :
            <strong
                *ngIf="candidateAssessmentResult.assessmentVersions[0].assessmentStatus == 'Submitted' || 'Auto-Submitted'"
               >
                {{candidateAssessmentResult.assessmentName}}
                <span *ngIf="candidateAssessmentResult.assessmentVersions[0].assessmentStatus != 'In-Progress' && assessmentTypeForLoadMore != 'Psychometer'"
                    class="pt-2 pb-2 ml-3 badge badge-calibrat" style="font-size: 14px; font-weight: bolder;">
                    <img alt="" src="/assets/img/score-badge-32.png" style="margin-right: 5px;" width="20">
                    {{candidateAssessmentResult.assessmentVersions[0].assessmentPercentage}}
                </span>
            </strong>
        </div>
    </div>
    <div class="row mt-0">
        <div class="col">Batch Name: <strong>{{linkName}}</strong></div>
      </div>
    <div class="">
        <table class="table table-bordered mtop15">
            <thead>
                <tr>
                    <th scope="col "><span class="font-weight-normal text text-muted">Invite Type</span></th>
                    <th scope="col "><span class="font-weight-normal text text-muted">Access Code</span></th>
                    <th scope="col "><span class="font-weight-normal text text-muted">Test Status</span></th>
                    <th scope="col"><span class="font-weight-normal text text-muted">Appeared On</span></th>
                    <th scope="col"><span class="font-weight-normal text text-muted">Report</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <h6>{{linkType}}</h6>
                    </td>
                    <td>
                        <h6>{{selectedCandidate.accessCode}}</h6>
                    </td>
                    <td>
                        <h6>{{candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].assessmentStatus}}
                        </h6>
                    </td>
                    <td
                        *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].assessmentStatus != 'Not Taken' ">
                        <h6> {{
                            (candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].appearedOn)
                            | date: 'dd/MM/yyyy' }} </h6>
                    </td>
                    <td
                        *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].assessmentStatus == 'Not Taken' ">
                        <h6> ----- </h6>
                    </td>

                    <td>
                        <a data-toggle="tooltip" data-placement="top" title="Reset/Resume" style="cursor: pointer"
                        *ngIf="assessmentDetails.assessmentType !='Online Compiler'"
                        (click)="CandidateResetTest();">
                        <i class="material-icons fa-bg blue">settings_backup_restore</i>
                    </a>
                        <span style="cursor: pointer"
                            *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].assessmentStatus != 'In-Progress' ">


                            <!--CandidateSnap removed from condition for bug fixing -->
                            <a *ngIf="LinkCongiguration.captureAttendanceSnapshot||LinkCongiguration.captureCandidateSnapshot"
                                data-toggle="tooltip" data-placement="top" title="Candidate Snapshot"
                                (click)="showSnapshots(candidateAssessmentResult)">
                                <i class="material-icons fa-bg blue" data-toggle="modal">perm_identity</i>
                            </a>

                            <!--CandidateTrackLocation removed from condition for bug fixing-->
                            <a *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].trackGeo.startLocation.latitude.length > 1"
                                data-toggle="tooltip" data-placement="top" title="Geo-Location"
                                (click)="showLocation(candidateAssessmentResult);">
                                <i class="material-icons fa-bg blue">location_on</i>
                            </a>
                            <a *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].pdfLocation !=''"
                                (click)="openCandidateAssessmentReport(candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].pdfLocation)"
                                title="Pdf-Report">
                                <i class="material-icons fa-bg blue">insert_drive_file</i>
                            </a>

                            <a *ngIf="showDescriptiveIcon.isReviewAnswer !== '' && isLinkOpenForEvaluation"
                                (click)="getReviewDetails(true)" title="Evaluate-Assessment">
                                <i class="material-icons fa-bg blue">description</i>
                            </a>
                        </span>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <hr *ngIf="assessmentDetails.assessmentType!='Psychometer' && assessmentDetails.assessmentType!='Online Compiler' ">
    <h5 class="pt-4"
        *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].assessmentStatus != 'In-Progress' && assessmentDetails.assessmentType!='Psychometer'  && assessmentDetails.assessmentType!='Online Compiler'">
        Section Details</h5>
    <div class="row mt-3"
        *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].assessmentStatus != 'In-Progress'">
        <div class="card card-body border-right-0 border-left-0"
            *ngIf="assessmentDetails.assessmentType!='Psychometer' && assessmentDetails.assessmentType!='Online Compiler'">
            <div>
                <div class="card-body ">
                    <div class="row GR-14">
                        <div class="col"> Section Name </div>
                        <div class="col"> No. of Questions </div>
                        <div class="col"> Correct </div>
                        <div class="col"> Incorrect </div>
                        <div class="col"> Unattempted </div>
                        <div class="col"> Max Score </div>
                        <div class="col"> Score </div>
                        <div class="col"> Score(%) </div>
                    </div>
                </div>
            </div>
            <div class="card card__two mtop15 border-0"
                *ngFor="let sectionData of candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].sectionDetails; let j=index;">
                <div class="card-body card-bg">
                    <div class="row">
                        <div class="col text-truncate qHeading">{{sectionData.sectionName}} <sup *ngIf="sectionData.hasOptionalQuestions" class="blue RR-12">*</sup></div>
                        <div class="col text-truncate qRest" *ngIf="!sectionData.hasOptionalQuestions">{{sectionData.numberOfQuestions}}</div>
                        <div class="col text-truncate qRest" *ngIf="sectionData.hasOptionalQuestions">{{sectionData.questionsToBeAnswered}} of {{sectionData.numberOfQuestions}}</div>
                        <div class="col text-truncate qRest">{{sectionData.correctQuestions}}</div>
                        <div class="col text-truncate qRest">{{sectionData.InCorrectQuestions}}</div>
                        <div class="col text-truncate qRest">{{sectionData.unAttemptedQuestions}}</div>
                        <div class="col text-truncate qRest">{{sectionData.sectionOverAllScore}}</div>
                        <div class="col text-truncate qRest">{{sectionData.sectionMarksScored}}</div>
                        <div class="col text-truncate qRest">{{sectionData.percentage}}</div>
                    </div>
                    <div *ngIf="sectionData.pendingEvaluationQuestions > 0" class="row" style="color: #1782a0;">
                        <div class="col text-truncate qRest mx-3 mt-3 pl-0">
                            ** No. of question(s) pending for evaluation : {{sectionData.pendingEvaluationQuestions}}
                        </div>
                    </div>
                    <div *ngIf="sectionData.hasOptionalQuestions" class="row">
                        <div class="col text-truncate qRest mx-3 mt-3 pl-0">
                          <span class="grey RR-12 disabled2">Optional questions setting is enabled for this section</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card__two mtop15 border-0"
                *ngIf="candidateAssessmentResult.assessmentVersions[0].totalSectionsSummary !== null">
                <div class="card-body card-bg" style="background:#d8d8d8;">
                    <div class="row">
                        <div class="col text-truncate qHeading"> Total </div>
                        <div class="col text-truncate qRest" *ngIf="!candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].hasOptionalQuestions"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalNumberOfQuestions}}
                        </div>
                        <div class="col text-truncate qRest" *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].hasOptionalQuestions"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalNoOfQuesToBeAnswered}}
                        </div>
                        <div class="col text-truncate qRest"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalCorrectQuestions}}
                        </div>
                        <div class="col text-truncate qRest"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalInCorrectQuestions}}
                        </div>
                        <div class="col text-truncate qRest"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalUnAttemptedQuestions}}
                        </div>
                        <div class="col text-truncate qRest"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalSectionOverAllScore}}
                        </div>
                        <div class="col text-truncate qRest"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalSectionMarksScored}}
                        </div>
                        <div class="col text-truncate qRest"> {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalPercentage}}
                        </div>
                    </div>
                    <div *ngIf="candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalPendingEvaluationQuestions > 0"
                        class="row" style="color: #1782a0;">
                        <div class="col text-truncate qRest mx-3 mt-3 pl-0">
                            ** No. of total question(s) pending for evaluation :
                            {{
                            candidateAssessmentResult.assessmentVersions[candidateAssessmentResult.assessmentVersions.length-1].totalSectionsSummary[0].totalPendingEvaluationQuestions
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--</div>-->
        <!--</div>-->
    </div>
    <!--</div>-->
</div>


<!--Start review assessment modal-->
<div class="modal fade" id="reviewModal" tabindex="-1" role="dialog" aria-labelledby="descriptiveQnModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="descriptiveQnModalLabel"> Descriptive Questions </h5>
                <button (click)="reviewCancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mt-2">
                <div class="row">
                    <div class="col">
                        <div class="container-fluid bg-white">
                            <div class="row">
                                <div class="col">
                                    <div class="row" *ngFor="let list of reviewDetails; let i=index;">
                                        <div class="col" *ngIf="i < reviewDetails.length">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row mt-2 mb-2">
                                                        <div class="col-8">
                                                            <span class="GR-16">{{ i+1 }}. </span>
                                                            <span class="GR-16" [innerHTML]="list.question"> ?</span>
                                                        </div>
                                                        <div class="col-1"></div>
                                                        <div class="col-3">
                                                            <div class="col">
                                                                <div class="form-group mb-0 mt-1">
                                                                    <!--<label> <b> Marks Scored </b> </label>  -->
                                                                    <input maxlength="{{maxLength}}"
                                                                        (keydown)="validateNumber($event, i)"
                                                                        attr.id="marks-{{i}}"
                                                                        (blur)="marksScored($event, i)"
                                                                        (keyup)="restrictMax($event, i)" step=".01"
                                                                        type="text" class="marks-input"
                                                                        [(ngModel)]="descriptiveMarks[i].marks"
                                                                        name="marks">
                                                                    &nbsp;/ <b> {{ list.questionScore }} </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 mb-2">
                                                        <div>
                                                            <span class="badge mr-1"
                                                                [ngClass]="list.difficultyLevel == 'EASY'? 'badge-success': list.difficultyLevel == 'MEDIUM'? 'badge-warning':'badge-danger'">{{list.difficultyLevel}}</span>
                                                            <span
                                                                class="badge badge-single">{{list.questionType}}</span>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="desc-ans-para">
                                                            <div *ngIf="list.answer !== ''"
                                                                class="form-control bg-white" style="border: none;overflow-wrap: anywhere;height: auto; "
                                                                readonly>
                                                                <span class="answer-provided"
                                                                    [innerHTML]="formattedAns[i]"> </span>
                                                            </div>
                                                            <div *ngIf="list.answer === ''"
                                                                class="form-control bg-white" style="border: none;"
                                                                readonly>
                                                                <span class="no-answer-provided"> Not Answered </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row" *ngIf="list.attachmentFiles != null">
                                                        <div class="desc-ans-para pl-3 pt-1" style="font-size: 14px;">
                                                          <span style="font-weight: bold; padding-left: 4px;">Attachment(s) -</span>
                                                          <div>
                                                            <ol>
                                                              <li *ngFor="let attachment of list.attachmentFiles; let i=index;">
                                                                <a href="{{attachment.fileLocation}}" target="_blank">{{attachment.fileName}}</a>
                                                              </li>
                                                            </ol>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showCommentSecion" class="row answer-provided mt-2">
                                <div class="col-2">
                                    <label class="GR-16">
                                        Remark <span style="color: red">*</span>
                                    </label>
                                </div>
                                <div class="col-10">
                                    <input type="text" maxlength="200" placeholder="Remark for re-evaluation"
                                        (keyup)="checkForComment($event.target.value)" [(ngModel)]="reviewComment"
                                        class="form-control" placeholder="">
                                </div>
                                <div *ngIf="remarkDetails.length > 0"
                                    class="col m-3 p-2 comments-section desc-ans-para">
                                    <div *ngFor="let comment of remarkDetails; let i=index;">
                                        <p *ngIf="comment.remark !== ''" class="m-2">
                                            <i class="fa fa-comment-o" aria-hidden="true"> </i> &nbsp; {{ comment.remark
                                            }} <br>
                                            <b class="name-date"> {{ commentedBy }}, {{ comment.remarkedOn | date
                                                :'dd/MM/yyyy h:mm a ' }} </b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="text-center">-->
                <!--<h5 class="text-info">No records found</h5>-->
                <!--</div>-->
            </div>
            <div class="text-center">
                <div class="mt-3 mb-5 text-center">
                    <button [disabled]="isEvaluateBtnDisable || commentFlag" type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="publishResult();">
                        {{ evaluateBtnLabel }}
                    </button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="reviewCancel()"
                        aria-label="Close">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--end of review assessment modal-->

<!--Start of  candidate location modal-->
<div class="modal fade" id="candidatesLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Candidate Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pl-3">
                <h5 style="color: #0f74a8" class="pl-2">
                    Start Location <span class="ml-2" style="font-size: 15px;"> {{startLocation}} </span>
                    <span *ngIf="startTimeMap !== ''" style="float: right;font-size: 15px;" class="start-time"> {{
                        startTimeMap | date:'dd/MM/yyyy h:mm a '}} </span>
                </h5>
                <div class="vr"><img class=" " src="/assets/img/location.png" alt=""></div>
                <div class="row pl-2">
                    <div class="col-md-12">
                        <div id="map1" [hidden]="trackGeoLocation.startLocation.latitude === ''"
                            style="width:100%;height:150px"></div>
                        <div *ngIf="trackGeoLocation.startLocation.latitude === ''"
                            style="width:100%;height:150px;background-color: #ededee">
                            <p style="padding-top: 14%" class="text-center">Could not fetch location details</p>
                        </div>
                    </div>
                </div>

                <h5 class="mt-4 pl-2" style="color: #0f74a8">
                    End Location <span class="ml-2" style="font-size: 15px;">{{endLocation}}</span>
                    <span *ngIf="endTimeMap > startTimeMap" style="float: right;font-size: 15px;" class="start-time"> {{
                        endTimeMap | date:'dd/MM/yyyy h:mm a '}} </span>
                </h5>
                <div class="row pl-2">
                    <div class="col-md-12">
                        <div id="map2" [hidden]="trackGeoLocation.endLocation.latitude === ''"
                            style="width:100%;height:150px"></div>
                        <div *ngIf="trackGeoLocation.endLocation.latitude === ''"
                            style="width:100%;height:150px;background-color: #ededee">
                            <p style="padding-top: 14%" class="text-center">Could not fetch location details</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="modal-footer d-block mx-auto">

            </div>
        </div>
    </div>
</div>
<!--End of candidate location modal-->
<!--Start candidate snap modal-->
<div *ngIf="flag" class="modal fade" id="popUp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Candidate Snapshot</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mt-1">
                <div class="row">
                    <!--<div class="col">-->

                    <!--<div class="" style="width: 630px;margin: 0 auto;">-->
                    <!--<div *ngIf="candidateAttendanceSnapshot && candidateAttendanceSnapshot.fileName.length > 0 " style="width:200px;float:left;margin: 5px;">-->
                    <!--<img style="height: 200px;width: 200px" [src]="candidateAttendanceSnapshot.fileName">-->
                    <!--<p class="text-center mb-0 mt-3 GR-14">Attendance Snapshot</p>-->
                    <!--<p class="text-center GR-12">{{candidateAttendanceSnapshot.snapShot}}</p>-->

                    <!--&lt;!&ndash;<p class="text-center GR-12">{{dateOF(candidateAttendanceSnapshot.snapShotTime)| date:'short'}}</p>&ndash;&gt;-->

                    <!--&lt;!&ndash;<p class="text-center GR-12">{{CandidateData.appearedOn.substring(0,10)}} {{CandidateData.appearedOn | date:'shortTime'}}</p>&ndash;&gt;-->
                    <!--</div>-->

                    <!--<div *ngIf="candidateCaputerdSnapshot.length != 0 " >-->
                    <!--<div *ngFor="let image of candidateCaputerdSnapshot; let i = index" style="float: left;width: 200px;margin: 5px;">-->

                    <!--<img style="height: 200px;width: 200px" src={{image.fileName}}>-->
                    <!--<p class="text-center mb-0 mt-3 GR-14">Candidate Snapshot</p>-->
                    <!--&lt;!&ndash;<p class="text-center GR-12">{{dateOF(image.snapShotTime)| date:'short'}}</p>&ndash;&gt;-->
                    <!--<p class="text-center GR-12">{{image.snapShot}}</p>-->

                    <!--</div>-->
                    <!--</div>-->

                    <!--</div>-->


                    <div class="col-6">
                        <p class="">Name: {{ candidateName }}</p>
                        <p class="">Mobile No: {{ countryCodeNum }} {{ mobileNum }}</p>
                    </div>
                    <div class="col-6 text-right">
                        <div>
                            <span *ngIf="assessmentTypeForLoadMore == 'Aptitude'">
                                <img alt="Responsive image" src="/assets/img/aptitude-test.png" style="height:20px;">
                                <p> {{assessment_name}} </p>
                            </span>
                        </div>
                        <span *ngIf="assessmentTypeForLoadMore == 'Technical'">
                            <img alt="Responsive image" src="/assets/img/technical-assessment.png" style="height:20px;">
                            <p>{{assessment_name}}</p>
                        </span>
                    </div>

                    <hr class="mt-0">

                    <!-- main slider carousel -->

                    <div class="col-lg-12 offset-lg-0" id="slider">
                        <!-- main slider carousel items -->

                        <div class="row">
                            <div class="col">

                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel"
                                    data-interval="2000">

                                    <div class="carousel-inner">
                                        <div *ngIf="candidateAttendanceSnapshot.fileName != ''"
                                            class="active item carousel-item" attr.data-slide-number="0">
                                            <div style="height: 250px; background-size: contain;">
                                                <img src="{{candidateAttendanceSnapshot.fileName}}"
                                                    class="col img-fluid">
                                            </div>
                                        </div>
                                        <div *ngFor="let image of candidateCaputerdSnapshot; let i=index;"
                                            [ngClass]="{'active': i===0 && candidateAttendanceSnapshot.fileName === '' }"
                                            class="item carousel-item" attr.data-slide-number="{{ i + 1 }}">
                                            <div style="height: 250px; background-size: contain;">
                                                <img src="{{image.fileName}}" class="col img-fluid">
                                            </div>
                                            <div *ngIf="image.suspiciousCount && suspicionReason"
                                                class="suspicion_reason_cnt">
                                                {{ image.reason }}
                                            </div>
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>

                            </div>

                            <div class="col">
                                <div [hidden]="trackGeoLocation.startLocation.latitude === ''" id="map3"
                                    style="width:100%;height:150px"></div>
                                <span class="badge badge-light mb-2 mt-3" style="color:#808080">
                                    <i class="material-icons mr-1 align-middle" style="font-size: 12px">warning</i>
                                    Violation <span style="color:#000">{{candidateSuspiciousCount}}</span>
                                </span>
                                <br>
                                <span class="badge badge-light mb-2" style="color:#808080">
                                    <i class="material-icons mr-1 align-middle" style="font-size: 12px">image</i>
                                    Image Violation <span style="color:#000">{{candidateFaceViolationCount}}</span>
                                </span>
                                <br>
                                <span class="badge badge-light mb-2" style="color:#808080">
                                    <i class="material-icons mr-1 align-middle" style="font-size: 12px">schedule</i>
                                    Time Spent <span style="color:#000">{{candidateTimeSpentForAssessment}}</span>
                                </span>

                            </div>

                            <!-- main slider carousel nav controls -->


                            <div class="carousel-inner ml-3 thumnail">
                                <!-- <div class="indicators list-inline mt-3"
                                    *ngFor="let image of candidateImages; let j = index;">

                                    <div *ngIf="j == 0 " class=" active list-inline-item"
                                        style="position: fixed;z-index:33">
                                        <a class=" selected" attr.data-slide-to="{{j}}"
                                            data-target="#carouselExampleControls">
                                            <img src="{{image.fileName}}" class="" height="100px" width="100px">
                                        </a>
                                    </div>
                                    <div *ngIf="j == 0 " class=" list-inline-item diff">
                                        <a class="selected" attr.data-slide-to="{{j+1}}"
                                            data-target="#carouselExampleControls">
                                            <div *ngIf="image.suspiciousCount">
                                                <img src="{{image.fileName}}" class="" height="100px" width="100px"
                                                    (click)="getReason(image.reason)">
                                                <span aria-hidden="true"
                                                    style="color:red;position:absolute;top:15px;z-index:30;"><i
                                                        class="material-icons">flag</i></span>
                                            </div>
                                            <div *ngIf="!image.suspiciousCount" style="margin-left: -105px;">
                                                <img src="{{image.fileName}}" class="" height="100px" width="100px">
                                            </div>
                                        </a>
                                    </div>
                                    <div *ngIf="j > 0 " class="list-inline-item ml-1">
                                        <a class="selected" attr.data-slide-to="{{j+1}}"
                                            data-target="#carouselExampleControls">
                                            <div *ngIf="image.suspiciousCount">
                                                <img src="{{image.fileName}}" class="" height="100px" width="100px"
                                                    (click)="getReason(image.reason)">
                                                <span aria-hidden="true"
                                                    style="color:red;position:absolute;top:15px;z-index:30;"><i
                                                        class="material-icons">flag</i></span>
                                            </div>
                                            <div *ngIf="!image.suspiciousCount">
                                                <img src="{{image.fileName}}" class="" height="100px" width="100px">
                                            </div>
                                        </a>
                                    </div>
                                </div> -->
                            </div>

                            <div style="display: block;overflow-x: auto;white-space: nowrap;margin-right: 1rem;"
                                class="carousel-inner ml-3 thumnail">
                                <div *ngIf="candidateAttendanceSnapshot.fileName !== ''" class="active list-inline-item mt-3 cursor-pointer">
                                    <img (click)="getReason('', 'first')" src="{{candidateAttendanceSnapshot.fileName}}"
                                    height="100px" width="100px">
                                    <img src="assets/img/attendee_snapshot.png" class="snapshot-badge">
                                </div>
                                <div *ngFor="let image of candidateCaputerdSnapshot;let i=index;"
                                    class="active list-inline-item mt-3 cursor-pointer">
                                    <a class="selected" attr.data-slide-to="0" data-target="#carouselExampleControls">
                                        <img src="{{image.fileName}}" class="" (click)="getReason(image.reason, i)"
                                            height="100px" width="100px">
                                        <span *ngIf="image.suspiciousCount" aria-hidden="true"
                                            (click)="getReason(image.reason, i)"
                                            style="color:red;position:absolute;top:15px;margin-left: -6.3rem;z-index:30;"><i
                                                class="material-icons">flag</i></span>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <!-- main slider carousel -->
                        <!-- notify changes -->
                    </div>
                    <hr>

                </div>
            </div>

            <div *ngIf="candidateAttendanceSnapshot && candidateAttendanceSnapshot.fileName.length == 0 && candidateCaputerdSnapshot.length == 0"
                class="text-center">
                <h5 class="text-info">No records found</h5>
            </div>


        </div>
        <div class="modal-footer float-right">
        </div>
    </div>
</div>

<!--end of candidate snap modal-->
<!--Start of candidate reset modal-->
<div class="modal show" id="candidateReset" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">

        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Test Configuration</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" (click)="modalResetCandidate.reset();">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="modalResetCandidate">
                        <div class="row ">
                            <div class="col">
                                <div class="form-group" >
                                    <label>
                                        <h6>Reason</h6>
                                    </label>
                                    <textarea class="form-control" id="formGroupExampleI" placeholder="Reason"
                                        formControlName="resetReason"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="manageRadio">
                            <div>
                                <h6>Select mode for the Test</h6>
                            </div>
                            <div *ngIf="!hasCodingQuestions" class="form-check form-check-inline">
                                <input type="radio" id="radioReset" class="form-check-input" name="reset" value="1"
                                    formControlName="reset" (change)="checkReset('1')">
                                <label class="form-check-label" for="radioReset">Reset</label>
                            </div>
                            <div   class="form-check form-check-inline">
                                <input type="radio" id="radioResume" class="form-check-input" name="reset" value="2"
                                    formControlName="reset" (change)="checkReset('2')">
                                <label class="form-check-label" for="radioResume">Resume</label>
                            </div>
                            <div *ngIf="!hasCodingQuestions"  class="form-check form-check-inline">
                                <input type="radio" id="radioResetNResume" class="form-check-input" name="reset" value="3"
                                    formControlName="reset" (change)="checkReset('3')" >
                                <label class="form-check-label" for="radioResetNResume">Reset & Resume</label>
                            </div>
                        </div>
                    </form>
                    <div>
                        <small class="text-danger">{{publicerrorMsg}}</small>
                    </div>

                </div>
                <div class="modal-footer d-block mx-auto">
                    <button type="button" [disabled]="modalResetCandidate.invalid" class="btn btn-primary"
                        (click)="CandidateRRReson(modalResetCandidate.value)">Submit
                    </button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal" aria-label="Close"
                        (click)="modalResetCandidate.reset();">Cancel
                    </button>
                </div>
            </div>
        </div>

</div>
<!--End of candidate reset modal-->

 <!--schedule time modal-->
 <div class="modal fade" id="scheduleTime" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
 aria-hidden="true" data-backdrop="static" data-keyboard="false">
<div class="modal-dialog modal-dialog-centered" role="document">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Individual candidate schedule</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true" (click)="resetDateTimeScheduling();">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- start div for time and date-->
    <div class="row ">
      <div class="col-6">
        <h6 class="mtop15">Start Date</h6>
        <!--<input type="date" class="form-control " placeholder="Enter Date" name="startDate" [(ngModel)]="publicStartDate">-->

        <my-date-picker name="mystartdate" [options]="myDatePickerOptions" placeholder="Enter date"
                        [(ngModel)]="publicSTARTDate" (dateChanged)="onDateChanged($event,'start')" required>

        </my-date-picker>


      </div>


      <div class="col-6 ">
        <h6 class="mtop15">Start Time</h6>
        <input class="form-control" id="startTime" placeholder="Select Time" readonly
               (focus)="onClock('startTime');" (keydown)="validateTime($event)"
               [disabled]="publicStartDate==''|| publicStartDate==null" name="startTime" [(ngModel)]="publicStartTime">
      </div>


    </div>

    <div class="row mtop10">
      <div class="col-6">
        <h6 class="mtop15">End Date</h6>
        <!--<input type="date" class="form-control " placeholder="Enter Date" name="endDate" [(ngModel)]="publicEndDate">-->

        <my-date-picker name="myenddate" [options]="myDatePickerOptions" placeholder="Enter date"
                        [(ngModel)]="publicENDDate" (dateChanged)="onDateChanged($event,'end')" required></my-date-picker>

      </div>

      <div class="col-6">
        <h6 class="mtop15">End Time</h6>
        <input class="form-control " placeholder="Select Time" id="endTime" readonly
               (keydown)="validateTime($event)" (focus)="onClock('endTime');"
               [disabled]="publicEndDate==''||publicEndDate==null" name="endTime" [(ngModel)]="publicEndTime">
      </div>
      <div>
      </div>
    </div>

  </div>
  <small style="color: red" class="text-center">{{publicerrorMsg}}</small>
  <div class="modal-footer d-block mx-auto">
    <button type="button" class="btn btn-primary" (click)="dateTimeScheduling();">Save</button>
  </div>
</div>
</div>
</div>
<!--end of schedule time modal-->

 <!-- image upload alert added here-->
 <div class="modal fade" id="image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
 data-backdrop="static" data-keyboard="false">
<div class="modal-dialog" role="document">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Upload Candidate Image</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="assesmentUploadClear();"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body text-center">
    <input type="file" name="file" value="image" id="assesmentuploadvalidation"
           (change)="assesmentUpload($event);"><br><i>(Supported extensions: .jpg,.png,jpeg ) Maximum 2 Mb</i>
  </div>
  <div class="modal-footer d-block mx-auto">
    <button type="button" class="btn btn-primary" [disabled]='!afterSelect'
            (click)="assesmentImageUpload();">Upload</button>
  </div>
  <!--<div >-->
  <!--<p style="color:red;" >{{fileSizeErrMsg}}</p>-->
  <!--</div>-->
  <!--<div *ngIf="multipleFaceErrorMsg != '' && somethingOtherErrorMessage !=''">-->
  <!--&lt;!&ndash;<p style="color:red; text-align: center; padding-top: 5px;" >{{uploadedFileErrMsg}}</p>&ndash;&gt;-->
  <!--<p style="color:red; text-align: center;" >{{multipleFaceErrorMsg}}</p><br>-->
  <!--<p style="color:red; text-align: center; padding-top: 5px;" >{{somethingOtherErrorMessage}}</p>-->
  <!--</div>-->
  <div>
    <p style="color:red; text-align: center; padding-top: 5px;">{{uploadedFileErrMsg}}</p>
  </div>
  <div class="processingLoader" *ngIf="isUploadingCandImg">
    <i class="fa fa-refresh fa-spin"></i> Processing...
  </div>

</div>
</div>
</div>
<!-- image upload alert  added ends here -->
