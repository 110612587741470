import {Component, ElementRef, EventEmitter, OnInit, Input, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import '../externalJs/external.js';
// import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { JobService } from './jobs.service';
import { AssessmentService } from '../assessments-module/assessment.service';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
declare let myExtObject: any;
declare let $: any;
declare let google: any;

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css'],
  providers: [JobService, AssessmentService]
})

export class JobsComponent implements OnInit {

  @Input('mceContent') mceContent: any;
  @Output('contentChanged') contentChanged = new EventEmitter();
  @ViewChild('gmapstart') gmapElementstart: any;
  @ViewChild('gmapend') gmapElementend: any;
  companyType;
  companyId;
  accessToken;
  customeField = {
    'fieldName': '',
    'fieldValue': ''
  };
  fieldArr = [];
  customFieldErr = '';
  customFields = [this.customeField];
  jodDetails = {
    _id: '',
    Location: '',
    jobTitle: '',
    tags: [],
    minExperience: '',
    status: '',
    createdOn: '',
    jobDescription: '',
    assessmentCount: '',
    invites: '',
  };
  deleteData = [];
  multipleDelete: boolean;
  jobTittle = '';
  Experience = '';
  Description = '';
  createJobStep1;
  listJobcreated = [];
  noJobCreated: boolean;
  cretedJobList: boolean;
  JobDetailsData: boolean;
  JobDescriptionDash: boolean;
  jobAssessmentCreation: boolean;
  assementListforJob: boolean;
  listOfLinkforJob: boolean;
  listOfAssessmentforJob = [];
  dateandTimeError = '';
  startDate = [];
  endDate = [];
  file: File[];
  candidatesDetail = {
    'candidatesName': '',
    'email': '',
    'phoneNo': '',
    'countryCode': ''
  };
  candidatesDetails = [this.candidatesDetail];
  countryCode;
  dateandTimeErrorcheck;
  linkPrivate: Boolean = false;
  linkPublic: Boolean = true;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedItem = [];
  createdJobId;
  candidatesDetailList = [];
  experianceData = [];
  createWizard: Boolean = false;
  modalLink: FormGroup;
  modalResetCandidate: FormGroup;
  setWizard1: Boolean = false;
  setWizard2: Boolean = false;
  setWizard3: Boolean = false;
  hideEditDelete: Boolean = false;
  p = 1;
  skip = 0;
  skip1 = 0;
  skip2 = 0;
  canReportskip = 0;
  terms = '';
  listForJob = [];
  isLoadmore: boolean;
  isLoadmore1: boolean;
  isLoadmore2: boolean;
  getAssementList = [];
  getList = [];
  privateInviteName = '';
  privateInviteStatus = 'Active';
  privateBlockIpAddress = '';
  privateTags = '';
  privateStartDate = '';
  privateStartTime = '';
  privateEndDate = '';
  privateEndTime = '';
  privateAuthanticationOTP: Boolean = false;
  privateMessage = '';
  privateSubject = '';
  publicInviteName = '';
  publicInviteStatus = 'Active';
  publicBlockIpAddress = '';
  publicTags = '';
  publicStartDate = '';
  publicStartTime = '';
  publicEndDate = '';
  publicEndTime = '';
  publicLinkcreateion = '';
  publicAuthanticationOTP: Boolean = false;
  inviteCheck: Boolean = false;
  TrackLocationForLink: Boolean = false;
  IDProofForLink: Boolean = false;
  candidateAttendanceLink: Boolean = false;
  candidateSnapshotForLink: Boolean = false;
  imageUploadForLink: Boolean = false;
  privateLinkCreationStep1: Boolean = false;
  privateLinkCreationStep2: Boolean = false;
  assessmentCandidatesDetails: boolean;
  publicLinkCreation: boolean;
  addLinkID: string;
  private = 'private';
  public = 'public';
  Testcode = '';
  addprivate: boolean;
  candidateProctor: boolean;
  candidateDetails = [];
  tagsForLink = [];
  linkStatus = ['Active', 'Inactive'];
  candidatesADD: boolean;
  date = new Date();
  currentYear = this.date.getFullYear();
  currentMonth = this.date.getMonth() + 1;
  currentDate = this.date.getDate();
  myInputVariable: ElementRef;
  proctor = '';
  isLoading: boolean;
  selectScreenFreezeForLink: Boolean = false;
  candidatDetails = [{
      'candidateName': '',
      'email': '',
      'phone': '',
      'countryCode': ''
  }];
  CandidateReportDetails: boolean;
  CandidateReportData = [];
  CandidateData: any;
  mobileNum: any;
  countryCodeNum: any;
  candidateAttendanceSnapshot: any;
  filteredItems = [];
  pages = 4;
  pageSize = 8;
  pageNumber = 0;
  currentIndex = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart = 1;
  checkBoxSelect = [];
  CandidatelinkId = '';
  CandidatelinkName = '';
  assementCandiateReport: Boolean = false;
  CandidatelinkType = '';
  CandidateTrackLocation: boolean;
  CandidateSnap: boolean;
  candidateListValue: any;
  canReport: any;
  CandidateStartDT;
  CandidateEndDT: any;
  emptyLocation = '';
  candidateReportResponse = [];
  candidateLocation = '';
  candidateAssessmentName = '';
  startCurrentLat: any;
  startCurrentLong: any;
  endCurrentLat: any;
  endCurrentLong: any;
  startmap: any;
  endmap: any;
  marker: any;
  location: string;
  startLocation: string;
  endLocation: string;
  startLocationMark: string;
  endLocationMark: string;
  validFileUploaded: Boolean = false;
  afterSelect: Boolean = false;
  uploadedFileErrMsg: string;
  responseUrlImage: any;
  isUploadingCandImg: Boolean = false;
  selCandidateRowIndex = 0;
  selectedRowMobileNumber: string;
  selectedRowCountryCode: string;
  candidateMandidate: Boolean = false;
  proctorLinkId: any;
  showFileUpload: Boolean = false;
  base64textString = '';
  createdassessmentId: string;
  CandidatesResetReson: string;
  candidateCaputerdSnapshot = [];
  candidatePhoneNumber: number;
  candidateCountryCode: number;
  candidateDetialsss = [];
  candidateSataus: boolean;
  public myDatePickerOptions: IMyDpOptions = {
    disableUntil: {year: this.currentYear, month: this.currentMonth, day: this.currentDate - 1},
    dateFormat: 'yyyy-mm-dd',
  };
  listCount: any;
  deleteJobId: any;
  errorMsg = '';
  editData = [];
  editAssesmentCount: any;
  jobId: any;
  differentAssentType = [];
  selectedAssesmntType = [];
  inviteListForJob = [];
  listMessage: boolean;
  checkForExiting: boolean;
  index = 0;
  selectedId = [];
  selectedData = [0, 0, 0, 0, 0, 0, 0];
  selectedassessmentbyId = [];
  addAssessmentPage: Boolean = false;
  somOfAddedAssesments = 0;
  sequence = [];
  publishedLink: boolean;
  editJob: boolean;
  deleteAssessmentId: any;
  publicLink: boolean;
  privateLink: boolean;
  candidatesData = {
    'candidatesName': '',
    'email': '',
    'phoneNo': '',
    'countryCode': '+91'
  };
  candidateData = [this.candidatesData];
  deleteLinkId = '';
  publicerrorMsg = '';
  isUpload: Boolean = false;
  fileUploadMsg: boolean;
  uploaderrorMsg = '';
  byLink: Boolean = false;
  editedLinkType = '';
  editCandidates: boolean;
  editedLinkID = '';
  startDateTime: string;
  modalValue: string;
  publicSTARTDate: any;
  publicENDDate: any;
  editedErrMsg = '';
  inviteCandidates = [];
  inviteByFileUpload = [];
  errorMessage: boolean;
  candidatDetailsChecked = [];
  testLink = '<TestLink>';
  userInfo = '<UserInfo>';
  linkAttached = '';

  constructor(
    public router: Router,
    public jobService: JobService,
    public assessmentService: AssessmentService,
    public toastrService: ToastrService
  ) {
    this.accessToken = localStorage.getItem('accessToken');
    if (this.accessToken == null || this.accessToken === undefined) {
      this.router.navigate(['']);
      return;
    }
    this.router.events.subscribe(() => {
      window.scroll(0, 0);
    });
    const message = localStorage.getItem('assessmentPage');
    if (message === 'HelloAssessment') {
      this.router.navigate(['assessment']);
      localStorage.removeItem('assessmentPage');
      localStorage.removeItem('questionbank');
      return;
    }
  }

  ngOnInit() {
    localStorage.removeItem('redirectValue');
    this.router.events.subscribe(() => {
      window.scroll(0, 0);
    });
    this.companyType = localStorage.getItem('companyType');
    this.companyId = localStorage.getItem('companyId');
    this.proctor = localStorage.getItem('proctor');
    if (this.proctor === 'proctor') {
      this.observerLanding();
      localStorage.removeItem('proctor');
      localStorage.removeItem('assessmentType');
      this.isLoading = true;
    }
    this.listOFJD(true);
    const jobId = localStorage.getItem('jobId');
    const linkforJob = localStorage.getItem('createLinkForJob');
    this.getSkillsSet();
    this.experience();
    this.getTagsForLink();

    this.modalLink = new FormGroup({
      link: new FormControl('', Validators.required),
    });
    this.modalResetCandidate = new FormGroup({
      reset: new FormControl('', Validators.required),
      Resetreason: new FormControl('', Validators.required),
    });

    this.dropdownSettings = {
      singleSelection: false,
      text: 'Enter Skills',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };

  }

  dashboardRefresh() {
    localStorage.setItem('profileInfo', 'show profileInfo');
    this.router.navigate(['dashboard']);
  }

  observerLanding() {
    this.createdJobId = localStorage.getItem('jobID');
    this.jodDetails._id = localStorage.getItem('jobID');
    const payload = { 'jobId': this.jodDetails._id };
    this.jobService.jobIdDetails(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.showJobsDetails(response.message[0], response.message[0].invites, response.message[0].assessmentCount);
        },
        error => {
          this.publicerrorMsg = error.error.message;
     });
  }

  getTagsForLink() {
    const payload = { 'type': 'tagsForLink' };
    this.assessmentService.getLevelOfAssess(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.tagsForLink = response;
        },
        error => {
          console.log(error.error.message);
    });
  }

  startcreateJobs() {
    if (this.editJob === false) {
      this.cleardata();
    }
    this.privateInviteStatus = 'Active';
    this.publicInviteStatus = 'Active';
    this.setWizard1 = true;
    this.multipleDelete = false;
    window.scroll(0, 0);
    const p = 0;
    $('#prog').width(p + '%');
    this.createWizard = true;
    this.createJobStep1 = true;
    this.noJobCreated = false;
    this.cretedJobList = false;
    this.JobDescriptionDash = false;
    this.JobDetailsData = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
  }

  RidirectionMessage() {
    localStorage.setItem('jobPage', 'HelloJob');
    this.router.navigate(['question']);
  }

  onItemSelect(item: any) {
    this.selectedItem.push(item.itemName);
  }

  OnItemDeSelect(item: any) {}

  onSelectAll(items: any) {}

  onDeSelectAll(items: any) {}

  getSkillsSet() {
    const payload = { 'type': 'skills' };
    this.assessmentService.getLevelOfAssess(payload, this.companyId, this.accessToken).subscribe(
        response => {
          let obj;
          for (let i = 0; i < response.length; i++) {
            obj = {
              'id': i + 1,
              'itemName': response[i].name,
            };
            this.dropdownList.push(obj);
          }
        },
        error => {
          console.log(error.error.message);
     });
  }

  experience() {
    const payload = { 'type': 'experience' };
    this.jobService.getexperience(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.experianceData = response;
        },
        error => {
          console.log(error.error.message);
     });
  }

  addOrRemoveCustomField(index) {
    let name, value, i;
    let err = false;
    for (i = 0; i < this.customFields.length; i++) {
      name = $('#name-' + i).val();
      value = $('#value-' + i).val();
      if (name === '' || value === '') {
        this.customFieldErr = 'Please Enter All Fields.';
        err = true;
        return;
      }
    }
    if (!err) {
      name = $('#name-' + i).val();
      value = $('#value-' + i).val();
      this.customeField.fieldName = '';
      this.customeField.fieldValue = '';
      this.customFields.push(this.customeField);
    }
  }

  onKey(event: any) {
    this.customFieldErr = '';
  }

  createJobs(check) {
    this.skip = 0;
    this.fieldArr = [];
    this.listJobcreated = [];
    this.listForJob = [];
    window.scroll(0, 0);
    this.selectedItem = [];
    let name, value;
    let obj;
    for (let i = 0; i < this.customFields.length; i++) {
      name = $('#name-' + i).val();
      value = $('#value-' + i).val();
      obj = {
        'fieldName': name,
        'fieldValue': value
      };
      this.fieldArr.push(obj);
    }
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.selectedItem.push(this.selectedItems[i].itemName);
    }
    const payLaod = {
      '_id': '',
      'jobTitle': this.jobTittle,
      'minExperience': this.Experience,
      'jobDescription': this.Description,
      'tags': this.selectedItem,
      'customFields': this.fieldArr
    };
    if (this.editJob === true) {
      payLaod._id = this.jodDetails._id;
      if (check === false) {
        this.jobService.editedJob(payLaod, this.companyId, this.accessToken).subscribe(
            response => {
              const p = 100 / 2;
              $('#prog').width(p + '%');
            },
            error => {
              this.errorMsg = error.error.message;
        });
      } else {
        this.jobService.editedJob(payLaod, this.companyId, this.accessToken).subscribe(
            response => {
              this.toastrService.success('Job edited successfully', '', {
                timeOut: 5000,
              });
              this.editJob = false;
              this.listOFJD(true);
            },
            error => {
              this.errorMsg = error.error.message;
        });
      }
    } else {
      this.jobService.createJD(payLaod, this.companyId, this.accessToken).subscribe(
          response => {
            if (check === true) {
              this.customFields = [this.customeField];
              this.toastrService.success('Job created successfully', '', {
                timeOut: 5000,
              });
              this.listOFJD(true);
            } else {
              localStorage.setItem('jobId', response.jobId);
              this.jodDetails._id = response.jobId;
              this.customFields = [this.customeField];
              const p = 100 / 2;
              $('#prog').width(p + '%');
            }
          },
          error => {
            console.log(error.error.message);
      });
    }
  }

  listOFJD(check) {
    this.hideEditDelete = false;
    this.listJobcreated = [];
    this.listForJob = [];
    if (this.skip === 0) {
      window.scroll(0, 0);
    }
    this.createWizard = false;
    if (check === true) {
      this.listJobcreated = [];
      this.skip = 0;
    }
    const skip = '' + this.skip;
    this.listOfAssessmentforJob = [];
    this.jobService.listOfJD(skip, this.companyId, this.accessToken).subscribe(
        response => {
          this.noJobCreated = false;
          this.cretedJobList = true;
          this.createJobStep1 = false;
          this.assementListforJob = false;
          this.addAssessmentPage = false;
          this.JobDescriptionDash = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
          this.privateLinkCreationStep1 = false;
          this.listForJob = response.jobList;
          this.listCount = response.jobListCount;
          this.isLoadmore = response.isMoreList;
          this.listJobcreated = this.listJobcreated.concat(this.listForJob);
          this.deleteData = [];
          this.cleardata();
        },
        error => {
          this.noJobCreated = true;
          this.cretedJobList = true;
          this.listJobcreated = [];
          this.addAssessmentPage = false;
          this.JobDescriptionDash = false;
          this.isLoadmore = false;
          this.listCount = 0;
    });
  }

  publishListJob(data, invites, assessmentCount) {
    this.jodDetails.jobTitle = data.jobTitle;
    this.jodDetails.minExperience = data.minExperience;
    this.jodDetails.jobDescription = data.jobDescription;
    this.jodDetails.tags = data.tags;
    this.jodDetails.createdOn = data.createdOn;
    this.jodDetails.status = data.status;
    this.jodDetails._id = data._id;
    this.jodDetails.assessmentCount = assessmentCount;
    this.jodDetails.invites = invites;
    this.createdJobId = this.jodDetails._id;
    this.cretedJobList = false;
    this.JobDescriptionDash = true;
    this.JobDetailsData = false;
    this.assementListforJob = false;
    this.listOfLinkforJob = true;
    this.multipleDelete = false;
    this.listofInvitationLinkofJob(true);
  }

  deleteJobDescript(data) {
    this.deleteJobId = data;
    $('#deleteSingle').modal('show');
  }

  selectedDelete(event) {
    if (event.target.checked) {
      if (this.deleteData.indexOf(event.target.value) < 0) {
        this.deleteData.push(event.target.value);
      }
    } else {
      if (this.deleteData.indexOf(event.target.value) >= 0) {
        const index = this.deleteData.indexOf(event.target.value);
        this.deleteData.splice(index, 1);
      }
    }
    if (this.deleteData.length === 0) {
      this.multipleDelete = false;
    } else {
      this.multipleDelete = true;
    }
  }

  deleteMultipleAssessment() {
    $('#deleteMultiple').modal('show');
  }

  deleteJob(jobId) {
    const payload = { 'arrayData': [jobId] };
    this.jobService.removeJob(payload, this.companyId, this.accessToken).subscribe(
        response => {
          if (this.JobDescriptionDash) {
            this.toastrService.success('Job deleted successfully', '', {
              timeOut: 5000,
            });
          }
          if (this.cretedJobList) {
            this.toastrService.success('Job archived successfully', '', {
              timeOut: 5000,
            });
          }
          this.listOFJD(true);
        },
        error => {
          this.errorMsg = error.error.message;
    });

  }

  showJobsDetails(data, invites, assessmentCount) {
    this.editData = data;
    this.hideEditDelete = true;
    this.editAssesmentCount = assessmentCount;
    if (document.getElementById('description') != null) {
      document.getElementById('description').style.color = '#0ec2d0';
    }
    window.scroll(0, 0);
    this.jodDetails.jobTitle = data.jobTitle;
    this.jodDetails.minExperience = data.minExperience;
    this.jodDetails.jobDescription = data.jobDescription;
    this.jodDetails.tags = data.tags;
    this.jodDetails.createdOn = data.createdOn;
    this.jodDetails.status = data.status;
    this.jodDetails._id = data._id;
    this.jodDetails.assessmentCount = assessmentCount;
    this.jodDetails.invites = invites;
    this.createdJobId = this.jodDetails._id;
    if (this.proctor === 'proctor') {
      this.listofInvitationLinkofJob(true);
      this.candidatesLinkIDDetails();
    }
    this.createJobStep1 = false;
    this.noJobCreated = false;
    this.cretedJobList = false;
    this.JobDescriptionDash = true;
    this.JobDetailsData = true;
    this.listOfLinkforJob = false;
    this.multipleDelete = false;
    const payload = { 'jobId': this.jodDetails._id };
    this.jobService.listOfAssessForJob(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.getAssementList = response;
          this.listOfAssessmentforJob = response;
        },
        error => {
          this.errorMsg = error.error.message;
     });
  }

  nextOfJobCreation() {
    this.setWizard2 = true;
    const p = 100 / 2;
    $('#prog').width(p + '%');
    this.createWizard = true;
    this.setWizard2 = true;
    this.jobAssessmentCreation = false;
    this.createJobStep1 = false;
    if (this.editJob === true) {
      const payload = { 'jobId': this.jodDetails._id };
      this.addAssesment();
      this.jobService.listOfAssessForJob(payload, this.companyId, this.accessToken).subscribe(
          response => {
            this.listOfAssessmentforJob = response;
            if (this.listOfAssessmentforJob.length !== 0) {
              this.createJobs(false);
              this.assementListforJob = true;
              this.JobDetailsData = false;
              this.JobDescriptionDash = true;
              this.listOfLinkforJob = false;
              this.hideEditDelete = false;
              this.editJob = false;
              this.createWizard = false;
              this.jodDetails.jobDescription = this.Description;
            }
          },
          error => {
            this.errorMsg = error.error.message;
            this.createJobs(false);
            this.listOfAssessmentforJob = [];
            localStorage.setItem('jobId', this.jodDetails._id);
            this.jobAssessmentCreation = true;
            this.createJobStep1 = false;
            this.editJob = true;
       });
    } else {
      this.createJobs(false);
      localStorage.setItem('jobId', this.jodDetails._id);
      this.jobAssessmentCreation = true;
      this.createJobStep1 = false;
    }
  }

  addAssesment() {
    window.scroll(0, 0);
    this.jobService.addAssessment(this.companyId, this.accessToken).subscribe(
        response => {
          this.differentAssentType = response;
          this.AssessmentListAccnType(response[0].assessmentType, 0);
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  assessmentDetailsHeader() {
    if (document.getElementById('assesment') != null) {
      document.getElementById('description').style.color = '';
      document.getElementById('invite').style.color = '';
      document.getElementById('assesment').style.color = '#0ec2d0';
      document.getElementById('description').style.borderBottom = '';
      document.getElementById('invite').style.borderBottom = '';
      document.getElementById('assesment').style.borderBottom = '2px solid';
    }
    window.scroll(0, 0);
    this.selectedId = [];
    this.selectedData = [0, 0, 0, 0, 0, 0, 0];
    this.errorMsg = '';
    const payload = { 'jobId': this.jodDetails._id };
    this.jobService.listOfAssessForJob(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.getAssementList = response;
          this.listOfAssessmentforJob = response;
          this.assementListforJob = true;
          this.JobDetailsData = false;
          this.JobDescriptionDash = true;
          this.listOfLinkforJob = false;
          this.createWizard = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
          this.privateLinkCreationStep1 = false;
          this.assementCandiateReport = false;
          this.assessmentCandidatesDetails = false;
          this.CandidateReportDetails = false;
        },
        error => {
          this.assementListforJob = true;
          this.JobDetailsData = false;
          this.JobDescriptionDash = true;
          this.listOfLinkforJob = false;
          this.listOfAssessmentforJob = [];
          this.errorMsg = error.error.message;
    });
  }

  listofInvitationLinkofJob(check) {
    this.listMessage = false;
    if (document.getElementById('invite') != null) {
      document.getElementById('description').style.color = '';
      document.getElementById('invite').style.color = '#0ec2d0';
      document.getElementById('assesment').style.color = '';
      document.getElementById('description').style.borderBottom = '';
      document.getElementById('invite').style.borderBottom = '2px solid';
      document.getElementById('assesment').style.borderBottom = '';
    }
    window.scroll(0, 0);
    const payload = { 'referenceId': this.jodDetails._id };
    const skip = '' + this.skip2;
    if (check === true) {
      this.inviteListForJob = [];
      this.skip2 = 0;
    } else {
      this.skip2++;
    }
    this.jobService.listOfLinkForJob(this.skip2, payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.getList = response.message;
          this.CandidateReportDetails = false;
          this.listOfLinkforJob = true;
          this.JobDescriptionDash = true;
          this.assementListforJob = false;
          this.JobDetailsData = false;
          this.createWizard = false;
          this.cretedJobList = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
          this.privateLinkCreationStep1 = false;
          this.assementCandiateReport = false;
          this.assessmentCandidatesDetails = false;
          this.publicLinkcreateion = response.linkUrl;
          this.isLoadmore2 = response.isMoreList;
          this.inviteListForJob = this.inviteListForJob.concat(this.getList);
        },
        error => {
          this.errorMsg = error.error.message;
          this.inviteListForJob = [];
          this.listOfLinkforJob = true;
          this.assementListforJob = false;
          this.JobDetailsData = false;
          this.listMessage = true;
    });
  }

  assessmentDescriptionHeader() {
    if (document.getElementById('description') != null) {
      document.getElementById('description').style.color = '#0ec2d0';
      document.getElementById('invite').style.color = '';
      document.getElementById('assesment').style.color = '';
      document.getElementById('description').style.borderBottom = '2px solid';
      document.getElementById('invite').style.borderBottom = '';
      document.getElementById('assesment').style.borderBottom = '';
    }
    this.createWizard = false;
    this.assementListforJob = false;
    this.listOfLinkforJob = false;
    this.JobDetailsData = true;
    this.listOfAssessmentforJob = [];
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
    this.assementCandiateReport = false;
    this.assessmentCandidatesDetails = false;
    this.CandidateReportDetails = false;
  }

  addexistingAssesment() {
    this.addAssesment();
    const p = 100 / 2;
    $('#prog').width(p + '%');
    if (this.createWizard === true) {
      this.jobAssessmentCreation = true;
    } else {
      this.jobAssessmentCreation = false;
    }
    this.checkForExiting = true;
    this.listOfLinkforJob = false;
    this.cretedJobList = false;
    this.noJobCreated = false;
    $('#selectionOfJob').modal('show');
  }

  addMoreAssessment() {
    this.addAssesment();
    const p = 100 / 2;
    $('#prog').width(p + '%');
    this.checkForExiting = true;
    this.listOfLinkforJob = false;
    this.cretedJobList = false;
    this.noJobCreated = false;
    $('#selectionOfJob').modal('show');
  }

  AssessmentListAccnType(assesstype, i: number) {
    this.errorMsg = '';
    window.scroll(0, 0);
    this.index = Number(i);
    const payload = {
      'jobId': this.jodDetails._id,
      'assType': assesstype
    };
    this.jobService.particularAssesmentType(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.selectedAssesmntType = response.message;
        },
        error => {
          this.errorMsg = error.error.message;
          this.selectedAssesmntType = [];
          this.index = 0;
    });
  }

  selectedIdOfassessment(event) {
    if (event.target.checked) {
      if (this.selectedId.indexOf(event.target.value) < 0) {
        this.selectedId.push(event.target.value);
        this.selectedData[this.index]++;
        this.somOfAddedAssesments++;
      }
    } else {
      if (this.selectedId.indexOf(event.target.value) >= 0) {
        const index = this.selectedId.indexOf(event.target.value);
        this.selectedId.splice(index, 1);
        this.selectedData[this.index]--;
        this.somOfAddedAssesments--;
      }
    }

  }

  checkCheckBox(id) {
    for (let i = 0; i < this.selectedId.length; i++) {
      if (this.selectedId[i] === id) {
        return true;
      }
    }
    return false;
  }

  checkDeletBox(id) {
    for (let i = 0; i < this.deleteData.length; i++) {
      if (this.deleteData[i] === id) {
        return false;
      }
    }
  }

  assessmentDetailsById() {
    window.scroll(0, 0);
    this.jobAssessmentCreation = false;
    if (this.assementListforJob === true) {
      for (let i = 0; i < this.selectedId.length; i++) {
        this.sequence[i] = i + '';
      }
      const payload1 = {
        'jobId': this.jodDetails._id,
        'assessmentId': this.selectedId,
        'sequence': this.sequence
      };
      this.jobService.addAssessForJob(payload1, this.companyId, this.accessToken).subscribe(
          response => {
            this.assessmentDetailsHeader();
            this.toastrService.success('Assessment(s) added successfully', '', {
              timeOut: 5000,
            });
          },
          error => {
            // this.publicerrorMsg=error.error.message;
      });
    } else {
      this.assementListforJob = false;
      this.JobDescriptionDash = false;
      for (let i = 0; i < this.selectedId.length; i++) {
        const payload = { 'assessmentId': this.selectedId[i] };
        if (this.checkForExiting === true) {
          this.selectedassessmentbyId = [];
        }
        this.jobService.assessmentDetailsById(payload, this.companyId, this.accessToken).subscribe(
            response => {
              const responseDetails = response.message[0];
              this.selectedassessmentbyId.push(responseDetails);
              this.addAssessmentPage = true;
              this.createJobStep1 = false;
              this.createWizard = true;
              this.somOfAddedAssesments++;
            },
            error => {
              this.errorMsg = error.error.message;
              this.selectedAssesmntType = [];
              this.index = 0;
        });
      }
    }
  }

  closeExitingModel() {
    if (this.addAssessmentPage === true) {
      $('#selectionOfJob').modal('hide');
    } else {
      this.selectedId = [];
      this.selectedData = [0, 0, 0, 0, 0, 0, 0];
      this.AssessmentListAccnType(this.differentAssentType[0].assessmentType, 0);
      $('#selectionOfJob').modal('hide');
    }
  }

  addAssessForJob(check) {
    this.listForJob = [];
    this.listJobcreated = [];
    for (let i = 0; i < this.selectedId.length; i++) {
      this.sequence[i] = i + '';
    }
    const payload = {
      'jobId': this.jodDetails._id,
      'assessmentId': this.selectedId,
      'sequence': this.sequence
    };
    this.jobService.addAssessForJob(payload, this.companyId, this.accessToken).subscribe(
        response => {
          if (check === true) {
            this.listOFJD(true);
            this.toastrService.success('Assessment(s) added successfully', '', {
              timeOut: 5000,
            });
            // this.createJobs(true)
          }
        },
        error => {
          // this.publicerrorMsg=error.error.message;
          this.dateandTimeErrorcheck = false;
    });
    this.createJobStep1 = true;
  }

  invitationLinkByHeader(check) {
    this.publicerrorMsg = '';
    this.errorMsg = '';
    this.publishedLink = check;
    this.privateInviteStatus = 'Active';
    this.publicInviteStatus = 'Active';
    this.hideEditDelete = false;
    const p = 3 * (100 / 3);
    $('#prog').width(p + '%');
    localStorage.setItem('createLinkForJob', 'job');
    localStorage.setItem('jobId', this.jodDetails._id);
    localStorage.removeItem('clickAssesment');
    this.publicLinkCreate();
  }

  createNewAssesment() {
    localStorage.setItem('jobId', this.jodDetails._id);
    localStorage.setItem('createAssessmentForJob', 'create');
    localStorage.removeItem('clickAssesment');
    $('#selectionOfJob').modal('hide');
    this.router.navigate(['assessment']);
  }

  editAssesmentThroughID(assessID) {
    localStorage.setItem('EditassessmentIdForJob', assessID);
    localStorage.setItem('jobId', this.jodDetails._id);
    localStorage.setItem('editAssess', 'edited');
    localStorage.removeItem('clickAssesment');
    this.router.navigate(['assessment']);
  }

  editDetails(data, assessmentCount) {
    window.scroll(0, 0);
    this.customFields = [this.customeField];
    this.editJob = true;
    this.jodDetails._id = data._id;
    this.jobTittle = data.jobTitle;
    this.Experience = data.minExperience;
    this.Description = data.jobDescription;
    this.customFields = data.customFields;
    this.jodDetails.jobTitle = data.jobTitle;
    this.jodDetails.minExperience = data.minExperience;
    this.jodDetails.createdOn = data.createdOn;
    this.jodDetails.assessmentCount = assessmentCount;
    for (let j = 0; j < data.tags.length; j++) {
      for (let i = 0; i < this.dropdownList.length; i++) {
        if (this.dropdownList[i].itemName === data.tags[j]) {
          this.selectedItems.push(this.dropdownList[i]);
        }
      }
    }
    this.startcreateJobs();
  }

  cleardata() {
    this.jobTittle = '';
    this.Experience = '';
    this.selectedItems = [];
    this.selectedItem = [];
    this.Description = '';
    this.customeField = {
      'fieldName': '',
      'fieldValue': ''
    };
    this.customFields = [this.customeField];
    this.selectedId = [];
    this.selectedData = [0, 0, 0, 0, 0, 0, 0];
    this.editJob = false;
    this.selectedassessmentbyId = [];
    this.modalLink.reset();
    this.privateInviteName = '';
    this.privateInviteStatus = 'Active';
    this.privateBlockIpAddress = '';
    this.privateTags = '';
    this.privateStartDate = '';
    this.privateStartTime = '';
    this.privateEndDate = '';
    this.privateEndTime = '';
    this.privateAuthanticationOTP = false;
    this.privateMessage = '';
    this.privateSubject = '';
    this.publicInviteName = '';
    this.publicInviteStatus = 'Active';
    this.publicBlockIpAddress = '';
    this.publicTags = '';
    this.publicStartDate = '';
    this.publicStartTime = '';
    this.publicEndDate = '';
    this.publicEndTime = '';
    this.publicAuthanticationOTP = false;
    this.inviteCheck = false;
    this.CandidateReportDetails = false;
    this.TrackLocationForLink = false;
    this.IDProofForLink = false;
    this.candidateSnapshotForLink = false;
    this.candidateAttendanceLink = false;
    this.candidateProctor = false;
    this.candidatesDetails = [this.candidatesDetail];
    this.byLink = false;
    this.editCandidates = false;
    this.setWizard3 = false;
    this.setWizard2 = false;
    this.linkPrivate = false;
  }

  removeField(i) {
    if (this.customFields.length > 1) {
      this.customFields.splice(i, 1);
    } else {
      return;
    }
  }

  getData(event): void { }

  LinkCreation(modalLink) {
    this.errorMsg = '';
    this.publicerrorMsg = '';
    this.publicENDDate = '';
    this.publicSTARTDate = '';
    this.publicStartTime = '';
    this.publicEndTime = '';
    this.setWizard3 = true;
    if (this.publishedLink === true) {
      this.addAssessForJob(false);
      this.publishedLink = false;
    }
    const jobId = localStorage.getItem('jobId');
    if (modalLink === 'private') {
      this.linkPrivate = true;
      this.linkPublic = false;
      setTimeout(function () {
        myExtObject.loadFlagId(0);
      });
      this.privateLink = true;
      this.publicLink = false;
    } else {
      this.testCodeGeneration();
      this.publicLink = true;
      this.privateLink = false;
      this.linkPublic = true;
      this.linkPrivate = false;
    }
  }

  deleteAssessment(assessmentId) {
    this.deleteAssessmentId = assessmentId;
    $('#deleteAssessment').modal('show');
  }

  cancelButton() {
    this.listOFJD(true);
    this.multipleDelete = false;
  }

  loadMoreJobList() {
    this.skip++;
    this.listForJob = [];
    this.listOFJD(false);
  }

  deleteLink(linkID) {
    this.deleteLinkId = linkID;
    $('#deleteLink').modal('show');
  }

  deleteMultipleMessage() {
    const payload = { 'arrayData': this.deleteData };
    this.jobService.removeJob(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.listOFJD(true);
          this.multipleDelete = false;
          this.deleteData = [];
          this.toastrService.success('Job(s) deleted successfully', '', {
            timeOut: 5000,
          });
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  deleteMultipleNo() {
    this.listOFJD(true);
    this.deleteData = [];
    this.multipleDelete = false;
  }

  deletesingleMessage() {
    this.deleteJob(this.deleteJobId);
  }

  deleteAssessmentMessage() {
    const payload = {
      'jobId': this.jodDetails._id,
      'assessmentId': this.deleteAssessmentId
    };
    this.jobService.assessmentDelete(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.deleteAssessmentId = '';
          this.assessmentDetailsHeader();
          this.toastrService.success('Assessment deleted successfully', '', {
            timeOut: 5000,
          });
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  deleteLinkMessage() {
    const payload = { 'linkId': this.deleteLinkId };
    this.jobService.deleteLink(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.listofInvitationLinkofJob(true);
          this.deleteLinkId = '';
          this.toastrService.success('Link archived successfully', '', {
            timeOut: 5000,
          });
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  testCodeGeneration() {
    this.assessmentService.testCodeGenerate(this.companyId, this.accessToken).subscribe(
        response => {
          this.Testcode = response.message;
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  privateLinkCreate() {
    const p = 3 * (100 / 3);
    $('#prog').width(p + '%');
    window.scroll(0, 0);
    this.privateInviteStatus = 'Active';
    this.publicInviteStatus = 'Active';
    this.addAssessmentPage = false;
    this.JobDescriptionDash = false;
    this.listOfLinkforJob = false;
    this.cretedJobList = false;
    this.noJobCreated = false;
    this.assementListforJob = false;
    this.jobAssessmentCreation = false;
    this.jobAssessmentCreation = false;
    this.JobDetailsData = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = true;
    this.privateLinkCreationStep2 = false;
  }

  resetFile() {
    this.myInputVariable.nativeElement.value = '';
  }

  publicLinkCreate() {
    this.publicSTARTDate = { date: '' };
    this.publicENDDate = { date: '' };
    const p = 3 * (100 / 3);
    $('#prog').width(p + '%');
    window.scroll(0, 0);
    if (!this.editCandidates) {
      this.testCodeGeneration();
    }
    this.privateInviteStatus = 'Active';
    this.publicInviteStatus = 'Active';
    this.addAssessmentPage = false;
    this.JobDescriptionDash = false;
    this.listOfLinkforJob = false;
    this.cretedJobList = false;
    this.noJobCreated = false;
    this.assementListforJob = false;
    this.jobAssessmentCreation = false;
    this.jobAssessmentCreation = false;
    this.JobDetailsData = false;
    this.publicLinkCreation = true;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
  }

  privateLinkStep1() {
    window.scroll(0, 0);
    setTimeout(function () {
      myExtObject.loadFlagId(0);
    });
    for (var i = 0; i < this.candidatesDetails.length; i++) {
      $('a').click();
      $('#phone-' + i).click();
      setTimeout(function () {
        myExtObject.loadFlagId(i);
      });
    }
    this.dateandTimeError = '';
    this.errorMsg = '';
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (this.privateStartDate !== '' && this.privateEndDate === '') {
      this.startDate = this.privateStartDate.split('-');
      if (this.startDate[0] < yyyy) {
        this.dateandTimeError = 'please choose valid year';
        return;
      } else if (this.startDate[1] < mm) {
        this.dateandTimeError = 'please choose valid Month';
        return;
      } else if (this.startDate[2] < dd) {
        this.dateandTimeError = 'please choose valid Day';
        return;
      } else {
        this.dateandTimeError = '';
      }
    } else if (this.privateStartDate === '' && this.privateEndDate !== '') {
      this.endDate = this.privateEndDate.split('-');
      if (this.endDate[0] < yyyy) {
        this.dateandTimeError = 'please choose valid year';
        return;
      } else if (this.endDate[1] < mm) {
        this.dateandTimeError = 'please choose valid Month';
        return;
      } else if (this.endDate[2] < dd) {
        this.dateandTimeError = 'please choose valid Day';
        return;
      } else {
        this.dateandTimeError = '';
      }
    } else if (this.privateStartDate !== '' && this.privateEndDate !== '') {
      this.startDate = this.privateStartDate.split('-');
      this.endDate = this.privateEndDate.split('-');
      if (this.startDate[0] < yyyy || this.endDate[0] > 2020) {
        this.dateandTimeError = 'please choose valid year';
        return;
      }
      if ((this.startDate[0] === yyyy && this.startDate[1] < mm) || (this.endDate[1] < mm && this.endDate[0] === yyyy)) {
        this.dateandTimeError = 'please choose valid Month';
        return;
      }
      if ((this.startDate[0] === yyyy && this.endDate[0] === yyyy ) && ( this.startDate[1] === mm && this.endDate[1] === mm )
        && (this.startDate[2] < dd || this.endDate[2] < dd)) {
        this.dateandTimeError = 'please choose valid Day';
        return;
      } else if (this.endDate[0] < this.startDate[0]) {
        this.dateandTimeError = 'please choose valid year';
        return;
      } else if (this.startDate[0] === this.endDate[0] && this.startDate[1] > this.endDate[1]) {
        this.dateandTimeError = 'please choose valid Month';
        return;
      } else if (this.startDate[0] === this.endDate[0] && this.startDate[1] === this.endDate[1] && this.startDate[2] > this.endDate[2]) {
        this.dateandTimeError = 'please choose valid Day';
        return;
      } else {
        this.dateandTimeError = '';
      }
    }
    this.JobDescriptionDash = false;
    this.listOfLinkforJob = false;
    this.cretedJobList = false;
    this.noJobCreated = false;
    this.assementListforJob = false;
    this.jobAssessmentCreation = false;
    this.jobAssessmentCreation = false;
    this.JobDetailsData = false;
    this.privateLinkCreationStep2 = true;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
  }

  sendInvitation() {
    this.errorMsg = '';
    this.candidateDetails = [];
    this.candidatDetailsChecked = [];
    let name, email, mobile, countryCode, endDateTime;
    this.publicEndTime = $('#endTime').val();
    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
    }
    if (this.inviteByFileUpload.length !== 0) {
      for (let i = 0; i < this.inviteByFileUpload.length; i++) {
        const obj1 = {
          'name': this.inviteByFileUpload[i].name,
          'email': this.inviteByFileUpload[i].email,
          'mobile': this.inviteByFileUpload[i].mobile,
          'countryCode': this.inviteByFileUpload[i].countryCode
        };
        this.candidateDetails.push(obj1);
      }
    }
    if (this.candidatesDetails.length > 0) {
      for (let i = 0; i < this.candidatesDetails.length; i++) {
        name = $('#canname-' + i).val();
        email = $('#email-' + i).val();
        mobile = $('#phone-' + i).val();
        countryCode = '+91';
        const obj = {
          'name': $('#canname-' + i).val(),
          'email': $('#email-' + i).val(),
          'mobile': $('#phone-' + i).val(),
          'countryCode': '+91'
        };
        if (obj.name !== '' || obj.email !== '' || obj.mobile !== '') {
          this.candidateDetails.push(obj);
        }
        if (this.candidatDetailsChecked.indexOf(mobile) < 0) {
          this.candidatDetailsChecked.push(mobile);
          $('#phone-' + i).css('border-color', '');
          $('#duplicate-' + i).html('');
          this.errorMsg = '';
        } else {
          $('#phone-' + i).css('border-color', 'red');
          $('#duplicate-' + i).html('mobile number is duplicate');
          this.errorMsg = 'you filled duplicate candidate entry';
          return;
        }
      }
    }

    if (this.candidateDetails.length === 0) {
      this.errorMsg = 'Add the Candidates';
      return;
    }
    if (!this.addprivate) {
      const payload = {
        'linkFor': 'job',
        'referenceId': this.jodDetails._id,
        'linkName': this.publicInviteName,
        'linkType': 'private',
        'status': this.publicInviteStatus,
        'subject': this.privateSubject,
        'message': '',
        'candidates': this.candidateDetails,
        'configuration': {
          'startDate': this.startDateTime,
          'endDate': endDateTime,
          'timeZone': 'IST',
          'loginTypeOtp': (this.publicAuthanticationOTP === true ? true : false),
          'captureAttendanceSnapshot': this.candidateAttendanceLink,
          'captureCandidateSnapshot': this.candidateSnapshotForLink,
          'idVerification': this.IDProofForLink,
          'trackGeoLocation': this.TrackLocationForLink,
          'proctor': this.candidateProctor,
          'uploadImage': this.imageUploadForLink,
          'screenFreeze': this.selectScreenFreezeForLink
        }
      };

      this.assessmentService.privateLinkInvitation(payload, this.companyId, this.accessToken)
        .subscribe(
          response => {
            this.candidatesDetails = [];
            this.privateLinkCreationStep2 = false;
            this.candidatesDetail.candidatesName = '';
            this.candidatesDetail.email = '';
            this.candidatesDetail.phoneNo = '';
            this.candidatesDetail.countryCode = '';
            this.candidatesDetails.push(this.candidatesDetail);
            this.candidatesData = {
              'candidatesName': '',
              'email': '',
              'phoneNo': 'getNumber',
              'countryCode': '+91'
            };
            this.inviteByFileUpload = [];
            this.file = [];
            this.candidateData = [this.candidatesData];
            this.listofInvitationLinkofJob(true);
            this.isUpload = false;
            this.toastrService.success('Test link created successfully.', '', {
              timeOut: 5000,
            });
            this.candidatesDetailList = response.linkDetails.candidates;
            this.cleardata();
             this.ngOnInit();
          },
          error => {
            this.candidateDetails = [];
            this.errorMsg = error.error.message;
      });
    } else {
      const Payload = {
        'linkId': this.CandidatelinkId,
        'candidates': this.candidateDetails
      };
      this.assessmentService.privateAddEditCandidates(Payload, this.companyId, this.accessToken)
        .subscribe(
          response => {
            this.privateLinkCreationStep2 = false;
            this.candidateData = [this.candidatesData];
            this.candidatesDetails = [];
            this.candidatesDetails.push(this.candidatesDetail);
            this.inviteByFileUpload = [];
            this.toastrService.success('Candidates added successfully', '', {
              timeOut: 5000,
            });
            this.candidatesDetailList = response.linkInfo.candidates;
            this.candidateReportLinkId(this.candidateListValue, true);
            this.cleardata();
          },
          error => {
            this.candidateDetails = [];
            this.errorMsg = error.error.message;
            this.dateandTimeErrorcheck = false;
      });
    }
  }

  publicAssessmentLink() {
    this.publicerrorMsg = '';
    let endDateTime;
    this.errorMsg = '';
    this.publicEndTime = $('#endTime').val();
    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
    }
    const payload = {
      'linkFor': 'job',
      'referenceId': this.jodDetails._id,
      'linkName': this.publicInviteName,
      'linkType': 'public',
      'subject': '',
      'status': this.publicInviteStatus,
      'testCode': this.Testcode + '',
      'message': '',
      'configuration': {
        'startDate': this.startDateTime,
        'endDate': endDateTime,
        'timeZone': 'IST',
        'loginTypeOtp': (this.publicAuthanticationOTP === true ? true : false),
        'captureAttendanceSnapshot': this.candidateAttendanceLink,
        'captureCandidateSnapshot': this.candidateSnapshotForLink,
        'idVerification': this.IDProofForLink,
        'trackGeoLocation': this.TrackLocationForLink,
        'proctor': this.candidateProctor,
        'screenFreeze': this.selectScreenFreezeForLink
      }
    };

    this.assessmentService.privateLinkInvitation(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.publicLinkcreateion = response.link;
          this.Testcode = response.testCode;
          $('#publicLink').modal('show');
          this.cleardata();
          this.toastrService.success('Test link created successfully.', '', {
            timeOut: 5000,
          });
        },
        error => {
          this.publicerrorMsg = error.error.message;
          this.dateandTimeErrorcheck = false;
    });
  }

  fileChangeEvent(fileInput) {
    this.file = fileInput.target.files;
    this.fileUploadMsg = false;
    const filenameArray = this.file[0].name.split('.');
    const fileType = filenameArray[filenameArray.length - 1];
    if (fileType === 'xls' || fileType === 'xlsx') {
      this.isUpload = true;
    } else {
      this.isUpload = false;
      this.fileUploadMsg = true;
      this.uploaderrorMsg = 'Upload xlsx file only.';
    }
    this.assessmentService.uploadInvites(this.companyId, this.accessToken, this.file).subscribe(
        response => {
          this.inviteByFileUpload = response.message;
        },
        error => {
          this.publicerrorMsg = error.error.message;
    });
  }

  previewCandidates() {
    $('#previewCandidtesList').modal('show');
  }

  removeCandidatesDetails(index) {
    if (this.candidatesDetails.length > 1) {
      this.candidatesDetails.splice(index, 1);
      this.candidatDetails.splice(index, 1);
      if (this.candidatesDetails.length === 1) {
        this.candidatesADD = false;
      }
    } else {
      this.candidatesADD = false;
      return;
    }
  }

  addCandidateDetails(index, k) {
    this.candidatDetailsChecked = [];
    let name, email, phone, countryCode;
    let err = false;
    this.errorMessage = false;
    const len = this.candidatesDetails.length;
    for (var i = 0; i < len; i++) {
      setTimeout(function () {
        myExtObject.loadFlagId(i);
      });
      countryCode = myExtObject.getCountryCode();
      const counttryDeatilsArray = countryCode.split(':');
      if (counttryDeatilsArray != null && counttryDeatilsArray.length > 0) {
        countryCode = counttryDeatilsArray[1];
        countryCode = countryCode.trim();
      }
      this.countryCode = countryCode;
      name = $('#canname-' + i).val();
      email = $('#email-' + i).val();
      phone = $('#phone-' + i).val();
      countryCode = countryCode;
      if (name === '' || email === '' || phone === '' || countryCode === '') {
        err = true;
        this.errorMessage = true;
        return;
      }
      if (this.candidatDetailsChecked.indexOf(phone) < 0) {
        this.candidatDetailsChecked.push(phone);
        $('#phone-' + i).css('border-color', '');
        $('#duplicate-' + i).html('');
        this.errorMsg = '';
      } else {
        $('#phone-' + i).css('border-color', 'red');
        $('#duplicate-' + i).html('mobile number is duplicate');
        return;
      }
    }

    if (!err) {
      const objec = {
        'candidatesName': '',
        'email': '',
        'phoneNo': '',
        'countryCode': countryCode + ''
      };
      this.candidateData.push(objec);
      this.candidatesADD = true;
      this.candidatesDetail.candidatesName = '';
      this.candidatesDetail.email = '';
      this.candidatesDetail.phoneNo = '';
      this.candidatesDetail.countryCode = '';
      this.candidatesDetails.push(this.candidatesDetail);
    }
  }

  addAssessmentTestLink() {
    const selBox = document.createElement('textarea');
    selBox.value = this.testLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    document.getElementById('remo').style.color = '#0ec2d0';
  }

  addUserInfo() {
    const selBox = document.createElement('textarea');
    selBox.value = this.userInfo;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    document.getElementById('remo1').style.color = '#0ec2d0';
  }

  copyLink() {
    const copyText = document.getElementById('linkGeneration') as HTMLInputElement;
    copyText.select();
    document.execCommand('copy');
  }

  addCandidates() {
    this.candidatDetails = [];
    const obj = {
      'candidateName': '',
      'email': '',
      'phone': '',
      'countryCode': ''
    };
    this.candidatDetails.push(obj);
    this.byLink = true;
    this.addprivate = true;
    this.candidateDetails = [];
    this.candidatesDetails = [];
    this.candidatesDetails = [this.candidatesDetail];
    this.privateLinkStep1();
  }

  redirectToPlace() {
    this.publicerrorMsg = '';
    if (this.createWizard === true) {
      this.listOFJD(true);
    } else {
      this.JobDescriptionDash = true;
      this.privateLinkCreationStep2 = false;
      this.publicLinkCreation = false;
      this.privateLinkCreationStep1 = false;
      this.listofInvitationLinkofJob(true);
    }
    this.cleardata();
  }

  viewCandidates(data) {
    this.checkBoxSelect = [];
    this.deleteData = [];
    this.inviteCandidates = data.candidates;
    this.addLinkID = data._id;
    this.multipleDelete = false;
    $('#viewCandidtesList').modal('show');
  }

  ViewAccessCode(data) {
    this.Testcode = data.testCode;
    $('#publicLink').modal('show');
  }

  removeModal() {
    if (this.checkBoxSelect[(this.currentIndex - 1)] === false) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.deleteData.indexOf(this.items[i].mobile) < 0) {
          this.deleteData.push(this.items[i].mobile);
        }
      }
      this.checkBoxSelect[(this.currentIndex - 1)] = true;
    } else {
      for (let i = 0; i < this.items.length; i++) {
        if (this.deleteData.indexOf(this.items[i].mobile) >= 0) {
          const k = this.deleteData.indexOf(this.items[i].mobile);
          this.deleteData.splice(k, 1);
        }
      }
      this.checkBoxSelect[(this.currentIndex - 1)] = false;
    }
    if (this.deleteData.length === 0) {
      this.multipleDelete = false;
    } else {
      this.multipleDelete = true;
    }
  }

  checkCheckBox1(id) {
    if (this.deleteData.indexOf(id) >= 0) {
      return true;
    }
    return false;
  }

  addPreviewCandidates() {
    this.JobDescriptionDash = false;
    this.listOfLinkforJob = false;
    this.candidatesDetails = [this.candidatesDetail];
    this.byLink = true;
    this.addprivate = true;
    this.inviteCheck = true;
    this.multipleDelete = false;
    this.privateLinkStep1();
  }

  deleteMultipleCandidates() {
    const payload = {
      'linkId': this.addLinkID,
      'deleteCandidate': true,
      'deleteCandidateList': this.deleteData
    };
    this.assessmentService.privateAddEditCandidates(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.multipleDelete = false;
          this.listofInvitationLinkofJob(true);
          $('#viewCandidtesList').modal('show');
          if (response.candidates == null) {
            this.inviteCandidates = [];
          } else {
            this.inviteCandidates = response.candidates;
          }
        },
        error => {
          this.candidateDetails = [];
          this.errorMsg = error.error.message;
    });
  }

  selectedDeleteCan(event1, event) {
    let count = 0;
    if (event1.target.checked) {
      if (this.deleteData.indexOf(event) < 0) {
        this.deleteData.push(event);
      }
    } else {
      if (this.deleteData.indexOf(event) >= 0) {
        const index = this.deleteData.indexOf(event);
        this.deleteData.splice(index, 1);
      }
    }
    for (let i = 0; i < this.items.length; i++) {
      if (this.deleteData.indexOf(this.items[i].mobile) >= 0) {
        count = 1;
      }
    }
    if (count === 0) {
      this.checkBoxSelect[(this.currentIndex - 1)] = false;
    }
    if (this.deleteData.length === 0) {
      this.multipleDelete = false;
    } else {
      this.multipleDelete = true;
    }
  }

  publishAssessmentLink(modalLink: any, candidatChecked) {
    if (candidatChecked && this.errorMsg !== '') {
      $('#publishPopup').modal('show');
      this.modalValue = modalLink;
    } else {
      this.publicStartTime = $('#startTime').val();
      if (this.publicStartDate === '') {
        this.startDateTime = '';
      } else {
        this.startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
      }
      if (this.publicStartTime === '' && this.publicStartDate !== '') {
        this.startDateTime = this.publicStartDate + 'T' + '00:00:00';
      }
      if (modalLink.link === this.private) {
        this.sendInvitation();
        if (this.errorMsg !== null || this.errorMsg !== '') {
          return;
        }
      } else {
        this.publicAssessmentLink();
      }
    }
  }

  editLink(data) {
    if (data.configuration.screenFreeze === true) {
      this.selectScreenFreezeForLink = true;
    }
    if (data.configuration.uploadImage === true) {
      this.imageUploadForLink = true;
    }
    if (data.configuration.proctor === true) {
      this.candidateMandidate = true;
      this.linkPublic = false;
    }
    this.hideEditDelete = false;
    this.publicerrorMsg = '';
    this.editCandidates = true;
    this.editedLinkType = data.linkType;
    this.editedLinkID = data._id;
    let startDate, endDate, startMonth, endMonth, startDay, endDay;
    this.publicerrorMsg = '';
    this.publicStartTime =  '';
    this.publicSTARTDate = '';
    this.publicENDDate = '';
    this.publicEndTime = '';
    this.editedErrMsg = '';
    const startDateTime = data.configuration.startDate;
    const endDateTime = data.configuration.endDate;
    if (startDateTime !== '') {
      startDate = startDateTime.split('T');
      this.publicStartDate = startDate[0];
      if (startDate[1] !== '00:00:00' && startDate[1] !== '') {
        this.publicStartTime = startDate[1].substring(0, startDate[1].length - 3);
      }
    } else {
      this.publicStartDate = '';
    }
    if (endDateTime !== '') {
      endDate = endDateTime.split('T');
      this.publicEndDate = endDate[0];
      if (endDate[1] !== '00:00:00' && endDate[1] !== '') {
        this.publicEndTime = endDate[1].substring(0, endDate[1].length - 3);
      }
    } else {
      this.publicEndDate = '';
    }
    if (data.linkType === 'PUBLIC') {
      this.publicLinkCreate();
      this.linkPublic = true;
    } else {
      this.privateLinkCreate();
      this.linkPublic = false;
    }
    if (startDateTime !== '') {
      if (startDate[0].substring(5, 7).charAt(0) === 0) {
        startMonth = startDate[0].substring(6, 7);
      } else {
        startMonth = startDate[0].substring(5, 7);
      }
      if (startDate[0].substring(8, 10).charAt(0) === 0) {
        startDay = startDate[0].substring(9, 10);
      } else {
        startDay = startDate[0].substring(8, 10);
      }
      this.publicSTARTDate = {
        date: { year: startDate[0].substring(0, 4) ,
          month: startMonth,
          day: startDay
        }
      };
    }
    if (endDateTime !== '') {
      if (endDate[0].substring(5, 7).charAt(0) === 0) {
        endMonth = endDate[0].substring(6, 7);
      } else {
        endMonth = endDate[0].substring(5, 7);
      }
      if (endDate[0].substring(8, 10).charAt(0) === 0) {
        endDay = endDate[0].substring(9, 10);

      } else {
        endDay = endDate[0].substring(8, 10);
      }

      this.publicENDDate = {
        date: {
          year: endDate[0].substring(0, 4) ,
          month: endMonth,
          day: endDay
        }
      };
    }
    this.byLink = true;
    this.editCandidates = true;
    this.editedLinkType = data.linkType;
    this.editedLinkID = data._id;
    this.publicInviteName = data.linkName;
    this.publicBlockIpAddress = '';
    this.publicAuthanticationOTP = data.configuration.loginTypeOtp;
    this.candidateAttendanceLink = data.configuration.captureAttendanceSnapshot;
    this.candidateSnapshotForLink = data.configuration.captureCandidateSnapshot;
    this.IDProofForLink = data.configuration.idVerification;
    this.TrackLocationForLink = data.configuration.trackGeoLocation;
    this.candidateProctor = data.configuration.proctor;
    this.publicInviteStatus = data.status;
    this.Testcode = data.testCode;
  }

  editedLink() {
    let endDateTime, startDateTime;
    this.publicStartTime = $('#startTime').val();
    this.publicEndTime = $('#endTime').val();
    if (this.publicStartDate === '') {
      startDateTime = '';
    } else {
      startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
    }
    if (this.publicStartTime === '' && this.publicStartDate !== '') {
      startDateTime = this.publicStartDate + 'T' + '00:00:00';
    }
    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
    }
    const payload = {
      'linkId': this.editedLinkID,
      'linkName': this.publicInviteName,
      'isEditLink': true,
      'status': this.publicInviteStatus,
      'configuration': {
        'startDate': startDateTime,
        'endDate': endDateTime,
        'timeZone': 'IST',
        'loginTypeOtp': this.publicAuthanticationOTP,
        'idVerification': this.IDProofForLink,
        'captureAttendanceSnapshot': this.candidateAttendanceLink,
        'captureCandidateSnapshot': this.candidateSnapshotForLink,
        'trackGeoLocation': this.TrackLocationForLink,
        'proctor': this.candidateProctor,
        'uploadImage': this.imageUploadForLink,
        'screenFreeze': this.selectScreenFreezeForLink
      }
    };
    this.assessmentService.privateAddEditCandidates(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.listofInvitationLinkofJob(true);
          this.toastrService.success('Test link edited successfully.', '', {
            timeOut: 5000,
          });
          this.editCandidates = false;
          this.editedLinkID = '';
          this.privateInviteName = '';
          this.publicInviteName = '';
          this.privateAuthanticationOTP = false;
          this.IDProofForLink = false;
          this.candidateSnapshotForLink = false;
          this.candidateAttendanceLink = false;
          this.publicStartTime = '';
          this.publicSTARTDate = '';
          this.publicENDDate = '';
          this.publicEndTime = '';
          this.editedErrMsg = '';
          $('#startTime').val('');
          $('#endTime').val('');
          this.ngOnInit();
        },
        error => {
          this.editedErrMsg = error.error.message;
          this.publicerrorMsg = error.error.message;
    });
  }

  cadidatesReports(data, status) {
    if (status === 'Not Taken') {
      return;
    }
    this.CandidateData = data;
    this.mobileNum = data.mobile;
    this.countryCodeNum = data.countryCode;
    const payload = {
      'linkId': this.CandidatelinkId,
      'mobile': this.mobileNum,
      'countryCode': this.countryCodeNum
    };
    this.assessmentService.cadidatesReports(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.CandidateReportData = response.results;
          const len = this.CandidateReportData[0].assessmentVersions.length;
          this.CandidateReportDetails = true;
          this.listOfLinkforJob = false;
          this.JobDescriptionDash = true;
          this.assementListforJob = false;
          this.JobDetailsData = false;
          this.createWizard = false;
          this.cretedJobList = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
          this.privateLinkCreationStep1 = false;
          this.assementCandiateReport = false;
          this.assessmentCandidatesDetails = false;
        },
        error => {
          this.errorMsg = error.error.message;
          this.CandidateReportDetails = false;
    });
  }

  showMoreInfo(check, i, status) {
    if (status === 'Not Taken' || status === 'InComplete') {
      return;
    }
    if (check === true) {
      $('#moreInfo-' + i).hide();
      $('#showLess-' + i).show();
      $('#moreInfoNa-' + i).hide();
      $('#showLessNa-' + i).show();
    } else {
      $('#moreInfo-' + i).show();
      $('#showLess-' + i).hide();
      $('#moreInfoNa-' + i).show();
      $('#showLessNa-' + i).hide();
    }
  }

  showMoreInfoNa(check, i, status) {
    if (status === 'NotTaken') {
      return;
    } else {
      if (check === true) {
        $('#moreInfoNa-' + i).hide();
        $('#showLessNa-' + i).show();
        $('#moreInfo-' + i).hide();
        $('#showLess-' + i).show();
        $('#outline-' + i).css('border-color', 'red');
      } else {
        $('#moreInfoNa-' + i).show();
        $('#showLessNa-' + i).hide();
        $('#moreInfo-' + i).show();
        $('#showLess-' + i).hide();
        $('#outline-' + i).css('border-color', 'red');
        $('#outline-' + i).css('display', 'table');
        $('#outline-' + i).css('box-sizing', 'border-box');
      }
    }
  }

  searchPagination() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 4;
    this.pageNumber = parseInt('' + (this.filteredItems.length / this.pageSize));
    if (this.filteredItems.length % this.pageSize !== 0) {
      ++this.pageNumber;
    }
    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.checkBoxSelect.length = this.pageNumber;
    this.checkBoxSelect.fill(false);
  }

  onDateChanged(event, key) {
    if (key === 'start') {
      this.publicStartDate = event.formatted;
    } else {
      this.publicEndDate = event.formatted;
    }
  }

  dateOF(data) {
    const utcData = new Date(data);
    const newDate = new Date(utcData.getTime() + utcData.getTimezoneOffset() * 60 * 1000);
    const offset = utcData.getTimezoneOffset() / 60;
    const hours = utcData.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  redirectUrl(data) {
    window.open(data);
  }

  fillArray(): any {
    const obj = new Array();
    for (let index = this.pageStart; index < this.pageStart + this.pages; index++) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice((this.currentIndex - 1) * this.pageSize, (this.currentIndex) * this.pageSize);
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= (this.pageStart + this.pages)) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  candidateReportLinkId(data, check) {
    this.candidateListValue = data;
    this.candidateDetialsss = data.candidate;
    this.CandidatelinkId = data._id;
    this.CandidatelinkName = data.linkName;
    this.CandidatelinkType = data.linkType;
    this.CandidateTrackLocation = data.configuration.trackGeoLocation;
    this.CandidateSnap = data.configuration.captureCandidateSnapshot;
    this.CandidateStartDT = data.configuration.startDate;
    this.CandidateEndDT = data.configuration.endDate;
    this.assessmentCandidatesDetails = false;
    this.JobDescriptionDash = true;
    this.cretedJobList = false;
    this.noJobCreated = false;
    this.assementListforJob = false;
    this.jobAssessmentCreation = false;
    this.jobAssessmentCreation = false;
    this.JobDetailsData = false;
    this.CandidateReportDetails = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;

    if (check === false) {
      this.canReportskip++;
    } else {
      this.canReportskip = 0;
      this.candidateReportResponse = [];
    }
    const payload = { 'linkId': this.CandidatelinkId };
    this.assessmentService.candidateReport(payload, this.canReportskip, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.candidateLocation = response.location;
          this.canReport = response.isMoreCandidates;
          localStorage.setItem('location',  'this.candidateLocation');
          const listofcan = response.results;
          this.candidateReportResponse = this.candidateReportResponse.concat(listofcan);
          this.assessmentCandidatesDetails = true;
          this.listOfLinkforJob = false;
          this.isLoading = false;
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  candidateReport(assesmentId, assessmentName) {
    this.candidateAssessmentName = assessmentName;
    const payload = { 'linkId': this.CandidatelinkId };
    this.assessmentService.candidateReport(payload, 0, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.candidateLocation = response.location;
          localStorage.setItem('location', 'this.candidateLocation');
          this.candidateReportResponse = response.results;
          },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  showLocation(data) {
    const len = data.assessmentVersions.length;
    this.startCurrentLat = data.assessmentVersions[len - 1].trackGeo.startLocation.latitude;
    this.startCurrentLong = data.assessmentVersions[len - 1].trackGeo.startLocation.longitude;
    this.endCurrentLat = data.assessmentVersions[len - 1].trackGeo.endLocation.latitude;
    this.endCurrentLong = data.assessmentVersions[len - 1].trackGeo.endLocation.longitude;

    if (this.startCurrentLat != null && this.startCurrentLong != null) {
      // myExtObject.getLocation(this.startCurrentLat, this.startCurrentLong, true);
      this.startLocationMark = localStorage.getItem('startAddress');
    } else {
      this.startLocationMark = '';
    }

    if (this.endCurrentLat != null && this.endCurrentLong != null) {
      // myExtObject.getLocation(this.endCurrentLat, this.endCurrentLong, false);
      this.endLocationMark = localStorage.getItem('endAddress');
    } else {
      this.endLocationMark = '';
    }

    const startmapProp = {
      center: new google.maps.LatLng(this.startCurrentLat, this.startCurrentLong),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const endmapProp = {
      center: new google.maps.LatLng(this.endCurrentLat, this.endCurrentLong),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.startmap = new google.maps.Map(this.gmapElementstart.nativeElement, startmapProp);
    this.endmap = new google.maps.Map(this.gmapElementend.nativeElement, endmapProp);
    $('#candidatesLocation').modal('show');
  }

  candidateSnapcaptured(data) {
    const len = data.assessmentVersions.length;
    if (data.assessmentVersions[len - 1].assessmentStatus === 'Not Taken') {
      return;
    } else {
      this.candidateAttendanceSnapshot = data.assessmentVersions[len - 1].attendanceSnapshot;
      if (data.assessmentVersions[len - 1].candidateSnapShot == null) {
        this.candidateCaputerdSnapshot = [];
      } else {
        this.candidateCaputerdSnapshot = data.assessmentVersions[len - 1].candidateSnapShot;
      }
      $('#CandidateSnapShot').modal('show');
    }
  }

  downloadLocation() {
    const payload = { 'linkId': this.CandidatelinkId };
    this.assessmentService.exportCandidateExcel(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.candidateLocation = response.location;
          localStorage.setItem('location', 'this.candidateLocation');
          window.open(this.candidateLocation, '_blank');
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  resetAddExiting() {
    this.selectedId = [];
    this.selectedData = [0, 0, 0, 0, 0, 0, 0];
  }

  removeAllCandidates() {}

  downloadFormat() {
    window.open('http://cdn.calibrat.com/static/CandidateUploadFormat.xlsx', '_blank');
  }

  backButtonForLink() {
    if (this.assessmentCandidatesDetails) {
      this.listOfLinkforJob = true;
      this.assessmentCandidatesDetails = false;
    } else if (this.CandidateReportDetails) {
      this.CandidateReportDetails = false;
      this.assessmentCandidatesDetails = true;
    }
  }

  backButton() {
    if (this.jobAssessmentCreation) {
      this.createJobStep1 = true;
      this.jobAssessmentCreation = false;
      const p = 0;
      $('#prog').width(p + '%');
    } else if (this.addAssessmentPage) {
      this.addAssessmentPage = false;
      this.jobAssessmentCreation = true;
      const p = 100 / 2;
      $('#prog').width(p + '%');
    } else if (this.privateLink) {
      if (this.privateLinkCreationStep2) {
        this.privateLinkCreationStep1 = true;
        this.publicLinkCreation = false;
        this.privateLinkCreationStep2 = false;
        const size = this.candidatesDetails.length;
        this.candidatesDetails = [];
        for (var i = 0; i < size; i++) {
          setTimeout(function () {
            myExtObject.loadFlagId(size);
          });
          const obj = {
            'candidatesName': $('#canname-' + i).val(),
            'email': $('#email-' + i).val(),
            'phoneNo': $('#phone-' + i).val(),
            'countryCode': '+91'
          };
          this.candidatesDetails.push(obj);
        }
      } else {
        this.addAssessmentPage = true;
        this.privateLinkCreationStep1 = false;
        this.publicLinkCreation = false;
        this.privateLinkCreationStep2 = false;
      }
    } else {
      this.publicLinkCreation = true;
      this.privateLinkCreationStep1 = false;
      this.privateLinkCreationStep2 = false;
    }

  }

  stepWizard1() {
    if (!this.setWizard1) {
      return;
    }
    this.createJobStep1 = true;
    this.jobAssessmentCreation = false;
    this.addAssessmentPage = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    const p = 0;
    $('#prog').width(p + '%');
    const size = this.candidatesDetails.length;
    this.candidatesDetails = [];
    for (var i = 0; i < size; i++) {
      setTimeout(function () {
        myExtObject.loadFlagId(size);
      });

      const obj = {
        'candidatesName': $('#canname-' + i).val(),
        'email': $('#email-' + i).val(),
        'phoneNo': $('#phone-' + i).val(),
        'countryCode': '+91'
      };
      this.candidatesDetails.push(obj);
    }
  }

  stepWizard2() {
    if (!this.setWizard2) {
      return;
    }
    this.jobAssessmentCreation = true;
    this.createJobStep1 = false;
    this.addAssessmentPage = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    const p = 100 / 2;
    $('#prog').width(p + '%');
    const size = this.candidatesDetails.length;
    this.candidatesDetails = [];
    for (var i = 0; i < size; i++) {
      setTimeout(function () {
        myExtObject.loadFlagId(size);
      });
      const obj = {
        'candidatesName': $('#canname-' + i).val(),
        'email': $('#email-' + i).val(),
        'phoneNo': $('#phone-' + i).val(),
        'countryCode': '+91'
      };
      this.candidatesDetails.push(obj);
    }
  }

  stepWizard3() {
    if (!this.setWizard3) {
      return;
    }
    this.createJobStep1 = false;
    this.addAssessmentPage = false;
    this.jobAssessmentCreation = false;
    const p = 2 * (100 / 2);
    $('#prog').width(p + '%');
    if (this.privateLink) {
      this.privateLinkCreationStep1 = true;
      this.publicLinkCreation = false;
      this.privateLinkCreationStep2 = false;
    } else {
      this.publicLinkCreation = true;
      this.privateLinkCreationStep1 = false;
      this.privateLinkCreationStep2 = false;
    }
  }

  scheduleTime(data) {
    let startDate, endDate, startDateTime, endDateTime;
    this.mobileNum = data.mobile;
    this.countryCodeNum = data.countryCode;
    if (data.startDate === '') {
      startDateTime = this.CandidateStartDT;
    } else {
      if (data.hasOwnProperty('startDate')) {
        startDateTime = data.startDate;
      } else {
        startDateTime = '';
      }
    }
    if (data.endDate === '') {
      endDateTime = this.CandidateEndDT;
    } else {
      if (data.hasOwnProperty('endDate')) {
        endDateTime = data.endDate;
      } else {
        endDateTime = '';
      }
    }
    if (startDateTime !== '') {
      startDate = startDateTime.split('T');
      this.publicStartDate = startDate[0];
      if (startDate[1] !== '00:00:00' && startDate[1] !== '') {
        this.publicStartTime = startDate[1].substring(0, startDate[1].length - 3);
      }
    } else {
      this.publicStartDate = '';
    }

    if (endDateTime !== '') {
      endDate = endDateTime.split('T');
      this.publicEndDate = endDate[0];
      if (endDate[1] !== '00:00:00' && endDate[1] !== '') {
        this.publicEndTime = endDate[1].substring(0, endDate[1].length - 3);
      }
    } else {
      this.publicEndDate = '';
    }

    if (startDateTime !== '') {
      this.publicSTARTDate = {
        date: {
            year: startDate[0].substring(0, 4),
            month: startDate[0].substring(5, 7),
            day: startDate[0].substring(8, 10),
        }
      };
    }
    if (endDateTime !== '') {
      this.publicENDDate = {
        date: {
            year: endDate[0].substring(0, 4),
            month: endDate[0].substring(5, 7),
            day: endDate[0].substring(8, 10),
        }
      };
    }
    $('#scheduleTime').modal('show');
  }

  CandidateResetTest(data) {
    this.createdassessmentId = data.assessmentId;
    $('#candidateReset').modal('show');
  }

  CandidateRRReson(modal: any) {
    this.publicerrorMsg = '';
    const payload = {
      'linkId': this.CandidatelinkId,
      'mobile': this.mobileNum,
      'countryCode': this.countryCodeNum,
      'status': modal.reset,
      'assessmentId': this.createdassessmentId,
      'reason': modal.Resetreason
    };
    this.assessmentService.CandidateRRReson(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.modalResetCandidate.reset();
          $('#candidateReset').modal('hide');
        },
        error => {
          this.publicerrorMsg = error.error.message;
    });
  }

  onClock(id) {
    myExtObject.loadClock(id);
  }

  resetDateTimeScheduling() {
    this.publicerrorMsg = '';
    this.publicStartTime = '';
    this.publicSTARTDate = '';
    this.publicENDDate = '';
    this.publicEndTime = '';
    $('#startTime').val('');
    $('#endTime').val('');
  }

  dateTimeScheduling() {
    this.publicerrorMsg = '';
    this.publicStartTime = $('#startTime').val();
    this.publicEndTime = $('#endTime').val();
    let startDateTime, endDateTime;
    if (this.publicStartDate === '') {
      startDateTime = '';
    } else {
      startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
    }
    if (this.publicStartTime === '' && this.publicStartDate !== '') {
      startDateTime = this.publicStartDate + 'T' + '00:00:00';
    }

    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
    }

    const payload = {
      'linkId': this.CandidatelinkId,
      'candidates': [{
          'mobile': this.mobileNum,
          'countryCode': this.countryCodeNum,
          'startDate': startDateTime,
          'endDate': endDateTime

      }]
    };
    this.assessmentService.CandidateReschudling(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.modalResetCandidate.reset();
          this.candidateReportLinkId(this.candidateListValue, true);
          this.publicStartTime = '';
          this.publicSTARTDate = '';
          this.publicENDDate = '';
          this.publicEndTime = '';
          $('#startTime').val('');
          $('#endTime').val('');
          $('#scheduleTime').modal('hide');
        },
        error => {
          this.publicerrorMsg = error.error.message;
     });
  }

  proctorActive(event) {
    if (!this.candidateProctor) {
      $('#proctor').modal('show');

    } else {
      this.candidateAttendanceLink = false;
      this.candidateSnapshotForLink = false;
      this.TrackLocationForLink = false;
      this.candidateMandidate = false;
    }
  }

  proctorActivate() {
    if (this.candidateProctor) {
      this.candidateAttendanceLink = true;
      this.candidateSnapshotForLink = true;
      this.TrackLocationForLink = true;
      this.candidateMandidate = true;
    }
  }

  proctorcancelation() {
    this.candidateProctor = false;
  }

  candidatesLinkIDDetails() {
    this.proctorLinkId = localStorage.getItem('linkedId');
    const payload = { 'linkId': this.proctorLinkId };
    this.assessmentService.candidatesLinkIDDetails(payload, this.companyId, this.accessToken).subscribe(
        response => {
          this.candidateReportLinkId(response.message, true);
        },
        error => {
          this.errorMsg = error.error.message;
    });
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  checkUploadImage(event) {
    let validFileUploaded = false;
    const files = event.target.files;
    const file = files[0];
    const uploadedFileNameArr = file.name.split('.');
    const uploadedFileExt = uploadedFileNameArr[uploadedFileNameArr.length - 1].toLowerCase();

    if (uploadedFileExt === 'jpg' || uploadedFileExt === 'png' || uploadedFileExt === 'jpeg') {
      validFileUploaded = true;
      this.uploadedFileErrMsg = '';
    } else {
      validFileUploaded = false;
      this.uploadedFileErrMsg = 'Invalid file extentions.';
      return;
    }
    if (files[0].size > 2000000) {
      this.uploadedFileErrMsg = 'File size exceeds 2MB.';
      validFileUploaded = false;
      return;
    } else {
      this.uploadedFileErrMsg = '';
      validFileUploaded = true;
    }
    if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    if (validFileUploaded) {
      this.afterSelect = true;
    }
  }

  clearFileData() {
    $('#imagevalidation').val('');
    this.afterSelect = false;
    this.uploadedFileErrMsg = '';
  }

  imageUploadActive() {
    this.isUploadingCandImg = true;
    this.uploadedFileErrMsg = '';
    $('#imagevalidation').val('');
    const payload = {
        'companyId': this.companyId,
        'mobile': this.selectedRowMobileNumber,
        'countryCode': this.selectedRowCountryCode,
        'linkId': this.CandidatelinkId,
        'baseString': this.base64textString,
        'uploadImage': this.imageUploadForLink
    };
    this.jobService.imageUploadActive(payload, this.companyId, this.accessToken).subscribe(
         response => {
           this.isUploadingCandImg = false;
           this.responseUrlImage = response.location;
           this.candidateReportResponse[this.selCandidateRowIndex].imageUrl = this.responseUrlImage + '?mc=' + Math.random();
           $('#image').modal('hide');
           this.validFileUploaded = true;
           this.afterSelect = false;
           this.toastrService.success('Successfully Image Uploaded ', '', {
             timeOut: 5000,
           });
         },
         error => {
           this.isUploadingCandImg = false;
           this.errorMsg = error.error.message;
           this.uploadedFileErrMsg = this.errorMsg;
           this.afterSelect = false;
    });
  }

  UploadImage(data, rowIndex, via) {
    if (via === 'imageUpload') {
    $('#image').modal('show');
    } else if (via === 'menuUpload') {
    $('#image').modal('show');
    }
    this.isUploadingCandImg = false;
    this.uploadedFileErrMsg = '';
    this.selCandidateRowIndex = rowIndex;
    this.selectedRowMobileNumber = data.mobile;
    this.selectedRowCountryCode = data.countryCode;
    $('#image').modal('show');
  }

  selectImageUpload(event) {
    const d6ValidationChkbx = event.target || event.srcElement;
      if (d6ValidationChkbx.checked) {
        $('#candidatePopup').modal('show');
        this.imageUploadForLink = true;
      } else {
        this.imageUploadForLink = false;
    }
  }

  selectScreenFreezer(event) {
    const d6ValidationChkbx = event.target || event.srcElement;
    if (d6ValidationChkbx.checked) {
      $('#screenPopup').modal('show');
      this.selectScreenFreezeForLink = true;
    } else {
      this.selectScreenFreezeForLink = false;
    }
  }

  assesmentProceed() {
    this.publicStartTime = $('#startTime').val();
    if (this.publicStartDate  === '' ) {
      this.startDateTime = '';
    } else {
      this.startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
    }
    if (this.publicStartTime === '' && this.publicStartDate !== '') {
      this.startDateTime = this.publicStartDate + 'T' + '00:00:00';
    }
    if (this.modalValue === this.private ) {
      this.publicAssessmentLink();
    } else {
      this.sendInvitation();
    }
  }

  cancleProceed() {
    $('#publishPopup').modal('hide');
  }

  jobMadidate() {
    $('#candidatePopup').modal('hide');
    this.imageUploadForLink = true;
  }

  cancleJobMandidate() {
    $('#candidatePopup').modal('hide');
    this.imageUploadForLink = false;
  }

  jobScreenFreeze() {
    $('#screenPopup').modal('hide');
    this.selectScreenFreezeForLink = true;
  }

  cancleScreenFreeze() {
    $('#screenPopup').modal('hide');
    this.selectScreenFreezeForLink = false;
  }
}










