<app-user-header></app-user-header>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <i class="material-icons green align-middle" style="cursor: pointer"
            routerLink="/profile">keyboard_arrow_left</i>
          <li class="breadcrumb-item active" aria-current="page" routerLink="/profile"><a>Edit Profile</a></li>
        </ol>
      </nav>
    </div>
  </div>
</nav>

<div class="main-div"><!-- showing for form-->
  <div class="container ">
    <form [formGroup]="editForm">
      <div class="row mtop30">
        <div class="col">
          <label>First name</label> <span style="color: red">*</span>
          <input type="text" class="form-control height50" id="firstname" name="firstname" aria-describedby="emailHelp"
            [(ngModel)]="userDetails.FirstName" placeholder="First Name" formControlName="firstname">
          <div class="text text-danger" *ngIf="firstname.touched && firstname.invalid">
            <small *ngIf="firstname.errors.pattern">Enter valid first name.</small>
            <small *ngIf="firstname.errors.required">Enter valid first name.</small>
            <small *ngIf="firstname.errors.maxlength">Maximum 101 characters are allowed.</small>
          </div>
        </div>
        <div class="col">
          <label>Last name</label>
          <input type="text" class="form-control height50" id="lastname" name="lastname" aria-describedby="emailHelp"
            [(ngModel)]="userDetails.LastName" placeholder="Last Name" formControlName="lastname">
          <div class="text text-danger" *ngIf="lastname.touched && lastname.invalid">
            <small *ngIf="lastname.errors.pattern">Provide valid last name.</small>
            <small *ngIf="lastname.errors.maxlength">Maximum 101 characters are allowed.</small>

          </div>
        </div>
      </div>
      <div class="row mtop30">
        <div class="col">
          <label>Company name</label> <span style="color: red">*</span>
          <input type="text" class="form-control height50" id="companyname" name="companyname"
            aria-describedby="emailHelp" [(ngModel)]="userDetails.Companies[0].CompanyName" placeholder="Comapany Name"
            formControlName="companyname" (input)="checkForValue($event)">
          <div class="text text-danger" *ngIf="companyname.touched && companyname.invalid">
            <small *ngIf="companyname.errors.pattern">Enter company name.</small>
            <small *ngIf="companyname.errors.required">Enter company name.</small>
            <small *ngIf="companyname.errors.minlength && showMinimumError">Minimum 3 characters are required.</small>
            <small *ngIf="companyname.errors.maxlength">Maximum 265 characters are allowed.</small>
          </div>
        </div>
        <div class="col">
          <label>Company website URL</label>
          <input type="text" class="form-control height50" id="companyurl" name="companyurl"
            aria-describedby="emailHelp" [(ngModel)]="userDetails.Companies[0].CompanyUrl" placeholder="Company url"
            formControlName="companyurl">
          <div class="text text-danger" *ngIf="companyurl.touched && companyurl.invalid">
            <small *ngIf="companyurl.errors.pattern">Provide valid url.</small>
          </div>
        </div>
      </div>
      <div class="mtop15 ">
        <label>Brief about the company</label>
        <angular-editor formControlName="companyinfo" [(ngModel)]="userDetails.Companies[0].CompanyDescription"
          [config]="config"></angular-editor>
        <div class="text text-danger" *ngIf="companyinfo.touched && companyinfo.invalid">
          <span *ngIf="companyinfo.hasError('whitespace')" class="text-danger">Space not allowed</span>
        </div>
      </div>
      <div class="mtop15">
        <label>Company logo</label><br>
        <input type="file" class="form-control" name="fileupload" formControlName="fileupload" id="fileupload"
          placeholder="Upload file" (change)="fileChangeEvent($event);">(max size 2 mb)<br />
        <small class="text-danger"> {{logoMessage}} </small>
        <div *ngIf="isLoading==true" class="buttonload">
          <i class="fa fa-refresh fa-spin" style="color: #0ce2b8;margin-left: 500px"></i> Image Uploading...
        </div>
      </div>
      <div class="row bg-white pt-3 pb-3 pl-3 mt-3">
        <div class="col no-padding-right" style="margin-left:-17px;">
          <div class="card">
            <h5 class="card-header">Support detail</h5>
            <div class="card-body">
              <div class="row mtop30">
                <div class="col">
                  <div class="">
                    <label>Email ID</label>
                  </div>
                  <div class="form-group">
                    <input class="form-control" type="text" placeholder="Enter Email Id" name="supportemail"
                      id="supportemail" formControlName="supportemail"
                      [(ngModel)]="userDetails.Companies[0].SupportEmail">
                    <div class="text text-danger" *ngIf="supportemail.touched && supportemail.invalid">
                      <small *ngIf="supportemail.errors.pattern">Enter valid email ID.</small>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="">
                    <label>Phone No.</label>
                  </div>
                  <div>
                    <input class="form-control" type="text  " name="supportphoneno" id="supportphoneno"
                      placeholder="Enter Phone No" OnlyNumber="true" formControlName="supportphoneno"
                      [(ngModel)]="userDetails.Companies[0].SupportMobile">
                    <div class="text text-danger" *ngIf="supportphoneno.touched && supportphoneno.invalid">
                      <small *ngIf="supportphoneno.errors.minlength">Enter valid mobile number.</small>
                      <small *ngIf="supportphoneno.errors.maxlength">Enter valid mobile number.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <small style="color:red;">{{dashboardErrorMsg}}</small>
      </div>
      <button type="button" [disabled]="isLoading||editForm.invalid" class="btn btn-primary mtop15"
        (click)="submitForm(editForm.value); " style="margin-left:-1px;">Save</button>
      <button type="button" class="btn btn-primary mtop15" (click)="cancelProfileEditProcess();">Cancel</button>
    </form>
  </div>
</div><!-- end of show form data-->


<img class="p-by" src="assets/img/p-by.png" alt="">