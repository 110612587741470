<app-user-header (jobPage)="RidirectionMessage();"(profileInfo)="dashboardRefresh()"></app-user-header>


<div *ngIf="isLoading==true" class="buttonload">
  <i class="fa fa-refresh fa-spin"></i>  Loading Assessment...
</div>

<nav  class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div  *ngIf="cretedJobList || JobDescriptionDash" class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item" (click)="listOFJD(true);">
            <!--<i class="material-icons green align-middle"  style="cursor: pointer">keyboard_arrow_left</i>-->
            <a >Jobs</a>
          </li>
          <li *ngIf="JobDescriptionDash" class="breadcrumb-item active" aria-current="page"><a>{{jodDetails.jobTitle}}</a></li>

        </ol>
      </nav>
    </div>
    <div>
      <i *ngIf="privateLinkCreationStep2" class="material-icons green align-middle"  style="cursor: pointer" (click)="backButton();">keyboard_arrow_left</i>
      <a *ngIf="privateLinkCreationStep2 || privateLinkCreationStep1">Private Invites Details</a>
    </div>



    <div class="col-2">
      <div class="btn-group float-right">

        <button *ngIf="multipleDelete && !hideEditDelete" class="btn btn-link"  (click)="deleteMultipleAssessment()">
          <!--<i class="material-icons align-middle green" >delete</i><span class="align-middle">Delete</span>-->
          <span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>
        </button>

        <button *ngIf="hideEditDelete" type="button" class="btn btn-link" aria-hidden="true">
          <!--<i class="material-icons align-middle green"(click)="editDetails(editData,editAssesmentCount);">edit</i><span class="align-middle" (click)="editDetails(editData,editAssesmentCount);">Edit</span>-->
          <span class="align-middle" (click)="editDetails(editData,editAssesmentCount);"><img class=" " src="/assets/img/Edit.png" alt=""> Edit</span>
        </button>

        <button  *ngIf="hideEditDelete" class="btn btn-link"  (click)="deleteJobDescript(jodDetails._id)">
          <!--<i class="material-icons align-middle green" >delete</i><span class="align-middle">Delete</span>-->
          <span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>
        </button>

        <button *ngIf="cretedJobList || hideEditDelete" type="button" class="btn btn-primary " aria-haspopup="true" (click)="startcreateJobs();">
          Create New
        </button>
      </div>
    </div>
  </div>
</nav>



<div *ngIf="cretedJobList" class=" ">

  <!--<div class="row" style="margin-top: 70px;">-->
  <!--<button type="button" class="btn btn-login pull-right" (click)="startcreateJobs();">-->
  <!--Create Jobs-->
  <!--</button>-->
  <!--</div>-->




  <div class="row no-gutters">
    <div class="col-3">
      <div class="panel-left container-fluid bg1">

        <!--<h6>Filter By</h6>-->
        <!--<div id="accordion">-->
        <!--<div class="card border-0">-->
        <!--<div class="card-header" id="headingOne">-->
        <!--<h5 class="mb-0">-->
        <!--<button class="btn btn-link btn-block text-left " data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">-->
        <!--Job Title-->
        <!--<i class="material-icons icon-left"></i>-->
        <!--</button>-->
        <!--</h5>-->
        <!--</div>-->

        <!--<div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">-->
        <!--<div class="card-body">-->
        <!--<ul class="list-group list-group-flush">-->
        <!--<li class="list-group-item ">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Programing</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Coding</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Database Query</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->

        <!--<h6 class="ml-3 mt-3"><a >Experience(years)</a></h6>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="card border-0">-->
        <!--<div class="card-header" id="headingTwo">-->
        <!--<h5 class="mb-0">-->
        <!--<button class="btn btn-link  btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">-->
        <!--Total marks-->
        <!--<i class="material-icons icon-left"></i>-->
        <!--</button>-->
        <!--</h5>-->
        <!--</div>-->
        <!--<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">-->
        <!--<div class="card-body">-->
        <!--<ul class="list-group list-group-flush">-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Programing</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Coding</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Database Query</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="card border-0">-->
        <!--<div class="card-header" id="headingThree">-->
        <!--<h5 class="mb-0">-->
        <!--<button class="btn btn-link  btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
        <!--status-->
        <!--<i class="material-icons icon-left"></i>-->
        <!--</button>-->
        <!--</h5>-->
        <!--</div>-->

        <!--<div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">-->
        <!--<div class="card-body">-->
        <!--<ul class="list-group list-group-flush">-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Programing</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Coding</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Database Query</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="card border-0">-->
        <!--<div class="card-header" id="headingThree">-->
        <!--<h5 class="mb-0">-->
        <!--<button class="btn btn-link  btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
        <!--Created On-->
        <!--<i class="material-icons icon-left"></i>-->
        <!--</button>-->
        <!--</h5>-->
        <!--</div>-->

        <!--<div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">-->
        <!--<div class="card-body">-->
        <!--<ul class="list-group list-group-flush">-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Programing</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Coding</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="list-group-item">-->
        <!--<div class="form-check">-->
        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">-->
        <!--<label class="form-check-label" for="exampleCheck1">Database Query</label>-->
        <!--</div>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--</div>-->
      </div>
    </div>

    <div class="col-9">
      <div class="panel-right container-fluid">
        <!--<div class="row">-->
        <!--<div class="col mb-3">-->
        <!--<form action="" class="Search">-->
        <!--<input class="Search-box" type="search" id="Search-box" autocomplete="off" placeholder="Search">-->
        <!--<label class="Search-label" for="Search-box"><i class="material-icons">search</i></label>-->
        <!--</form>-->
        <!--</div>-->
        <!--</div>-->

        <p *ngIf="listCount > 0" class="RR-14">{{listCount}} Job(s) Created</p>
        <div class="row mtop30" *ngIf="listJobcreated.length != 0">
          <div class="col">
            <div class="card  ">
              <div class="card-body ">
                <div class="row GR-14">
                  <div class="col-1"></div>
                  <div class="col-2">Job Title</div>
                  <div class="col-2">Assessments</div>
                  <div class="col-2">Invites</div>
                  <div class="col-2 pr-0">Exp (Years)</div>
                  <div class="col-2">Created On</div>
                  <div class="col-1"></div>
                </div>
              </div>
            </div>
            <div class="card card__two mtop15 border-0" *ngFor="let list of listJobcreated ;let i=index;">
              <div class="card-body card-bg" >
                <div class="row" >

                  <div class="col-1 text-center">
                    <label class="mb-0"><input  value="{{list.jdDetails._id}}" (change)="selectedDelete($event);" type="checkbox"></label>
                  </div>
                  <div class="col-2 dect text-truncate qHeading " (click)="showJobsDetails(list.jdDetails,list.invites,list.assessmentCount);">{{list.jdDetails.jobTitle}}</div>
                  <div class="col-2 text-truncate qRest " >{{list.assessmentCount}}</div>
                  <div class="col-2 text-truncate qRest ">{{list.invites}}</div>
                  <div class="col-2 pr-0 text-truncate qRest "> {{list.jdDetails.minExperience}}</div>
                  <div class="col-2 text-truncate qRest ">{{list.jdDetails.modifiedOn  | date:'dd, MMM y'}}</div>

                  <div class="col-1">
                    <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; cursor: pointer; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                      <a class="dropdown-item" (click)="editDetails(list.jdDetails,list.assessmentCount);">Edit</a>
                      <a class="dropdown-item" (click)="publishListJob(list.jdDetails,list.invites,list.assessmentCount);">Publish</a>
                      <a class="dropdown-item" (click)="deleteJobDescript(list.jdDetails._id,i);">Archive</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="listJobcreated.length == 0 && noJobCreated" class="container-fluid mt-5">
          <div class="row">
            <div class="col text-center">
              <div class="mt-5">
                <img class=" mx-auto d-block" src="/assets/img/Create-Jobs.png" alt="">
                <h5 class="mt-5" >Not created any Job?</h5>
                <p>Get going!</p>
                <button type="button" class="btn btn-outline " aria-haspopup="true" (click)="startcreateJobs();">
                  Create New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="listJobcreated.length != 0" class="row my-5">
          <div class="col text-center">
            <button type="button" [hidden]="!isLoadmore"  class="btn btn-more mtop30 " (click)="loadMoreJobList();">
              Load more  <div class="col" *ngIf="candidateMandidate">
          <div class="form-group"  >
            <input  class="form-control"  type="file" attr.id="file-{{i}}" name="uploadimage" accept=".png,.gif,.jpeg" (change)="imageuploadfile($event);" id="imageuploads"  [(ngModel)]="imageupload">
          </div>
        </div>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!--start job details page 3-->
<div *ngIf="JobDescriptionDash" class="">
  <div class="container-fluid main-div-tab ">
    <p class="GR-18 mb-0">{{jodDetails.jobTitle}}</p>
    <nav class="navbar  navbar-expand-lg navbar-light navAdjust pl-0 pb-0">
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto ml-0 mt-2 mt-lg-0">
          <li class="nav-item mr-5">
            <a class="nav-link GR-14" id="description" (click)="assessmentDescriptionHeader();">Details</a>
          </li>
          <li class="nav-item mr-5">
            <a class="nav-link GR-14" id="assesment" (click)="assessmentDetailsHeader();" >Assessments</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link GR-14" id="invite" (click)="listofInvitationLinkofJob(true);">Invites</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>





  <!--//end header-->

  <!-- start job description page3-->
  <div *ngIf="JobDetailsData" class="main-div-assessment container-fluid custom-wrap bg-white">
    <table class="table table-bordered mtop15">
      <thead>
      <tr>
        <th scope="col "><span class="font-weight-normal text text-muted">Assessments</span> </th>
        <th scope="col "><span class="font-weight-normal text text-muted">Invites</span> </th>
        <th scope="col "><span class="font-weight-normal text text-muted">Exp (years)</span></th>
        <th scope="col"><span class="font-weight-normal text text-muted">Created On</span></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td ><h6>{{jodDetails.assessmentCount}}</h6></td>
        <td ><h6>{{jodDetails.invites}}</h6></td>
        <td ><h6>{{jodDetails.minExperience}}</h6></td>
        <td ><h6>{{jodDetails.createdOn | date:'dd, MMM y'}}</h6></td>
      </tr>
      </tbody>
    </table>

    <!--<div class="row mtop30">-->
    <!--<div class="col">-->
    <!--<h6 >Skills</h6>-->
    <!--<div class="text text-muted " >{{jodDetails.tags}}</div>-->
    <!--</div>-->
    <!--</div>-->

    <div class="row mtop30 " style="margin-left: 0px;">
      <h6 >Skills</h6>
    </div>
    <div class="row">
      <div class="p-3 " *ngFor="let list of jodDetails.tags "> <span class="box-3">{{list}}</span></div>

    </div>

    <div class="row mtop30">
      <div class="col">
        <h6>Job Description</h6>
        <p class="text text-muted" *ngIf="jodDetails.jobDescription !='' " [innerHTML]="jodDetails.jobDescription" ></p>
        <p class="text text-muted" *ngIf="jodDetails.jobDescription =='' " > Not available </p>
      </div>
    </div>

  </div>


  <!--ended job description-->


  <!--start page 4 for job assessment details-->
  <div *ngIf="assementListforJob" class="main-div-assessment container-fluid custom-wrap bg-white">
    <div class="row">
      <div class="col ">
        <div class="btn-group float-right">

          <div *ngIf="listOfAssessmentforJob.length != 0" class="mt-3 mb-3">
            <button type="button" class="btn btn-primary pull-right " (click)="addexistingAssesment();">
              Add Existing
            </button>
            <button type="button" class="btn btn-primary pull-right " (click)="createNewAssesment();">
              Add New
            </button>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="listOfAssessmentforJob.length != 0" class="row mt-3">
      <div class="col">
        <div class="card ">
          <div class="card-body ">
            <div class="row GR-14">
              <!--<div class="col-1 "></div>-->
              <div class="col ">Name</div>
              <div class="col">Type</div>
              <div class="col">Duration</div>
              <div class="col">Passing (%)</div>
              <div class="col">Created On</div>
              <div class="col-1 "></div>
            </div>
          </div>
        </div>
        <div class="card card__two mtop15 border-0" *ngFor="let list of listOfAssessmentforJob ;let i=index;">
          <div class="card-body card-bg" >
            <div class="row" >
              <!--<div class="col-1 text-center">-->
              <!--<div class="form-check">-->
              <!--<label class="mb-0"></label>-->
              <!--</div>-->
              <!--</div>-->
              <div class="col text-truncate qHeading">{{list.assessmentName}}</div>
              <div class="col text-truncate qRest">{{list.assessmentType}}</div>
              <div class="col text-truncate qRest">{{list.duration}}</div>
              <div class="col text-truncate qRest">{{list.cutOff}}</div>
              <div class="col qRest">{{list.modifiedOn | date:'dd, MMM y'}}</div>
              <div class="col-1">
                <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <a class="dropdown-item" (click)="editAssesmentThroughID(list._id);">Edit</a>
                  <a class="dropdown-item" (click)="deleteAssessment(list._id)">Remove</a>
                  <!--<a class="dropdown-item" routerLink="/question" (click)="showModal2(list._id)">Delete Question</a>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="listOfAssessmentforJob.length == 0">
      <div class="row pt-4 pb-5">
        <div class="col text-center">
          <div class="mt-5">
            <img _ngcontent-c3="" alt="" class=" mx-auto d-block" src="../assets/img/Create-Jobs.png">
            <p class="GR-18 mt-5 mb-3">Select from existing assessments or create new assessment for this job.</p>
            <button type="button" class="btn btn-primary " (click)="addexistingAssesment();">
              Add Existing
            </button>or<button type="button" class="btn btn-primary " (click)="createNewAssesment();">
            Add New
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--end page 4 for job assessment details-->


  <!--start invite link for job step 5-->

  <div *ngIf="listOfLinkforJob" class="main-div-assessment container-fluid custom-wrap bg-white">

    <div class="row">
      <div *ngIf="inviteListForJob.length > 0" class="col mt-3 mb-3">
        <p class="RR-14">{{inviteListForJob.length}} invite(s) created </p>
      </div>

      <div class="col ">
        <div class="btn-group float-right mt-3 mb-3">

          <button *ngIf="inviteListForJob.length != 0" type="button" class="btn btn-primary pull-right" (click)="invitationLinkByHeader(false);" >
            Create New
          </button>

        </div>
      </div>
    </div>

    <div  *ngIf="inviteListForJob.length != 0" class="row">


      <div class="col">
        <div class="card ">
          <div class="card-body ">
            <div class="row GR-14">
              <!--<div class="col-1 "></div>-->

              <div class="col">Batch/Group</div>
              <div class="col">Invite Type</div>
              <div class="col">Status</div>
              <div class="col">Candidates</div>
              <div class="col">Created On</div>
              <div class="col-1 "></div>

            </div>
          </div>
        </div>
        <div class="card card__two mtop15 border-0" *ngFor="let list of inviteListForJob ;let i=index;">
          <div class="card-body card-bg" >
            <div class="row" >
              <!--<div class="col-1 text-center">-->
              <!--<div class="form-check">-->
              <!--<label class="mb-0"></label>-->
              <!--</div>-->
              <!--</div>-->
              <div class="col text-truncate qHeading dect" (click)="candidateReportLinkId(list,true);">{{list.linkName}}</div>
              <div class="col text-truncate qRest">{{list.linkType}}</div>
              <div *ngIf=" list.status == 'Active' "  class="col text-truncate qRest"><span class="badge badge-success">{{list.status}}</span></div>
              <div *ngIf=" list.status == 'Inactive' "  class="col text-truncate qRest"><span class="badge badge-danger">{{list.status}}</span><i class="fa fa-exclamation-triangle" title="Test admin has mandated the upload of candidate's profile photo. To activate the test link, upload the profile photos for all the candidates." style="padding-left:4px;cursor: pointer;color:red;" *ngIf="list.reason != ''" ></i></div>
              <div class="col text-truncate qRest">{{list.candidatesCount}}</div>
              <div class="col qRest">{{list.modifiedOn | date:'dd, MMM y'}}</div>

              <div class="col-1">
                <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <a *ngIf="list.linkType == 'PUBLIC' " class="dropdown-item" (click)="ViewAccessCode(list);">Get Link</a>
                  <a *ngIf="list.linkType == 'PUBLIC' " class="dropdown-item" (click)="ViewAccessCode(list);">Access Code</a>

                  <a class="dropdown-item" (click)="editLink(list);">Edit</a>
                  <!--<a class="dropdown-item" routerLink="/question" (click)="questiondetails(list,true)">Edit Question</a>-->
                  <a class="dropdown-item" (click)="deleteLink(list._id)">Archive</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="listMessage && listOfLinkforJob">

      <div class="row">
        <div class="col text-center">
          <div class="mt-5">
            <img class=" mx-auto d-block" src="/assets/img/link-landing.png" alt="">
            <h5 class="mt-5 mb-3">Not created any link for Batch/Group?</h5>
            <p>Start inviting!</p>

            <button type="button" [disabled]="jodDetails.assessmentCount == 0 && listOfAssessmentforJob.length == 0" class="btn btn-primary " (click)="invitationLinkByHeader(false);">
              Create Link
            </button>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="inviteListForJob.length != 0" class="row mtop30">
      <div class="col text-center">
        <button type="button" [hidden]="!isLoadmore2" class="btn btn-more mtop30" (click)="listofInvitationLinkofJob(false);">
          Load more
        </button>
      </div>
    </div>

  </div>

  <!--end invite link for job step 5-->

  <div *ngIf="assessmentCandidatesDetails" class="main-div-assessment container-fluid custom-wrap bg-white">

    <!--<div class="row">-->
    <!--<div class="col input-group mb-3">-->
    <!--<input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="basic-addon2">-->
    <!--<div class="input-group-append">-->
    <!--<button class="btn btn-outline-secondary" type="button"><span class="oi oi-magnifying-glass"></span></button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->


    <div class="row mt-3">


      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <i class="material-icons green align-middle"  style="cursor: pointer" (click)="backButtonForLink();">keyboard_arrow_left</i>
            <a>{{CandidatelinkName}}</a>
          </li>
        </ol>

      </nav>

      <div class="col">
        <button type="button" class="btn btn-primary pull-right " (click)="downloadLocation();">
          Export
        </button>
      </div>
      <div class="">
        <div *ngIf="CandidatelinkType == 'PRIVATE'"  class="">
          <button type="button" class="btn btn-primary float-right" data-dismiss="modal" style="background-color: #0f74a8; cursor: pointer" (click)="addPreviewCandidates();">Add New</button>
        </div>
      </div>

    </div>



    <div class="row mt-3 mb-5">

      <div class="col">
        <div class="card ">
          <div class="card-body ">
            <div class="row GR-14">
              <div class="col-1" style="max-width:50px;"></div>
              <div class="col " >Name</div>
              <div class="col ">Access Code</div>
              <div class="col ">Test Status</div>
              <!--<div class="col">Appeared On</div>-->
              <div class="col">Total Assessments</div>
              <div *ngIf="CandidatelinkType == 'PRIVATE'" class="col-1"></div>

            </div>
          </div>
        </div>
        <div class="card card__two mtop15 border-0" *ngFor="let list of candidateReportResponse ;let i=index;">
          <div class="card-body card-bg" >
            <div class="row" >
              <!--<div ><img src ="" alt="{{list.candidateName | slice: 0:1| uppercase}}" class="dot" ></div>-->
              <div class="col-1" *ngIf ="list.imageUrl == ''" style="max-width:100px;"><span class="dot" style="padding-top:3px;"><b>{{list.candidateName | slice: 0:1 | uppercase}}</b></span></div>
              <div  class="col-1" *ngIf ="list.imageUrl != ''  || list.linkType == 'PUBLIC'" style="max-width:50px;"style="cursor: not-allowed"><img src ="{{list.imageUrl}}" alt="image" class="dot"  (click)="UploadImage(list,i,'imageUpload')"></div>
              <!--<div class="col text-truncate qHeading dect" (click)="cadidatesReports(list,list.testStatus)" >{{list.candidateName}}</div>-->
              <div  *ngIf="list.status!='Not Taken'"  class="col text-truncate qHeading dect"  (click)="cadidatesReports(list,list.testStatus)" style="margin-left:-55px;">
                {{list.candidateName}}
              </div>
              <div *ngIf="list.status=='Not Taken'" class="col text-truncate qHeading dect" style="margin-left:-56px;">
                {{list.candidateName}}
              </div>

              <div class="col text-truncate qRest">{{list.accessCode}}</div>

              <div  class="col text-truncate qRest ">{{list.status}}</div>
              <!--<div *ngIf="list.status == 'Completed' " class="col text-truncate qRest "><span class="badge badge-success">{{list.status}}</span></div>-->
              <!--<div *ngIf="list.status == 'Not Taken' " class="col text-truncate qRest"><span class="badge badge-secondary">Not Taken</span></div>-->
              <!--<div *ngIf="list.status == 'Expired' " class="col text-truncate qRest "><span class="badge badge-danger">{{list.status}}</span></div>-->
              <!--<div *ngIf="list.status == 'InProgress' " class="col text-truncate qRest "><span class="badge badge-info">In Progress</span></div>-->
              <!--<div *ngIf="list.status == 'Live' " class="col text-truncate qRest "><span class="badge badge-warning">{{list.status}}</span></div>-->
              <!---->
              <!--<div *ngIf="list.testStatus == 'NotTaken' || list.testStatus == 'Expired' || list.testStatus == 'Live' " class="col text-truncate qRest">-&#45;&#45;&#45;&#45;</div>-->
              <!--<div *ngIf="list.testStatus != 'NotTaken' && list.testStatus != 'Live' && list.testStatus != 'Expired' " class="col text-truncate qRest">{{dateOF(list.appearedOn) | date:"short"}}</div>-->
              <!---->
              <div class="col text-truncate qRest">{{list.totalAssessments}}</div>

              <div *ngIf="CandidatelinkType == 'PRIVATE'" class="col-1">
                <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <a class="dropdown-item" (click)="scheduleTime(list)">Schedule Time</a>
                  <a class="dropdown-item" (click)="UploadImage(list,i,'menuUpload')">Upload Image</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="candidateReportResponse.length != 0" class="row mtop30">
      <div class="col text-center">
        <button type="button" [hidden]="!canReport" class="btn btn-more mtop30" (click)="candidateReportLinkId(candidateListValue,false);">
          Load more
        </button>
      </div>
    </div>

  </div>

  <!--candidates report ending-->


  <!--start candidate reports details-->


  <div *ngIf="CandidateReportDetails" class="main-div-assessment container-fluid bg-white  pt-1 pb-5">


    <div class="row mt-3">

      <nav class="row mt-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <i class="material-icons green align-middle"  style="cursor: pointer" (click)="backButtonForLink();">keyboard_arrow_left</i>
            <a>{{CandidateData.candidateName}}</a>
          </li>
        </ol>
      </nav>

      <!--<div class="col">-->

      <!--<button type="button" class="btn btn-primary pull-right " (click)="downloadLocation();" >-->
      <!--Export-->
      <!--</button>-->
      <!--</div>-->
    </div>


    <div class="">


      <table class="table table-bordered mtop15">
        <thead>
        <tr>
          <th scope="col "><span class="font-weight-normal text text-muted">Invite Type</span> </th>
          <th scope="col "><span class="font-weight-normal text text-muted">Access Code</span> </th>
          <th scope="col "><span class="font-weight-normal text text-muted">Test Status</span></th>
          <!--<th scope="col"><span class="font-weight-normal text text-muted">Appeared On</span></th>-->
        </tr>
        </thead>
        <tbody>
        <tr>
          <td ><h6>{{CandidatelinkType}}</h6></td>
          <td ><h6>{{CandidateData.accessCode}}</h6></td>
          <td class="text-info"><h6>{{CandidateData.status}}</h6></td>
          <!--<td *ngIf="CandidateData.testStatus != 'Not Taken' " class="text-success" ><h6>Completed</h6></td>-->
          <!--<td *ngIf="CandidateData.testStatus == 'Completed' " class=""><h6>Not Taken</h6></td>-->
          <!--<td *ngIf="CandidateData.testStatus == 'Expired' " class="text-danger"><h6>Expired</h6></td>-->
          <!--<td *ngIf="CandidateData.testStatus == 'InProgress' " class="text-warning"><h6>In Progress</h6></td>-->
          <!--<td *ngIf="CandidateData.testStatus == 'Live' " class="text-warning"><h6>Live</h6></td>-->
          <!--<td *ngIf="CandidateData.testStatus == 'Live' " ><h6>-&#45;&#45;&#45;&#45;</h6></td>-->
          <!--<td *ngIf="CandidateData.testStatus != 'Live' " ><h6>{{dateOF(CandidateData.appearedOn) | date:"short"}}</h6></td>-->
       <!---->
        </tr>
        </tbody>
      </table>
    </div>
    <hr>


    <h5 class="pt-4">Section Details</h5>

    <div class="row mt-3">

      <div class="col">
        <div class="card ">
          <div class="card-body ">
            <div class="row GR-14" >

              <div class="col ">Assessment Name</div>
              <div class="col ">Overall Score</div>
              <div class="col ">Status</div>
              <div class="col">Report</div>
              <div class="col">Appeared On</div>

            </div>
          </div>
        </div>


        <div class="card card__two mtop15 border-0 " *ngFor="let list of CandidateReportData ;let i=index;let k=index">
          <div class="card-body card-bg" id="outline-{{i}}">
            <div class="row" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">

              <div class="col text-truncate qHeading dect"  (click)="showMoreInfoNa(true,i,list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus)" data-toggle="collapse" [attr.data-target]="'#' + i" aria-expanded="false" aria-controls="collapseExample" id="moreInfoNa-{{i}}"><i class="material-icons fa-lg blue"  style="cursor: pointer" >keyboard_arrow_down</i> {{list.assessmentVersions[list.assessmentVersions.length-1].assessmentName}}</div>
              <div class="col text-truncate qHeading dect" (click)="showMoreInfoNa(false,i,list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus)" data-toggle="collapse" [attr.data-target]="'#' + i" aria-expanded="false" aria-controls="collapseExample" id="showLessNa-{{i}}" style="display: none;cursor: pointer;"><i class="material-icons fa-lg blue"  style="cursor: pointer;">keyboard_arrow_up</i> {{list.assessmentVersions[list.assessmentVersions.length-1].assessmentName}}</div>

              <div class="col text-truncate qRest">{{list.assessmentVersions[list.assessmentVersions.length-1].assessmentScore}}</div>

              <div class="col text-truncate qRest">{{list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus}}</div>
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Submitted' " class="col text-truncate qRest text-success">Completed</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'NotTaken' " class="col text-truncate qRest">Not Taken</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'In-Progress' " class="col text-truncate qRest text-warning ">Live</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Auto-Submitted' " class="col text-truncate qRest text-success ">Auto Completed</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'InComplete-Reset' " class="col text-truncate qRest text-success">Reset</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Reset-Submitted' " class="col text-truncate qRest text-success">Reset</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'InComplete-Resume' " class="col text-truncate qRest text-success">Resume</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Resume-Submitted' " class="col text-truncate qRest text-success">Resume</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'InComplete-Reset&Resume' " class="col text-truncate qRest text-success">Reset & Resume</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Reset&Resume-Submitted' " class="col text-truncate qRest text-success">Reset & Resume</div>-->
              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Terminated' " class="col text-truncate qRest text-danger">Terminated</div>-->

              <div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus != 'In-Progress' && list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus != 'Not Taken'  " class="col text-truncate qRest" style="cursor: pointer;">
                <a data-toggle="tooltip" data-placement="top" title="Reset/Resume" (click)="CandidateResetTest(list);"><i class="material-icons fa-bg blue">settings_backup_restore</i></a>
                <a *ngIf="CandidateSnap" (click)="candidateSnapcaptured(list);" data-toggle="tooltip" data-placement="top" title="Candidate Snapshot"><i class="material-icons fa-bg blue" >perm_identity</i></a>
                <a *ngIf="CandidateTrackLocation" (click)="showLocation(list);" data-toggle="tooltip" data-placement="top" title="Geo-Location"> <i class="material-icons fa-bg blue">location_on</i></a>
                <a *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].pdfLocation !=''" (click)="redirectUrl(list.assessmentVersions[list.assessmentVersions.length-1].pdfLocation)"  title="PDF-Report"><i class="material-icons fa-bg blue">insert_drive_file</i></a>
                <!--<a data-toggle="collapse" [attr.data-target]="'#' + i" aria-expanded="false" aria-controls="collapseExample" (click)="showMoreInfo(true,i,list.assessmentVersions[listassessmentVersions.length-1].assessmentStatus)" class="pl-3" id="moreInfo-{{i}}" style="cursor: pointer"><i class="material-icons fa-lg blue" style="cursor: pointer" >keyboard_arrow_down</i></a>-->
                <!--<a data-toggle="collapse" [attr.data-target]="'#' + i" aria-expanded="false" aria-controls="collapseExample" (click)="showMoreInfo(false,i,list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus)" class="pl-3" id="showLess-{{i}}" style="cursor: pointer;display: none"><i class="material-icons fa-lg blue" >keyboard_arrow_up</i></a>-->
              </div>
              <div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'In-Progress' || list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Not Taken'" class="col text-truncate qRest">

              </div>

              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus != 'Not Taken' " class="col text-truncate qRest">-->
                     <!--{{dateOF(list.assessmentVersions[list.assessmentVersions.length-1].appearedOn) | date:'d MMM y'}},{{dateOF(list.assessmentVersions[list.assessmentVersions.length-1].appearedOn) | date:'shortTime'}}-->
              <!--</div>-->

              <div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus != 'Not Taken' " class="col text-truncate qRest">
                {{(list.assessmentVersions[list.assessmentVersions.length-1].appearedOn) }}
              </div>
              <div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'Not Taken' " class="col text-truncate qRest">
                  -----
              </div>

              <!--<div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus == 'NotTaken' " class="col text-truncate qRest"></div>-->
              <!--<div class="col">-->
              <!--<button type="button" class="btn float-right btnMore" role="button" aria-expanded="false" (click)="CandidateResetTest(list);"><i class="material-icons">more_vert</i></button>-->
              <!--</div>-->

            </div>

            <div *ngIf="list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus != 'In-Progress' && list.assessmentVersions[list.assessmentVersions.length-1].assessmentStatus != 'Not Taken' " class="collapse" id="{{i}}">
              <div class="card card-body">

                <div>
                  <div class="card-body ">
                    <div class="row GR-14">

                      <div class="col ">Section Name</div>
                      <div class="col-2 ">No. of Question</div>
                      <div class="col">Total Score</div>
                      <div class="col ">Attempted</div>
                      <div class="col ">Correct</div>
                      <div class="col ">Wrong</div>
                      <div class="col">Marks Scored</div>
                      <div class="col">Score(%)</div>

                    </div>
                  </div>
                </div>

                <div class="card card__two mtop15 border-0" *ngFor="let listData of list.assessmentVersions[list.assessmentVersions.length-1].sectionDetails; let j=index;">
                  <div class="card-body card-bg" >
                    <div class="row">

                      <div class="col text-truncate qHeading ml-1">{{listData.sectionName}}</div>
                      <div class="col-2 text-truncate qRest ml-1">{{listData.numberOfQuestions}}</div>
                      <div class="col text-truncate qRest ml-1">{{listData.sectionOverAllScore}}</div>
                      <div class="col text-truncate qRest ml-1">{{listData.attemptedQuestions}}</div>
                      <div class="col text-truncate qRest ml-1">{{listData.correctQuestions}}</div>
                      <div class="col text-truncate qRest ml-1">{{listData.InCorrectQuestions}}</div>
                      <div class="col text-truncate qRest ml-1">{{listData.sectionMarksScored}}</div>
                      <div class="col text-truncate qRest ml-1">{{listData.percentage}}</div>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>



  <!--end candidate reports details-->



</div>




<!--starting of job creation-->



<div *ngIf="createWizard" class="">
  <nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
    <div class="row">
      <div class="container-fluid">
        <div class="row ">
          <div class="col">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <i *ngIf="!createJobStep1" class="material-icons green align-middle"  style="cursor: pointer" (click)="backButton();">keyboard_arrow_left</i>
                  <a *ngIf="editJob == false ">Create New Job</a>
                  <a *ngIf="editJob == true">Edit Job</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="main-div-wizard wizard-block" id="container">
    <div class="step-wizard">
      <div class="progress">
        <div class="progressbar empty"></div>
        <div id="prog" class="progressbar"></div>
      </div>
      <ul>
        <li class="active">
          <a id="step1"  (click)="stepWizard1();" style="cursor: pointer">
            <span id="st1" class="step" style="cursor: pointer">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>
        <li *ngIf="!setWizard2" class="">
          <a id="step2" (click)="stepWizard2();"  style="cursor: not-allowed">
            <span class="stepCom" style="cursor: pointer">2</span>
            <span class="title">Assessment</span>
          </a>
        </li>
        <li *ngIf="setWizard2" class="active" >
          <a id="step2" (click)="stepWizard2();"  style="cursor: pointer">
            <span id="st2" class="step" style="cursor: pointer">2</span>
            <span class="title">Assessment</span>
          </a>
        </li>
        <li *ngIf="!setWizard3" class="" >
          <a id="step3" (click)="stepWizard3();" style="cursor: not-allowed">
            <span class="stepCom" style="cursor: pointer">3</span>
            <span class="title">Publish</span>
          </a>
        </li>
        <li *ngIf="setWizard3" class="active" >
          <a id="step3" (click)="stepWizard3();" style="cursor: pointer">
            <span id="st3" class="step" style="cursor: pointer">3</span>
            <span class="title">Publish</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div  *ngIf="createJobStep1" class="">
  <div class="main-div-assessment custom-wrap container-fluid bg-white mt-5">
    <div class="row">
      <div class="col">
        <form class="mt-3" #form="ngForm" novalidate>
          <h5>Fill Job Details</h5>
          <div class="row mt-3">
            <div class="col form-group">
              <label>Job Title<span style="color: red">*</span></label>
              <input type="text" class="form-control " #tittle="ngModel" [(ngModel)]="jobTittle" name="jobTittle"
                     placeholder="Enter Job Title " required>
            </div>
            <div class="col form-group">
              <label >Minimum Experience(in years)<span style="color: red">*</span></label>
              <select class="form-control " OnlyNumber="true" #minimumExperience="ngModel" [(ngModel)]="Experience" name="Experience"
                      placeholder="Enter Experience " required>
                <option value="" hidden >Select Experience</option>
                <option *ngFor="let list of experianceData;" [value]="list.name">{{list.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group mt-3">
            <label for="sel2" id="sel2">Skills<span style="color: red">*</span></label>
            <angular2-multiselect [data]="dropdownList" name="selectedItems" [(ngModel)]="selectedItems"
                                  #skillSet="ngModel" required [settings]="dropdownSettings"
                                  (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                                  (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
            </angular2-multiselect>
            <!--<input type="text" class="form-control height50" id="usr" #skillSet="ngModel" [(ngModel)]="selectedItem" name="skills"-->
            <!--placeholder="Enter Skills " required>-->
          </div>
          <div class="form-group mt-4">
            <label >Job Description</label><br>
            <!--<textarea  class="form-control" id="Job Description"  rows="4"  name="jobDescription" [(ngModel)]="Description"></textarea>-->
          </div>

          <!--<p class="mt-3"><a type="file" class="form-control-file" id="exampleFormControlFile1">ADD ATTACHMENT</a></p>-->

          <!--<div class="row mt-3">-->
          <!--<div class="col">-->
          <!--<div class=" ">-->
          <!--<label>Field Name</label>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="col">-->
          <!--<div class="">-->
          <!--<label >Field Value</label>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->


          <!--<div  *ngFor="let customField of customFields;let i=index;">-->
          <!--<div class="row">-->

          <!--<div class="col-md-6">-->
          <!--<div class="">-->
          <!--<input class="form-control height50" type="text" attr.id="name-{{i}}" (keyup)="onKey($event)" [value]="customField.fieldName"  name="fieldName" >-->
          <!--</div>-->
          <!--</div>-->

          <!--<div class="col-md-6">-->
          <!--<div class="">-->
          <!--<input class="form-control height50" type="text"  attr.id="value-{{i}}" (keyup)="onKey($event)" [value]="customField.fieldValue"  name="fieldValue" >-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="mb-3">-->
          <!--<small style="color: red;cursor: pointer" (click)="removeField(i);">Remove</small>-->
          <!--</div>-->
          <!--</div>-->


          <!--<div>-->
          <!--<p style="color: red;text-align: center">{{customFieldErr}}</p>-->
          <!--</div>-->

          <!--<p class="mtop30"><a type="file" class="form-control-file"-->
          <!--(click)="addOrRemoveCustomField('')" style="cursor: pointer;">+ ADD MORE</a></p>-->

        </form>
      </div>
    </div>

    <div class="row">
      <div class="col pb-3">
        <button class="btn btn-primary" id="next" [disabled]="tittle.invalid || minimumExperience.invalid || skillSet.invalid" (click)="nextOfJobCreation();">Next</button>
        <button class="btn btn-primary" id nxt [disabled]="tittle.invalid || minimumExperience.invalid || skillSet.invalid"
                (click)="createJobs(true);">Save & Exit</button>
        <button class="btn btn-cancel" id="prev" (click)="listOFJD(true);cleardata();">Cancel</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="jobAssessmentCreation" class="main-div-assessment container-fluid mtop100 bg-white">
  <div class="row pt-4 pb-5">
    <div class="col text-center">
      <div class="mt-5">
        <img _ngcontent-c3="" alt="" class=" mx-auto d-block" src="../assets/img/Create-Jobs.png">
        <p class="GR-18 mt-5 mb-3">Select from existing assessments or create new assessment for this job.</p>
        <button type="button" class="btn btn-primary " (click)="addexistingAssesment();">
          Add Existing
        </button> or
        <button type="button" class="btn btn-primary " (click)="createNewAssesment();">
          Add New
        </button>
      </div>
    </div>
  </div>
</div>

<div class="col">
  <div class="modal fade" id="selectionOfJob" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg2" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Assessment(s)</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeExitingModel();">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row no-gutters">
          <div class="btn-group" role="group"  *ngFor="let list of differentAssentType; let i=index;">
            <button type="button" class="btn btn-assessment text-center" (click)="AssessmentListAccnType(list.assessmentType,i);">
              <img  *ngIf="i == 0" src="../../assets/img/aptitude-test.png" class="img-fluid mb-2" alt="Responsive image " style="height: 45px;" >
              <img  *ngIf="i == 1" src="../../assets/img/technical-assessment.png" class="img-fluid mb-2" alt="Responsive image"  style="height: 45px;">
              <p>{{list.assessmentType}}<span  *ngIf="selectedData[i] > 0" class="badge badge-warning p-1">({{selectedData[i]}})</span></p>
            </button>
          </div>
        </div>
        <div class="modal-body" style="background:#f8f9fb;">
          <!--<div class="row">-->
          <!--<div class="col mb-3">-->
          <!--<form action="" class="Search">-->
          <!--<input class="Search-box" type="search" id="Search-box" autocomplete="off" placeholder="Search">-->
          <!--<label class="Search-label" for="Search-box"><i class="material-icons">search</i></label>-->
          <!--</form>-->
          <!--</div>-->
          <!--</div>-->
          <div class="row">
            <div class="col" *ngIf="selectedId.length > 0 ">
              {{selectedId.length}} Assessment(s) Selected
            </div>
            <div class="col text-right mt-3 mb-3">
              <button [disabled]="selectedAssesmntType.length == 0 || selectedId.length == 0 " type="button"
                      class="btn btn-primary" data-dismiss="modal" (click)="assessmentDetailsById();">
                Add Selection
              </button>
              <button type="button" class="btn btn-outline" (click)="resetAddExiting();">
                Reset
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div *ngIf="selectedAssesmntType.length != 0">
                <div class="col">
                  <div class="card ">
                    <div class="card-body ">
                      <div class="row GR-14">
                        <div class="col-1"></div>
                        <div class="col">Name</div>
                        <div class="col">Duration</div>
                        <div class="col">Passing(%)</div>
                        <div class="col">Created On</div>
                      </div>
                    </div>
                  </div>
                  <div class="card card__two mtop15 border-0" *ngFor="let list of selectedAssesmntType ;let i=index;">
                    <div class="card-body card-bg" >
                      <div class="row" >

                        <div class="col-1 text-center">
                          <label class="mb-0"><input  [checked]="checkCheckBox(list._id)" value="{{list._id}}" (change)="selectedIdOfassessment($event);" type="checkbox"></label>
                        </div>
                        <div class="col text-truncate qHeading ">{{list.assessmentName}}</div>
                        <div class="col text-truncate qRest">{{list.duration}}</div>
                        <div class="col text-truncate qRest">{{list.cutOff}}</div>
                        <div class="col qRest">{{list.modifiedOn | date:'dd, MMM y'}}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="selectedAssesmntType.length == 0">
                <div class="row">
                  <div class="col text-center">
                    <div class="">
                      <p>No Assessment Available </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pages">
            <!--<div *ngIf="selectedAssesmntType.length != 0" class="d-block mx-auto">-->
            <!--<i [ngClass]="{'disabled': (currentIndex == 1 || pageNumber == 0)}"  (click)="prevPage();"-->
            <!--class="material-icons green align-middle"  style="cursor: pointer">keyboard_arrow_left</i>-->

            <!--<label >Page {{currentIndex}} of {{pageNumber}}</label>-->

            <!--<i [ngClass]="{'disabled': (currentIndex == pageNumber || pageNumber == 0)}" (click)="nextPage();"-->
            <!--class="material-icons green align-middle"  style="cursor: pointer">keyboard_arrow_right</i>-->

            <!--</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div *ngIf="addAssessmentPage" class="main-div-assessment  bg-white">
  <!--<button type="button" class="btn btn-login " (click)="createNewAssesment();">-->
  <!--Create AssessMent-->
  <!--</button>-->
  <div>
    <div class="row pt-3">
      <div class="col">
        <!--<strong>Assesments</strong>-->
        <p class="mt-2" *ngIf="selectedId.length > 0 ">{{selectedId.length}} Assessment(s) Selected</p>
      </div>
      <div class="col">
        <button type="button" class="btn btn-primary pull-right" (click)="addMoreAssessment();">Add More</button>
      </div>
    </div>
  </div>
  <div class="row mtop30">
    <div class="col">
      <div class="card ">
        <div class="card-body ">
          <div class="row GR-14">
            <!--<div class="col-1"></div>-->
            <div class="col ">Name</div>
            <div class="col ">Type</div>
            <div class="col ">Duration</div>
            <div class="col ">Passing(%)</div>
            <div class="col">Created On</div>
            <!--<div class="col-1"></div>-->
          </div>
        </div>
      </div>
      <div class="card card__two mtop15 border-0" ngDraggable *ngFor="let list of selectedassessmentbyId ;let i=index;">
        <div class="card-body card-bg" >
          <div class="row" >
            <!--<div class="col-1 text-center">-->
            <!--<div class="form-check">-->
            <!--<label class="mb-0"></label>-->
            <!--</div>-->
            <!--</div>-->
            <div class="col text-truncate qHeading">{{list.assessmentName}}</div>
            <div class="col text-truncate qRest">{{list.assessmentType}}</div>
            <div class="col text-truncate qRest">{{list.duration}}</div>
            <div class="col text-truncate qRest">{{list.cutOff}}</div>
            <div class="col text-truncate qRest">{{list.modifiedOn | date:'dd, MMM y'}}</div>
            <!--<div class="col-1"></div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mtop30 text-center" >
    <button type="button" class="btn btn-primary"  (click)="addAssessForJob(true);">Save & Exit</button>
    <button type="button" class="btn btn-primary" (click)=" invitationLinkByHeader(true);addAssessForJob(false);">Publish</button>
    <button type="button" class="btn btn-cancel"  (click)="listOFJD(true)">Cancel</button>
  </div>
</div>


<!--</div>-->


<!--ending of job creation-->


<!--start delete message for job-->

<div class="modal fade" id="deleteMultiple" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteMultipleNo();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to delete the selected job requirement(s)?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteMultipleMessage();">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="deleteMultipleNo();">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteSingle" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="JobDescriptionDash" class="modal-body text-center">
        Do you want to delete the job?
      </div>
      <div *ngIf="cretedJobList" class="modal-body text-center">
         Are you sure you want to archive this job requirement?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deletesingleMessage();">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteAssessment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to delete the assessment(s)?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteAssessmentMessage();">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteLink" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to archive this job requirement?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteLinkMessage();">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button>
      </div>
    </div>
  </div>
</div>


<!--end delete message for job-->

<!--<Modal for start link creation>-->


<div class="modal fade" id="linkModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <form [formGroup]="modalLink">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Publish Job Link</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="manageRadio">
            <div >
              <p>Select mode to publish this assessment</p>
            </div>

            <div class="form-check form-check-inline" >
              <input type="radio" class="form-check-input"  name="link" value="private" formControlName="link">
              <label class="form-check-label">Private Link</label>
            </div>
            <div class="form-check form-check-inline" >
              <input type="radio" class="form-check-input"  name="link" value="public" formControlName="link">
              <label class="form-check-label">Public Link</label>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block mx-auto">
          <button type="button" [disabled]="modalLink.invalid" class="btn btn-primary" data-dismiss="modal" (click)="LinkCreation(modalLink.value)">Next</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="modalLink.reset();">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</div>


<div class="modal fade" id="inviteCandidtesList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Invite List</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="redirectToPlace();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-primary float-right" data-dismiss="modal" style="background-color: #0f74a8; cursor: pointer" (click)="addCandidates();">Add New</button>
          </div>
        </div>


        <div class="row mt-3">
          <div class="col">
            <div class="card ">
              <div class="card-body ">
                <div class="row GR-14">
                  <div class="col-1"></div>
                  <div class="col">Name</div>
                  <div class="col">Email ID</div>
                  <div class="col">Mobile No</div>
                  <div class="col">Access Code</div>
                  <div class="col-1"></div>
                </div>
              </div>
            </div>
            <div class="card card__two mtop15 border-0" *ngFor="let list of items ;let i=index;">
              <div class="card-body card-bg" >
                <div class="row" >

                  <div class="col-1 text-center">
                    <label class="mb-0"></label>
                  </div>
                  <div class="col text-truncate qHeading ">{{list.name}}</div>
                  <div class="col text-truncate qRest">{{list.email}}</div>
                  <div class="col text-truncate qRest">{{list.mobile}}</div>
                  <div class="col qRest">{{list.accessCode}}</div>
                  <div class="col-1">
                    <button type="button" class="btn float-right btnMore" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; cursor: pointer; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer ">


        <div *ngIf="candidatesDetailList.length >8  " class="d-block mx-auto">
          <i [ngClass]="{'disabled': (currentIndex == 1 || pageNumber == 0)}"  (click)="prevPage();"
             class="material-icons green align-middle"  style="cursor: pointer">keyboard_arrow_left</i>



          <label >Page {{currentIndex}} of {{pageNumber}}</label>


          <i [ngClass]="{'disabled': (currentIndex == pageNumber || pageNumber == 0)}" (click)="nextPage();"
             class="material-icons green align-middle"  style="cursor: pointer">keyboard_arrow_right</i>

        </div>


      </div>
    </div>
  </div>
</div>

<!-- ending of Model invited candidtaes list-->


<!-- starting Model for public link generatipon-->

<div class="modal fade" id="publicLink" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Publish Assessment Link</h5>
        <button *ngIf="!listOfLinkforJob" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="redirectToPlace();">
          <span aria-hidden="true">&times;</span>
        </button>
        <button *ngIf="listOfLinkforJob" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col text-center">
            <img src="./assets/img/publish-assessment-link.png"  id="" alt="" class="mt-3 mb-3">
            <p class="mb-1">Access Code</p>
            <h5>{{Testcode}}</h5>
            <div class="input-group mt-3">
              <input type="text" class="form-control"id="linkGeneration" name="publiclink" aria-label="Recipient's username"
                     aria-describedby="basic-addon2" [value]="publicLinkcreateion" [(ngModel)]="publicLinkcreateion" readonly>
              <div class="input-group-append" >
                <i class="fa fa-clone fa-lg green" (click)="copyLink();" tooltip="copy" style=" margin-left: 7px;margin-top: 13px; cursor: pointer;"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer ">
        <!--<button type="button" class="btn btn-secondary pull-right" data-dismiss="modal" (click)="privateCandidateinvitationList();">Done</button>-->
      </div>
    </div>
  </div>
</div>

<!-- ending Model for public link generation-->


<!-- starting Model for preview assessment-->

<div class="modal fade" id="privateLink" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title">Publish Assessment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="redirectToPlace();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p>Assessment link sent successfully<br>
          Would you like to review the invites?
        </p>
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#inviteCandidtesList">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="redirectToPlace();" >No</button>
      </div>
    </div>
  </div>
</div>

<!-- ending Model for private link succeesfully-->

<!--< Modal for end link creation>-->








<div *ngIf="privateLinkCreationStep1" class="main-div2 mtop100 bg-white pt-3 pb-5">


  <div class="row mtop30">
    <div class="col-4 ">
      <label>Batch/Group Name<span style="color: red">*</span></label>
      <input type="text" class="form-control " placeholder="Invite Name" #inviteName [(ngModel)]="publicInviteName" name="invitename">
    </div>
    <div class="col-4 ">
      <label>Associate With</label>
      <select class="form-control"  placeholder="Select Category" name="tags" [(ngModel)]="publicTags">
        <option value="" hidden>Select Association</option>
        <option *ngFor="let list of tagsForLink"   [value]="list.name">{{list.name}}</option>

      </select>
    </div>

  </div>



  <!--<div class="row mtop15">-->
  <!--&lt;!&ndash;<div class="col-4 ">&ndash;&gt;-->
  <!--&lt;!&ndash;<label>Block IP Address</label>&ndash;&gt;-->

  <!--&lt;!&ndash;<div class="input-group mb-3">&ndash;&gt;-->
  <!--&lt;!&ndash;<input type="text" class="form-control" placeholder="IP Address" aria-label="Recipient's username"&ndash;&gt;-->
  <!--&lt;!&ndash;aria-describedby="basic-addon2" name="ipAddress" [(ngModel)]="privateBlockIpAddress">&ndash;&gt;-->
  <!--&lt;!&ndash;<div class="input-group-append">&ndash;&gt;-->
  <!--&lt;!&ndash;<i class="fa fa-plus fa-lg" style="color: #2b669a;margin-left: 3px;"></i>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->


  <!--</div>-->


  <div class="row mtop15">
    <div class="col-4">
      <label>Status<span style="color: red">*</span></label>
      <select class="form-control" id="privateinviteStatus" #inviteStatus [(ngModel)]="publicInviteStatus" name="invitestatus">
        <option *ngFor="let list of linkStatus" [value]="list">{{list}}</option>

      </select>
    </div>
  </div>

  <div class="row mt-5">
    <div class=" col-lg-7">
      <ul class="checkboxes-container">
        <li>
          <input id="checkbox1" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt1" [(ngModel)]="publicAuthanticationOTP" />
          <label for="checkbox1" class="styled-checkbox-label text-center">
            <p><img src="../../assets/img/otp.png"  height="55" alt=""></p>
            Authenticate with OTP
          </label>
        </li>

        <li>
          <input id="checkbox2" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt2" [(ngModel)]="TrackLocationForLink" />
          <label for="checkbox2" class="styled-checkbox-label text-center">
            <p><img src="../../assets/img/geo_tracking.png"  height="55" alt=""></p>
            Track Geo Location
          </label>
        </li>

        <li>
          <input id="checkbox3" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt3" [(ngModel)]="IDProofForLink" />
          <label for="checkbox3" class="styled-checkbox-label text-center">
            <p><img src="../../assets/img/id_proof.png"  height="55" alt=""></p>
            ID Proof
          </label>
        </li>

        <li>
          <input id="checkbox4" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt4" [(ngModel)]="candidateAttendanceLink" />
          <label for="checkbox4" class="styled-checkbox-label text-center">
            <p><img src="../../assets/img/Attendee Snapshot.png"  height="55" alt=""></p>
            Attendance Snapshot
          </label>
        </li>
        <li>
          <input id="checkbox5" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt5" [(ngModel)]="candidateSnapshotForLink" />
          <label for="checkbox5" class="styled-checkbox-label text-center">
            <p><img src="../../assets/img/candidate_snapshot.png"  height="55" alt=""></p>
            Candidate Snapshot
          </label>
        </li>
        <li>
          <input id="checkbox6" (click)="proctorActive();" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt6" [(ngModel)]="candidateProctor" />
          <label for="checkbox6" class="styled-checkbox-label text-center">
            <p><img src="../../assets/img/candidate_snapshot.png"  height="55" alt=""></p>
            Proctor
          </label>
        </li>
      </ul>
    </div>
    <!--screen buffer checkbox-->
    <!--<div class="col-lg-7" *ngIf="candidateMandidate && !linkPublic" >
      <ul class="checkboxes-container">
        <li >
          <input id="checkbox8"  type="checkbox" name="screenfreezer" value="screenfreezer" (change)="selectScreenFreezer($event)" [checked]="selectScreenFreezeForLink" >
          <label for="checkbox8" > Enable Screen Feezer</label>
          <span title="Enabling this will mandate the candidate to take the assessment through downloadable EXE application. The candidate will not be able to take the assessment through any browser." > <i class="fa fa-info-circle" style="cursor: pointer;color:skyblue;padding-left:5px;" ></i></span>
        </li>
      </ul>
    </div>-->
    <!--screen buffer checkbox ends-->

    <!--image upload checkbox-->
    <div class="col-lg-7" *ngIf="candidateMandidate && !linkPublic">
      <ul class="checkboxes-container">
        <li class="fileupload">
          <input  style="float:left;width:20px;margin-top: 5px;"id="checkbox7" type="checkbox" name="uploadimage" value="imageupload"  (change)="selectImageUpload($event)" [checked]="imageUploadForLink">
          <label for="checkbox7" style="float:left;width:175px;">Mandate Candidate Image</label>
          <span title="Enabling this will mandate the upload of candidate's profile photo. Link will be active only once the profile photos for all the candidates are uploaded successfully." > <i class="fa fa-info-circle"style="cursor: pointer;color:skyblue;padding-left:5px;" ></i></span>
        </li>
      </ul>
    </div>
    <!-- image upload checkbox ends-->
  </div>


  <div class="row ">
    <div class="col-4 ">
      <h6 class="mtop15">Start Date</h6>
      <my-date-picker name="mydate" [options]="myDatePickerOptions" placeholder="Enter date" [(ngModel)]="publicSTARTDate"
                      (dateChanged)="onDateChanged($event,'start')" required></my-date-picker>
    </div>

    <div class="col-4 ">
      <h6 class="mtop15">Start Time</h6>
      <input class="form-control" id="startTime" placeholder="Select Time" (focus)="onClock('startTime');" name="startTime" [(ngModel)]="publicStartTime">
    </div>
  </div>
  <div class="row mtop10">
    <div class="col-4">
      <h6 class="mtop15">End Date</h6>
      <!--<input type="date" class="form-control " placeholder="Enter Date" name="endDate" [(ngModel)]="publicEndDate">-->
      <my-date-picker name="mydate" [options]="myDatePickerOptions" placeholder="Enter date" [(ngModel)]="publicENDDate" [options]="myDatePickerOptions"
                      (dateChanged)="onDateChanged($event,'end')" required></my-date-picker>
    </div>
    <div class="col-4">
      <h6 class="mtop15">End Time</h6>
      <input class="form-control" id="endTime" placeholder="Select Time" name="endTime" (focus)="onClock('endTime');" [(ngModel)]="publicEndTime">
    </div>
    <!--<div>-->
      <!--<small class="text-danger">{{publicerrorMsg}}</small>-->
    <!--</div>-->
  </div>
  <span style="color:red;">{{editedErrMsg}}</span>
  <div class="mt-3">
    <button *ngIf="!editCandidates" type="button" class="btn btn-primary"  [disabled]="!inviteName.value" (click)="privateLinkStep1();">Next</button>
    <button *ngIf="editCandidates" type="button" class="btn btn-primary"  [disabled]="!inviteName.value" (click)="editedLink();">Save</button>
    <button type="button" class="btn btn-cancel"  (click)="redirectToPlace();">Cancel</button>
  </div>
</div>

<!--end step1 for private link creation-->

<!--end step2 for private link creation-->

<div  *ngIf="privateLinkCreationStep2" class="main-div2 mtop100 bg-white pt-3 pb-5">

  <div class="row mtop30">
    <div class="col">
      <p>ADD Candidates</p>
      <p class="dectTo" (click)="downloadFormat();">Download Candidate List Format</p>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="padding-form" >
        <input type="file" #myInput class="form-control" name="fileUpload" (click)="resetFile();"
               id="inputdefault"  placeholder="Upload file" (change)="fileChangeEvent($event);" accept=".pdf,.doc,.docx">
      </div>
    </div>
    <div *ngIf="isUpload" class="col">
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="previewCandidates();" >Review</button>
    </div>
    <div *ngIf="fileUploadMsg" class="col" class="text-danger">
      <small>{{uploaderrorMsg}}</small>
    </div>
  </div>
  <div class="row mtop30">
    <div class="col">
      <div class="">
        <label>Name</label>
      </div>
    </div>
    <div class="col">
      <div class="">
        <label >Email ID</label>
      </div>
    </div>
    <div class="col">
      <div class="">
        <label >Mobile No.</label>
      </div>
    </div>
  </div>



  <div class="row " *ngFor="let customField of candidatesDetails;let i=index;">

    <div class="col">
      <div class="form-group">

        <input class="form-control" type="text"  attr.id="canname-{{i}}"
               placeholder="Name" name="candidatesName" [value]="candidatesDetails[i].candidatesName">
        <span *ngIf="candidatesADD" class="mb-3">
            <small style="color: red;cursor: pointer" (click)="removeCandidatesDetails(i);">Remove</small>
          </span>

      </div>
    </div>
    <div class="col">
      <div class="form-group">

        <input class="form-control" type="email" #emailID  attr.id="email-{{i}}" id="email"
               pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"  placeholder="Email ID"  name="email" [value]="candidatesDetails[i].email">

        <!--<span *ngIf="pattern.invalid" style="color: red">please fill valid Email ID</span>-->
      </div>
    </div>
    <div class="col">
      <div class="form-group">

        <input #phoneValue class="form-control"  attr.id="phone-{{i}}" OnlyNumber="true" aria-describedby="emailHelp" required
               placeholder=" Mobile No." maxlength="14" minlength="5" name="phone" [value]="candidatesDetails[i].phoneNo">
      </div>
    </div>

  </div>



  <div class="mtop30">
    <p  *ngIf="errorMessage" style="color: red;text-align: center">Enter All Fields.</p>

    <a type="file" id="clickEvent" class="form-control-file" style="cursor: pointer"
       (click)="addCandidateDetails('',i)">+ ADD MORE</a>
  </div>

  <div class="row mtop30">
    <div class="col">
      <div class="form-group">
        <label for="formGroupExampleInput">Remarks </label>
        <textarea class="form-control" id="formGroupExampleInput" placeholder="Remarks" [(ngModel)]="privateSubject"></textarea>
      </div>
    </div>
  </div>


  <div>
    <p style="color: red">{{errorMsg}}</p>
  </div>

  <div>
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="sendInvitation();" >Invite</button>
    <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="redirectToPlace();">Cancel</button>
  </div>


</div>

<!--end step2 for private link creation-->



<!--Public Link Creation Start -->

<div *ngIf="publicLinkCreation" class="container-fluid main-div-invite bg-white pb-5">
  <h5 *ngIf="!inviteCheck" class="mt-5">Invite Details</h5>
  <div class="manageRadio">

    <div *ngIf="!editCandidates" >
      <div >
        <h5>Select mode to publish this assessment</h5>
      </div>
      <div class="row pt-2 ml-2">
        <form [formGroup]="modalLink">

          <div class="form-check form-check-inline" >
            <input type="radio" class="form-check-input" [checked]="true"  name="link" value="public" formControlName="link" (click)="LinkCreation('public', emailID)">
            <label class="form-check-label" for="inlineRadio1">Public Link</label>
          </div>

          <div class="form-check form-check-inline" >
            <input type="radio" class="form-check-input"   name="link" value="private" formControlName="link" (click)="LinkCreation('private',emailID)">
            <label class="form-check-label" for="inlineRadio1">Private Link</label>
          </div>

        </form>
      </div>
    </div>


    <div  class="row mtop30">

      <div class="col-4 ">
        <label>Batch/Group Name<span style="color: red">*</span></label>
        <input type="text" class="form-control " placeholder="Invite Name" #inviteName [(ngModel)]="publicInviteName"  name="invite">
      </div>
      <div class="col-4 ">
        <label >Associate With</label>
        <select class="form-control"  placeholder="Select Category" name="tags" [(ngModel)]="publicTags">
          <option value="" hidden>Select Association</option>
          <option *ngFor="let list of tagsForLink"   [value]="list.name">{{list.name}}</option>
        </select>
      </div>
    </div>




    <!--<div class="col-4 ">-->
    <!--<label >Block IP Address</label>-->

    <!--<div class="input-group mb-3">-->
    <!--<input type="text" class="form-control" placeholder="IP Address" aria-label="Recipient's username"-->
    <!--aria-describedby="basic-addon2" name="ipAddress" [(ngModel)]="publicBlockIpAddress">-->
    <!--<div class="input-group-append">-->
    <!--<i class="material-icons align-middle green">add_circle_outline</i>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->


    <div class="row mtop15">
      <div class="col-4" id="hai">
        <label>Status<span style="color: red">*</span></label>
        <select class="form-control" id="publicinviteStatus" #inviteStatus [(ngModel)]="publicInviteStatus"  name="status">
          <option *ngFor="let list of linkStatus" [value]="list">{{list}}</option>
        </select>
      </div>
      <div *ngIf = "linkPublic" class="col-4 ">
        <label>Access Code <span style="color: red">*</span></label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Test Code" aria-label="Recipient's username"
                 aria-describedby="basic-addon2" #testCode [(ngModel)]="Testcode"  name="test" readonly>
          <div *ngIf="!editCandidates" class="input-group-append">
            <button type="button" (click)="testCodeGeneration();" class="btn btn-link">

              <i class="material-icons align-middle green">refresh</i>
            </button>
          </div>
        </div>
      </div>
    </div>



    <div class="row mt-4">
      <div class="col-lg-7">
        <ul class="checkboxes-container">
          <li>
            <input id="checkbox1" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt1" [(ngModel)]="publicAuthanticationOTP" />
            <label for="checkbox1" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/otp.png"  height="55" alt=""></p>
              Authenticate with OTP
            </label>
          </li>

          <li>
            <input id="checkbox2" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt2" [(ngModel)]="TrackLocationForLink" />
            <label for="checkbox2" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/geo_tracking.png"  height="55" alt=""></p>
              Track Geo Location
            </label>
          </li>

          <li>
            <input id="checkbox3" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt3" [(ngModel)]="IDProofForLink" />
            <label for="checkbox3" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/id_proof.png"  height="55" alt=""></p>
              ID Proof
            </label>
          </li>

          <li>
            <input id="checkbox4" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt4" [(ngModel)]="candidateAttendanceLink" />
            <label for="checkbox4" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/Attendee Snapshot.png"  height="55" alt=""></p>
              Attendance Snapshot
            </label>
          </li>
          <li>
            <input id="checkbox5" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt5" [(ngModel)]="candidateSnapshotForLink" />
            <label for="checkbox5" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/candidate_snapshot.png"  height="55" alt=""></p>
              Candidate Snapshot
            </label>
          </li>
          <li>
            <input id="checkbox6" (click)="proctorActive();" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt6" [(ngModel)]="candidateProctor" />
            <label for="checkbox6" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/candidate_snapshot.png"  height="55" alt=""></p>
              Proctor
            </label>
          </li>
        </ul>
      </div>
      <!--screen buffer checkbox-->
      <!--<div class="col-lg-7" *ngIf="candidateMandidate" >
        <ul class="checkboxes-container">
          <li>
            <input   id="checkbox8" type="checkbox" name="screenfreezer" value="screenfreezer" (change)="selectScreenFreezer($event)" [checked]="selectScreenFreezeForLink"   >
            <label for="checkbox8"  > Enable Screen Feezer</label>
            <span title="Enabling this will mandate the candidate to take the assessment through downloadable EXE application. The candidate will not be able to take the assessment through any browser." > <i class="fa fa-info-circle"style="cursor: pointer;color:skyblue;padding-left:5px;"  ></i></span>
          </li>
        </ul>
      </div>-->
      <!--screen buffer checkbox ends-->
    </div>
    <!--image upload checkbox-->
    <div class="col-lg-7" *ngIf="candidateMandidate && !linkPublic" >
      <ul class="checkboxes-container">
        <li class="fileupload">
          <input  id="checkbox7" type="checkbox" name="uploadimage" value="imageupload"  (change)="selectImageUpload($event)" [checked]="imageUploadForLink">
          <label for="checkbox7" >Mandate Candidate Image</label>
          <span title="Enabling this will mandate the upload of candidate's profile photo. Link will be active only once the profile photos for all the candidates are uploaded successfully." > <i class="fa fa-info-circle" style="cursor: pointer;color:skyblue;padding-left:5px;"  ></i></span>
        </li>
      </ul>
    </div>
    <!-- image upload checkbox ends-->



    <div class="row ">
      <div class="col-4 ">
        <h6 class="mtop15">Start Date</h6>

        <my-date-picker name="mydate" [options]="myDatePickerOptions" placeholder="Enter date" [(ngModel)] = "publicSTARTDate"
                        (dateChanged)="onDateChanged($event,'start')" required></my-date-picker>

      </div>

      <div class="col-4 ">
        <h6 class="mtop15">Start Time</h6>
        <input class="form-control" id="startTime" placeholder="Select Time" (focus)="onClock('startTime');" name="startTime" [(ngModel)]="publicStartTime">
      </div>
    </div>

    <div class="row mtop10">
      <div class="col-4">
        <h6 class="mtop15">End Date</h6>
        <!--<input type="date" class="form-control " placeholder="Enter Date" name="endDate" [(ngModel)]="publicEndDate">-->

        <my-date-picker name="mydate" [options]="myDatePickerOptions" placeholder="Enter date" [(ngModel)]="publicENDDate"
                        (dateChanged)="onDateChanged($event,'end')" required></my-date-picker>

      </div>

      <div class="col-4">
        <h6 class="mtop15">End Time</h6>
        <input class="form-control" id="endTime" placeholder="Select Time" name="endTime" (focus)="onClock('endTime');" [(ngModel)]="publicEndTime">
      </div>
    </div>



    <div *ngIf="linkPrivate" class="mtop30 mt-15">


      <div class="row ">
        <div class="col">
          <p class="dectTo" (click)="downloadFormat();">Download Candidate List Format</p>

        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="padding-form" >
            <input type="file" href="/home/usear/Downloads/Candidate.xlsx" class="form-control" name="fileUpload"
                   id="inputdefault"  placeholder="Upload file" (change)="fileChangeEvent($event);" accept=".pdf,.doc,.docx">
          </div>
        </div>
        <div *ngIf="isUpload" class="col">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="previewCandidates();" >Review</button>
        </div>
        <div *ngIf="fileUploadMsg" class="col" class="text-danger">
          <small>{{uploaderrorMsg}}</small>
        </div>
      </div>

      <div class="row mtop30">
        <div class="col">
          <div class="">
            <label>Name</label>
          </div>
        </div>
        <div class="col">
          <div class="">
            <label >Email ID</label>
          </div>
        </div>
        <div class="col">
          <div class="">
            <label >Mobile No.</label>
          </div>
        </div>

      </div>



      <div class="row" *ngFor="let customField of candidatesDetails;let i=index;">

        <div class="col">
          <div class="form-group">

            <input class="form-control" type="text"  attr.id="canname-{{i}}"
                   placeholder="Name" name="candidatesName" [(ngModel)]="candidateData[i].candidatesName">
            <span *ngIf="candidatesADD" class="mb-3">
            <small style="color: red;cursor: pointer" (click)="removeCandidatesDetails(i);">Remove</small>
          </span>

          </div>
        </div>
        <div class="col">
          <div class="form-group">

            <input class="form-control" type="email" #emailID  attr.id="email-{{i}}"
                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"  placeholder="Email ID"  name="email" [(ngModel)]="candidateData[i].email" >

            <!--<span *ngIf="pattern.invalid" style="color: red">please fill valid Email ID</span>-->
          </div>
        </div>
        <div class="col">
          <div class="form-group">

            <input #phoneValue class="form-control"  attr.id="phone-{{i}}" OnlyNumber="true" aria-describedby="emailHelp" required
                   placeholder="Mobile No." maxlength="14" minlength="5" name="phone" [(ngModel)]="candidateData[i].phoneNo">
            <small class="text-danger" attr.id="duplicate-{{x}}"></small>
          </div>
        </div>
        <!--<div class="col" *ngIf="showFileUpload">-->
          <!--<div class="form-group"  >-->
            <!--<input  class="form-control"  type="file" attr.id="file-{{i}}" name="uploadimage" accept=".png,.gif,.jpeg" (change)="imageuploadfile($event);" id="imageuploads"  [(ngModel)]="imageupload">-->
          <!--</div>-->
        <!--</div>-->
      </div>


      <div class="mtop30">
        <small  *ngIf="errorMessage" class="text-danger mb-3 text-center">Enter All Fields.</small>

        <a type="file" class="form-control-file" style="cursor: pointer"
           (click)="addCandidateDetails('',i)"><i class="material-icons  align-middle">add</i> ADD MORE</a>
      </div>

      <div class="row mtop30">
        <div class="col">
          <div class="form-group">
            <label for="formGroupExampleInput">Remarks </label>
            <textarea class="form-control" id="formGroupExampleInput" placeholder="Remarks" [(ngModel)]="privateSubject"></textarea>
          </div>
        </div>
      </div>


    </div>
      <small style="color: red">{{errorMsg}}</small>
      <small style="color: red">{{publicerrorMsg}}</small>
    <div class="mt-3">
      <button  *ngIf="!editCandidates" [disabled]="!inviteName.value " class="btn btn-primary" (click)="publishAssessmentLink(modalLink.value,imageUploadForLink);" >Publish</button>
      <button *ngIf="editCandidates"  [disabled]="!inviteName.value" class="btn btn-primary" (click)="editedLink();"  >Save</button>

      <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="redirectToPlace();">Cancel</button>
    </div>

  </div>
</div>

<!--end public link creation-->


<div class="modal fade" id="previewCandidtesList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Invite Candidates Link</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row mt-3">
          <div class="col">
            <div class="card ">
              <div class="card-body ">
                <div class="row GR-14">
                  <!--<div class="col-1"></div>-->
                  <div class="col">Name</div>
                  <div class="col">Email ID</div>
                  <div class="col">Mobile No</div>
                  <!--<div class="col">Access Code</div>-->
                  <div class="col-1"></div>
                </div>
              </div>
            </div>
            <div class="card card__two mtop15 border-0" *ngFor="let list of inviteByFileUpload; let i=index;">
              <div class="card-body card-bg" >
                <div class="row" >

                  <!--<div class="col-1 text-center">-->
                  <!--<label class="mb-0"></label>-->
                  <!--</div>-->
                  <div class="col text-truncate qHeading ">{{list.name}}</div>
                  <div class="col text-truncate qRest">{{list.email}}</div>
                  <div class="col text-truncate qRest">{{list.mobile}}<span *ngIf="list.isValidInfo == false " class="ml-3 text-danger">{{list.error.name}}</span></div>
                  <!--<div class="col qRest">{{list.accessCode}}</div>-->
                  <div class="col-1">
                    <button type="button" class="btn float-right btnMore" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; cursor: pointer; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer ">

      </div>
    </div>
  </div>
</div>


<!--<div class="modal fade" id="viewCandidtesList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">-->
<!--<div class="modal-dialog modal-lg" role="document">-->
<!--<div class="modal-content">-->
<!--<div class="modal-header">-->
<!--<h5 class="modal-title" >Invite Candidates Link</h5>-->

<!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--<span aria-hidden="true">&times;</span>-->
<!--</button>-->

<!--</div>-->
<!--<div class="modal-body">-->

<!--<div class="row">-->

<!--&lt;!&ndash;<div class="col-md-8">&ndash;&gt;-->
<!--&lt;!&ndash;<form action="" class="Search">&ndash;&gt;-->
<!--&lt;!&ndash;<input class="Search-box" type="search" id="Search-box" autocomplete="off" placeholder="Search" name="terms" [(ngModel)]="terms">&ndash;&gt;-->
<!--&lt;!&ndash;<label class="Search-label" for="Search-box"><i class="material-icons">search</i></label>&ndash;&gt;-->
<!--&lt;!&ndash;</form>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->


<!--&lt;!&ndash;<button type="button" class="btn btn-group-sm" style=" cursor: pointer" (click)="removeModal();">Select All</button>&ndash;&gt;-->
<!--<div class="col-md-12">-->
<!--<button type="button" class="btn btn-primary float-right" data-dismiss="modal" style="background-color: #0f74a8; cursor: pointer" (click)="addPreviewCandidates();">Add New</button>-->
<!--<button   *ngIf="multipleDelete" class="btn btn-link float-right" (click)="deleteMultipleCandidates()">-->
<!--<span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>-->
<!--</button>-->
<!--</div>-->

<!--</div>-->

<!--<div *ngIf="inviteCandidates.length != 0" class="row mt-3">-->
<!--<div *ngIf="terms == '' " class="col">-->
<!--<div class="card ">-->
<!--<div class="card-body ">-->
<!--<div class="row GR-14">-->
<!--<div class="col-1"><input [(ngModel)]="checkBoxSelect[(currentIndex - 1)]" type="checkbox" (click)="removeModal();"></div>-->
<!--<div class="col">Name</div>-->
<!--<div class="col">Email ID</div>-->
<!--<div class="col">Mobile No</div>-->
<!--<div class="col">Access Code</div>-->
<!--&lt;!&ndash;<div class="col-1"></div>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="card card__two mtop15 border-0" *ngFor="let list of inviteCandidates; let i=index;">-->
<!--<div class="card-body card-bg" >-->
<!--<div class="row" >-->

<!--<div class="col-1 ">-->
<!--<label class="mb-0"> <input name="check" [checked]="checkCheckBox1(list.mobile)" value="{{list.mobile}}" (change)="selectedDeleteCan($event, list.mobile);" type="checkbox" [value]="deleteData.indexOf([list.mobile]) >= 0 "></label>-->
<!--</div>-->
<!--<div class="col text-truncate qHeading ">{{list.name}}</div>-->
<!--<div class="col text-truncate qRest">{{list.email}}</div>-->
<!--<div class="col text-truncate qRest">{{list.mobile}}</div>-->
<!--<div class="col qRest">{{list.accessCode}}</div>-->
<!--&lt;!&ndash;<div class="col-1">&ndash;&gt;-->
<!--&lt;!&ndash;<button type="button" class="btn float-right btnMore" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; cursor: pointer; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">&ndash;&gt;-->

<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--&lt;!&ndash;<div *ngIf="terms != ''" class="col">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="card ">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="card-body ">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="row heading2">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col-1"><input [(ngModel)]="checkBoxSelect[(currentIndex - 1)]" type="checkbox" (click)="removeModal();"></div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col">Name</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col">Email ID</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col">Mobile No</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col">Access Code</div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<div class="col-1"></div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="card card__two mtop15 border-0" *ngFor="let list of inviteCandidates| filter:terms; let i=index;">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="card-body card-bg" >&ndash;&gt;-->
<!--&lt;!&ndash;<div class="row" >&ndash;&gt;-->

<!--&lt;!&ndash;<div class="col-1 ">&ndash;&gt;-->
<!--&lt;!&ndash;<label class="mb-0"> <input name="check" [checked]="checkCheckBox1(list.mobile)" value="{{list.mobile}}" (change)="selectedDeleteCan($event, list.mobile);" type="checkbox" [value]="deleteData.indexOf([list.mobile]) >= 0 "></label>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col text-truncate qHeading ">{{list.name}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col text-truncate qRest">{{list.email}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col text-truncate qRest">{{list.mobile}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col qRest">{{list.accessCode}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<div class="col-1">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<button type="button" class="btn float-right btnMore" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; cursor: pointer; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;&lt;!&ndash;</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->


<!--</div>-->

<!--<div *ngIf="inviteCandidates.length == 0" class="text-center mt-5">-->
<!--<h6>Candidate list is not available</h6>-->
<!--</div>-->

<!--</div>-->

<!--<div class="modal-footer ">-->


<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->


<div class="modal fade" id="removeALL" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Do you want to remove all the candidates detail?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="removeAllCandidates();">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="candidatesLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog " role="document">
    <div class="modal-content" >
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Candidate Location</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pl-3">
        <h5 style="color: #0f74a8"class="pl-2">Start Location  <span class="ml-2" style="font-size: 15px;">{{startLocation}}</span></h5>
        <div class="vr"><img class=" " src="/assets/img/location.png" alt=""></div>
        <div class="row pl-2">
          <div class="col-md-12">
            <div>

              <div #gmapstart style="width:100%;height:150px"></div>
              <div class="map-data">{{startLocationMark}}</div>

            </div>
          </div>
        </div>

        <h5 class="mt-4 pl-2" style="color: #0f74a8">End Location <span class="ml-2" style="font-size: 15px;">{{endLocation}}</span></h5>
        <div class="row pl-2">
          <div class="col-md-12">
            <div >

              <div #gmapend style="width:100%;height:150px"></div>
              <div class="map-data">{{endLocationMark}}</div>

            </div>
          </div>
        </div>

      </div>

      <div class="modal-footer d-block mx-auto">

      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="scheduleTime" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" >
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Individual candidate schedule</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="resetDateTimeScheduling();">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row ">
          <div class="col-6">
            <h6 class="mtop15">Start Date</h6>
            <!--<input type="date" class="form-control " placeholder="Enter Date" name="startDate" [(ngModel)]="publicStartDate">-->

            <my-date-picker name="mydate" [options]="myDatePickerOptions" placeholder="Enter date" [(ngModel)]="publicSTARTDate"
                            (dateChanged)="onDateChanged($event,'start')" required></my-date-picker>

          </div>

          <div class="col-6 ">
            <h6 class="mtop15">Start Time</h6>
            <input class="form-control" id="startTime" placeholder="Select Time" (focus)="onClock('startTime');" name="startTime" [(ngModel)]="publicStartTime">
          </div>
        </div>

        <div class="row mtop10">
          <div class="col-6">
            <h6 class="mtop15">End Date</h6>
            <!--<input type="date" class="form-control " placeholder="Enter Date" name="endDate" [(ngModel)]="publicEndDate">-->

            <my-date-picker name="mydate" [options]="myDatePickerOptions" placeholder="Enter date" [(ngModel)] = "publicENDDate"
                            (dateChanged)="onDateChanged($event,'end')" required></my-date-picker>

          </div>

          <div class="col-6">
            <h6 class="mtop15">End Time</h6>
            <input class="form-control " placeholder="Select Time" id="endTime" (focus)="onClock('endTime');" name="endTime" [(ngModel)]="publicEndTime">
          </div>
        </div>

      </div>
      <small style="color: red" class="text-center">{{publicerrorMsg}}</small>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary"  (click)="dateTimeScheduling();">Save</button>
      </div>
    </div>
  </div>
</div>


<div class="modal show" id="candidateReset" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <form [formGroup]="modalResetCandidate">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Test Configuration</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" (click)="modalResetCandidate.reset();">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="row ">
            <div class="col">
              <div class="form-group">
                <label><h6>Reason</h6></label>
                <textarea class="form-control" id="formGroupExampleI" placeholder="Reason" formControlName="Resetreason" [(ngModel)]="CandidatesResetReson"></textarea>
              </div>
            </div>
          </div>

          <div class="manageRadio ">
            <div>
              <h6>Select Mode for the Test</h6>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" name="reset" value="1" formControlName="reset">
              <label class="form-check-label" for="inlineRadio1">Reset</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" name="reset" value="2" formControlName="reset">
              <label class="form-check-label" for="inlineRadio1">Resume</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" name="reset" value="3" formControlName="reset">
              <label class="form-check-label" for="inlineRadio1">Reset & Resume</label>
            </div>
          </div>
          <div>
            <small class="text-danger">{{publicerrorMsg}}</small>
          </div>

        </div>
        <div class="modal-footer d-block mx-auto">
          <button type="button" [disabled]="modalResetCandidate.invalid" class="btn btn-primary"
                  (click)="CandidateRRReson(modalResetCandidate.value)">Submit
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" aria-label="Close"
                  (click)="modalResetCandidate.reset();">Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>


<div class="modal fade" id="CandidateSnapShot" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Candidate Snapshot</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mt-3">

        <div class="row ">
          <div class="col">

            <div  class="" style="width: 630px;margin: 0 auto;">

              <div *ngIf="candidateAttendanceSnapshot != null " style="width:200px;float:left;margin: 5px;">
                <img style="height: 200px;width: 200px" src={{candidateAttendanceSnapshot.fileName}}>
                <p class="text-center mb-0 mt-3 GR-14">Attendance Snapshot</p>
                <p class="text-center GR-12">{{candidateAttendanceSnapshot.snapShot}}</p>
                <!--<p class="text-center GR-12">{{candidateAttendanceSnapshot.snapShotTime.substring(0,10)}} {{candidateAttendanceSnapshot.snapShotTime | date:'shortTime'}}</p>-->

                <!--<p class="text-center GR-12">{{CandidateData.appearedOn.substring(0,10)}} {{CandidateData.appearedOn | date:'shortTime'}}</p>-->
              </div>

              <div *ngIf="candidateCaputerdSnapshot.length > 0 " >
                <div *ngFor="let image of candidateCaputerdSnapshot; let i = index" style="float: left;width: 200px;margin: 5px;">

                  <img style="height: 200px;width: 200px" src={{image.fileName}}>
                  <p class="text-center mb-0 mt-3 GR-14">Candidate Snapshot</p>
                  <!--<p class="text-center GR-12">{{image.snapShotTime.substring(0,10)}} {{image.snapShotTime | date:'shortTime'}}</p>-->
                  <p class="text-center GR-12">{{image.snapShot}}</p>

                </div>
              </div>

            </div>


          </div>
        </div>

        <div *ngIf="candidateCaputerdSnapshot.length == 0 && candidateAttendanceSnapshot == null " class="text-center">
          <h5 class="text-info">No records found</h5>
        </div>

      </div>
      <div class="modal-footer float-right">
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="proctor" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Selecting Proctor will enable Track-Location, candidate snapshot and attendance snapshot automatically. You will not be allowed to disable the same.
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="proctorActivate();">Ok</button>
        <button type="button" class="btn btn-cancel" dsata-dismiss="modal" (click)="proctorcancelation();">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" >Upload Candidate Image</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clearFileData();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
       <input type="file" name="upload" value="imageuploaded" id="imagevalidation" (change)="checkUploadImage($event)"><br><i >(Supported extensions: .jpg,.jpeg,.png)  Maximum 2 MB</i>
      </div>

      <div class="modal-footer d-block mx-auto" >
            <button type="button"  class="btn btn-primary" [disabled]='!afterSelect' (click)="imageUploadActive()" >Upload</button>
      </div>

      <div *ngIf="uploadedFileErrMsg != ''">
        <p style="color:red; text-align: center; padding-top: 5px;" >{{uploadedFileErrMsg}}</p>
      </div>
      <!--<div *ngIf="multipleFaceErrorMsg != '' && somethingOtherErrorMessage !=''">-->
       <!---->
        <!--<p style="color:red; text-align: center;" >{{multipleFaceErrorMsg}}</p><br>-->
        <!--<p style="color:red; text-align: center;margin-top:-28px;" >{{somethingOtherErrorMessage}}</p>-->
      <!--</div>-->
      <!--<div>-->
        <!--&lt;!&ndash;<p style="color:red; text-align: center; padding-top: 5px;" >{{uploadedFileErrMsg}}</p>&ndash;&gt;-->
      <!--</div>-->

      <div class="processingLoader" *ngIf="isUploadingCandImg">
        <i class="fa fa-refresh fa-spin"></i> Processing...
      </div>

    </div>
  </div>
</div>

<!--assesment creat link publish-link popup-->
<div class="modal fade" id="publishPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        The link will be published in inactive status until all the candidate image(s) is/are uploaded sucessfully.<br>Do you wish to proceed ?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal"  (click)="assesmentProceed();">Proceed</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="cancleProceed();" >Cancel</button>
      </div>
    </div>
  </div>
</div>
<!--assesment creat link publish-link popup ends here-->


<!--assesment candidate mandidate checkbox popup-->
<div class="modal fade" id="candidatePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Enabling this will mandate the upload of candidate's profile photo. Link will be active only once the profile photos for all the candidates are uploaded successfully.      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal"  (click)="jobMadidate();">Ok</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="cancleJobMandidate();" >Cancel</button>
      </div>
    </div>
  </div>
</div>
<!--assesment candidate mandidate checkbox popup ends here-->

<!--job screen freeze checkbox popup-->
<div class="modal fade" id="screenPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Enabling this will mandate the candidate to take the assessment through downloadable EXE application. The candidate will not be able to take the assessment through any browser.
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal"  (click)="jobScreenFreeze();">Ok</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="cancleScreenFreeze();" >Cancel</button>
      </div>
    </div>
  </div>
</div>
<!--job screen freeze checkbox popup ends here-->


<img class="p-by" src="assets/img/p-by.png" alt="">
