<app-user-header></app-user-header>
<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item active" aria-current="page">
            <a>My Profile</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</nav>

<div>
  <div class="container main-div">
    <div class="row bg-white pt-3 pr-3 pb-3 pl-3">
      <div class="col-7">
        <div class="card">
          <div class="card-header" style="display: flex">
            <h5 class="col-4 pl-0 mb-0">Profile Info</h5>

            <button *ngIf="userRole == 'admin'" routerLink="/profile/edit" type="submit" id="submit"
              class="btn btn-login  col-3" value="Login">
              Edit Profile
            </button>

          </div>
          <div class="card-body" style="display: flex">
            <div>
              <a id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                <img [src]="userDetails.Companies[0].CompanyLogo" width="100%" alt="profile" class="mt-2 pro-img" />
              </a>
            </div>

            <div class="col ">
              <p class="mb-0">
                <strong>{{
                  userDetails.FirstName + " " + userDetails.LastName
                  }}</strong>
              </p>
              <p class="mb-0">{{ userDetails.Companies[0].CompanyName }}</p>
              <p class="mb-0">
                {{ userDetails.MobileCountryCode }} {{ userDetails.MobileNo }}
              </p>
              <p>{{ userDetails.Companies[0].EmailId }}</p>
              <strong *ngIf="
                  (userDetails.Companies[0].SupportEmail != '' ||
                    userDetails.Companies[0].SupportMobile != '') &&
                  userRole == 'admin'
                ">Support details</strong>
              <p class="mb-0" *ngIf="
                  userDetails.Companies[0].SupportEmail != '' &&
                  userRole == 'admin'
                ">
                {{ userDetails.Companies[0].SupportEmail }}
              </p>
              <p class="mb-0" *ngIf="
                  userDetails.Companies[0].SupportMobile != '' &&
                  userRole == 'admin'
                ">
                {{ userDetails.Companies[0].SupportMobile }}
              </p>
            </div>
          </div>
        </div>


      </div>
      <div class="col-5">
        <div class="card">
          <div class="card-header" style="display: flex">
            <h5>Account Settings</h5>

          </div>
          <div class="card-body">
            <div class="row pt-2">
              <div class="col col-8">Premium Access</div>
              <div *ngIf="userDetails.Companies[0].IsPremiumAccount" class="col col-4 text-right">
                <strong>Enabled</strong>
              </div>
              <div *ngIf="!userDetails.Companies[0].IsPremiumAccount" class="col col-4 text-right">
                <strong>Disabled</strong>
              </div>

            </div>
            <div class="row pt-2">
              <div class="col col-8">Question Bank Assets Access</div>
              <div *ngIf="userDetails.Companies[0].MasterQuestionAccess=='allowed'" class="col col-4 text-right">
                <strong>Enabled</strong>
              </div>
              <div *ngIf="userDetails.Companies[0].MasterQuestionAccess!='allowed'" class="col col-4 text-right">
                <strong>Disabled</strong>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col col-8">Subscription Count</div>
              <div *ngIf="userDetails.Companies[0].IsPremiumAccount" class="col col-4 text-right"><strong>N/A</strong>
              </div>
              <div *ngIf="!userDetails.Companies[0].IsPremiumAccount" class="col col-4 text-right">
                <strong>{{userDetails.Companies[0].SubscriptionCount}}</strong>
              </div>

            </div>


          </div>
        </div>
      </div>

    </div>
    <div class="row bg-white pt-3 pr-3 pb-3 pl-3">
      <div class="col">
        <div style="display: flex;">
          <strong>Company Brief : </strong>
          <p class="mb-0" *ngIf="userDetails.Companies[0].CompanyDescription != ''"
            [innerHTML]="userDetails.Companies[0].CompanyDescription"> </p>
        </div>
        <strong>Website : </strong>
        <a class="mb-0" *ngIf="userDetails.Companies[0].CompanyUrl != ''"
          href="{{ userDetails.Companies[0].CompanyUrl }}" target="_blank">{{ userDetails.Companies[0].CompanyUrl }}</a>
      </div>
    </div>
    <div class="row bg-white pt-3 pr-3 pb-3 pl-3">
      <div class="col-12">
        <div class="card">
          <h5 class="card-header">Choose your login method</h5>
          <div class="card-body">
            <div *ngIf="showPasswordButton" class="row">
              <div class="col">
                <ul class="radio-container ml-4 mt-3">
                  <li *ngIf="!OTPBasedLogin" style="filter: drop-shadow(5px 5px 10px #eee)">
                    <label (click)="changePassword()" class="styled-radio-label checkedAll text-center">
                      <p>
                        <img src="../../assets/img/change-password.png" height="60" alt="" />
                      </p>
                      Change Password
                    </label>
                  </li>
                  <li *ngIf="!changedPassword" style="filter: drop-shadow(5px 5px 10px #eee)">
                    <input id="radio2" (click)="changeOtpBased()" class="styled-radio" name="radio1" type="checkbox"
                      value="upload" />
                    <label for="radio2" class="styled-radio-label text-center">
                      <p>
                        <img src="../../assets/img/otp-based-login.png" height="60" alt="" />
                      </p>
                      OTP Based Login
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div *ngIf="changePasswordButton" class="row">
              <div class="col">
                <ul class="radio-container ml-4 mt-3">
                  <li *ngIf="!OTPBasedLogin" style="filter: drop-shadow(5px 5px 10px #eee)">
                    <label (click)="changePassword()" class="styled-radio-label text-center">
                      <p>
                        <img src="../../assets/img/change-password.png" height="60" alt="" />
                      </p>
                      Set Password
                    </label>
                  </li>
                  <li *ngIf="!OTPBasedLogin" style="filter: drop-shadow(5px 5px 10px #eee)">
                    <input style="cursor: default !important" id="radio2" class="styled-radio" name="radio1"
                      type="checkbox" value="upload" />
                    <label style="cursor: default !important" for="radio2"
                      class="styled-radio-label checkedAll text-center">
                      <p>
                        <img src="../../assets/img/otp-based-login.png" height="60" alt="" />
                      </p>
                      OTP Based Login
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <form (ngSubmit)="onSubmit(feed2)" #feed2="ngForm">
              <div *ngIf="OTPBasedLogin" class="row">
                <div class="col">
                  <h6>Change to OTP based login</h6>
                  <div class="input-group mtop30 col-md-5 no-padding-left">
                    <input appPassword (input)="errorMsgRemove($event)" type="password" autocomplete="false"
                      class="verifyMobile rbw-0 form-control" id="passwordd" #passwordd="ngModel" [(ngModel)]="password"
                      name="password" required placeholder="Enter current Password" reverse="false" />
                  </div>
                  <div class="mb-3">
                    <small class="text-danger">{{ matchPassword }}</small>
                  </div>
                  <div class="row pt-3">
                    <button [disabled]="
                          passwordd.invalid ||
                          disableButton ||
                          matchPassword != ''
                        " class="btn btn-primary" id="submitPassword" (click)="submitPassword()">
                      Continue
                    </button>
                    <button class="btn btn-cancel" (click)="skipCreatePassword()">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!--set password implementation-->
            <form (ngSubmit)="onSubmit(feed1)" #feed1="ngForm">
              <div *ngIf="setPassword" class="row">
                <div class="col">
                  <h6>Set Password</h6>
                  <div class="input-group mtop30 col-md-5 no-padding-left">
                    <input appPassword (blur)="matchPasswordFormat($event)" (keyup)="passwordMatch()" type="password"
                      autocomplete="false" class="verifyMobile rbw-0 form-control" id="passwordd" #passwordd="ngModel"
                      [(ngModel)]="password" name="password"
                      pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$" popover="{{ pwdFormat }}"
                      [popoverCloseOnClickOutside]="true" popoverPlacement="right" required placeholder="Enter Password"
                      reverse="false" />
                  </div>
                  <div class="mb-3">
                    <small *ngIf="invalidPassword" class="text-danger">
                      {{ passwordValidation }}
                      <i class="material-icons password-info-icon" popover="{{ pwdFormat }}" [popoverOnHover]="true"
                        [popoverCloseOnMouseOutside]="true" popoverPlacement="right">info</i>
                    </small>
                  </div>
                  <div class="form-group col-md-5 no-padding-left">
                    <input (keyup)="confirmMatch()" type="password" autocomplete="false"
                      pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$" class="verifyMobile form-control"
                      id="cPassword" #cPassword="ngModel" [(ngModel)]="confirmPassword" name="confirmPassword" required
                      placeholder="Confirm Password" validateEqual="password" reverse="false" />
                    <small class="text-danger">{{ matchPasswordd }}</small>
                  </div>
                  <!--<small id="emailHelp" class="form-text text-muted float-right"><a  class="text-info">Forgot Password?</a></small>-->
                  <div class="row pt-3">
                    <button [disabled]="
                          passwordd.invalid ||
                          feed1.invalid ||
                          cPassword.invalid ||
                          invalidPassword ||
                          matchPassword != ''
                        " class="btn btn-primary" id="submitPassword" (click)="submitPassword()">
                      Continue
                    </button>
                    <button class="btn btn-cancel" (click)="skipCreatePassword()">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!--change password implementation-->
            <form (ngSubmit)="onSubmit(feed)" #feed="ngForm">
              <div *ngIf="changedPassword" class="row">
                <div class="col">
                  <h6>Change Password</h6>

                  <div class="input-group mtop30 col-md-5 no-padding-left">
                    <input appPassword (keyup)="errorMsgRemove($event)" type="password" autocomplete="false"
                      class="form-control rbw-0 verifyMobile" id="curpasswordd" #curpasswordd="ngModel"
                      [(ngModel)]="currentPassword" name="curpasswordd" required placeholder="Current Password"
                      reverse="false" />
                  </div>
                  <div class="mb-0 pb-0 small text-right col-md-5">
                    <a (click)="forgotPassword.show()" class="forgot-password">Forgot Password</a>
                  </div>
                  <div class="mb-3">
                    <small class="text-danger">{{ matchPassword }}</small>
                  </div>

                  <div class="input-group mtop30 col-md-5 no-padding-left">
                    <input appPassword (keyup)="passwordMatch()" (blur)="matchPasswordFormat($event)" type="password"
                      autocomplete="false" class="form-control rbw-0 verifyMobile" id="setpassword"
                      #setpassword="ngModel" popover="{{ pwdFormat }}" [popoverCloseOnClickOutside]="true"
                      popoverPlacement="right" [(ngModel)]="password" name="password"
                      pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$" required
                      placeholder="New Password" reverse="false" />
                  </div>
                  <div class="mb-3">
                    <small *ngIf="invalidPassword" class="text-danger">
                      {{ passwordValidation }}
                      <i class="material-icons password-info-icon" popover="{{ pwdFormat }}" [popoverOnHover]="true"
                        [popoverCloseOnMouseOutside]="true" popoverPlacement="right">info</i>
                    </small>
                  </div>

                  <div class="form-group col-md-5 no-padding-left">
                    <input (keyup)="confirmMatch()" type="password" autocomplete="false"
                      class="form-control verifyMobile" id="conPassword" #conPassword="ngModel"
                      [(ngModel)]="confirmPassword" name="confirmPassword"
                      pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$" required
                      placeholder="Confirm Password" validateEqual="password" reverse="false" />
                    <small class="text-danger">{{ matchPasswordd }}</small>
                  </div>
                  <!--<small id="emailHelp" class="form-text text-muted float-right"><a  class="text-info">Forgot Password?</a></small>-->
                  <div class="row pt-3">
                    <button [disabled]="
                          setpassword.invalid ||
                          conPassword.invalid ||
                          curpasswordd.invalid ||
                          feed.invalid ||
                          invalidPassword ||
                          matchPassword != ''
                        " class="btn btn-primary" (click)="submitPassword()">
                      Continue
                    </button>
                    <button class="btn btn-cancel" (click)="skipCreatePassword()">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <!-- end for change password implementation-->
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-white pt-3 pr-3 pb-3 pl-3" *ngIf="showIntegrationBlock && userRole == 'admin'">
      <div class="col">
        <div class="card">
          <h5 class="card-header">Integration</h5>
          <div class="card-body">
            <div *ngIf="integrationAPIKey != ''" id="linkGener" class="ml-4 mt-4">
              <p style="font-weight: bold">
                API Key :
                <span style="font-weight: normal">{{
                  integrationAPIKey
                  }}</span>
                &nbsp;
                <i style="color: #00b3d4; cursor: pointer" class="fa fa-copy pr-2" tooltip="copy"
                  (click)="copyLink(integrationAPIKey)" aria-hidden="true"></i>
              </p>
            </div>
            <div *ngIf="integrationAPIKey == ''" class="ml-4 mt-4">
              <p style="font-weight: bold">
                API Key :
                <span style="font-weight: normal; color: grey">Not set yet</span>
              </p>
            </div>

            <div *ngIf="integrationAPISecretKey != ''" class="ml-4 mt-4">
              <p style="font-weight: bold">
                API Secret Key :
                <span style="font-weight: normal">{{
                  integrationAPISecretKey
                  }}</span>
                &nbsp;
                <i style="color: #00b3d4; cursor: pointer" class="fa fa-copy pr-2" tooltip="copy"
                  (click)="copyLink(integrationAPISecretKey)" aria-hidden="true"></i>
              </p>
            </div>
            <div *ngIf="integrationAPISecretKey == ''" class="ml-4 mt-4">
              <p style="font-weight: bold">
                API Secret Key :
                <span style="font-weight: normal; color: grey">Not set yet</span>
              </p>
            </div>

            <div class="ml-4 mt-2" *ngIf="integrationAPIKeysCreatedOn != ''">
              <span class="help-block"><i class="fa fa-info-circle" aria-hidden="true"></i> Active
                since
                {{
                integrationAPIKeysCreatedOn | date: "dd/MM/yyyy hh:mm:ss a"
                }}</span>
            </div>

            <div *ngIf="integrationAPIKey == '' && integrationAPISecretKey == ''" class="ml-3 mt-3">
              <button type="button" class="btn btn-primary mtop10 ml-0" (click)="getApiKeyRes()">
                <i class="fa fa-refresh pr-2" aria-hidden="true"></i>Generate
                Keys
              </button>
            </div>

            <div *ngIf="integrationAPIKey != '' && integrationAPISecretKey != ''" class="ml-4 mt-3">
              <button type="button" class="btn btn-primary mtop10 ml-0" (click)="regenerateKeys.show()">
                <i class="fa fa-refresh pr-2" aria-hidden="true"></i>Regenerate Keys
              </button>
            </div>

            <div class="ml-4 mt-5">
              <label>
                <h6>
                  Webhook URL
                  <i class="fa fa-info-circle pr-2 webhook-info" popover={{webhookInfo}} [popoverOnHover]="true"
                    [popoverCloseOnMouseOutside]="true" popoverPlacement="right"   aria-hidden="true"></i>


                </h6>
              </label>

              <form [formGroup]="webhookform" (ngSubmit)="submit()" *ngIf="resWebhookUrl == '' || textWebhookUrl"
                class="form-inline">
                <input formControlName="url" type="url" class="form-control" id="Webhook" name="Webhook"
                  [(ngModel)]="webhookUrlInput" style="width: 80%" placeholder="Enter Webhook URL" />

                <button type="button" class="btn btn-primary mtop18" [disabled]="!webhookform.valid"
                  (click)="webhookmodal.show()">
                  <i class="fa fa-save pr-2" aria-hidden="true"></i>Save
                </button>
                <small *ngIf="errorwebhook" class="text-danger">{{
                  errormsg
                  }}</small>
                <div *ngIf="f.url.touched && f.url.invalid">
                  <small *ngIf="f.url.errors.required" class="text-danger">URL is required.</small>
                  <small *ngIf="f.url.errors.pattern" class="text-danger">Please enter valid url.</small>
                </div>
              </form>

              <div *ngIf="resWebhookUrl != '' && !textWebhookUrl">
                <h6>
                  {{ resWebhookUrl }} &nbsp;
                  <i style="color: #00b3d4; cursor: pointer" class="fa fa-pencil pr-2" (click)="opentext()"
                    aria-hidden="true"></i>
                </h6>
              </div>
            </div>

            <div class="ml-4 mt-5">
              API Documentation:
              <a href="https://cdn.calibrat.com/static/Calibrat-Integration-Document.pdf" target="_blank"
                class="pdf-document-link"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                Download</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row bg-white pt-3 pr-3 pb-3 pl-3" *ngIf="!showIntegrationBlock && userRole == 'admin'">
      <div class="col">
        <div class="card">
          <h5 class="card-header">Integration</h5>
          <div class="card-body">
            <div class="grey">
              <p>
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum
                passages.
              </p>
              <button class="btn btn-primary ml-0 mt-2" (click)="integrationNo.show()">
                <i class="fa fa-envelope pr-2" aria-hidden="true"></i>Contact
                Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<img class="p-by" src="assets/img/p-by.png" alt="" />

<div class="modal fade" bsModal tabindex="-1" role="dialog" #integrationNo="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <form>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="integrationNo.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6 class="text2 text-center mt-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </h6>
        </div>
        <div class="modal-footer d-block mx-auto mb-3">
          <button type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="integrationNo.hide(); ConfirmFunction()">
            Confirm
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="integrationNo.hide()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- end for modal-->

<div class="modal fade" bsModal tabindex="-1" role="dialog" #regenerateKeys="bs-modal"
  [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <form>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Regenerate Keys</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="regenerateKeys.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6 class="text2 text-center mt-2">
            Regenerating a new key set will automatically deactivate the
            existing ones and will suspend the integration. Replace the old
            key set with new ones to reactivate the process.
            <br /><br />Click 'OK' to continue.
          </h6>
        </div>
        <div class="modal-footer d-block mx-auto mb-3">
          <button type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="regenerateKeys.hide(); getApiKeyRes()">
            OK
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="regenerateKeys.hide()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- end for modal-->

<div class="modal fade" bsModal tabindex="-1" role="dialog" #webhookmodal="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <form>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Webhook Reference</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="webhookmodal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6 class="text2 text-center mt-2">
            You are about to update the webhook reference of your application.
            Click 'OK' to continue.
          </h6>
        </div>
        <div class="modal-footer d-block mx-auto mb-3">
          <button type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="webhookmodal.hide(); getWebhookRes()">
            OK
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="webhookmodal.hide()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- end for modal-->

<div class="modal fade" bsModal tabindex="-1" role="dialog" #forgotPassword="bs-modal"
  [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <form>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Forgot Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="forgotPassword.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6 class="text2 text-center mt-2">
            You are about to initiate a process to reset your password.
          </h6>
        </div>
        <div class="modal-footer d-block mx-auto mb-3">
          <button type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="forgotPassword.hide(); forGotPassword()">
            Continue
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="forgotPassword.hide()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- end for modal-->
