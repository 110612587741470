import { Component, OnInit, ViewChild } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { VerifyService } from '../verify-comp/verify-comp.service';
import {Form, NgForm} from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [VerifyService]
})

export class ProfileComponent implements OnInit {
  userRole: any;
  webhookform: FormGroup = new FormGroup({});
  integrationAPIKey: string;
  integrationAPISecretKey: string;
  integrationAPIKeysCreatedOn: string;
  integrationAPIClientId: string;
  webhookUrlInput = '';
  resWebhookUrl = '';
  webhookInfo = 'We will notify you about various events by POSTing data on this URL. Please specify the complete URL and make sure it starts with https://.';
  accessToken: string;
  companyId: string;
  userDetails: any;
  showPasswordButton: boolean;
  changePasswordButton: boolean;
  changedPassword: boolean;
  OTPBasedLogin: boolean;
  currentPassword = '';
  setPassword: boolean;
  changeCompany = false;
  password: string;
  confirmPassword: string;
  passwordValidation: string;
  matchPassword: string;
  matchPasswordd: string;
  logoMessage: string;
  invalidPassword: boolean;
  disableButton: Boolean = false;
  pwdFormat = 'Password must contain minimum 8 and maximum 15 characters, including at least one uppercase alphabet, one lowercase alphabet, one numeric digit and one special character. Password must not contain spaces';
  @ViewChild('feed') feed: any;
  @ViewChild('feed1') feed1: any;
  @ViewChild('forgotPassword') forgotPassword: ModalDirective;
  showIntegrationBlock: boolean;
  textWebhookUrl = false;
  errormsg: any;
  errorwebhook = false;


  constructor(public verifyservice: VerifyService, private toastrService: ToastrService,private fb: FormBuilder) {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.webhookform = fb.group({
      url: ['', [Validators.required, Validators.pattern(reg)]]
    })
    this.changeCompany = false;
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
  }
  get f() {
    return this.webhookform.controls;
  }
  submit() {

  }
  ngOnInit() {

    this.showPasswordButton = false;
    this.changePasswordButton = false;
    this.changedPassword = false;
    this.OTPBasedLogin = false;
    this.currentPassword = '';
    this.setPassword = false;
    this.changeCompany = false;
    this.password = '';
    this.confirmPassword = '';
    this.passwordValidation = '';
    this.matchPassword = '';
    this.matchPasswordd = '';
    this.logoMessage = '';
    this.invalidPassword = false;
    this.userDetails = JSON.parse(localStorage.getItem('userInfo'));
    this.userRole = this.userDetails.Companies[0].RoleId;
    if (this.userDetails.IsPassword === true ) {
      this.showPasswordButton = true;
      this.changePasswordButton = false;
    } else {
      this.changePasswordButton = true;
      this.showPasswordButton = false;
    }

    this.showIntegrationBlock = localStorage.getItem('showIntegrationBlock') === 'true' ? true : false;
    this.integrationAPIKey = localStorage.getItem('apiKey');
    this.integrationAPISecretKey = localStorage.getItem('apiSecretKey');
    this.integrationAPIKeysCreatedOn = localStorage.getItem('activeKeySetCreatedOn');
    this.integrationAPIClientId = this.userDetails.Companies[0].ClientId;
    this.resWebhookUrl = localStorage.getItem('webhookRef');
  }

  changePassword() {
    if (this.changePasswordButton === true ) {
      this.setPassword = true;
      this.changedPassword = false;
      this.changePasswordButton = false;
      this.showPasswordButton = false;
      this.currentPassword = '';
      this.OTPBasedLogin = false;
    } else {
      this.setPassword = false;
      this.changedPassword = true;
      this.changePasswordButton = false;
      this.showPasswordButton = false;
      this.OTPBasedLogin = false;
    }
  }

  confirmMatch() {
    if (this.password === '' || this.confirmPassword === '') {
      return;
    }
    if (this.password.length < this.confirmPassword.length) {
      this.matchPasswordd = 'Password Mismatch';
      return ;
    } else if ((this.confirmPassword !== this.password) && (this.password.length === this.confirmPassword.length)) {
      this.matchPasswordd = 'Password Mismatch';
      return;
    } else {
      this.matchPassword = '';
      this.matchPasswordd = '';
    }
  }

  skipCreatePassword() {
    this.currentPassword = '';
    this.password = '';
    this.confirmPassword = '';
    this.passwordValidation = '';
    this.matchPassword = '';
    this.matchPasswordd = '';
    this.invalidPassword = false;
    this.logoMessage = '';
    if (this.changeCompany) {
      this.changeCompany  = false;
    } else if (this.OTPBasedLogin === true) {
      this.OTPBasedLogin = false;
      this.changePasswordButton = false;
      this.showPasswordButton = true;
    } else if (this.changedPassword) {
      this.showPasswordButton = true;
      this.changePasswordButton = false;
      this.OTPBasedLogin = false;
      this.changedPassword = false;
    } else {
      this.changePasswordButton = true;
      this.showPasswordButton = false;
      this.OTPBasedLogin = false;
      this.setPassword = false;
    }
  }

  changeOtpBased() {
    this.setPassword = false;
    this.changedPassword = false;
    this.changePasswordButton = false;
    this.showPasswordButton = false;
    this.OTPBasedLogin = true;
  }

  passwordMatch() {
    this.matchPassword = '';
    this.matchPasswordd = '';

    for (let i = 0; i < this.password.length; i++) {
      if (this.password.charAt(i) === ' ') {
        // this.passwordValidation = 'Space is not allowed';
        this.password = '';
        // this.invalidPassword = true;
        break;
      } else {
        this.invalidPassword = false;
      }
    }
    if (this.invalidPassword === false) {
      this.passwordValidation = '';
    }
  }

  errorMsgRemove(event) {
    if (event.target.value.trim() === '' && event.target.value.length !== 0) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
    this.matchPassword = '';
    this.matchPasswordd = '';
  }

  matchPasswordFormat(e) {
    if (this.feed.controls.password !== undefined && e.target.value !== '' && !this.feed.controls.password.valid ) {
      this.invalidPassword = true;
      this.passwordValidation = 'Invalid Password';
    } else if (this.feed1.controls.password !== undefined && e.target.value !== '' && !this.feed1.controls.password.valid) {
      this.invalidPassword = true;
      this.passwordValidation = 'Invalid Password';
    } else {
      this.invalidPassword = false;
      this.passwordValidation = '';
    }
  }

  submitPassword() {
    if ((this.confirmPassword !== this.password) && !this.OTPBasedLogin) {
      this.matchPasswordd = 'Password Mismatch';
      return false;
    }
    let currentPwd = '';
    let confirmPwd = '';
    if (this.changedPassword) {
      const curpassword = this.SubmitsEncry(this.currentPassword);
      currentPwd = curpassword.toString();
      const password = this.SubmitsEncry(this.confirmPassword);
      confirmPwd = password.toString();
    } else if (this.setPassword) {
      const password = this.SubmitsEncry(this.confirmPassword);
      confirmPwd = password.toString();
      this.currentPassword = '';
    } else {
      const curpassword = this.SubmitsEncry(this.password);
      currentPwd = curpassword.toString();
    }

    const payLaod = {
      'countryCode': this.userDetails.MobileCountryCode,
      'mobileNo': this.userDetails.MobileNo,
      'password': JSON.stringify(confirmPwd),
      'loginTypeOtp': false,
      'oldPassword': JSON.stringify(currentPwd)
    };

    if (this.setPassword === true) {
      payLaod.oldPassword = '';
    }
    if (this.OTPBasedLogin === true) {
      payLaod.password = '';
      payLaod.loginTypeOtp = true;
    }
    this.verifyservice.generatePassword(payLaod, this.companyId, this.accessToken).subscribe(
        response => {
          this.matchPassword = '';
          this.confirmPassword = '';
          this.password = '';
          this.currentPassword = '';
          this.matchPassword = '';
          if (this.setPassword) {
            const tempUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            localStorage.removeItem('userInfo');
            tempUserInfo.IsPassword = true;
            localStorage.setItem('userInfo', JSON.stringify(tempUserInfo));
            localStorage.setItem('userRole', tempUserInfo.Companies[0].RoleId);
            this.userDetails = tempUserInfo;
            this.changePasswordButton = false;
            this.showPasswordButton = true;
            this.OTPBasedLogin = false;
            this.changedPassword = false;
            this.setPassword = false;
            this.toastrService.success('Password registered successfully. Password based login is now your preferred login approach', 'Success');
          } else if (this.OTPBasedLogin) {
            const tempUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            localStorage.removeItem('userInfo');
            tempUserInfo.IsPassword = false;
            localStorage.setItem('userInfo', JSON.stringify(tempUserInfo));
            localStorage.setItem('userRole', tempUserInfo.Companies[0].RoleId);
            this.userDetails = tempUserInfo;
            this.OTPBasedLogin = false;
            this.changePasswordButton = true;
            this.showPasswordButton = false;
            this.changedPassword = false;
            this.changedPassword = false;
            this.setPassword = false;
            this.toastrService.success('OTP based login is now your preferred login approach', 'Success');
          } else {
            const tempUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            localStorage.removeItem('userInfo');
            tempUserInfo.IsPassword = true;
            localStorage.setItem('userInfo', JSON.stringify(tempUserInfo));
            localStorage.setItem('userRole', tempUserInfo.Companies[0].RoleId);
            this.userDetails = tempUserInfo;
            this.OTPBasedLogin = false;
            this.changePasswordButton = false;
            this.showPasswordButton = true;
            this.changedPassword = false;
            this.changedPassword = false;
            this.setPassword = false;
            this.toastrService.success('Password updated successfully', 'Success');
          }
        },
        error => {
          this.matchPassword = 'Invalid current password';
          this.matchPasswordd = '';
          this.confirmPassword = this.password;
          this.currentPassword = '';
    });
  }

  onSubmit(ngForm: NgForm) {}

  SubmitsEncry(password): string {
    const key = CryptoJS.enc.Utf8.parse('8080808080808080');
    const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    const encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encryptedpassword;
  }
  forGotPassword() {
    this.accessToken = localStorage.getItem('accessToken');
    this.verifyservice.forGotPassword(this.accessToken, this.companyId).subscribe((response: any) => {
        this.toastrService.success(response.response, 'Success');
        this.ngOnInit();
      },
        error => {
        this.toastrService.error(error.error.message, 'Error');
        this.ngOnInit();
      });
  }

  getApiKeyRes() {
    const payLaod = {};
    this.verifyservice.getIntegrationApikey(this.companyId,this.accessToken).subscribe((response: any) => {
      this.integrationAPIKey = response.apiKey;
      localStorage.setItem('apiKey', this.integrationAPIKey );

      this.integrationAPISecretKey = response.apiSecretKey;
      localStorage.setItem('apiSecretKey', this.integrationAPISecretKey );

      this.integrationAPIKeysCreatedOn =  response.activeKeySetCreatedOn;
      localStorage.setItem('activeKeySetCreatedOn', this.integrationAPIKeysCreatedOn );

      this.integrationAPIClientId = response.userDetails.Companies[0].ClientId;
      localStorage.setItem('ClientId', this.integrationAPIClientId);
    },
      error => {
        this.toastrService.error(error.error.message, 'Error');
    });
  }


  copyLink(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastrService.success('success', 'Link copied to clipboard.');
  }

  getWebhookRes(){
  this.resWebhookUrl = this.webhookUrlInput;
    const payLaod = {
      "webhookAPI": this.webhookUrlInput
    };

    this.verifyservice.getIntegrationWebHook(payLaod,this.companyId,this.accessToken).subscribe((response: any) => {
      this.toastrService.success(response.status);
      localStorage.setItem('webhookRef', this.webhookUrlInput );
      this.textWebhookUrl = false;
      this.errorwebhook = false;
    },
      error => {
      this.errorwebhook = true;
        this.toastrService.error(error.error.message);
        this.errormsg = error.error.message;
    });
  }

  opentext(){
    if(this.webhookUrlInput == ''){
      this.webhookUrlInput = localStorage.getItem('webhookRef')
    }

    this.textWebhookUrl = true;
  }

  //on flag comin No
  ConfirmFunction(){

    this.verifyservice.GetIntegrationContactUs(this.companyId,this.accessToken).subscribe((response: any) => {
      this.toastrService.success(response.message, 'Success');
    },
      error => {
        this.toastrService.error(error.error.message, 'Error');
    });

  }

}
