import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import {
  RegisterCompComponent,
  RegistrationSuccessComponent,
} from "./register-comp/register-comp.component";
import { HeaderCompComponent } from "./header-comp/header-comp.component";
// import { ReCaptchaModule } from 'angular2-recaptcha';
import { AppRoutingModule } from "./app.route";
import { VerifyCompComponent } from "./verify-comp/verify-comp.component";
import { LoginComponent } from "./login/login.component";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { PartnerComponent } from "./partner/partner.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { OnlyNumberDirective } from "./utilComp/numberOnlyDirective";
import { OnlyNumberPasteDirective } from "./utilComp/numberWithOutPaste";
import { AppPasswordDirective } from "./utilComp/appPasswordDirective";
import { NgxPaginationModule } from "ngx-pagination";
// import { FilterPipe } from './filter.pipe';
import { TreeModule } from "@circlon/angular-tree-component";
import { NgGridModule } from "angular2-grid";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { JobsComponent } from "./jobs/jobs.component";
import { BlogModule } from "./blog/blog.module";
import { SecurityPageComponent } from "./security-page/security-page.component";
import { FeaturesPageComponent } from "./features-page/features-page.component";
import { HomeComponent } from "./home/home.component";
import { TermsOfUseComponent } from "./terms-of-use/termsofuse.component";
import { AssessmentPageComponent } from "./assessment-page/assessment-page.component";
import { MyDatePickerModule } from "mydatepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { SharedModule } from "./shared/shared.module";
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { ObserverModule } from "./observer-module/observer.module";
import { ProfileComponent } from "./profile/profile.component";
import { EditProfileComponent } from "./profile/edit-profile/edit-profile.component";
import { AuthGuardService, NonLoggedInState } from "./auth-guard.service";
import { NoInternetComponent } from "./modals/no-internet/no-internet.component";
import { InternationalPhoneNumber2Module } from "ngx-international-phone-number2";
import { TextMaskModule } from "angular2-text-mask";
import { NgImageSliderModule } from "ng-image-slider";
import { CookieService } from "ngx-cookie-service";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { MediaManagerComponent } from "./media-manager/media-manager.component";
import { AngularFileUploaderModule } from "angular-file-uploader";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LogOutComponent } from "./log-out/log-out.component";
import { MediaComponent } from "./media/media.component";
import { ListOfCandidatesComponent } from "./assessments-module/list-of-candidates/list-of-candidates.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DatePipe } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { Router } from "@angular/router";
import { AuthInterceptor } from "./auth.interceptor";
import { UnauthorisedErrorHandleComponent } from "./unauthorised-error-handle/unauthorised-error-handle.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ListAssignedBatchesComponent } from "./assessments-module/list-assigned-batches/list-assigned-batches.component";
import { AssessmentService } from "./assessments-module/assessment.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ArchiveCatModalComponent } from './superadmin-qb-module/archive-cat-modal/archive-cat-modal.component';
import { SuperadminCreateCatSubcatComponent } from "./superadmin-qb-module/superadmin-create-cat-subcat/superadmin-create-cat-subcat.component";
import { SkillBasedTemplateComponent } from './skill-based-template/skill-based-template.component';
import { SkillBasedTemplateDetailPublicViewComponent } from "./skill-based-template/skill-based-template-detail-public-view/skill-based-template-detail-public-view.component";
import { BiReportComponent } from './bi-report/bi-report.component';
import { AngularEditorModule } from "@kolkov/angular-editor";
// import { MockInterceptor } from "./qb-module/mock.interceptor";
// import { MockDetailInterceptor } from "./qb-module/mock-detail.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    RegisterCompComponent,
    HeaderCompComponent,
    RegistrationSuccessComponent,
    LoginComponent,
    VerifyCompComponent,
    OnlyNumberDirective,
    OnlyNumberPasteDirective,
    AppPasswordDirective,
    DashboardComponent,
    PartnerComponent,
    JobsComponent,
    HomeComponent,
    TermsOfUseComponent,
    SecurityPageComponent,
    FeaturesPageComponent,
    AssessmentPageComponent,
    ProfileComponent,
    EditProfileComponent,
    NoInternetComponent,
    PrivacyPolicyComponent,
    MediaManagerComponent,
    ForgotPasswordComponent,
    LogOutComponent,
    MediaComponent,
    UnauthorisedErrorHandleComponent,
    CarouselComponent,
    SkillBasedTemplateComponent,
    SkillBasedTemplateDetailPublicViewComponent,
    BiReportComponent,
  ],
  exports: [MatProgressBarModule, MatTabsModule],

  imports: [
    ObserverModule,
    SharedModule,
    TabsModule,
    TabsModule.forRoot(),
    AppRoutingModule,
    // ReCaptchaModule,
    HttpClientModule,
    // SuperadminModule,
    HttpClientJsonpModule,
    NgxPaginationModule,
    MatProgressBarModule,
    MatTabsModule,
    NgImageSliderModule,
    BlogModule,
    TreeModule,
    NgGridModule,
    MyDatePickerModule,
    AngularFileUploaderModule,
    InternationalPhoneNumber2Module,
    TextMaskModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NgxCaptchaModule,
    NgxUiLoaderModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger",
    }),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    BrowserAnimationsModule,
  ],

  providers: [
    AuthGuardService,
    NonLoggedInState,
    CookieService,
    ListOfCandidatesComponent,
    ArchiveCatModalComponent,
    AssessmentService,
    ListAssignedBatchesComponent,
    SuperadminCreateCatSubcatComponent,
    HttpClient,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router) {
        return new AuthInterceptor(router);
      },
      multi: true,
      deps: [Router],

    },
    // { provide: HTTP_INTERCEPTORS,
    //   useClass: MockInterceptor,
    //   multi: true
    // },
    // { provide: HTTP_INTERCEPTORS,
    //   useClass: MockDetailInterceptor,
    //   multi: true
    // }
  ],
  entryComponents: [NoInternetComponent],
  bootstrap: [AppComponent],

})
export class AppModule { }
