<app-user-header></app-user-header>

<!--starting assessment creation-->
<div class="create-assessment" *ngIf="templateDetails">
  <!--start of common wizard-->
  <nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
    <div class="row">
      <div class="container-fluid">
        <div class="row" *ngIf="showBasicInfo || showConfigurations">
          <div class="col">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" *ngIf="!editFlag">
                  <span class="page-title">Create New Quick Assessment</span>
                  <p class="mt-2 mb-2">
                    <span class="RR-16 font-weight-bold text-gray">Template Title: </span>
                    <span class="page-title-info">{{templateDetails.templateTitle}}</span>
                  </p>
                </li>
                <li class="breadcrumb-item" *ngIf="editFlag">
                  <span class="page-title">Edit Quick Assessment</span>

                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="main-div-wizard wizard-block" id="container">
    <div class="step-wizard">
      <div [ngClass]="[editFlag ? 'psycho-edit' : 'progress-edit']">
        <div class="progressbar empty"></div>
        <div id="prog" class="progressbar"></div>
      </div>
      <ul>
        <li *ngIf="!setWizard1">
          <a id="step1" style="cursor: not-allowed">
            <span class="stepCom">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>
        <li *ngIf="setWizard1" class="active">
          <a id="step1" style="cursor: pointer" (click)="stepWizard1()">
            <span class="step">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>

        <li *ngIf="!setWizard2">
          <a id="step2" style="cursor: not-allowed">
            <span class="stepCom">2</span>
            <span class="title">Question Details & Configuration</span>
          </a>
        </li>
        <li class="active" *ngIf="setWizard2">
          <a id="step2" style="cursor: pointer" (click)="stepWizard2()">
            <span class="step">2</span>
            <span class="title">Question Details & Configuration</span>
          </a>
        </li>
        <li class="" *ngIf="!setWizard3 && !editFlag">
          <a id="step3" style="cursor: not-allowed">
            <span class="stepCom">3</span>
            <span class="title">Publish</span>
          </a>
        </li>
        <li class="active" *ngIf="setWizard3 && !editFlag">
          <a id="step3" style="cursor: pointer">
            <span class="step">3</span>
            <span class="title">Publish</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- end of common wizard-->


  <!-- Start of 'Basic Info' view -->
  <div class="main-div-assessment container-fluid bg-white pt-3 pb-5" *ngIf="showBasicInfo">
    <div class="row">
      <div class="col">
        <form class="mt-3" autocomplete="off">
          <h5>Fill Assessment Details</h5>
          <div class="row mtop30">
            <div class="col">
              <label>Assessment Name <span class="alert-red">*</span></label>
              <input type="text" minlength="3" maxlength="51" class="form-control placehold" id="templateName"
                pattern="^[ A-Za-z0-9_@.+-]*$" #AssessmentName="ngModel" name="templateName"
                placeholder="Enter Assessment Name" [(ngModel)]="assessmentName" required />
              <small *ngIf="assessmentNameError" class="text-danger">{{
                assessmentNameError
                }}</small>
              <small *ngIf="AssessmentName.touched && AssessmentName.invalid" class="text-danger">Please enter valid
                assessment name</small>
            </div>

            <div class="col-md-6">
              <div>
                <label>Assessment Type</label>
                <span class="form-control placehold read-only-input">{{assessmentType}}</span>
              </div>
            </div>
          </div>

          <div class="row mtop30">
            <div class="col-md-6">
              <label>Duration</label>
              <span class="form-control placehold read-only-input">{{duration}}</span>
            </div>

            <div class="col">
              <label>Passing Score(%) <span class="alert-red">*</span></label>
              <input type="text" class="form-control height50 placehold" maxlength="3" OnlyNumber="true"
                id="passingPercentage" name="Passing Percentage" placeholder="Enter Passing Percentage"
                [(ngModel)]="percentageMarks" (keyup)="checkForZeroValue($event)"
                (keydown)="checkPassingScore($event)" />

              <small *ngIf="showPassingScoreErr" class="text-danger select-hidden">
                Choose value from 0 to 100.
              </small>
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Assessment Description</label>
            <angular-editor [(ngModel)]="templateDescription" placeholder="Enter Description" name="textArea"
              [config]="config"></angular-editor>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-4 pb-5">
      <div class="col">
        <button class="btn btn-primary" id="next" [disabled]="!assessmentName || !percentageMarks"
          (click)="showConfigurationsSection();">
          Next
        </button>
        <button class="btn btn-cancel" (click)="goToDetailsPage()">Cancel</button>
      </div>
    </div>
  </div>
  <!-- End of 'Basic Info' view -->

  <div *ngIf="showConfigurations" class="main-div-assessment container-fluid bg-white pt-3 pb-5">
    <section id="questionConfig">
      <div class="row mt-3">
        <div class="col">
          <h5>Question Details</h5>
        </div>
      </div>
      <div class="row" *ngFor="let section of templateDetails.sections">
        <div class="col-12">
          <h6 class="mt-3">{{section.sectionName}}</h6>
          <table class="table tbl-Section-details">
            <thead class="thead-light">
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Sub-Category</th>
                <th scope="col">Question Type</th>
                <th scope="col">Difficulty Level</th>
                <th scope="col">No. of Questions</th>
              </tr>
            </thead>
            <tr *ngFor="let category of section.categories">
              <td>{{category.parentCategoryName}}</td>
              <td>{{category.subCategoryName}}</td>
              <td>{{category.questionType[0]}}</td>
              <td>{{category.difficulty}}</td>
              <td>{{category.numberOfQuestions}}</td>
            </tr>

          </table>
        </div>
      </div>


    </section>
    <hr>
    <section id="assessmentConfig">
      <div class="bg-white">
        <div class="row mt-3">
          <div class="col">
            <h6 class="pt-3">Test Question Configuration</h6>
            <div class="form-check form-check-inline ml-0">
              <i *ngIf="shuffleQuestions" class="fa fa-check-square mr-2 static-check-box-color" aria-hidden="true"></i>
              <i *ngIf="!shuffleQuestions" class="fa fa-square-o mr-2" aria-hidden="true"></i>

              <label class="form-check-label">Shuffle questions</label>
            </div>
            <div class="form-check form-check-inline ml-3">
              <i *ngIf="shuffleOptions" class="fa fa-check-square mr-2 static-check-box-color" aria-hidden="true"></i>
              <i *ngIf="!shuffleOptions" class="fa fa-square-o mr-2" aria-hidden="true"></i>

              <label class="form-check-label">Shuffle options</label>
            </div>
            <div class="form-check form-check-inline ml-3">
              <i *ngIf="attemptAll" class="fa fa-check-square mr-2 static-check-box-color" aria-hidden="true"></i>
              <i *ngIf="!attemptAll" class="fa fa-square-o mr-2" aria-hidden="true"></i>
              <label class="form-check-label">All question compulsory</label>
            </div>

            <div class="form-check form-check-inline ml-3">
              <i *ngIf="moveForwardOnly" class="fa fa-check-square mr-2 static-check-box-color" aria-hidden="true"></i>
              <i *ngIf="!moveForwardOnly" class="fa fa-square-o mr-2" aria-hidden="true"></i>
              <label class="form-check-label">Move forward only</label>
            </div>

          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col">
            <h6 class="pt-3">Display Maximum Marks for Each Question:
              <span *ngIf="disMaxMarks" class="badge badge-primary RR-14">Enabled</span>
              <span *ngIf="!disMaxMarks" class="badge badge-secondary RR-14">Disabled</span>
            </h6>

          </div>
        </div>
        <hr />

        <div class="row mt-3">
          <div class="col">
            <h6 class="pt-3">Display Question Category Information:
              <span *ngIf="disCatSubCat" class="badge badge-primary RR-14">Enabled</span>
              <span *ngIf="!disCatSubCat" class="badge badge-secondary RR-14">Disabled</span>
            </h6>

          </div>
        </div>
        <hr />
        <div class="row mt-4">
          <div class="col">
            <h6>Negative Marking:
              <span *ngIf="negativeMarking" class="badge badge-primary RR-14">Enabled</span><span class="ml-3">Count:
                {{Penalty}}</span>
              <span *ngIf="!negativeMarking" class="badge badge-secondary RR-14">Disabled</span>
            </h6>

          </div>
        </div>
        <hr />
        <div class="row mt-4">
          <div class="col">
            <h6>Violation:
              <span *ngIf="allowSuspiciosEnable" class="badge badge-primary RR-14">Enabled</span><span
                class="ml-3">Count: {{allowsuspiciosCount}}</span>
              <span *ngIf="!allowSuspiciosEnable" class="badge badge-secondary RR-14">Disabled</span>
            </h6>


          </div>
        </div>
        <hr />
        <div class="row mt-4">
          <div class="col">
            <h6>Additional Information</h6>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="option1" id="chkbTestInst" name="instruction"
                checked="checked" (change)="checkConfigEnableOption()" [(ngModel)]="instructForTest" />
              <label class="form-check-label" for="chkbTestInst">Instructions for test</label>
            </div>
            <div class="form-check" *ngIf="instructForTest">
              <angular-editor [(ngModel)]="instructForTestValue" (change)="checkConfigEnableOption()"
                [config]="config"></angular-editor>

            </div>
            <p id="instruction" style="color: red; text-align: center"></p>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="option1" id="chkbTestMessage"
                name="afterTestMessage" checked="checked" (change)="checkConfigEnableOption()"
                [(ngModel)]="afterTestMessage" />
              <label class="form-check-label" for="chkbTestMessage">After test message</label>
            </div>

            <div class="form-check" *ngIf="afterTestMessage">
              <angular-editor [(ngModel)]="afterTestMessageValue" (change)="checkConfigEnableOption()"
                [config]="config"></angular-editor>
            </div>
            <p id="message" style="color: red; text-align: center"></p>
          </div>
        </div>
        <div>
          <div class="row mt-3">
            <div class="col">
              <button *ngIf="!quickAssessmentTemplateId " type="button" class="btn btn-primary"
                [disabled]="disableSave == true " (click)="createQuickAssessment(true)">Save & Exit</button>
              <button *ngIf="quickAssessmentTemplateId" type="button" class="btn btn-primary"
                [disabled]="disableSave == true " (click)="editQuickAssessment()">Save & Exit</button>
              <button *ngIf="!quickAssessmentTemplateId" type="button" class="btn btn-primary" (click)="publishLink()"
                [disabled]="disableSave == true ">Save & Publish</button>
              <button *ngIf="!quickAssessmentTemplateId" type="button" class="btn btn-cancel"
                (click)="goToDetailsPage()">Cancel</button>
              <button *ngIf="quickAssessmentTemplateId" type="button" class="btn btn-cancel"
                (click)="goToListPage()">Cancel</button>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!--Public Link Creation Start -->
  <div *ngIf="publicLinkCreation">
    <app-publish></app-publish>
  </div>
  <!--end public link creation-->
</div>