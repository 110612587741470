import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import { AssessmentService } from "../assessment.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { JobService } from "../../jobs/jobs.service";
import { IMyDpOptions } from "mydatepicker";


declare let myExtObject: any;
@Component({
  selector: "app-list-of-candidates",
  templateUrl: "./list-of-candidates.component.html",
  styleUrls: ["./list-of-candidates.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListOfCandidatesComponent implements OnInit {
  @Input() linkId: any;
  @Output() changeval = new EventEmitter<boolean>();
  flag: boolean = false;
  searchText: string;
  assessmentDescriptionDash: any;
  assessmentDetails: any;
  companyId: any;
  accessToken: any;
  showSectionQuestion: Boolean = false;
  showAssessemt: Boolean;
  assessmentList1: Boolean;
  assessmentData: Boolean = true;
  assessmentConfigurationDash: Boolean = false;
  assessmentListOfCandidates: Boolean = false;
  assessmentCandidatesDetails: Boolean = false;
  asseementQuestion: Boolean = false;
  errorMsg: any;
  createdassessmentId: String = "";
  statusChangedFlag: boolean = false;
  trackGeoLocation = {
    startLocation: {
      latitude: "",
      location: "",
      longitude: "",
      reverseGeo: "",
    },
    endLocation: {
      latitude: "",
      location: "",
      longitude: "",
      reverseGeo: "",
    },
  };
  assessmentTypeForLoadMore = "";
  proctor: any;
  publicLinkCreation: Boolean;
  privateLinkCreationStep1: Boolean;
  privateLinkCreationStep2: Boolean;
  CandidatelinkID = "";
  candidateReportResponse = [];
  CandidateReportDetails: Boolean = false;
  date = new Date();
  currentYear = this.date.getFullYear();
  currentMonth = this.date.getMonth() + 1;
  currentDate = this.date.getDate();
  public myDatePickerOptions: IMyDpOptions = {
    disableUntil: {
      year: this.currentYear,
      month: this.currentMonth,
      day: this.currentDate - 1,
    },
    editableDateField: false,
    dateFormat: "yyyy-mm-dd",
  };

  publicerrorMsg = "";
  mobileNum: string;
  countryCodeNum: string;
  modalResetCandidate: FormGroup;
  // to check weather candidate is eveluated from candidate list or candidate details page
  assessmentListReloadFlag: boolean = false;
  CandidateStartDT;
  CandidateEndDT;
  CandidateReportData = [];
  CandidateData;
  candidateCaputerdSnapshot = [];
  candidateAttendanceSnapshot: any;
  CandidatelinkType = "";
  marker: any;
  location: string;
  startLocation: string;
  endLocation: string;
  data: any;
  selectedRowMobileNumber: string;
  selectedRowCountryCode: string;
  selectRow = 0;
  isUploadingCandImg: Boolean = false;
  uploadedFileErrMsg: string;
  reviewDetails: any = [];
  descriptiveMarks: any = [];
  isReviewLoading: Boolean = false;
  linkData: any = {};
  countryCode: any;
  reviewId: any;
  isEvaluateBtnDisable: Boolean = true;
  fromList: Boolean = false;
  maxLength = 4;
  assessment_name: any;
  candidateSuspiciousCount: any;
  candidateFaceViolationCount: any;
  candidateTimeSpentForAssessment: any;
  candidateName: any;
  suspicionReason: any;
  emptyArray: any = [];
  formattedAns: any = [];
  statusLabel: any = "";
  reviewComment: any = "";
  remarkDetails: any = [];
  commentedBy: any = "";
  showCommentSecion: Boolean = false;
  showDescriptiveIcon: any;
  startTimeMap: any = "";
  endTimeMap: any = "";
  candidateIndex = 0;
  linkName: string = "";
  showBatchCandidateList: any;
  showCandidateAssessmentDetails: any;
  isLinkOpenForEvaluation: boolean = false;
  @Input() batchCandidates: any = [];
  commentFlag: boolean = false;
  publicStartDate = "";
  publicStartTime = "";
  publicEndDate = "";
  publicEndTime = "";
  publicSTARTDate: any;
  publicENDDate: any;
  afterSelect = false;
  candidateListValue;

  isFetchingCandidateList: boolean = true;
  batchCandidatePageNo: number = 0;
  @Input() hasMoreCandidate: boolean = false;
  selectedCandidate: any;
  evaluateBtnLabel: any;
  candidateAssessmentResult: any;
  // linkId: string = '';
  linkDetails: any;
  linkType: string = "";
  LinkCongiguration: any;
  @Input() noDataFoundFlag: boolean = false;
  @Input() candidateErr: string = "";
  base64textString = "";
  responseUrlImage = "";
  imageUploadForLink: boolean;
  roleId: string = "";
  isDescriptive: boolean = false;
  hasCodingQuestions: boolean = false;

  constructor(
    private assessmentService: AssessmentService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private jobService: JobService,
    private ngxService: NgxUiLoaderService
  ) {
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.modalResetCandidate = new FormGroup({
      reset: new FormControl("", Validators.required),
      resetReason: new FormControl("", Validators.required),
    });
  }
  async ngOnInit() {
    var listOfCan: any = [];
    this.batchCandidatePageNo = 0;

    this.assessmentListReloadFlag = false;
    if (localStorage.getItem("IsDescriptive") == "true") {
      this.isDescriptive = true;
    }
    else {
      this.isDescriptive = false;
    }


    window.scrollTo(0, 0);

    this.isEvaluateBtnDisable = true;
    this.linkId = this.assessmentService.getLinkId();
    listOfCan = await this.assessmentService.getBatchData();
    var UserInfo = localStorage.getItem("userInfo");
    var user = JSON.parse(UserInfo);
    
    this.hasCodingQuestions = localStorage.getItem("hasCodingQuestions") === 'true';
    this.roleId = user.Companies[0].RoleId;
    this.LinkCongiguration = this.assessmentService.getConfigurationData();
    this.ngxService.stop();

    if (typeof listOfCan === "undefined") {
      await this.fetchBatchCandidates(true);
    } else {

      this.isFetchingCandidateList = false;
      if (listOfCan.results != null) {

        this.batchCandidates = [];
        this.batchCandidates = this.batchCandidates.concat(listOfCan.results);
        this.seperateBatchStatus();
        this.noDataFoundFlag = false;
      } else {

        this.noDataFoundFlag = true;
      }
      this.hasMoreCandidate = listOfCan.isMoreCandidates;
    }
    this.showBatchCandidateList = true;

    this.fetchLinkDetails();

    this.modalResetCandidate = new FormGroup({
      reset: new FormControl("", Validators.required),
      resetReason: new FormControl("", Validators.required),
    });
    this.assessmentTypeForLoadMore = localStorage.getItem(
      "assessmentTypeFromDetails"
    );
  }

  getRouteParams() { }

  reviewCancel() {
    ($("#reviewModal") as any).modal("hide");
    this.descriptiveMarks = [];
    this.reviewDetails = [];
  }
  checkForEmpty() {
    // this.descriptiveMarks.forEach((val, k) => {
    //   if (this.reviewDetails[k].answer !== "" && val.marks === "") {
    //
    //     this.isEvaluateBtnDisable =false;
    //   }
    //   else{
    //     this.isEvaluateBtnDisable =true;
    //   }

    // });

    for (var i = 0; i < this.descriptiveMarks.length; i++) {
      if (this.descriptiveMarks[i].marks === "") {
        this.isEvaluateBtnDisable = true;
        return;
      } else {
        this.isEvaluateBtnDisable = false;
      }
    }
  }
  marksScored(e, idx) {
    if (e.target.value !== "" && e.target.value !== " ") {
      this.descriptiveMarks[idx]["marks"] = e.target.value;
    }
  }
  validateNumber(e, idx) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && e.keyCode < 96) ||
      (e.keyCode !== 190 && e.keyCode > 105) ||
      (e.keyCode > 105 && e.shiftKey)
    ) {
      e.preventDefault();
    }
  }
  showBatchCandidateListView() {
    this.changeval.emit(true);
    this.showBatchCandidateList = true;
    this.showCandidateAssessmentDetails = false;
    if (this.statusChangedFlag == true) {
      this.fetchBatchCandidates(true);
    }
  }
  restrictMax(e, idx) {
    const num = e.target.value.split(".");
    if (num[0] === "") {
      this.maxLength = 3;
    } else if (num[0].length > 1) {
      this.maxLength = 5;
    } else {
      this.maxLength = 4;
    }

    if (e.target.value > Number(this.reviewDetails[idx].questionScore)) {
      e.target.value = "";

      this.descriptiveMarks[idx]["marks"] = "";
      // this.isEvaluateBtnDisable = true;
      if (this.commentFlag) {
        this.checkForComment();
      }
      this.checkForEmpty();
    } else {
      if (this.commentFlag) {
        this.checkForComment();
      }
      this.checkForEmpty();
    }
  }
  fetchLinkDetails() {
    const payload = { linkId: this.linkId };

    this.assessmentService
      .candidatesLinkIDDetails(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.linkDetails = response.message;
          this.linkType = this.linkDetails.linkType;
          this.linkName = this.linkDetails.linkName;
          this.isLinkOpenForEvaluation = response.isLinkOpenForEvaluation;

          this.assessmentDetails = this.linkDetails.assessments[0];
          this.LinkCongiguration = this.linkDetails.configuration;
        },
        (error) => {
          let errorMsg = error.error.message;
        }
      );
  }
  async fetchBatchCandidates(fetchFirstSet: boolean, event?: any) {

    if (!(event === undefined)) {
      if (event.pointerType != "mouse") {
        return;
      }
    }
    this.ngxService.start();
    this.searchText = this.assessmentService.getSearchText();
    const payload = { linkId: this.linkId, searchText: this.searchText };
    if (fetchFirstSet) {
      this.batchCandidates = [];
      this.showBatchCandidateList = true;
      this.showCandidateAssessmentDetails = false;
      this.isFetchingCandidateList = true;
      this.batchCandidatePageNo = 0;
    } else {
      this.isFetchingCandidateList = true;
      this.batchCandidatePageNo++;
    }

    await this.assessmentService
      .candidateReport(
        payload,
        this.batchCandidatePageNo,
        this.companyId,
        this.accessToken
      )
      .toPromise()
      .then(
        (response) => {
          this.isFetchingCandidateList = false;
          let fetchedCandidates = response.results;

          if (!(fetchedCandidates == null)) {
            this.batchCandidates =
              this.batchCandidates.concat(fetchedCandidates);
            this.seperateBatchStatus();

          }

          this.hasMoreCandidate = response.isMoreCandidates;
          if (!this.hasMoreCandidate) {
            this.batchCandidatePageNo = 0;
          }
          this.ngxService.stop();

        },
        (error) => {
          this.isFetchingCandidateList = false;
          this.noDataFoundFlag = true;
          this.candidateErr = error.error.message;
          this.ngxService.stop();

        }
      );
  }
  async getReviewDetails(assessmentListReloadFlag) {
    this.assessmentListReloadFlag = assessmentListReloadFlag;
    if (!this.isLinkOpenForEvaluation) {
      return;
    }
    const payload = {
      assessmentId: this.linkDetails.assessments[0].assessmentId,
      linkId: this.linkId,
      mobile: this.selectedCandidate.mobile,
      countryCode: this.selectedCandidate.countryCode,
    };
    this.remarkDetails = [];
    this.reviewDetails = [];
    this.reviewComment = "";
    await this.assessmentService
      .getDescriptiveQuestions(payload, this.companyId, this.accessToken)
      .toPromise()
      .then(
        (response) => {
          if (response.descriptiveQuestions !== null) {
            if (
              response.remarkedDetails &&
              response.remarkedDetails.length > 0
            ) {
              this.remarkDetails = response.remarkedDetails.reverse();
              this.commentedBy = response.remarkedBy;
              this.showCommentSecion = true;
            }

            this.reviewDetails = response.descriptiveQuestions;
            this.formatAnswer(this.reviewDetails);
            this.reviewDetails.forEach((val, j) => {
              if (val.answer === "") {
                this.descriptiveMarks[j] = {
                  questionId: val.questionId,
                  marks: val.adminScore,
                };
                this.emptyArray.push(val);
              } else {
                this.descriptiveMarks[j] = {
                  questionId: val.questionId,
                  marks: val.adminScore,
                };
                if (val.adminScore >= 0) {
                  this.descriptiveMarks[j] = {
                    questionId: val.questionId,
                    marks: val.adminScore,
                  };
                }
                // if (val.adminScore === '') {
                //   this.isEvaluateBtnDisable = true;
                // }
              }
            });
            this.setEvaluateButtonLabel();
            this.checkForLabel(this.selectedCandidate.mobile);
            ($("#reviewModal") as any).modal("show");
            // this.isReviewLoading = true;
          } else {
            ($("#reviewModal") as any).modal("hide");
          }
          ($("#reviewModal") as any).modal("show");
        },
        (error) => {
          // this.errorMsg = error.error.message;
          ($("#reviewModal") as any).modal("hide");
        }
      );
  }
  setEvaluateButtonLabel() {
    this.evaluateBtnLabel = "Evaluate";
  }
  checkForLabel(mobile: any) {
    this.batchCandidates.forEach(
      (val: { mobile: any; candidateReviewAssessmentDetails: any[] }) => {
        if (
          mobile === val.mobile &&
          val.candidateReviewAssessmentDetails != null
        ) {
          val.candidateReviewAssessmentDetails.forEach((review) => {
            if (this.reviewDetails.length === this.emptyArray.length) {
              if (review.isReviewAnswer === "false") {
                // this.commentFlag=false;
                this.evaluateBtnLabel = "Re-Evaluate";
                this.isEvaluateBtnDisable = false;
                this.statusLabel = "Re-Evaluate";
                this.showCommentSecion = true;
                this.checkForEmpty();
                this.checkForComment();
              } else {
                this.commentFlag = false;
                this.evaluateBtnLabel = "Evaluate";
                // this.isEvaluateBtnDisable = false;
                this.checkForEmpty();
                this.showCommentSecion = false;
                this.statusLabel = "";
              }
            } else if (review.isReviewAnswer === "true") {
              this.commentFlag = false;
              this.evaluateBtnLabel = "Evaluate";
              this.statusLabel = "";
              this.showCommentSecion = false;
              this.checkForEmpty();
              // this.isEvaluateBtnDisable = false;
            } else {
              // this.commentFlag=false;
              this.evaluateBtnLabel = "Re-Evaluate";
              this.statusLabel = "Re-Evaluate";
              this.showCommentSecion = true;
              this.isEvaluateBtnDisable = true;
              this.checkForEmpty();
              this.checkForComment();
            }
          });
        }
      }
    );
  }

  CandidateResetTest() {
    this.publicerrorMsg = "";
    ($("#candidateReset") as any).modal("show");
  }

  async fetchCandidateDetails(candidateInfo: any) {
    if (candidateInfo.status == "Expired") {
      return;
    }

    this.changeval.emit(false);
    this.candidateName = candidateInfo.candidateName;
    this.countryCodeNum = candidateInfo.countryCode;
    this.mobileNum = candidateInfo.mobile;
    //  candidateInfo.candidateReviewAssessmentDetails[0].assessmentId;

    this.selectedCandidate = candidateInfo;
    const payload = {
      linkId: this.linkId,
      mobile: this.selectedCandidate.mobile,
      countryCode: this.selectedCandidate.countryCode,
    };

    this.showDescriptiveIcon =
      this.selectedCandidate.candidateReviewAssessmentDetails[0];

    await this.assessmentService
      .cadidatesReports(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          window.scrollTo(0, 0);
          this.flag = true;
          this.ngxService.stop();

          this.candidateAssessmentResult = response.results[0];
          this.createdassessmentId =
            this.candidateAssessmentResult.assessmentId;

          this.showBatchCandidateList = false;
          this.showCandidateAssessmentDetails = true;
          this.CandidateReportData = response.results;
          const len = this.CandidateReportData[0].assessmentVersions.length;
          this.candidateAttendanceSnapshot =
            this.CandidateReportData[0].assessmentVersions[
              len - 1
            ].attendanceSnapshot;
          if (
            this.CandidateReportData[0].assessmentVersions[len - 1]
              .candidateSnapShot == null
          ) {
            this.candidateCaputerdSnapshot = [];
          } else {
            this.candidateCaputerdSnapshot =
              this.CandidateReportData[0].assessmentVersions[
                len - 1
              ].candidateSnapShot;
          }
          this.assessment_name = this.CandidateReportData[0].assessmentName;
          this.candidateSuspiciousCount =
            this.CandidateReportData[0].assessmentVersions[0].suspiciousCount;
          this.candidateFaceViolationCount =
            this.CandidateReportData[0].assessmentVersions[0].faceViolationCount;
          this.candidateTimeSpentForAssessment =
            this.CandidateReportData[0].assessmentVersions[0].totalTimeSpentForAssessment;
          this.assessmentCandidatesDetails = false;
          this.CandidateReportDetails = true;
          this.assessmentDescriptionDash = true;
          this.assessmentData = false;
          this.showAssessemt = false;
          this.assessmentList1 = false;
          this.asseementQuestion = false;
          this.showSectionQuestion = false;
          this.assessmentConfigurationDash = false;
          this.privateLinkCreationStep1 = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
        },
        (error) => {
          this.ngxService.stop();
          this.errorMsg = error.error.message;
          this.CandidateReportDetails = false;
        }
      );
  }
  async publishResult() {
    /*this.isReviewLoading = false;
    if (this.isReviewLoading ) {
      return;
    }*/
    this.ngxService.start();
    const payload = {
      linkId: this.linkId,
      mobile: this.selectedCandidate.mobile,
      countryCode: this.selectedCandidate.countryCode,
      assessmentId: this.linkDetails.assessments[0].assessmentId,
      adminScore: this.descriptiveMarks,
      isMarkAsEvaluated: true,
      status: this.statusLabel,
      remark: this.reviewComment,
    };
    await this.assessmentService
      .saveDescriptiveScore(payload, this.companyId, this.accessToken)
      .toPromise()
      .then(
        (response) => {
          if (!this.assessmentListReloadFlag) {
            this.fetchBatchCandidates(true);
          }
          if (this.assessmentListReloadFlag) {
            this.fetchCandidateDetails(this.selectedCandidate);
          }
          ($("#reviewModal") as any).modal("hide");
          this.toastrService.success(response.Response);
          this.reviewComment = "";

          this.ngxService.stop();
        },
        (error) => {
          // this.errorMsg = error.error.message;
          ($("#reviewModal") as any).modal("hide");
          this.reviewComment = "";
          this.ngxService.stop();
        }
      );
  }
  formatAnswer(data) {
    this.formattedAns = [];
    if (data !== undefined) {
      data.forEach((str) => {
        if (str.answer !== null) {
          const formatStr = str.answer
            .split("\n")
            .join("<br>")
            .split(" ")
            .join("&nbsp;");
          this.formattedAns.push(formatStr);
        }
      });
    }
  }

  initiateReviewProcess(candidateObj, assessmentListReloadFlag) {
    this.assessmentListReloadFlag = assessmentListReloadFlag;
    this.selectedCandidate = candidateObj;
    console.log(this.selectedCandidate);
    this.getReviewDetails(this.assessmentListReloadFlag);
  }
  checkForComment() {
    if (this.reviewComment === "") {
      this.commentFlag = true;
    } else {
      this.commentFlag = false;
    }
  }

  showSnapshots(data) {
    this.flag = true;

    this.trackGeoLocation = data.assessmentVersions[0].trackGeo;
    const startLat = parseFloat(
      data.assessmentVersions[0].trackGeo.startLocation.latitude
    );
    const startLng = parseFloat(
      data.assessmentVersions[0].trackGeo.startLocation.longitude
    );
    myExtObject.initMap3(startLat, startLng);
    ($("#popUp") as any).modal("show");
  }

  showLocation(data) {
    this.startTimeMap = data.assessmentVersions[0].appearedOn;
    this.endTimeMap = data.assessmentVersions[0].endedOn;
    this.trackGeoLocation = data.assessmentVersions[0].trackGeo;
    const startLat = parseFloat(
      data.assessmentVersions[0].trackGeo.startLocation.latitude
    );
    const startLng = parseFloat(
      data.assessmentVersions[0].trackGeo.startLocation.longitude
    );
    const endLat = parseFloat(
      data.assessmentVersions[0].trackGeo.endLocation.latitude
    );
    const endLng = parseFloat(
      data.assessmentVersions[0].trackGeo.endLocation.longitude
    );
    myExtObject.initMap1(startLat, startLng);
    myExtObject.initMap2(endLat, endLng);
    ($("#candidatesLocation") as any).modal("show");
  }

  openCandidateAssessmentReport(pdfFileLocation) {
    if (pdfFileLocation !== "") {
      window.open(pdfFileLocation);
    }
  }

  // cadidatesReports(data) {
  //   this.flag=true;
  //
  //   // if (status === 'Not Taken' || status === 'Expired') {
  //   //   return;
  //   // }
  //   this.fromList = true;
  //   // this.candidateIndex = idx;
  //   this.CandidateData = data;
  //   this.candidateName = data.candidateName;
  //   this.candidateAttendanceSnapshot = '';
  //   this.mobileNum = data.mobile;
  //   this.countryCodeNum = data.countryCode;
  //   // if (this.candidateReportResponse.length > 0) {
  //   //   this.showDescriptiveIcon = this.candidateReportResponse[idx].candidateReviewAssessmentDetails[0];
  //   // }
  //   const payload = {
  //     'linkId': this.CandidatelinkID,
  //     'mobile': this.mobileNum,
  //     'countryCode': this.countryCodeNum,
  //   };
  //   this.assessmentService.cadidatesReports(payload, this.companyId, this.accessToken)
  //     .subscribe(
  //       response => {
  //         this.CandidateReportData = response.results;
  //
  //         const len = this.CandidateReportData[0].assessmentVersions.length;
  //         this.candidateAttendanceSnapshot = this.CandidateReportData[0].assessmentVersions[len - 1].attendanceSnapshot;
  //         if (this.CandidateReportData[0].assessmentVersions[len - 1].candidateSnapShot == null) {
  //           this.candidateCaputerdSnapshot = [];
  //         } else {
  //           this.candidateCaputerdSnapshot = this.CandidateReportData[0].assessmentVersions[len - 1].candidateSnapShot;
  //         }
  //         this.assessment_name = this.CandidateReportData[0].assessmentName;
  //         this.candidateSuspiciousCount = this.CandidateReportData[0].assessmentVersions[0].suspiciousCount;
  //         this.candidateFaceViolationCount = this.CandidateReportData[0].assessmentVersions[0].faceViolationCount;
  //         this.candidateTimeSpentForAssessment = this.CandidateReportData[0].assessmentVersions[0].totalTimeSpentForAssessment;
  //         this.assessmentCandidatesDetails = false;
  //         this.CandidateReportDetails = true;
  //         this.assessmentDescriptionDash = true;
  //         this.assessmentData = false;
  //         this.showAssessemt = false;
  //         this.assessmentList1 = false;
  //         this.asseementQuestion = false;
  //         this.showSectionQuestion = false;
  //         this.assessmentConfigurationDash = false;
  //         this.privateLinkCreationStep1 = false;
  //         this.privateLinkCreationStep2 = false;
  //         this.publicLinkCreation = false;
  //       },
  //       error => {
  //         this.errorMsg = error.error.message;
  //         this.CandidateReportDetails = false;
  //       });

  // }
  checkReset(val) {
    this.changeval.emit(false);
    // $("input[value='" + val + "']").prop('checked', true);
    $("input[value=" + val + "]").prop("checked", true);
    this.modalResetCandidate.controls["reset"].setValue(val);
  }

  async CandidateRRReson(modal: any) {
    this.ngxService.start();
    this.publicerrorMsg = "";

    const payload = {
      linkId: this.linkId,
      mobile: this.mobileNum,
      countryCode: this.countryCodeNum,
      status: modal.reset,
      assessmentId: this.createdassessmentId,
      reason: modal.resetReason,
    };
    await this.assessmentService
      .CandidateRRReson(payload, this.companyId, this.accessToken)
      .toPromise()
      .then(
        (response) => {
          this.statusChangedFlag = true;
          ($("#candidateReset") as any).modal("hide");
          this.modalResetCandidate.reset();
          this.fetchCandidateDetails(this.selectedCandidate);
          this.toastrService.success(response.message, "", {
            timeOut: 5000,
          });
        },
        (error) => {
          this.publicerrorMsg = error.error.message;
          this.ngxService.stop();
        }
      );
  }

  getReason(data, idx) {
    if (data !== "") {
      this.suspicionReason = data;
    } else {
      this.suspicionReason = "";
    }
    if (idx === "first") {
      ($("#carouselExampleControls") as any).carousel(0);
    } else if (this.candidateAttendanceSnapshot["fileName"] === "") {
      ($("#carouselExampleControls") as any).carousel(idx);
    } else {
      ($("#carouselExampleControls") as any).carousel(idx + 1);
    }
  }

  scheduleTime(data) {

    let startDate, endDate, startDateTime, endDateTime;
    this.mobileNum = data.mobile;
    this.countryCodeNum = data.countryCode;
    if (data.startDate === "") {
      startDateTime = this.CandidateStartDT;
    } else {
      startDateTime = data.startDate;
    }
    if (data.endDate === "") {
      endDateTime = this.CandidateEndDT;
    } else {
      endDateTime = data.endDate;
    }
    if (startDateTime !== "" && startDateTime != undefined) {
      startDate = startDateTime.split("T");
      this.publicStartDate = startDate[0];
      if (startDate[1] !== "00:00:00" && startDate[1] !== "") {
        this.publicStartTime = startDate[1].substring(
          0,
          startDate[1].length - 3
        );
      }
    } else {
      this.publicStartDate = "";
    }
    if (endDateTime !== "" && endDateTime != undefined) {
      endDate = endDateTime.split("T");
      this.publicEndDate = endDate[0];
      if (endDate[1] !== "00:00:00" && endDate[1] !== "") {
        this.publicEndTime = endDate[1].substring(0, endDate[1].length - 3);
      }
    } else {
      this.publicEndDate = "";
    }
    if (startDateTime !== "" && startDateTime != undefined) {
      this.publicSTARTDate = {
        date: {
          year: parseInt(startDate[0].substring(0, 4), 10).toString(),
          month: parseInt(startDate[0].substring(5, 7), 10).toString(),
          day: parseInt(startDate[0].substring(8, 10), 10).toString(),
        },
      };
    }
    if (endDateTime !== "" && endDateTime != undefined) {
      this.publicENDDate = {
        date: {
          year: parseInt(endDate[0].substring(0, 4), 10).toString(),
          month: parseInt(endDate[0].substring(5, 7), 10).toString(),
          day: parseInt(endDate[0].substring(8, 10), 10).toString(),
        },
      };
    }
    ($("#scheduleTime") as any).modal("show");
  }

  imageUploadAlert(data, i, via) {
    if (this.CandidatelinkType === "PUBLIC") {
      return;
    }
    if (via === "imageUpload") {
      ($("#image") as any).modal("show");
    } else if (via === "menuUpload") {
      ($("#image") as any).modal("show");
    }
    this.selectedRowMobileNumber = data.mobile;
    this.selectedRowCountryCode = data.countryCode;
    this.selectRow = i;
    this.uploadedFileErrMsg = "";
    this.isUploadingCandImg = false;
  }

  assesmentImageUpload() {
    this.uploadedFileErrMsg = "";
    this.isUploadingCandImg = true;
    const payload = {
      companyId: this.companyId,
      mobile: this.selectedRowMobileNumber,
      countryCode: this.selectedRowCountryCode,
      linkId: this.linkId,
      baseString: this.base64textString,
      uploadImage: this.imageUploadForLink,
    };
    this.jobService
      .imageUploadActive(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.isUploadingCandImg = false;
          this.responseUrlImage = response.location;
          this.candidateReportResponse[this.selectRow].imageUrl =
            this.responseUrlImage + "?mc=" + Math.random();
          ($("#image") as any).modal("hide");
          $("#assesmentuploadvalidation").val("");
          this.afterSelect = false;
        },
        (error) => {
          this.errorMsg = error.error.message;
          this.uploadedFileErrMsg = this.errorMsg;
          this.afterSelect = false;
          this.isUploadingCandImg = false;
          $("#assesmentuploadvalidation").val("");
        }
      );
  }

  resetDateTimeScheduling() {
    this.publicerrorMsg = "";
    this.publicStartTime = "";
    this.publicSTARTDate = "";
    this.publicENDDate = "";
    this.publicEndTime = "";
    $("#startTime").val("");
    $("#endTime").val("");
  }

  onDateChanged(event, key) {
    this.errorMsg = "";
    if (key === "start") {
      this.publicStartDate = event.formatted;
      this.publicStartTime = "";
    } else {
      this.publicEndDate = event.formatted;
      this.publicEndTime = "";
    }
  }

  validateTime(e) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  onClock(id) {
    myExtObject.loadClock(id);
  }

  dateTimeScheduling() {

    this.publicerrorMsg = "";
    this.publicStartTime = ($("#startTime").val()).toString();;
    this.publicEndTime = ($("#endTime").val()).toString();
    let startDateTime, endDateTime;
    if (this.publicStartDate === "") {
      startDateTime = "";
    } else {
      startDateTime = this.publicStartDate + "T" + this.publicStartTime + ":00";
    }
    if (this.publicStartTime === "" && this.publicStartDate !== "") {
      startDateTime = this.publicStartDate + "T" + "00:00:00";
      this.publicerrorMsg = "Provide start time ";
      return;
    } else {
      this.publicerrorMsg = "";
    }
    if (this.publicEndDate === "") {
      endDateTime = "";
    } else {
      endDateTime = this.publicEndDate + "T" + this.publicEndTime + ":00";
    }
    if (this.publicEndTime === "" && this.publicEndDate !== "") {
      endDateTime = this.publicEndDate + "T" + "00:00:00";
      this.publicerrorMsg = "Provide end time ";
      return;
    } else {
      this.publicerrorMsg = "";
    }

    const payload = {
      linkId: this.linkId,
      candidates: [
        {
          mobile: this.mobileNum,
          countryCode: this.countryCodeNum,
          startDate: startDateTime,
          endDate: endDateTime,
        },
      ],
    };

    this.assessmentService
      .CandidateReschudling(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.modalResetCandidate.reset();

          this.fetchBatchCandidates(true);
          this.publicStartTime = "";
          this.publicSTARTDate = "";
          this.publicENDDate = "";
          this.publicEndTime = "";
          $("#startTime").val("");
          $("#endTime").val("");
          ($("#scheduleTime") as any).modal("hide");
        },
        (error) => {
          this.publicerrorMsg = error.error.message;
        }
      );
  }

  assesmentUploadClear() {
    $("#assesmentuploadvalidation").val("");
    this.uploadedFileErrMsg = "";
    this.afterSelect = false;
  }

  assesmentUpload(event) {
    let validFileUploaded = false;
    this.isUploadingCandImg = false;
    const files = event.target.files;
    const file = files[0];
    const uploadedFileNameArr = file.name.split(".");
    const uploadedFileExt =
      uploadedFileNameArr[uploadedFileNameArr.length - 1].toLowerCase();
    if (
      uploadedFileExt === "jpg" ||
      uploadedFileExt === "png" ||
      uploadedFileExt === "jpeg"
    ) {
      validFileUploaded = true;
      this.uploadedFileErrMsg = "";
    } else {
      validFileUploaded = false;
      this.uploadedFileErrMsg = "Invalid file extentions.";
      return;
    }
    if (files[0].size > 2000000) {
      this.uploadedFileErrMsg = "File size exceeds 2MB.";
      return;
    } else {
      this.uploadedFileErrMsg = "";
      validFileUploaded = true;
    }
    if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    if (validFileUploaded) {
      this.afterSelect = true;
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }


  seperateBatchStatus() {

    this.batchCandidates.forEach((element: any) => {
      if (element.status.includes('->')) {
        element.updatedStatus = (element.status.split('->'))[1];

      }
      else {
        element.updatedStatus = element.status;

      }

    });
  }
}

