<app-user-header></app-user-header>

<!-- Breadcrumb Section Starts -->
<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <!--<li class="breadcrumb-item" routerLink="/dashboard"><a >Home</a></li>-->
          <li class="breadcrumb-item active" aria-current="page" (click)="assessmentDashboard();"><a>Assessments</a>
          </li>
          <li *ngIf="assessmentTypeForLoadMore" class="breadcrumb-item active" aria-current="page">
            <a (click)="listAssessment( assessmentTypeForLoadMore );"> {{ assessmentTypeForLoadMore }} </a>
          </li>
          <li *ngIf="assessmentTypeForLoadMore && isDetails" class="breadcrumb-item inactive" aria-current="page">
            <a> Details </a>
          </li>
        </ol>
      </nav>
    </div>

    <div class="col-2" *ngIf="assessmentDetails">
      <div class="btn-group float-right">
        <button *ngIf="userRole == 'admin' && assessmentDetails.quickAssessmentTemplateId == ''" type="button"
          class="btn btn-link" aria-hidden="true"
          [ngClass]="{'disabled': masterQBAccess == false && editFlag == false}">
          <!--<i class="material-icons align-middle green"(click)="editAssessment(editId);">edit</i>-->
          <span class="align-middle" (click)="editAssessment(editId);"><img class=" " src="/assets/img/Edit.png" alt="">
            Edit</span>
        </button>
        <button *ngIf="userRole == 'admin' && assessmentDetails.quickAssessmentTemplateId != ''" type="button"
          class="btn btn-link" aria-hidden="true">
          <!--<i class="material-icons align-middle green"(click)="editAssessment(editId);">edit</i>-->
          <span class="align-middle" (click)="editAssessment(editId);"><img class=" " src="/assets/img/Edit.png" alt="">
            Edit</span>
        </button>
        <button *ngIf="userRole == 'admin'" class="btn btn-link" (click)="deleteAssessment(editId, true);">
          <!--<i class="material-icons align-middle green" >delete</i>-->
          <span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>
        </button>

        <button *ngIf="userRole == 'admin'" type="button" class="btn btn-primary " aria-haspopup="true"
          (click)="createNewAssessment();">
          Create Assessment
        </button>
      </div>
    </div>
  </div>
</nav>

<!-- Breadcrumb Section Ends -->

<div *ngIf="assessmentDetails" class="">
  <div class="container-fluid main-div-tab ">
    <div class="row">
      <div class="col">
        <p class="GR-18 mb-0">
          {{ assessmentDetails.assessmentName }}
          <span *ngIf="assessmentDetails.quickAssessmentTemplateId != ''" class="d-inline-block" tabindex="0"
            data-toggle="tooltip" title="Quick Assessment" data-animation="true" data-delay=0>
            <i class="fa fa-send ml-3" aria-hidden="true"></i>
          </span>
        </p>
        <nav class="navbar  navbar-expand-lg navbar-light navAdjust pl-0 pb-0">
          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav mr-auto ml-0 mt-2 mt-lg-0">
              <li class="nav-item mr-5">
                <a class="nav-link GR-14" id="Details" (click)="assessmentDetailsHeader();"
                  style="color: #0ec2d0; border-bottom: 2px solid;">Details</a>
              </li>
              <li class="nav-item mr-5 ">
                <a class="nav-link GR-14" id="Question" (click)="sectionDetails();">Questions</a>
              </li>
              <li class="nav-item mr-5 ">
                <a class="nav-link GR-14" id="config" (click)="aseessmentConfigHeader();">Configuration</a>
              </li>
              <li class="nav-item mr-5 ">
                <a class="nav-link GR-14" id="invites" (click)="listofInvitationLink(true,false);">Invites</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>


  <!-- Assessment Details  -->
  <div class="main-div-assessment container-fluid bg-white  pt-1 pb-1">
    <div *ngIf="assessmentData">
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th scope="col "><span class="font-weight-normal">Assessment Type</span></th>
            <th scope="col " *ngIf="assessmentDetails.assessmentType!='Psychometer'"><span
                class="font-weight-normal">Section</span></th>
            <th scope="col "><span class="font-weight-normal">Questions</span></th>
            <th scope="col "><span class="font-weight-normal">Duration</span></th>
            <th scope="col" *ngIf="assessmentDetails.assessmentType!='Psychometer'"><span
                class="font-weight-normal">Passing(%)</span></th>
            <th scope="col"><span class="font-weight-normal">Created On</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <h6>{{assessmentDetails.assessmentType}}</h6>
            </td>
            <td *ngIf="assessmentDetails.assessmentType!='Psychometer'">
              <h6>{{assessmentDetails.sections.length}}</h6>
            </td>
            <td>
              <h6>{{assessmentDetails.questionCount}}</h6>
            </td>
            <td>
              <h6>{{assessmentDetails.duration}}</h6>
            </td>
            <td *ngIf="assessmentDetails.assessmentType!='Psychometer'">
              <h6>{{assessmentDetails.cutOff}}</h6>
            </td>
            <td>
              <h6>{{assessmentDetails.createdOn | date: 'dd/MM/yyyy' }}</h6>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row mtop30">
        <div class="col">
          <h6>Assessment Description</h6>
          <p *ngIf="assessmentDetails.description !='' " [innerHTML]="assessmentDetails.description"></p>
          <p *ngIf="assessmentDetails.description =='' "> Not available. </p>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Assessment Details -->
  <!----Access Denied Popup Modal-->
  <div class="modal fade" id="AccessDenied" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Alert!</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          You have limited access to in-built question bank and premium features. Write to <b>support@calibrat.com'</b>.
        </div>
      </div>
    </div>
  </div>
  <!-- Access Denied Modal complete-->


  <!--Assessment Qusetion Details -->

  <div *ngIf="asseementQuestion" class="main-div-assessment container-fluid bg-white pt-1 pb-5 ">
    <div class="row mtop30">
      <div class="col">
        <div class="card ">
          <div class="card-body ">
            <div class="row GR-14">
              <!--<div class="col-1"></div>-->
              <div class="col">Section Name</div>
              <div class="col">Question Type</div>
              <div class="col">Questions</div>
              <div class="col">Question View Preference</div>
            </div>
          </div>
        </div>
        <div class="card card__two mtop15 border-0" *ngFor="let list of assessmentDetails.sections ;let i = index;">
          <div class="card-body card-bg">
            <div class="row">
              <!--<div class="col-1 text-center">-->
              <!--<div class="form-check text-truncate ">-->
              <!--<label class="mb-0"></label>-->
              <!--</div>-->
              <!--</div>-->
              <div class="col text-truncate qHeading dect" (click)="sectionWiseQuestion(list,i);">
                {{list.sectionName}} <sup *ngIf="list.hasOptionalQuestions" class="blue RR-12">*</sup>
              </div>
              <div
                *ngIf="assessmentDetails.assessmentType!='Psychometer' && assessmentDetails.assessmentType!='Online Compiler' "
                class="col text-truncate qRest">{{ assessmentQuestionType[i] }} </div>
              <div *ngIf="assessmentDetails.assessmentType === 'Psychometer'" class="col text-truncate qRest">
                Psychometer</div>
              <div *ngIf="assessmentDetails.assessmentType === 'Online Compiler'" class="col text-truncate qRest">
                Online Compiler</div>
              <div *ngIf="assessmentDetails.assessmentType != 'Online Compiler' && !list.hasOptionalQuestions"
                class="col qRest">{{assessmentNumQuestion[i]}} </div>
              <div *ngIf="assessmentDetails.assessmentType != 'Online Compiler' && list.hasOptionalQuestions"
                class="col qRest">{{list.questionsToBeAnswered}} of {{assessmentNumQuestion[i]}} </div>
              <div *ngIf="assessmentDetails.assessmentType === 'Online Compiler'" class="col qRest">{{
                onlinequestionCount}} </div>
              <div class="col qRest">{{ questionViewPreferences[i] === 'all'? 'All' : (questionViewPreferences[i] ===
                'default' ? 'Default' : 'Default') }}</div>
            </div>
            <div class="row mt-1 " *ngIf="list.hasOptionalQuestions">
              <div class="col">
                <span class="grey RR-12 disabled2">Optional questions setting is enabled for this section</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Question Details End -->

  <!--  Detailed Question Section -->

  <div *ngIf="showSectionQuestion" class="container-fluid main-div-assessment">

    <div class="row pt-3">
      <div class="col-4">
        <p class="GR-12 mb-0">Section Name</p>
        <p class="GR-22 mb-2">
          <i class="material-icons green align-middle" style="cursor: pointer; margin-left: -5px;"
            (click)="sectionDetails();">
            keyboard_arrow_left
          </i>
          <span *ngIf="sectionWiseQuestionDetail && sectionWiseQuestionDetail[0]"> {{
            sectionWiseQuestionDetail[0].sectionName}} </span>
        </p>
        <div class="row">
          <div class="col-4">
            <p class="GR-12 mb-1 grey">Questions</p>
            <p class="GR-22 mb-0 text-success" *ngIf="!sectionWiseQuestionDetail[0].hasOptionalQuestions">
              {{ assessmentNumQuestion[sectionIndex] }}
            </p>
            <p class="GR-22 mb-0 text-success" *ngIf="sectionWiseQuestionDetail[0].hasOptionalQuestions">
              {{ sectionWiseQuestionDetail[0].questionsToBeAnswered}} of {{ assessmentNumQuestion[sectionIndex] }}
            </p>
          </div>
        </div>
        <div class="row" *ngIf="sectionWiseQuestionDetail[0].hasOptionalQuestions">
          <div class="col">
            <span class="grey RR-12 disabled2">Optional questions setting is enabled for this section</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="assessmentNumQuestion && assessmentNumQuestion[sectionIndex] != 0">
      <div class="row">
        <div class="col" *ngIf="sectionWiseQuestionDetail[0].sectionName==='Psychometer'">
          <div class="col-12">
            <div class="row mt-3 mb-3">
              <div class="col">
                <span class="GR-16">1. </span>
                <span class="GR-16">I feel confident in my ability to deal with other people.?</span>
              </div>
            </div>
            <div style="color: green">
              <p class="GR-14">
                Strongly Disagree <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="">
              </p>
            </div>

            <div>
              <p class="GR-14">Disagree</p>
            </div>

            <div>
              <p class="GR-14">Neutral</p>
            </div>

            <div>
              <p class="GR-14">Agree</p>
            </div>

            <div>
              <p class="GR-14">Strongly Agree</p>
            </div>
          </div>
        </div>

        <div class="col" *ngIf="sectionWiseQuestionDetail[0].sectionName!='Psychometer' && !forOnlineCompiler">
          <div class="" *ngFor="let list of questionAnswer;let i=index;">

            <div class="row mt-3 mb-3">
              <div class="col">
                <span class="GR-16"> {{i+1}}. </span>
                <app-mathjax class="GR-16" [content]="list.question"> ? </app-mathjax>
              </div>
            </div>

            <div *ngIf="list.questionType === 'Descriptive'" class="row mt-3 mb-3">
              <div class="col">
                <span class="desc-answer">
                  {{ list.answer }}
                </span>
              </div>
            </div>

            <!-- displaying uploaded media of each question -->
            <div class="mt-3 mb-1">
              <ng-container *ngIf="list.attachedMedias && list.attachedMedias.length > 0">
                <div class="row mb-2 ">
                  <div *ngFor="let media of list.attachedMedias; let k = index" class="media-item" [ngClass]="{                        
                         'col-12': list.renderedOptions === '1',
                         'col-6 ': list.renderedOptions === '2',
                         'col-4 ': list.renderedOptions === '3',
                         'col-3 ': list.renderedOptions === '4',
                         'col-2 ': list.renderedOptions === '5',
                         'media-col-1 ': list.renderedOptions === 'all'
                       }">
                    <div *ngIf="media.fileType.startsWith('images')">
                      <img [src]="media.location" alt="Image Preview" (click)="openMedia(media.location,media.fileType)"
                        style="width: 200px; height: 130px;" class="mt-3">
                    </div>
                    <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                      <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                        disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                        style="width: 200px; height: 130px;" class="mt-3"></video>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="row">
              <div class="col">
                <div class="mt-1 mb-3">
                  <div *ngIf="list.difficultyLevel == easy ">
                    <span class="badge badge-success mr-1">{{list.difficultyLevel}}</span>
                    <span class="badge badge-single">{{list.questionType}}</span>
                  </div>
                  <div *ngIf="list.difficultyLevel == medium ">
                    <span class="badge badge-warning mr-1">{{list.difficultyLevel}}</span>
                    <span class="badge badge-single">{{list.questionType}}</span>
                  </div>


                  <div *ngIf="list.difficultyLevel == hard ">
                    <span class="badge badge-danger mr-1">{{list.difficultyLevel}}</span>
                    <span class="badge badge-single">{{list.questionType}}</span>
                  </div>
                </div>
                <div *ngIf="list.questionType !=='Reading Comprehension' && list.questionType!=='Coding'">
                  <div
                    *ngIf="list.questionType !== 'Fill In The Blanks' && list.questionType !== 'Descriptive' && list.questionType !== 'Match The Following'">
                    <div
                      *ngIf="list.answer.a ==  option1 || list.answer.b == option1 || list.answer.c == option1 || list.answer.d == option1"
                      class="green2">
                      <p class="GR-14 mb-1">Option A : <span [innerHTML]="list.options.option1"></span> &nbsp;
                        <img class=" " src="/assets/img/Upload completed.png" alt="">
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option1 && list.optionsFiles.option1.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option1 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div
                      *ngIf="list.answer.a != option1 && list.answer.b != option1 && list.answer.c != option1 && list.answer.d != option1 ">
                      <p class="GR-14 mb-1">Option A : <span [innerHTML]="list.options.option1"></span></p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option1 && list.optionsFiles.option1.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option1 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                    <div
                      *ngIf="list.answer.a == option2 || list.answer.b == option2 || list.answer.c == option2 || list.answer.d == option2"
                      class="green2">
                      <p class="GR-14 mb-1">Option B : <span [innerHTML]="list.options.option2"></span> &nbsp;
                        <img class=" " src="/assets/img/Upload completed.png" alt="">
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option2 && list.optionsFiles.option2.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option2 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div
                      *ngIf="list.answer.a != option2 && list.answer.b != option2 && list.answer.c != option2 && list.answer.d != option2 ">
                      <p class="GR-14 mb-1">Option B : <span [innerHTML]="list.options.option2"></span> </p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option2 && list.optionsFiles.option2.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option2 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                    <div
                      *ngIf="list.answer.a == option3 || list.answer.b == option3 || list.answer.c == option3 || list.answer.d == option3"
                      class="green2">
                      <p class="GR-14 mb-1" *ngIf="list.options.option3 !='' ">
                        Option C : <span [innerHTML]="list.options.option3"></span> &nbsp;
                        <img class=" " src="/assets/img/Upload completed.png" alt="">
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option3 && list.optionsFiles.option3.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option3 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div
                      *ngIf="list.answer.a != option3 && list.answer.b != option3 && list.answer.c != option3 && list.answer.d != option3 ">
                      <p class="GR-14 mb-1" *ngIf="list.options.option3 !='' ">Option C : <span
                          [innerHTML]="list.options.option3"></span></p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option3 && list.optionsFiles.option3.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option3 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                    <div
                      *ngIf="list.answer.a == option4 || list.answer.b == option4 || list.answer.c == option4 || list.answer.d == option4"
                      class="green2">
                      <p class="GR-14 mb-1" *ngIf="list.options.option4 !='' ">Option D : <span
                          [innerHTML]="list.options.option4"></span> &nbsp;
                        <img class="" src="/assets/img/Upload completed.png" alt="">
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option4 && list.optionsFiles.option4.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option4 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div
                      *ngIf="list.answer.a != option4 && list.answer.b != option4 && list.answer.c != option4 && list.answer.d != option4 ">
                      <p class="GR-14 mb-1" *ngIf="list.options.option4 !='' ">Option D : <span
                          [innerHTML]="list.options.option4"></span></p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option4 && list.optionsFiles.option4.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option4 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                  </div>

                  <!-- match the following -->
                  <div *ngIf="list.questionType =='Match The Following'" class="mt-4">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 8%;">#</th>
                          <th scope="col" style="width: 46%;">Option</th>
                          <th scope="col">Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let lhsOption of list.LHSOptions; let i = index">

                          <th scope="row">{{i+1}}</th>
                          <td>
                            <div [innerHTML]="getOptionText(lhsOption, 'LHSOption')"></div>
                            <div class="mb-2">
                              <ng-container *ngIf="lhsMediaDetails['option' + (i + 1)]?.length > 0">
                                <div *ngFor="let media of lhsMediaDetails['option' + (i + 1)]">
                                  <div *ngIf="media.fileType.startsWith('images')">
                                    <img [src]="media.location" alt="Image Preview"
                                      (click)="openMedia(media.location,media.fileType)"
                                      style="width: 170px; height: 100px;">
                                  </div>
                                  <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                                    <video [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                      controls disablePictureInPicture
                                      controlslist="nodownload noplaybackrate nofullscreen"
                                      style="width: 170px; height: 100px;"></video>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </td>
                          <td>

                            <select class="form-control">
                              <option disabled *ngFor="let rhsOption of list.RHSOptions"
                                [selected]="list.answer[i] == getOptionKey(rhsOption, 'RHSOption')">
                                {{getOptionText(rhsOption, 'RHSOption')}}</option>
                            </select>

                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>

                  <!-- match the following -->
                </div>






                <!-- For Comprehension que-->

                <div *ngIf="list.questionType =='Reading Comprehension'">
                  <div *ngIf="list.subQuestions">
                    <div *ngFor="let subQue of list.subQuestions; let qSlNo = index;"
                      class="card card__two mtop15 border-0">
                      <div class="card-body card-thin">
                        <div class="row mt-3 mb-2">
                          <div class="col" *ngIf="subQue.questionType!='Fill In The Blanks'">
                            <span class="GR-16"> {{i+1}}.{{qSlNo+1}}.&nbsp;</span> <app-mathjax class="GR-16"
                              [content]="subQue.question"></app-mathjax>
                          </div>
                          <div class="col" *ngIf="subQue.questionType =='Fill In The Blanks'">
                            <span class="GR-16">{{i+1}}.{{qSlNo+1}}.&nbsp;</span><span class="GR-16"
                              [innerHTML]="fillInTheBlanksQuesn"></span>
                          </div>
                        </div>

                        <!-- displaying uploaded media of each question -->
                        <div class="mt-3 mb-1">
                          <ng-container *ngIf="subQue.attachedMedias && subQue.attachedMedias.length > 0">
                            <div class="row mb-2 ">
                              <div *ngFor="let media of subQue.attachedMedias; let k = index" class="media-item"
                                [ngClass]="{                        
                                     'col-12': subQue.renderedOptions === '1',
                                     'col-6 ': subQue.renderedOptions === '2',
                                     'col-4 ': subQue.renderedOptions === '3',
                                     'col-3 ':subQue.renderedOptions === '4',
                                     'col-2 ': subQue.renderedOptions === '5',
                                     'mediaitem-col-1 ml-3 ': subQue.renderedOptions === 'all'
                                   }">
                                <div *ngIf="media.fileType.startsWith('images')">
                                  <img [src]="media.location" alt="Image Preview"
                                    (click)="openMedia(media.location,media.fileType)"
                                    style="width: 180px; height: 150px;" class="mt-3">
                                </div>
                                <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                                  <video [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                    controls disablePictureInPicture
                                    controlslist="nodownload noplaybackrate nofullscreen"
                                    style="width: 180px; height: 150px;" class="mt-3"></video>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>

                        <div
                          *ngIf="subQue.questionType !== 'Fill In The Blanks' && subQue.questionType !== 'Descriptive' && subQue.questionType !== 'Match The Following'">
                          <div
                            *ngIf="subQue.answer.a ==  option1 || subQue.answer.b == option1 || subQue.answer.c == option1 || subQue.answer.d == option1"
                            class="green2">
                            <p class="GR-14">Option A : <span [innerHTML]="subQue.options.option1"></span> &nbsp;
                              <img class=" " src="/assets/img/Upload completed.png" alt="">
                            </p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a != option1 && subQue.answer.b != option1 && subQue.answer.c != option1 && subQue.answer.d != option1 ">
                            <p class="GR-14">Option A : <span [innerHTML]="subQue.options.option1"></span></p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a == option2 || subQue.answer.b == option2 || subQue.answer.c == option2 || subQue.answer.d == option2"
                            class="green2">
                            <p class="GR-14">Option B : <span [innerHTML]="subQue.options.option2"></span> &nbsp;
                              <img class=" " src="/assets/img/Upload completed.png" alt="">
                            </p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a != option2 && subQue.answer.b != option2 && subQue.answer.c != option2 && subQue.answer.d != option2 ">
                            <p class="GR-14">Option B : <span [innerHTML]="subQue.options.option2"></span> </p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a == option3 || subQue.answer.b == option3 || subQue.answer.c == option3 || subQue.answer.d == option3"
                            class="green2">
                            <p class="GR-14" *ngIf="subQue.options.option3 !='' ">
                              Option C : <span [innerHTML]="subQue.options.option3"></span> &nbsp;
                              <img class=" " src="/assets/img/Upload completed.png" alt="">
                            </p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a != option3 && subQue.answer.b != option3 && subQue.answer.c != option3 && subQue.answer.d != option3 ">
                            <p class="GR-14" *ngIf="subQue.options.option3 !='' ">Option C : <span
                                [innerHTML]="subQue.options.option3"></span></p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a == option4 || subQue.answer.b == option4 || subQue.answer.c == option4 || subQue.answer.d == option4"
                            class="green2">
                            <p class="GR-14" *ngIf="subQue.options.option4 !='' ">Option D : <span
                                [innerHTML]="subQue.options.option4"></span> &nbsp;
                              <img class="" src="/assets/img/Upload completed.png" alt="">
                            </p>
                          </div>
                          <div
                            *ngIf="subQue.answer.a != option4 && subQue.answer.b != option4 && subQue.answer.c != option4 && subQue.answer.d != option4 ">
                            <p class="GR-14" *ngIf="subQue.options.option4 !='' ">Option D : <span
                                [innerHTML]="subQue.options.option4"></span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">

    </div>

  </div>

  <!--  Detailed Question Section Ends -->


  <!-- Assessment Configuration Section -->
  <div *ngIf="assessmentConfigurationDash" class="main-div-assessment container-fluid bg-white pt-1 pb-5 ">

    <div class="row mt-3">
      <div class="col-6"
        *ngIf="assessmentDetails.assessmentType!='Psychometer' && assessmentDetails.assessmentType!='Online Compiler' ">
        <h6 class="pt-3">Test Question Configuration</h6>
        <table class="table table-bordered mtop15">
          <thead>
            <tr>
              <th>Setting Title</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span class="font-weight-normal text text-muted"> Shuffle questions </span></td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.shuffleQuestions"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.shuffleQuestions"> Disabled </span>
              </td>
            </tr>
            <tr>
              <td scope="col ">
                <span class="font-weight-normal text text-muted"> Shuffle options </span>
              </td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.shuffleOptions"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.shuffleOptions"> Disabled </span>
              </td>
            </tr>
            <tr>
              <td scope="col ">
                <span class="font-weight-normal text text-muted"> All question compulsory </span>
              </td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.attemptAll"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.attemptAll"> Disabled </span>
              </td>
            </tr>
            <tr>
              <td scope="col ">
                <span class="font-weight-normal text text-muted"> Move forward only </span>
              </td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.moveForwardOnly"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.moveForwardOnly"> Disabled </span>
              </td>
            </tr>

          </tbody>
        </table>

      </div>
      <div *ngIf="assessmentDetails.assessmentType!='Online Compiler'" class="col-6">
        <h6 class="pt-3"> Other Settings </h6>
        <table class="table table-bordered mtop15">
          <thead>
            <tr>
              <th>Setting Title</th>
              <th>Status</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="assessmentDetails.assessmentType!='Psychometer'">
              <td><span class="font-weight-normal text text-muted"> Display Maximum Marks </span></td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.showQuestionScore"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.showQuestionScore"> Disabled </span>
              </td>
              <td>
                <span class="text-muted"> - </span>
              </td>
            </tr>
            <tr *ngIf="assessmentDetails.assessmentType!='Psychometer'">
              <td><span class="font-weight-normal text text-muted"> Display Question Category Information </span></td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.showCategoryInfo"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.showCategoryInfo"> Disabled </span>
              </td>
              <td>
                <span class="text-muted"> - </span>
              </td>
            </tr>
            <tr *ngIf="assessmentDetails.assessmentType!='Psychometer'">
              <td><span class="font-weight-normal text text-muted"> Auto Resume Incomplete Attempt </span></td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.allowedAutoResume"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.allowedAutoResume"> Disabled </span>
              </td>
              <td>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.allowedAutoResume"> - </span>
                <span class="text-muted" *ngIf="assessmentDetails.configuration.allowedAutoResume">
                  {{assessmentDetails.configuration.allowedAutoResumeValue}} </span>
              </td>
            </tr>
            <tr *ngIf="assessmentDetails.assessmentType!='Psychometer'">
              <td><span class="font-weight-normal text text-muted"> Negative Marking </span></td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.negativeMarking"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.negativeMarking"> Disabled </span>
              </td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.selectPenalty">
                  {{ assessmentDetails.configuration.selectPenalty }}
                </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.selectPenalty"> - </span>
              </td>
            </tr>
            <tr>
              <td scope="col ">
                <span class="font-weight-normal text text-muted"> Violation </span>
              </td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.allowedSuspiciousCount"> Enabled </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.allowedSuspiciousCount"> Disabled
                </span>
              </td>
              <td>
                <span class="enabled" *ngIf="assessmentDetails.configuration.suspiciousCountValue">
                  {{ assessmentDetails.configuration.suspiciousCountValue }}
                </span>
                <span class="text-muted" *ngIf="!assessmentDetails.configuration.suspiciousCountValue"> - </span>
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <h6>Additional Information</h6>

        <div class="form-check pl-0 pt-2">
          <strong><label class="form-check-label" for="inlineCheckbox1"> Instructions for test : </label></strong>
        </div>
        <div class="form-check pl-0">
          <label class="form-check-label small" *ngIf="instructForTestValue" [innerHTML]="instructForTestValue">
          </label>
          <label class="text-muted small" *ngIf="!instructForTestValue"> None provided </label>
        </div>

        <div class="form-check mt-3 pl-0">
          <strong><label class="form-check-label" for="inlineCheckbox1">After test message : </label></strong>
        </div>
        <div class="form-check pl-0">
          <label class="form-check-label small" *ngIf="afterTestMessageValue" [innerHTML]="afterTestMessageValue">
          </label>
          <label class="text-muted small" *ngIf="!afterTestMessageValue"> None provided </label>
        </div>

      </div>
    </div>

  </div>
  <!-- End of Assessment Configuaration -->


  <!-- start asseesment invitation -->
  <div *ngIf="assessmentListOfCandidates" class="main-div-assessment container-fluid bg-white pt-1 pb-5 ">
    <div *ngIf="listMessage">


      <div class="row">
        <div class="col-sm-6">
          <div *ngIf="listofLinkDetails.length > 0">
            {{listofLinkDetails.length}} invite(s) created
          </div>
        </div>
        <div class="col-sm-2">
          <button *ngIf="searchTextTag.length!=0 && searchTextTag.length &gt; 8" data-toggle="tooltip"
            data-placement="top" title="{{searchTextTag}}" style="margin-left: 70px;margin-top: 8px;"
            (click)="resetSearch()" class="btn  srchbadge "> {{searchTextTag|slice:0:8}}... <i class="fa fa-close"></i>
          </button>
          <button *ngIf="searchTextTag.length!=0 && searchTextTag.length &lt; 9"
            style="margin-left: 70px;margin-top: 8px;" (click)="resetSearch()" class="btn  srchbadge ">
            {{searchTextTag}} <i class="fa fa-close"></i> </button>
        </div>
        <div class="col-sm-3 pr-5 float-right">

          <div class="input-group ">
            <input class="input-group-text" type="text" class="form-control" placeholder=" Search Batch Name"
              [(ngModel)]="searchText1" (keyup.enter)="searchBatch()">
            <div class="input-group-append ">
              <span class="input-group-text " [ngClass]="[searchText1.length &lt; 3 ?'disabled2':'btnclr']"
                id="search-btn" (click)="searchBatch()"><i class="fa fa-search "></i></span>
            </div>
          </div>
        </div>

        <div class="col-sm-1">
          <button *ngIf="userRole == 'admin'" type="button" class="btn btn-primary pull-right "
            (click)="noInvitaionForAssesment();" [ngClass]="{'disabled': masterQBAccess == false && editFlag == false}">
            Create New
          </button>

        </div>
      </div>




      <div class="row mt-3">

        <div class="col">
          <div class="card ">
            <div class="card-body ">
              <div class="row GR-14">

                <!--<div class="col-1"></div>-->
                <div class="col ">Batch/Group</div>
                <div class="col ">Invite Type</div>
                <div class="col ">Status</div>
                <div class="col">Candidates</div>
                <div class="col">Created On</div>
                <div class="col-1"></div>
              </div>
            </div>
          </div>

          <div class="container-fluid text-center" *ngIf="noDataFoundFlag && searchFlag">
            <h3 class="text-center mt-5">{{inviteErr}}</h3>

          </div>
          <div class="container-fluid text-center" *ngIf="lessChar">
            <h3 class="text-center mt-5">Searchtext cannot be less than 3 characters</h3>

          </div>
          <div class="card card__two mtop15 border-0" *ngFor="let list of listofLinkDetails ;let i=index;">
            <div class="card-body card-bg">
              <!-- ******No data Found-->

              <div class="row">
                <!--<div class="col-1 text-center">-->
                <!--<div class="form-check">-->
                <!--<label class="mb-0"></label>-->
                <!--</div>-->
                <!--</div>-->

                <!--******************Enable Lock unlock changes -->
                <div class="col text-truncate qHeading dect" (click)="candidateReportList(list,true)">
                  {{list.linkName}}
                </div>
                <div class="col text-truncate qRest">{{list.linkType}}</div>
                <!--******** invite is active , has enableLockUnlock and enableLockUnlock == true  **************** -->
                <div
                  *ngIf=" list.status == 'Active' && list.configuration.hasOwnProperty('enableLockUnlock') && list.configuration.enableLockUnlock "
                  style="color: green;" class="col  text-truncate qRest">
                  <span class="badge badge-success">{{list.status}}</span>

                  <i *ngIf=" list.lockStatus=='LOCKED'" class="fa fa-lock" aria-hidden="true"
                    style="color: black; padding-left: 15px;"></i>
                  <i *ngIf="list.lockStatus=='UNLOCKED'" class="fa fa-unlock" aria-hidden="true"
                    style="color: black; padding-left: 15px;"></i>


                  <i *ngIf="checkCenterManager(list.assignedTo);else centMngr" class="fa fa-user " aria-hidden="true"
                    style=" padding-left: 15px;color:#01b3d3 !important;" data-toggle="tooltip" data-placement="top"
                    title="{{centerMngrAssignedTo}}"></i>



                  <ng-template #centMngr>
                    <i class="fa fa-user ng-star-inserted grey" aria-hidden="true"
                      style="color: black; padding-left: 15px;" data-toggle="tooltip" data-placement="top"
                      title="No Center Manager assigned yet"></i>
                  </ng-template>
                </div>

                <!--******** invite is active , has  enableLockUnlock and enableLockUnlock == false  **************** -->

                <div
                  *ngIf=" list.status == 'Active' && list.configuration.hasOwnProperty('enableLockUnlock')&& list.configuration.enableLockUnlock ==false "
                  style="color: green" class="col text-truncate qRest">
                  <span class="badge badge-success">{{list.status}}</span>

                </div>

                <!--******** invite is active , has no  enableLockUnlock   **************** -->

                <div *ngIf=" list.status == 'Active' && !list.configuration.hasOwnProperty('enableLockUnlock') "
                  style="color: green" class="col text-truncate qRest">
                  <span class="badge badge-success">{{list.status}}</span>

                </div>

                <!--******** invite is Inactive , has  enableLockUnlock and enableLockUnlock == true  **************** -->

                <div
                  *ngIf=" list.status == 'Inactive' && list.configuration.hasOwnProperty('enableLockUnlock') && list.configuration.enableLockUnlock "
                  style="color: red" class="col text-truncate qRest">
                  <span class="badge badge-danger">{{list.status}}</span>
                  <i *ngIf=" list.lockStatus=='LOCKED'" class="fa fa-lock" aria-hidden="true"
                    style="color: black; padding-left: 15px;"></i>
                  <i *ngIf="list.lockStatus=='UNLOCKED'" class="fa fa-unlock" aria-hidden="true"
                    style="color: black; padding-left: 15px;"></i>



                  <i *ngIf="list.assignedTo.length>0 && list.assignedTo[0].role.centerManager" class="fa fa-user "
                    aria-hidden="true" style=" padding-left: 15px;color: #01b3d3 !important;" data-toggle="tooltip"
                    data-placement="top" title="{{list.assignedTo[0].userName}}"></i>

                  <i *ngIf="list.assignedTo.length>0 && !list.assignedTo[0].role.centerManager || list.assignedTo.length==0 "
                    class="fa fa-user ng-star-inserted grey" aria-hidden="true"
                    style="color: black; padding-left: 15px;" data-toggle="tooltip" data-placement="top"
                    title="No Centre Manager assigned yet"></i>
                </div>

                <!--******** invite is Inactive , has  enableLockUnlock and enableLockUnlock == false  **************** -->

                <div
                  *ngIf=" list.status == 'Inactive' && list.configuration.hasOwnProperty('enableLockUnlock') && list.configuration.enableLockUnlock == false"
                  style="color: red" class="col text-truncate qRest">
                  <span class="badge badge-danger">{{list.status}}</span><i class="fa fa-exclamation-triangle"
                    title="Test admin has mandated the upload of candidate's profile photo. To activate the test link, upload the profile photos for all the candidates."
                    style="padding-left:4px;cursor: pointer" *ngIf="list.reason != ''"></i>

                </div>

                <!--******** invite is Inactive , has no  enableLockUnlock   **************** -->

                <div *ngIf=" list.status == 'Inactive' && !list.configuration.hasOwnProperty('enableLockUnlock') "
                  style="color: red" class="col text-truncate qRest">
                  <span class="badge badge-danger">{{list.status}}</span><i class="fa fa-exclamation-triangle"
                    title="Test admin has mandated the upload of candidate's profile photo. To activate the test link, upload the profile photos for all the candidates."
                    style="padding-left:4px;cursor: pointer" *ngIf="list.reason != ''"></i>

                </div>




                <div class="col text-truncate qRest">{{list.candidatesCount}}</div>
                <div class="col qRest">{{list.createdOn | date: 'dd/MM/yyyy'}}</div>

                <div class="col-1">
                  <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <i *ngIf="list.isReviewAnswer" popover="Assessment Evaluation Pending" [popoverOnHover]="true"
                    [popoverCloseOnMouseOutside]="true" popoverPlacement="left" class="material-icons blue-dot">
                    fiber_manual_record </i>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-end"
                    style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <a *ngIf="list.linkType == 'PUBLIC' " class="dropdown-item" (click)="ViewAccessCode(list,true);">
                      Get Invite Link
                    </a>
                    <a *ngIf="list.linkType == 'PUBLIC' " class="dropdown-item" (click)="ViewAccessCode(list,false);">
                      Access Code
                    </a>

                    <a *ngIf="userRole == 'admin'" class="dropdown-item" (click)="showAssignedPersonals(list);">Manage
                      Batch Assignment</a>
                    <a *ngIf="userRole == 'admin'" class="dropdown-item" (click)="editLink(list);">Edit</a>
                    <a *ngIf="userRole == 'admin'" class="dropdown-item" (click)="deleteLink(list._id)">Archive</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="listErrMessage" class="row">
      <div class="col text-center">
        <div class="mtop50">
          <img class=" mx-auto d-block" src="/assets/img/link-landing.png" alt="">
          <h5 class="mt-5 mb-3">Not created any Assessment link for Batch/Group?</h5>
          <p *ngIf="userRole == 'admin'">Start inviting!</p>

          <button *ngIf="userRole == 'admin'" type="button" class="btn btn-primary "
            (click)="noInvitaionForAssesment();">
            Create New
          </button>

        </div>
      </div>
    </div>


    <div *ngIf="listofLinkDetails.length != 0" class="row mtop30">
      <div class="col text-center">
        <button type="button" [hidden]="!listOfLinkForAssessment" class="btn btn-more mtop30 "
          (click)="listofInvitationLink(false,$event);">
          Load more
        </button>
      </div>
    </div>

  </div>
  <!-- end of asseesment invitation -->

  <!--start private link details-->
  <div *ngIf="assessmentCandidatesDetails" class="container-fluid bg-white  pt-1 pb-5">

    <div *ngIf="showCandidatelinkName"  class=" main-div-assessment row mt-3">
      <div class="col-sm-6">
        <nav aria-label="breadcrumb">
          <div class="pull-left pl-0">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <i class="material-icons green align-middle" style="cursor: pointer"
                  (click)="backButtonForLink();">keyboard_arrow_left</i>
                <a>{{CandidatelinkName}}</a>
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <div class="col-sm-2">
        <button *ngIf="candidateSearchTextTag.length!=0 && candidateSearchTextTag.length &gt; 8 " data-toggle="tooltip"
          data-placement="top" title="{{candidateSearchTextTag}}" style="margin-left: 70px;margin-top: 8px;"
          (click)="resetCandidateSearch()" class="btn   srchbadge "> {{candidateSearchTextTag |slice:0:8}}... <i
            class="fa fa-close"></i> </button>
        <button *ngIf="candidateSearchTextTag.length!=0 && candidateSearchTextTag.length &lt; 9 "
          style="margin-left: 70px;margin-top: 8px;" (click)="resetCandidateSearch()" class="btn  srchbadge ">
          {{candidateSearchTextTag}} <i class="fa fa-close"></i> </button>
      </div>
      <div class="col-sm-3 pr-5 float-right">

        <div class="input-group  ">
          <input type="text" class="form-control" placeholder=" Search Candidate " [(ngModel)]="candidateSearchText1"
            (keyup.enter)="searchCandidate()">
          <div class="input-group-append ">
            <span class="input-group-text  " [ngClass]="[candidateSearchText1.length &lt; 3 ?'disabled2':'btnclr']"
              id="search-btn" (click)="searchCandidate()"><i class="fa fa-search "></i></span>
          </div>
        </div>
      </div>

      <div class="col-sm-1">
        <div class="btn-group float-right ">
          <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            Actions
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" *ngIf="CandidatelinkType == 'PRIVATE'" (click)="addPreviewCandidates();"
              [ngClass]="{'disabled': masterQBAccess == false && editFlag == false}">Add
              New</a>
            <a class="dropdown-item" (click)="downloadLocation();">Export</a>
            <a class="dropdown-item" (click)="bulkReportDownload()">Bulk Report Download</a>
          </div>
        </div>
      </div>
    </div>

    <app-list-of-candidates [batchCandidates]="candidateReportResponse" [hasMoreCandidate]="candidateListForLink"
      [noDataFoundFlag]="noCandidateFound" [candidateErr]="CandidateErr"
      (changeval)="changeBreadCrumb($event)"></app-list-of-candidates>
  </div>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>





  <!--End of private link details-->


  <!-- starting Model for public link generatipon-->
  <div class="modal fade" id="publicLink" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Invite Link</h5>
          <button *ngIf="publicLinkCreation" type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="privateCandidateinvitationList(false);">
            <span aria-hidden="true">&times;</span>
          </button>
          <button *ngIf="!publicLinkCreation" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="row">
            <div class="col text-center">
              <img src="./assets/img/publish-assessment-link.png" id="" alt="" class="mt-3 mb-3">
              <p *ngIf="!appendAccessCodeFlag" class="mb-1">Access Code</p>
              <h5 *ngIf="!appendAccessCodeFlag">{{Testcode}}</h5>
              <p *ngIf="appendAccessCodeFlag" class="mb-1">Unique Invite Link</p>

              <div *ngIf="appendAccessCodeFlag" class="input-group mt-3">
                <input type="text" class="form-control" id="linkGener" name="publiclink"
                  aria-label="Recipient's username" aria-describedby="basic-addon2"
                  [value]="publicLinkcreateion +'&batchtoken='+ Testcode" [(ngModel)]="dispPublicLinkCreation" readonly>
                <div class="input-group-append">
                  <i class="fa fa-clone fa-lg green" (click)="copyLink();" tooltip="copy"
                    style=" margin-left: 7px;margin-top: 13px; cursor: pointer;"></i>
                </div>
              </div>
              <div *ngIf="!appendAccessCodeFlag" class="input-group mt-3">
                <input type="text" class="form-control" id="linkGener" name="publiclink"
                  aria-label="Recipient's username" aria-describedby="basic-addon2" [value]="publicLinkcreateion "
                  [(ngModel)]="publicLinkcreateion" readonly>
                <div class="input-group-append">
                  <i class="fa fa-clone fa-lg green" (click)="copyLink();" tooltip="copy"
                    style=" margin-left: 7px;margin-top: 13px; cursor: pointer;"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer ">
          <!--<button type="button" class="btn btn-secondary pull-right" data-dismiss="modal" (click)="privateCandidateinvitationList();">Done</button>-->
        </div>
      </div>
    </div>
  </div>
  <!-- ending Model for public link generation-->

  <!--delete link  modal starts-->
  <div class="modal fade" id="deleteLink" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          Are you sure you want to archive this test link?
        </div>
        <div class="modal-footer d-block mx-auto">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteLinkMessage();">Yes</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
  <!--delete link modal ends-->

  <!-- Assign Batch modal starts-->
  <div class="modal fade" id="assignedPersonalModal" tabindex="-1" role="dialog"
    aria-labelledby="assignedPersonalModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 600px;">
        <div class="modal-header text-center">
          <h5 class="modal-title" id="assignedPersonalModalLabel">Manage Batch</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" (click)="clearDataEval();">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="row" *ngIf="assignedTo.length == 0">
            <div class="col-12">
              <div class="alert alert-secondary mb-0" role="alert">None assigned yet</div>
            </div>
          </div>

          <div class="mt-2" *ngFor="let assignee of assignedTo">
            <div class="card card__two mt-1 border-top-0">
              <div class="card-body card-bg text-left vertical-vertical-middle">
                <div class="form-check form-check-inline col-12 pr-0 mr-0">
                  <div class="col-6 pl-0">
                    <i aria-hidden="true" class="fa fa-user-circle blue assignee-icon"></i>
                    <span class="assignee-name">{{assignee.userName}}</span>
                  </div>
                  <div class="col-6 pl-0 pr-0 text-right">
                    <span class="role-tag" *ngIf="assignee.role.evaluate">Evaluator</span>
                    <span class="role-tag" *ngIf="assignee.role.proctor">Proctor</span>
                    <span class="role-tag" *ngIf="assignee.role.centerManager">CenterManager</span>
                    <button class="btn-assignee-action" (click)="initiateAssigneePrivilegeEdit(assignee);">
                      <i aria-hidden="true" class="fa fa-pencil"></i>
                    </button>
                    <button class="btn-assignee-action" (click)="confirmActionAssigneeRemoval('individual', assignee)">
                      <i aria-hidden="true" class="fa fa-remove"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-left">
          <button type="button" class="btn btn-primary" (click)="manageAssigneePrivileges()">Add Assignee</button>
          <button *ngIf="assignedTo.length > 0" type="button" class="btn btn-outline"
            (click)="confirmActionAssigneeRemoval('all', '')">Remove All Assignee</button>
        </div>
      </div>
    </div>
  </div>
  <!--Evaluator link modal ends-->

  <!-- Manage Assignee Privilege Modal -->
  <div class="modal fade" id="manageAssigneePrivilegeModal" tabindex="-1" role="dialog"
    aria-labelledby="manageAssigneePrivilegeModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-600" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="manageAssigneePrivilegeModalTile">Manage Assignee Role</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <form *ngIf="availableAssignees.length > 0">
            <div class="row">
              <div class="col-4 form-group text-left">Assignee</div>
              <div class="col-8 form-group text-left">
                <select class="form-control" id="assignee" name="assignee" [(ngModel)]="selAssigneeId">
                  <option value="" [hidden]="hideSelectAssigneeOption" disabled>Select Assignee</option>
                  <option *ngFor="let iAssignee of availableAssignees" [value]="iAssignee.assigneeId">
                    {{iAssignee.assigneeName}}</option>
                </select>
              </div>  
              
            </div>
            <div class="row">
              <div class="col-4 form-group text-left">Role</div>
              <div class="col-8 form-group text-left mb-0">
                <p *ngIf="hasDescriptiveQuestions" class="mb-1">
                  <input type="checkbox" id="chkEvaluator" class="mr-1" value="Evaluator"
                    [checked]="assigneeRole.evaluate" (change)="setAssigneeRole($event, 'Evaluator')">Evaluator
                </p>
                <p class="mb-1">
                  <input type="checkbox" id="chkProctor" class="mr-1" value="Proctor" [checked]="assigneeRole.proctor"
                    (change)="setAssigneeRole($event, 'Proctor')">Proctor
                </p>
                <p class="mb-1" *ngIf="showCenterManagerChkBx">
                  <input type="checkbox" id="chkCenterMngr" class="mr-1" value="CenterManager"
                    [checked]="assigneeRole.centerManager" (change)="setAssigneeRole($event, 'CenterManager')">Center
                  Manager
                </p>

              </div>
            </div>
          </form>
          <div class="row" *ngIf="roleManagementError != ''">
            <div class="col">
              <span class="text-danger">{{roleManagementError}}</span>
            </div>
          </div>
          <div class="row" *ngIf="availableAssignees.length == 0">
            <div class="col-12">
              <div class="alert alert-secondary mb-0" role="alert">No more new assignee available for this bach</div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block mx-auto" *ngIf="availableAssignees.length > 0">
          <button type="button" class="btn btn-primary" (click)="assignEvaluator();">Save</button>
          <button type="button" class="btn btn-cancel" (click)="showAssignedPersonalsModal();">Back</button>
        </div>
      </div>
    </div>
  </div>
  <!--Alert popup for assign evaluators-->
  <!-- Confirmation popup for to detach assignee from a batch -->
  <div class="modal fade" id="confirmAssigneeRemoval" tabindex="-1" role="dialog"
    aria-labelledby="confirmAssigneeRemovalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm!</h5>
        </div>

        <div class="modal-body text-center">
          {{unlinkAssigneeConfirmationMessage}}
        </div>

        <div class="modal-footer d-block mx-auto">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="executeUnlinkingAction();">Yes
          </button>
          <button type="button" class="btn btn-cancel" (click)="cancelUnlinking();">No</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Confirmation popup for to detach assignee from a batch end-->

  <!--delete single assessment modal which is open -->
  <div class="modal fade" id="deleteSingleAssessmentData" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Alert!</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          Are you sure you want to delete the assessment?
        </div>
        <div class="modal-footer d-block mx-auto">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteAssessmentDATA(true);">Yes
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
  <!--end of delete single assessment-->

  <!--Start of  candidate location modal-->
  <div class="modal fade" id="candidatesLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Candidate Location</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body pl-3">
          <h5 style="color: #0f74a8" class="pl-2">
            Start Location <span class="ml-2" style="font-size: 15px;"> {{startLocation}} </span>
            <span *ngIf="startTimeMap !== ''" style="float: right;font-size: 15px;" class="start-time"> {{ startTimeMap
              | date:'dd/MM/yyyy h:mm a '}} </span>
          </h5>
          <div class="vr"><img class=" " src="/assets/img/location.png" alt=""></div>
          <div class="row pl-2">
            <div class="col-md-12">
              <div id="map1" [hidden]="trackGeoLocation.startLocation.latitude === ''" style="width:100%;height:150px">
              </div>
              <div *ngIf="trackGeoLocation.startLocation.latitude === ''"
                style="width:100%;height:150px;background-color: #ededee">
                <p style="padding-top: 14%" class="text-center">Could not fetch location details</p>
              </div>
            </div>
          </div>

          <h5 class="mt-4 pl-2" style="color: #0f74a8">
            End Location <span class="ml-2" style="font-size: 15px;">{{endLocation}}</span>
            <span *ngIf="endTimeMap > startTimeMap" style="float: right;font-size: 15px;" class="start-time"> {{
              endTimeMap | date:'dd/MM/yyyy h:mm a '}} </span>
          </h5>
          <div class="row pl-2">
            <div class="col-md-12">
              <div id="map2" [hidden]="trackGeoLocation.endLocation.latitude === ''" style="width:100%;height:150px">
              </div>
              <div *ngIf="trackGeoLocation.endLocation.latitude === ''"
                style="width:100%;height:150px;background-color: #ededee">
                <p style="padding-top: 14%" class="text-center">Could not fetch location details</p>
              </div>
            </div>
          </div>

        </div>

        <div class="modal-footer d-block mx-auto">

        </div>
      </div>
    </div>
  </div>
  <!--End of candidate location modal-->

  <!-- start of upload media preview modal -->
  <div class="modal" id="mediaPreview" tabindex="-1" role="dialog" *ngIf="selectedMediaUrl">
    <div class="modal-dialog" id="media-dialog" role="document">
      <div class="modal-content" id="content">
        <div class="modal-header" id="header">
          <h5 class="modal-title">Media Preview</h5>
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" id="media-body">
          <img *ngIf="selectedMediaType.startsWith('images')" [src]="selectedMediaUrl" alt="Image Preview"
            style="width: 400px; height: 400px;">
          <video *ngIf="selectedMediaType.startsWith('video')" [src]="selectedMediaUrl" controls autoplay
            disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen" class="mb-0"
            style="width: 400px; height: 270px;"></video>
        </div>
      </div>
    </div>
  </div>
  <!-- end of upload media preview modal -->