import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssessmentService } from '../assessment.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { SharedserviceService } from '../../sharedservice.service';
declare let myExtObject: any;
declare let $: any;

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css'],
  providers: [AssessmentService]
})

export class PublishComponent implements OnInit, OnDestroy {
  accessToken: any;
  companyId: any;
  companyType: any;
  modalLink: FormGroup;
  publicPrivatedChecked = 'public';
  linkTypeSelect = 'public';
  errorMsg: any;
  inviteCheck: Boolean;
  byLink: Boolean;
  LockStatus: string = "LOCKED";
  CenterManagerInstruction: any;
  candidatesDetail = {
    'candidatesName': '',
    'email': '',
    'phoneNo': '',
    'countryCode': '',
  };
  candidatesDetails = [this.candidatesDetail];
  candidatesData = {
    'candidatesName': '',
    'email': '',
    'phoneNo': '',
    'countryCode': '+91',
    'candidateIdentifier': ''
  };
  tagsForLink: any = [];
  linkStatus = ['Active', 'Inactive'];
  publicStartDate = '';
  publicInviteName = '';
  publicStartTime = '';
  publicEndDate = '';
  publicEndTime = '';
  evaluationStartDate: any;
  evaluationStartTime = '';
  evaluationEndDate: any;
  evaluationEndTime = '';
  evaluationStartsAt = '';
  evaluationEndsAt = '';
  checkForPsychometer = '';
  publicLinkcreateion = '';
  isRequestInProcess: Boolean = false;
  candidateMandidate: Boolean = false;
  imageUploadForLink: Boolean = false;
  isValidPhone: Boolean = true;
  modalValue: string;
  startDateTime = '';
  private = 'private';
  inviteByFileUpload: any = [];
  createdassessmentType = '';
  assessmentTypeFromDetails = '';
  hasDescriptiveQuestions: Boolean = false;
  Testcode = '';
  privateInviteStatus = 'Active';
  publicInviteStatus = 'Active';
  createdcreatedOn = '';
  createdassessmentId = '';
  publicTags = '';
  @Input('inputToPublish') inputToPublish: any;
  publicAuthanticationOTP: Boolean = false;
  publicerrorMsg: any;
  selectScreenFreezeForLink: Boolean = false;
  candidateAttendanceLink: Boolean = false;
  candidateSnapshotForLink: Boolean = false;
  displayScore: Boolean;
  EnableLockUnlock: Boolean;
  candidatDetailsChecked: any = [];
  IDProofForLink: Boolean = false;
  isSendInvitationInProcess: Boolean = false;
  candidateDetails: any = [];
  TrackLocationForLink: Boolean = false;
  candidateProctor: Boolean = false;
  publicLinkCreation: Boolean = true;
  privateLinkCreationStep2: Boolean;
  privateLinkCreationStep1: Boolean;
  editCandidates: Boolean = true;
  assessmentListOfCandidates: Boolean;
  listOfLinkForAssessment: Boolean;
  skip: any = 0;
  skipforLinkList = 0;
  listofLinkDetails: any = [];
  dateandTimeErrorcheck: Boolean;
  listMessage: Boolean;
  listErrMessage: Boolean;
  assessmentType = 'Technical';
  editAssesmentById: any;
  assessmentName = '';
  assessmentId: any;
  checkForEdit: boolean;
  linkPublic: Boolean = true;
  subCatDetails = [{ 'categories': [] }];
  candidateData = [this.candidatesData];
  publicSTARTDate: any;
  publicENDDate: any;
  linkPrivate: Boolean = false;
  countryCode: any;
  errorMessage: Boolean = false;
  candidatesADD: boolean;
  editedLinkType = '';
  editedLinkID = '';
  editedErrMsg = '';
  publicBlockIpAddress = '';
  addprivate: Boolean = false;
  isUpload: boolean;
  file: any = [];
  candidatesDetailList: any = [];
  addLinkID: any;
  inviteCandidates: any = [];
  privateSubject = '';
  candidatesDetailcandidatesADDs: any = [];
  @ViewChild('myInput') myInputVariable: ElementRef;
  fileUploadMsg: boolean;
  uploaderrorMsg = '';
  date = new Date();
  currentYear = this.date.getFullYear();
  currentMonth = this.date.getMonth() + 1;
  currentDate = this.date.getDate();
  public myDatePickerOptions: IMyDpOptions = {
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentDate - 1 },
    editableDateField: false,
    dateFormat: 'yyyy-mm-dd',
  };
  isOnlySpace: Boolean = false;
  isRemarkSpace: Boolean = false;
  onlineCompilerSelected: boolean = false;
  inviteName: FormControl;
  config: any;
  hasCodingQuestions: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assessmentService: AssessmentService,
    private toastrService: ToastrService,
    private sharedService: SharedserviceService,
    private _location: Location
  ) {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    this.companyType = localStorage.getItem('companyType');
    this.evaluationStartDate = '';
    this.evaluationEndDate = '';

    this.getTagsForLink();
    this.getRouteParams();

    if (localStorage.getItem('_isEditLink') === 'true') {
      this.editCandidates = true;
    } else {
      this.editCandidates = false;
      this.testCodeGeneration();
    }
  }

  ngOnInit() {
    this.config = this.sharedService.config;
   
    this.createdassessmentType = localStorage.getItem('_createdassessmentType');

    this.createdassessmentId = localStorage.getItem('_createdassessmentId');
    this.createdcreatedOn = localStorage.getItem('_createdcreatedOn');
    localStorage.setItem('fromInviteComp', 'FromInviteComponent');
    this.hasDescriptiveQuestions = localStorage.getItem('hasDescriptiveQuestions') == 'yes' ? true : false;
    this.assessmentTypeFromDetails = localStorage.getItem('assessmentTypeFromDetails');
    
    this.hasCodingQuestions = localStorage.getItem('hasCodingQuestions') == 'true' ? true : false;
    if (this.createdassessmentType == 'Online Compiler' || this.assessmentTypeFromDetails == 'Online Compiler') {
      this.onlineCompilerSelected = true;
      this.linkPrivate = true;
      this.linkPublic = false;
      this.isValidPhone = false;
      this.publicPrivatedChecked = 'private';
    } else if (this.hasCodingQuestions) {
      this.linkPrivate = true;
      this.linkPublic = false;
      this.isValidPhone = false;
      this.publicPrivatedChecked = 'private';

    }
    else {
      this.onlineCompilerSelected = false;

    }

    // setTimeout(() => {
    //   if (localStorage.getItem('_createdassessmentId') !== null ) {
    //     this.createdassessmentId = localStorage.getItem('_createdassessmentId');
    //     this.createdassessmentType = localStorage.getItem('_createdassessmentType');
    //     this.createdcreatedOn = localStorage.getItem('_createdcreatedOn');
    //   }
    // }, 2000 );

    if (localStorage.getItem('isAddCandidate') === 'AddNewCandidate') {
      setTimeout(function () { myExtObject.loadFlagId(0); });
      this.privateLinkCreationStep1 = false;
      this.inviteCheck = true;
      this.privateLinkCreationStep2 = true;
      this.isValidPhone = false;
      if (localStorage.getItem('isAddLinkId') !== null) {
        this.addprivate = true;
        this.addLinkID = localStorage.getItem('isAddLinkId');
      }
    }

    this.modalLink = new FormGroup({
      link: new FormControl('public', Validators.required),
      // inviteName : new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(265), Validators.pattern('^[ A-Za-z0-9_@.+-]*$')]),
    });

    this.inviteName = new FormControl('', [Validators.required, Validators.pattern('^[ A-Za-z0-9_@.+-]*$'), Validators.minLength(3),
    Validators.maxLength(100)]);
  }


  onsubmit() {

  }

  ngOnDestroy() {

    localStorage.removeItem('isAddLinkId');
    localStorage.removeItem('isAddCandidate');
    localStorage.removeItem('_createdassessmentId');
    localStorage.removeItem('_createdassessmentType');
    localStorage.removeItem('_createdcreatedOn');
    localStorage.removeItem('_isEditLink');
    localStorage.removeItem('hasDescriptiveQuestions');
    // localStorage.removeItem('assessmentTypeFromDetails');
  }

  getRouteParams() {

    this.route.params.subscribe(
      params => {
        if (params['id']) {

          this.getAssessmentDetails(params['id']);

          this.privateInviteStatus = 'Active';
          this.publicInviteStatus = 'Active';
          this.editCandidates = false;
          this.publicLinkCreation = true;
          this.publicLinkCreate();
        } else if (params['editId']) {

          this.editLinkDetails(params['editId']);
        }
      },
      error => console.log(error)
    );
  }

  resetFile() {
    this.myInputVariable.nativeElement.value = '';
  }

  LinkCreation(modalLink, pos) {
    this.linkTypeSelect = modalLink;
    this.errorMsg = '';
    if (!this.inviteCheck) {
      this.byLink = false;
    }
    if (modalLink === 'private') {
      setTimeout(function () { myExtObject.loadFlagId(0); });
      this.linkPrivate = true;
      this.linkPublic = false;
      this.isValidPhone = false;
      this.publicPrivatedChecked = 'private';
    } else {
      this.publicPrivatedChecked = 'public';
      this.linkPublic = true;
      this.linkPrivate = false;
      this.isValidPhone = true;
      if (!this.editCandidates) {
        this.testCodeGeneration();
      }
    }
  }

  getTagsForLink() {
    const payload = {
      'type': 'tagsForLink',
      'assessmentType': ''
    };
    this.assessmentService.getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.tagsForLink = response;
        },
        error => {
        });
  }

  onDateChanged(event, key) {

    this.errorMsg = '';
    if (key === 'start') {
      this.publicStartDate = event.formatted;
      this.publicStartTime = '';
    } else if (key === 'end') {
      this.publicEndDate = event.formatted;
      this.publicEndTime = '';
    } else if (key === 'evaluationStart') {
      this.evaluationStartDate = event.formatted;
      this.evaluationStartTime = '';
      $('#evaluationStartTime').val('');
    } else if (key === 'evaluationEnd') {
      this.evaluationEndDate = event.formatted;
      this.evaluationEndTime = '';
      $('#evaluationEndTime').val('');
    }
  }

  onClock(id) {
    myExtObject.loadClock(id);
  }

  fileChangeEvent(fileInput) {
    this.file = [];
    this.uploaderrorMsg = '';
    this.file = fileInput.target.files;
    this.fileUploadMsg = false;
    if (this.file.length === 0) {
      this.inviteByFileUpload = [];
      this.file = [];
      this.isUpload = false;
      return;
    }
    const filenameArray = this.file[0].name.split('.');
    const fileType = filenameArray[filenameArray.length - 1];
    if (fileType === 'xls' || fileType === 'xlsx') {
      this.isUpload = true;
    } else {
      this.isUpload = false;
      this.fileUploadMsg = true;
      this.uploaderrorMsg = 'Upload xlsx file only.';
      this.resetFile();
      return;
    }
    this.assessmentService.uploadInvites(this.companyId, this.accessToken, this.file)
      .subscribe(
        response => {
          this.inviteByFileUpload = response.message;
          this.isValidPhone = true;
        },
        error => {
          this.inviteByFileUpload = [];
          this.uploaderrorMsg = 'Invalid upload format';
          this.isUpload = false;
          this.fileUploadMsg = true;
          this.resetFile();
        });
  }

  publishAssessmentLink(modalLink: any, candidateChecked) {
    if (this.isRequestInProcess) {
      return;
    }
    
    this.evaluationStartsAt = '';
    this.evaluationEndsAt = '';
    if (this.onlineCompilerSelected || this.hasCodingQuestions) {
      if (candidateChecked) {
        this.modalValue = 'private';
        $('#publishPopup').modal('show');
      } else {
        this.publicStartTime = $('#startTime').val();
        if (this.publicStartDate === '') {
          this.startDateTime = '';
        } else {
          if (this.publicStartTime === '') {
            // User will not be allowed to submit if start date is provided but have not provided start time
            this.errorMsg = 'Provide start time';
            return;
          } else {
            this.errorMsg = '';
            this.startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
          }
        }
        if (this.publicEndDate !== '' && $('#endTime').val() === '') {
          // User will not be allowed to submit if end date is provided but have not provided end time
          this.errorMsg = 'Provide end time';
          return;
        } else {
          this.errorMsg = '';
        }
        if (this.hasDescriptiveQuestions) {
          if (!this.validateAndSetEvaluationDateRange()) {
            return;
          }
        }
        this.isRequestInProcess = true;
        
        if (this.onlineCompilerSelected == true || this.hasCodingQuestions && 'private' === this.private) {
          
          this.sendInvitation();
        } else {
          
          this.publicAssessmentLink();
        }
      }
    }

    if (!this.onlineCompilerSelected) {
      if (candidateChecked && modalLink.link === 'private') {
        this.modalValue = modalLink.link;
        $('#publishPopup').modal('show');
      } else {
        this.publicStartTime = $('#startTime').val();
        if (this.publicStartDate === '') {
          this.startDateTime = '';
        } else {
          if (this.publicStartTime === '') {
            // User will not be allowed to submit if start date is provided but have not provided start time
            this.errorMsg = 'Provide start time';
            return;
          } else {
            this.errorMsg = '';
            this.startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
          }
        }
        if (this.publicEndDate !== '' && $('#endTime').val() === '') {
          // User will not be allowed to submit if end date is provided but have not provided end time
          this.errorMsg = 'Provide end time';
          return;
        } else {
          this.errorMsg = '';
        }
        if (this.hasDescriptiveQuestions) {
          if (!this.validateAndSetEvaluationDateRange()) {
            return;
          }
        }
        this.isRequestInProcess = true;
        if (modalLink.link === this.private) {
          this.sendInvitation();
        } else if (this.onlineCompilerSelected == true) {
          this.sendInvitation();
        } else {
          this.publicAssessmentLink();
        }
      }
    }
  }

  sendInvitation() {
    if (this.isSendInvitationInProcess) {
      return;
    }
    
    this.isSendInvitationInProcess = true;
    this.candidateDetails = [];
    this.candidatDetailsChecked = [];
    let name, email, mobile, countryCode, endDateTime;
    this.publicEndTime = $('#endTime').val();
    this.byLink = true;
    this.errorMsg = '';
    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
    }
    if (this.inviteByFileUpload.length !== 0) {
      for (let i = 0; i < this.inviteByFileUpload.length; i++) {
        const obj1 = {
          'name': this.inviteByFileUpload[i].name,
          'email': this.inviteByFileUpload[i].email,
          'mobile': this.inviteByFileUpload[i].mobile,
          'countryCode': this.inviteByFileUpload[i].countryCode,
          'candidateIdentifier': ''
        };
        this.candidateDetails.push(obj1);
      }
    }
    if (this.candidatesDetails.length > 0) {
      for (let i = 0; i < this.candidatesDetails.length; i++) {
        name = $('#canname-' + i).val();
        email = $('#email-' + i).val();
        mobile = $('#phone-' + i).val();
        countryCode = '+91';
        const obj = {
          'name': $('#canname-' + i).val(),
          'email': $('#email-' + i).val(),
          'mobile': $('#phone-' + i).val(),
          'countryCode': '+91',
          'candidateIdentifier': $('#cand-id-' + i).val(),
        };

        if (obj.name !== '' || obj.email !== '' || obj.mobile !== '') {
          this.candidateDetails.push(obj);
        }

        // Check for duplicate entry
        for (let i = 0; i < this.candidateDetails.length; i++) {
          for (let j = i + 1; j < this.candidateDetails.length; j++) {
            if (this.candidateDetails[i].mobile === this.candidateDetails[j].mobile) {
              this.errorMsg = 'You filled duplicate candidate entry';
              this.isRequestInProcess = false;
              this.isSendInvitationInProcess = false;
              return;
            } else {
              this.errorMsg = '';
            }
          }
        }

        if (this.candidatDetailsChecked.indexOf(mobile) < 0) {
          this.candidatDetailsChecked.push(mobile);
          $('#phone-' + i).css('border-color', '');
          $('#duplicate-' + i).html('');
          this.errorMsg = '';

        } else {
          $('#phone-' + i).css('border-color', 'red');
          $('#duplicate-' + i).html('mobile number is duplicate');
          this.errorMsg = 'You filled duplicate candidate entry';
          this.isRequestInProcess = false;
          this.isSendInvitationInProcess = false;
          return;
        }

      }
    }
    if (this.candidateDetails.length === 0) {
      this.errorMsg = 'Add the candidates';
      this.isRequestInProcess = false;
      this.isSendInvitationInProcess = false;
      return;
    }
    if (!this.addprivate) {
      const payload = {
        'linkFor': 'assessment',
        'referenceId': this.createdassessmentId,
        'linkName': this.publicInviteName,
        'linkType': 'private',
        'CenterManagerInstruction': this.CenterManagerInstruction,
        'LockStatus': this.LockStatus,
        'status': this.publicInviteStatus,
        'tags': this.publicTags,
        'remarks': this.privateSubject,
        'assessments': [
          {
            'assessmentType': this.createdassessmentType,
            'createdOn': this.createdcreatedOn,
            'status': 'active',
            'assessmentId': this.createdassessmentId
          }],
        'candidates': this.candidateDetails,
        'configuration': {
          'startDate': this.startDateTime,
          'endDate': endDateTime,
          'evaluationStartDate': this.evaluationStartsAt,
          'evaluationEndDate': this.evaluationEndsAt,
          'enableLockUnlock': this.EnableLockUnlock,
          'timeZone': 'IST',
          'loginTypeOtp': (this.publicAuthanticationOTP === true ? true : false),
          'captureAttendanceSnapshot': this.candidateAttendanceLink,
          'captureCandidateSnapshot': this.candidateSnapshotForLink,
          'isDisplayScore': this.displayScore,
          'idVerification': this.IDProofForLink,
          'trackGeoLocation': this.TrackLocationForLink,
          'proctor': this.candidateProctor,
          'uploadImage': this.imageUploadForLink,
          'screenFreeze': this.selectScreenFreezeForLink
        }
      };
      this.assessmentService.privateLinkInvitation(payload, this.companyId, this.accessToken)
        .subscribe(
          response => {
            this.isRequestInProcess = false;
            this.isSendInvitationInProcess = false;
            this.privateLinkCreationStep2 = false;
            this.candidatesDetails = [this.candidatesDetail];
            this.candidateDetails = [];
            this.inviteByFileUpload = [];
            this.file = [];
            this.candidateData = [this.candidatesData];
            this.isUpload = false;
            this.router.navigate(['assessment/details/' + this.createdassessmentId]);
            this.toastrService.success('Test link created successfully.', '', {
              timeOut: 5000,
            });
            this.candidatesDetailList = response.linkDetails.candidates;

          },
          error => {
            this.isRequestInProcess = false;
            this.isSendInvitationInProcess = false;
            this.candidateDetails = [];
            this.errorMsg = error.error.message;
            this.dateandTimeErrorcheck = false;
          });
    } else {
      const Payload = {
        'linkId': this.addLinkID,
        'candidates': this.candidateDetails
      };

      this.assessmentService.privateAddEditCandidates(Payload, this.companyId, this.accessToken)
        .subscribe(
          response => {
            this.isRequestInProcess = false;
            this.isSendInvitationInProcess = false;
            this.privateLinkCreationStep2 = false;
            this.candidateData = [this.candidatesData];
            this.candidatesDetailcandidatesADDs = [];
            this.candidatesDetails.push(this.candidatesDetail);
            this.inviteByFileUpload = [];
            this.backClicked();
            this.candidatesDetailList = response.linkInfo.candidates;
            this.toastrService.success('Candidates added successfully', '', {
              timeOut: 5000,
            });
          },
          error => {
            this.isRequestInProcess = false;
            this.isSendInvitationInProcess = false;
            this.candidateDetails = [];
            this.errorMsg = error.error.message;
            this.dateandTimeErrorcheck = false;
          });
    }

  }

  publicAssessmentLink() {
    this.publicerrorMsg = '';
    let endDateTime;
    this.errorMsg = '';
    this.publicEndTime = $('#endTime').val();
    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
    }

    const payload = {
      'linkFor': 'assessment',
      'referenceId': this.createdassessmentId,
      'linkName': this.publicInviteName,
      'linkType': 'public',
      'tags': this.publicTags,

      'CenterManagerInstruction': this.CenterManagerInstruction,
      'status': this.publicInviteStatus,
      'LockStatus': this.LockStatus,
      'testCode': this.Testcode + '',
      'assessments': [
        {
          'assessmentType': this.createdassessmentType,
          'createdOn': this.createdcreatedOn,
          'status': 'active',
          'assessmentId': this.createdassessmentId
        }],
      'configuration': {
        'startDate': this.startDateTime,
        'endDate': endDateTime,
        'evaluationStartDate': this.evaluationStartsAt,
        'evaluationEndDate': this.evaluationEndsAt,
        'timeZone': 'IST',
        'loginTypeOtp': (this.publicAuthanticationOTP === true ? true : false),
        'captureAttendanceSnapshot': this.candidateAttendanceLink,
        'captureCandidateSnapshot': this.candidateSnapshotForLink,
        'EnableLockUnlock': this.EnableLockUnlock,
        'isDisplayScore': this.displayScore,
        'idVerification': this.IDProofForLink,
        'trackGeoLocation': this.TrackLocationForLink,
        'proctor': this.candidateProctor,
        'screenFreeze': this.selectScreenFreezeForLink
      }
    };

    this.assessmentService.privateLinkInvitation(payload, this.companyId, this.accessToken).subscribe(
      response => {
        this.isRequestInProcess = false;
        this.publicLinkcreateion = response.link;
        this.Testcode = response.testCode;
        localStorage.setItem('createdLinkId', response.linkId);
        this.router.navigate(['assessment/details/' + this.createdassessmentId]);
        this.toastrService.success('Test link created successfully.', '', {
          timeOut: 5000,
        });
      },
      error => {
        this.isRequestInProcess = false;
        this.errorMsg = error.error.message;
        this.dateandTimeErrorcheck = false;
      });
  }

  privateCandidateinvitationList(check) {
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    const jobId = localStorage.getItem('jobId');
    // const assesmentforJob = localStorage.getItem('createAssessmentForJob');
    if (check === true && this.inviteCheck === false) {
      this.toastrService.success('Assessment created successfully', '', {
        timeOut: 5000,
      });
    }
    if (jobId === null || jobId === undefined) {
      if (this.inviteCheck === true || this.editCandidates) {
        this.publicLinkCreation = false;
        this.privateLinkCreationStep2 = false;
        this.listofInvitationLink(true);
        this.inviteCheck = false;
      } else {
        this.backClicked();
      }
    } else {
      localStorage.removeItem('jobId');
      localStorage.removeItem('createAssessmentForJob');
      this.privateLinkCreationStep2 = false;
      this.publicLinkCreation = false;
      this.router.navigate(['jobs']);
    }
  }

  listofInvitationLink(check) {
    if (this.isRequestInProcess) {
      return;
    }
    if (check === false) {
      this.skipforLinkList++;
    } else {
      this.skipforLinkList = 0;
      this.listofLinkDetails = [];
    }
    window.scroll(0, 0);
    if (document.getElementById('invites') != null) {
      document.getElementById('invites').style.color = '#0ec2d0';
      document.getElementById('Details').style.color = '';
      document.getElementById('Question').style.color = '';
      document.getElementById('config').style.color = '';
      document.getElementById('Details').style.borderBottom = '';
      document.getElementById('invites').style.borderBottom = '2px solid';
      document.getElementById('Question').style.borderBottom = '';
      document.getElementById('config').style.borderBottom = '';
    }

    this.assessmentListOfCandidates = true;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    const payload = { 'referenceId': this.createdassessmentId };
    this.isRequestInProcess = true;
    this.assessmentService.listofInvitationLink(payload, this.companyId, this.accessToken, this.skipforLinkList)
      .subscribe(
        response => {
          this.isRequestInProcess = false;
          this.listOfLinkForAssessment = response.isMoreList;
          let listofLink = [];
          listofLink = response.message;
          this.listofLinkDetails = this.listofLinkDetails.concat(listofLink);
          this.publicLinkcreateion = response.linkUrl;
          this.listMessage = true;
          // this.skipforLinkList++;
          this.listErrMessage = false;
          this.selectInviteSection();
        },
        error => {
          this.isRequestInProcess = false;
          this.errorMsg = error.error.message;
          this.listMessage = false;
          this.listErrMessage = true;
        });
  }

  selectInviteSection() {
    if (document.getElementById('invites') != null) {
      document.getElementById('invites').style.color = '#0ec2d0';
      document.getElementById('Details').style.color = '';
      document.getElementById('Questio').style.color = '';
      document.getElementById('config').style.color = '';
      document.getElementById('Details').style.borderBottom = '';
      document.getElementById('invites').style.borderBottom = '2px solid';
      document.getElementById('Questio').style.borderBottom = '';
      document.getElementById('config').style.borderBottom = '';
    }
  }

  async getAssessmentDetails(id: any) {
    const payload = {
      'assessmentId': id
    };

    this.assessmentService.editAssessment(payload, this.companyId, this.accessToken).
      subscribe(
        response => {

          this.editAssesmentById = response.message;
          this.createdassessmentId = response.message[0]._id;
          this.assessmentName = response.message[0].assessmentName;
          this.createdassessmentType = response.message[0].assessmentType;
          this.createdcreatedOn = response.message[0].createdOn;
        }, err => {
          console.log(err);
        });
  }

  testCodeGeneration() {
    this.assessmentService.testCodeGenerate(this.companyId, this.accessToken).subscribe(
      response => {
        this.Testcode = response.message;
      },
      error => {
        this.errorMsg = error.error.message;
      });
  }

  publicLinkCreate() {
    this.candidatesDetails = [this.candidatesDetail];
    this.candidateData = [];
    const candObj = {
      'candidatesName': '',
      'email': '',
      'phoneNo': '',
      'countryCode': '+91',
      'candidateIdentifier': ''
    };
    this.candidateData.push(candObj);
    this.isRequestInProcess = false;
    this.candidateMandidate = false;
    this.imageUploadForLink = false;
    this.selectScreenFreezeForLink = false;
    this.publicPrivatedChecked = 'public';
    this.linkPublic = true;
    this.candidateProctor = false;
    this.publicAuthanticationOTP = false;
    this.TrackLocationForLink = false;
    this.IDProofForLink = false;
    this.candidateAttendanceLink = false;
    this.candidateSnapshotForLink = false;
    this.errorMsg = '';
    this.publicSTARTDate = { date: '' };
    this.publicENDDate = { date: '' };
    if (this.byLink === false) {
      const p = 3 * (100 / 3);
      $('#prog').width(p + '%');
    }
    window.scroll(0, 0);
    if (!this.editCandidates) {
      this.testCodeGeneration();
    }
    this.listErrMessage = false;
    this.publicLinkCreation = true;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    $('#main_Div').css('padding-top', '');

  }


  copyLink() {
    const copyText = document.getElementById('linkGener') as HTMLInputElement;
    copyText.select();
    document.execCommand('copy');
  }

  checkForUploadCandidate() {
    if (this.inviteByFileUpload.length > 0) {
      this.isValidPhone = true;
      return;
    }
  }

  addCandidateDetails(index, k) {
    this.candidatDetailsChecked = [];
    let name, email, phone, countryCode;
    let err = false;
    this.errorMessage = false;
    this.isValidPhone = false;
    const len = this.candidatesDetails.length;

    // keeping var i to load the flag in add candidate please do not change
    for (var i = 0; i < len; i++) {
      setTimeout(function () { myExtObject.loadFlagId(i); });
      countryCode = myExtObject.getCountryCode();
      const counttryDeatilsArray = countryCode.split(':');
      if (counttryDeatilsArray != null && counttryDeatilsArray.length > 0) {
        countryCode = counttryDeatilsArray[1];
        countryCode = countryCode.trim();
      }
      this.countryCode = countryCode;
      name = $('#canname-' + i).val();
      email = $('#email-' + i).val();
      phone = $('#phone-' + i).val();
      countryCode = countryCode;

      if (this.inviteByFileUpload.length > 0 && (name === '' || email === '' || phone === '' || countryCode === '')) {
        this.isValidPhone = true;
        return;
      }

      if (email !== '' && email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null) {
        this.errorMsg = 'Enter valid Email ID.';
        this.isValidPhone = false;
        return;
      } else {
        this.errorMsg = '';
        this.checkForUploadCandidate();
      }

      if (name === '' || email === '' || phone === '' || countryCode === '') {
        err = true;
        this.errorMessage = true;
        return;
      } else if (phone.length <= 6) {
        this.errorMsg = 'Enter valid Mobile Number.';
        return;
      } else {
        this.errorMessage = false;
      }

      if (this.candidatDetailsChecked.indexOf(phone) < 0) {
        this.candidatDetailsChecked.push(phone);
        $('#phone-' + i).css('border-color', '');
        $('#duplicate-' + i).html('');
        this.errorMsg = '';

      } else {
        $('#phone-' + i).css('border-color', 'red');
        $('#duplicate-' + i).html('mobile number is duplicate');
        return;
      }

    }

    if (!err) {
      const objec = {
        'candidatesName': '',
        'email': '',
        'phoneNo': '',
        'countryCode': '+91',
        'candidateIdentifier': ''
      };
      this.candidateData.push(objec);
      this.candidatesADD = true;
      this.candidatesDetail.candidatesName = '';
      this.candidatesDetail.email = '';
      this.candidatesDetail.phoneNo = '';
      this.candidatesDetail.countryCode = '';
      this.candidatesDetails.push(this.candidatesDetail);
    }
  }

  editLinkDetails(linkId: any) {

    const payload = { 'linkId': linkId };
    this.assessmentService.candidatesLinkIDDetails(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {

          this.editLink(response.message);
        },
        error => {
          this.errorMsg = error.error.message;
        });
  }

  editLink(data) {
    this.publicerrorMsg = '';
    this.candidateMandidate = false;
    this.imageUploadForLink = false;
    this.selectScreenFreezeForLink = false;
    this.editCandidates = true;
    this.editedLinkType = data.linkType;
    this.editedLinkID = data._id;
    let startDate, endDate, startMonth, endMonth, startDay, endDay;
    this.publicerrorMsg = '';
    this.publicStartTime = '';
    this.publicSTARTDate = '';
    this.publicENDDate = '';
    this.publicEndTime = '';
    this.editedErrMsg = '';

    const startDateTime = data.configuration.startDate;
    const endDateTime = data.configuration.endDate;
    if (startDateTime !== '') {
      startDate = startDateTime.split('T');
      this.publicStartDate = startDate[0];
      // changed to display 00:00 as time startDate[1] !== '00:00:00' &&
      if (startDate[1] !== '') {
        this.publicStartTime = startDate[1].substring(0, startDate[1].length - 3);
      }
    } else {
      this.publicStartDate = '';
      this.candidateMandidate = true;
    }

    if (endDateTime !== '') {
      endDate = endDateTime.split('T');
      this.publicEndDate = endDate[0];
      // changed to display endDate[1] !== '00:00:00' &&
      if (endDate[1] !== '') {
        this.publicEndTime = endDate[1].substring(0, endDate[1].length - 3);
      }
    } else {
      this.publicEndDate = '';
    }
    const evalStartDateTime = data.configuration.evaluationStartDate;
    const evalEndDateTime = data.configuration.evaluationEndDate;
    if (evalStartDateTime !== '') {
      let evalStartTimingParts = evalStartDateTime.split('T');
      this.evaluationStartDate = this.setDatePickerValue(evalStartTimingParts[0]);
      // changed to display 00:00 as time startDate[1] !== '00:00:00' &&
      if (evalStartTimingParts[1] !== '') {
        this.evaluationStartTime = evalStartTimingParts[1].substring(0, evalStartTimingParts[1].length - 3);
      }
    } else {
      this.evaluationStartDate = '';
    }
    if (evalEndDateTime !== '') {
      let evalEndTimingParts = evalEndDateTime.split('T');

      this.evaluationEndDate = this.setDatePickerValue(evalEndTimingParts[0]);
      // changed to display 00:00 as time startDate[1] !== '00:00:00' &&
      if (evalEndTimingParts[1] !== '') {
        this.evaluationEndTime = evalEndTimingParts[1].substring(0, evalEndTimingParts[1].length - 3);
      }
    } else {
      this.evaluationEndDate = '';
    }
    if (data.linkType === 'PUBLIC') {
      this.publicLinkCreate();

    } else {
      this.privateLinkCreate();
      // this.showFileUpload = true;
      this.linkPublic = false;

    }

    if (startDateTime !== '') {
      if (startDate[0].substring(5, 7).charAt(0) !== 0) {
        startMonth = startDate[0].substring(5, 7);
      } else {
        startMonth = startDate[0].substring(6, 7);
      }
      if (startDate[0].substring(8, 10).charAt(0) !== 0) {
        startDay = startDate[0].substring(8, 10);
      } else {
        startDay = startDate[0].substring(9, 10);
      }
      this.publicSTARTDate = {
        date: {
          year: startDate[0].substring(0, 4),
          month: parseInt(startMonth, 10),
          day: parseInt(startDay, 10)
        }
      };
    }
    if (endDateTime !== '') {
      if (endDate[0].substring(5, 7).charAt(0) !== 0) {
        endMonth = endDate[0].substring(5, 7);
      } else {
        endMonth = endDate[0].substring(6, 7);
      }
      if (endDate[0].substring(8, 10).charAt(0) !== 0) {
        endDay = endDate[0].substring(8, 10);
      } else {
        endDay = endDate[0].substring(9, 10);
      }

      this.publicENDDate = {
        date: {
          year: endDate[0].substring(0, 4),
          month: parseInt(endMonth, 10),
          day: parseInt(endDay, 10)
        }
      };
    }
    this.createdassessmentId = data._id;
    this.byLink = true;
    this.editCandidates = true;
    this.editedLinkType = data.linkType;
    // to show and hide creen freezer and candidate mandidate
    this.linkTypeSelect = data.linkType.toLowerCase();
    // end
    this.editedLinkID = data._id;
    this.publicInviteName = data.linkName;
    this.publicBlockIpAddress = '';
    this.publicAuthanticationOTP = data.configuration.loginTypeOtp;
    this.candidateAttendanceLink = data.configuration.captureAttendanceSnapshot;
    this.candidateSnapshotForLink = data.configuration.captureCandidateSnapshot;
    this.displayScore = data.configuration.isDisplayScore;
    this.EnableLockUnlock = data.configuration.enableLockUnlock;
    this.IDProofForLink = data.configuration.idVerification;
    this.TrackLocationForLink = data.configuration.trackGeoLocation;
    this.candidateProctor = data.configuration.proctor;
    if (data.status === 'active') {
      this.publicInviteStatus = 'Active';
    } else {
      this.publicInviteStatus = 'Inactive';
    }
    this.publicTags = data.tags;
    this.Testcode = data.testCode;
    if (data.configuration.screenFreeze === true) {
      this.selectScreenFreezeForLink = true;
    }
    if (data.configuration.uploadImage === true) {
      this.imageUploadForLink = true;
    }
    if (this.candidateProctor) {
      this.candidateMandidate = true;
    }
  }

  editedLink() {
    this.publicerrorMsg = '';
    this.evaluationStartsAt = '';
    this.evaluationEndsAt = '';
    let endDateTime, startDateTime;
    this.publicStartTime = $('#startTime').val();
    this.publicEndTime = $('#endTime').val();
    if (this.publicStartDate === '') {
      startDateTime = '';
    } else {
      startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
    }
    if (this.publicStartTime === '' && this.publicStartDate !== '') {

      startDateTime = this.publicStartDate + 'T' + '00:00:00';
      this.errorMsg = 'Provide start time ';
      return;
    }

    if (this.publicEndDate === '') {
      endDateTime = '';
    } else {
      endDateTime = this.publicEndDate + 'T' + this.publicEndTime + ':00';
    }
    if (this.publicEndTime === '' && this.publicEndDate !== '') {
      endDateTime = this.publicEndDate + 'T' + '00:00:00';
      this.errorMsg = 'Provide end time ';
      return;
    }
    if (this.hasDescriptiveQuestions) {
      if (!this.validateAndSetEvaluationDateRange()) {
        return;
      }
    }
    if (!this.candidateProctor) {
      this.imageUploadForLink = false;
      this.selectScreenFreezeForLink = false;
    }
    const payload = {
      'linkId': this.editedLinkID,
      'linkName': this.publicInviteName,
      'isEditLink': true,
      'status': this.publicInviteStatus,
      'tags': this.publicTags,
      'remarks': this.privateSubject,
      'centerManagerInstruction': this.CenterManagerInstruction,
      'configuration': {
        'startDate': startDateTime,
        'endDate': endDateTime,
        'enableLockUnlock': this.EnableLockUnlock,
        'evaluationStartDate': this.evaluationStartsAt,
        'evaluationEndDate': this.evaluationEndsAt,
        'timeZone': 'IST',
        'loginTypeOtp': this.publicAuthanticationOTP,
        'idVerification': this.IDProofForLink,
        'captureAttendanceSnapshot': this.candidateAttendanceLink,
        'captureCandidateSnapshot': this.candidateSnapshotForLink,
        'isDisplayScore': this.displayScore,
        'trackGeoLocation': this.TrackLocationForLink,
        'proctor': this.candidateProctor,
        'uploadImage': this.imageUploadForLink,
        'screenFreeze': this.selectScreenFreezeForLink
      }
    };
    this.assessmentService.privateAddEditCandidates(payload, this.companyId, this.accessToken).subscribe(
      response => {

        this.listofInvitationLink(true);
        this.toastrService.success('Test link edited successfully.', '', {
          timeOut: 5000,
        });
        this.backClicked();
      },
      error => {

        this.errorMsg = '';
        this.publicerrorMsg = error.error.message;
      });

  }

  checkTimeValue(event) {
    if (event.target.value) {
      this.errorMsg = '';
      this.publicerrorMsg = '';
    }
  }

  privateLinkCreate() {
    this.listErrMessage = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = true;
    this.privateLinkCreationStep2 = false;
  }

  removeAllCandidates() {
    this.byLink = true;
    this.addprivate = true;
    this.inviteCheck = true;
    this.candidateDetails = [];
    this.inviteCandidates = [];
    this.candidatesDetails = [this.candidatesDetail];
    const Payload = {
      'linkId': this.addLinkID,
      'deleteCandidate': false,
      'candidates': this.candidateDetails,
    };
    this.assessmentService.privateAddEditCandidates(Payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.listofInvitationLink(true);
        },
        error => {
          this.candidateDetails = [];
          this.errorMsg = error.error.message;

        });
  }

  selectScreenFreezer(event) {
    const d6ValidationChkbx = event.target || event.srcElement;
    if (d6ValidationChkbx.checked) {
      $('#screenPopup').modal('show');
      this.selectScreenFreezeForLink = true;
    } else {
      this.selectScreenFreezeForLink = false;

    }
  }

  removeCandidatesDetails(index) {
    if (this.candidateData[index].candidatesName === '' || this.candidateData[index].email === '' || this.candidateData[index].phoneNo === '') {
      this.isValidPhone = true;
      this.errorMsg = '';
      $('#canNameErr-' + index).html('');
      $('#emailErr-' + index).html('');
      $('#phoneErr-' + index).html('');
      $('#candidateIdErr-' + index).html('');
    } else if (this.candidateData[this.candidateData.length - 1].candidatesName !== '' &&
      this.candidateData[this.candidateData.length - 1].email !== '' &&
      this.candidateData[this.candidateData.length - 1].phoneNo !== ''
    ) {
      this.isValidPhone = true;
      this.errorMsg = '';
      $('#canNameErr-' + index).html('');
      $('#emailErr-' + index).html('');
      $('#phoneErr-' + index).html('');
      $('#candidateIdErr-' + index).html('');
    } else {
      this.isValidPhone = false;
      this.checkForUploadCandidate();
    }

    this.candidatesDetails.splice(index, 1);
    this.candidateData.splice(index, 1);
    if (this.candidatesDetails.length === 1) {
      this.candidatesADD = false;
    }
  }

  proctorActive() {
    if (!this.candidateProctor) {
      $('#proctor').modal('show');
    } else {
      this.candidateAttendanceLink = false;
      this.candidateSnapshotForLink = false;
      this.TrackLocationForLink = false;
      this.candidateMandidate = false;
      // remove check of mandate candidate image
      this.imageUploadForLink = false;
      this.selectScreenFreezeForLink = false;
    }
  }

  proctorActivate() {
    if (this.candidateProctor) {
      this.candidateAttendanceLink = true;
      this.candidateSnapshotForLink = true;
      this.TrackLocationForLink = true;
      this.candidateMandidate = true;
    }
  }

  proctorcancelation() {
    this.candidateProctor = false;
  }

  cancleMandidate() {
    $('#candidatePopup').modal('hide');
    this.imageUploadForLink = false;
  }

  selectImageUpload(event) {
    const d6ValidationChkbx = event.target || event.srcElement;
    if (d6ValidationChkbx.checked) {
      this.imageUploadForLink = true;
      $('#candidatePopup').modal('show');

    } else {
      // this.candidateMandidate = true;
      this.imageUploadForLink = false;

    }
  }

  assesmentMadidate() {
    $('#candidatePopup').modal('hide');
    this.imageUploadForLink = true;
  }

  validateTime(e) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  // Start Validation
  validateName(e, i) {
    const canname = $('#canname-' + i).val();
    const email = $('#email-' + i).val();
    const phone = $('#phone-' + i).val();

    if (canname.charAt(0) === ' ') {
      e.target.value = '';
      this.isValidPhone = false;
    } else if (canname === '') {
      this.isValidPhone = false;
    } else {
      $('#canNameErr-' + i).html('');
      if (canname !== '' && email !== '' && phone !== '') {
        this.isValidPhone = true;
      }
    }
    if (canname === '' && email === '' && phone === '') {
      this.isValidPhone = false;
      this.checkForUploadCandidate();
    }
  }

  validateEmail(e, i) {
    const canname = $('#canname-' + i).val();
    const email = $('#email-' + i).val();
    const phone = $('#phone-' + i).val();
    if (email.charAt(0) === ' ') {
      this.isValidPhone = false;
      e.target.value = '';
    } else if (email === '') {
      this.isValidPhone = false;
    } else if (canname.length > 2 && phone.length > 6 &&
      email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') !== null) {
      this.isValidPhone = true;
      $('#emailErr-' + i).html('');
      this.errorMsg = '';
    } else {
      this.isValidPhone = false;
    }
    if (canname === '' && email === '' && phone === '') {
      this.isValidPhone = false;
      this.checkForUploadCandidate();
    }
  }

  validatePhone(e, i) {
    const canname = $('#canname-' + i).val();
    const email = $('#email-' + i).val();
    const phone = $('#phone-' + i).val();

    if (phone === '') {
      this.isValidPhone = false;
    } else if (phone.length < 7) {
      this.isValidPhone = false;
    } else if (phone.length > 6) {
      $('#phoneErr-' + i).html('');
    } else {
      if (canname !== '' && phone !== '' && email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') !== null) {
        this.isValidPhone = true;
        this.errorMsg = '';
      }
    }
    if (canname === '' && email === '' && phone === '') {
      this.isValidPhone = false;
      this.checkForUploadCandidate();
    }
  }

  nameOnBlur(i) {
    const canname = $('#canname-' + i).val();
    if (canname === '' || canname.length < 3) {
      this.isValidPhone = false;
      $('#canNameErr-' + i).html('Enter valid Name.');
    }
  }

  emailOnBlur(i) {
    const email = $('#email-' + i).val();
    if (email === '' || email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null) {
      this.isValidPhone = false;
      $('#emailErr-' + i).html('Enter valid Email ID.');
    }
  }

  phoneOnBlur(i) {
    const phone = $('#phone-' + i).val();
    if (phone === '' || phone.length < 7) {
      this.isValidPhone = false;
      $('#phoneErr-' + i).html('Enter valid Mobile Number.');
    }

  }
  // End Validation

  validateMobile(e, i) {

    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

    if (e.target.value.match('^[0-9]*$') !== null) {
      let phone: any;
      if (this.candidatDetailsChecked.indexOf(phone) < 0) {
        $('#phone-' + i).css('border-color', '');
        $('#duplicate-' + i).html('');

      } else {
        $('#phone-' + i).css('border-color', 'red');
        $('#duplicate-' + i).html('mobile number is duplicate');
        return;
      }
      const canname = $('#canname-' + i).val();
      const email = $('#email-' + i).val();

      if (canname !== '' && email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') !== null
        && e.target.value.length >= 6) {
        this.errorMsg = '';
        this.errorMessage = false;
        this.isValidPhone = true;
        this.errorMsg = '';
      } else if (e.target.value.length >= 0) {
        this.errorMessage = false;
      } else {
        // this.isValidPhone = false;
        this.checkForUploadCandidate();
      }
    }
  }

  restrictNumbers(e) {
    if ((e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 48 && e.keyCode <= 57)) {
      e.preventDefault();
    }
  }

  spaceValidation(e) {
    if (e.target.value.charAt(0) === ' ' && e.srcElement.nodeName === 'INPUT') {
      this.isOnlySpace = true;
      this.isValidPhone = false;
    } else if (e.target.value.charAt(0) === ' ' && e.srcElement.nodeName === 'TEXTAREA') {
      this.isRemarkSpace = true;
      this.isValidPhone = false;
    } else {
      this.isOnlySpace = false;
      this.isRemarkSpace = false;
      if (this.linkTypeSelect === 'public') {
        this.isValidPhone = true;
      }
    }
  }

  previewCandidates() {
    $('#previewCandidtesList').modal('show');
  }

  deleteCandidate(candID) {
    this.inviteByFileUpload.splice(candID, 1);
  }

  backClicked() {
    this._location.back();
  }

  backButton() {
    this.listErrMessage = false;
    if (this.publicLinkCreation === true && this.byLink === false) {
      this.publicLinkCreation = false;
      window.scroll(0, 0);
    } else if (this.privateLinkCreationStep1 === true && this.byLink === false) {
      this.privateLinkCreationStep1 = false;
      window.scroll(0, 0);

    } else if (this.privateLinkCreationStep2 === true) {
      this.privateLinkCreationStep1 = true;
      this.privateLinkCreationStep2 = false;
      window.scroll(0, 0);
      const size = this.candidatesDetails.length;
      this.candidatesDetails = [];
      for (var i = 0; i < size; i++) {
        setTimeout(function () { myExtObject.loadFlagId(size); });
        const obj = {
          'candidatesName': $('#canname-' + i).val(),
          'email': $('#email-' + i).val(),
          'phoneNo': $('#phone-' + i).val(),
          'countryCode': '+91'
        };
        this.candidatesDetails.push(obj);
      }

    }

  }

  downloadFormat() {
    window.open('https://cdn.calibrat.com/static/CandidateUploadFormat.xlsx', '_blank');
  }

  assesmentProceed() {
    this.publicStartTime = $('#startTime').val();
    if (this.publicStartDate === '') {
      this.startDateTime = '';
    } else {
      this.startDateTime = this.publicStartDate + 'T' + this.publicStartTime + ':00';
    }
    if (this.publicStartTime === '' && this.publicStartDate !== '') {
      this.startDateTime = this.publicStartDate + 'T' + '00:00:00';
    }
    if (this.modalValue === this.private) {
      this.sendInvitation();
    }
  }

  cancleProceed() {
    $('#publishPopup').modal('hide');
  }

  validateAndSetEvaluationDateRange() {
    // return false;
    this.evaluationStartsAt = '';
    this.evaluationEndsAt = '';
    if ((this.evaluationStartDate == '' || this.evaluationStartDate == null) &&
      (this.evaluationEndDate == '' || this.evaluationEndDate == null)) {
      // Both evaluation start & end date not selected
      return true;
    }
    let evalStTpVal = $('#evaluationStartTime').val(); // #evaluationStartTime picker value
    let evalEndTpVal = $('#evaluationEndTime').val(); // #evaluationEndTime picker value

    if (this.evaluationStartDate != '' && this.evaluationStartDate != null && evalStTpVal == '') {
      // Evaluation start date is selected but time not
      this.errorMsg = 'Provide evaluation start time';
      return false;
    }
    if (this.evaluationEndDate != '' && this.evaluationEndDate != null && evalEndTpVal == '') {
      // Evaluation end date is selected but time not
      this.errorMsg = 'Provide evaluation end time';
      return false;
    }
    if (this.evaluationStartDate != '' && (this.evaluationEndDate == null || this.evaluationEndDate == '')) {
      // Evaluation start date & time selected but end date not selected
      this.evaluationStartsAt = this.evaluationStartDate.formatted + 'T' + evalStTpVal + ':00';
      return true;
    } else if ((this.evaluationStartDate == '' || this.evaluationStartDate == null) && this.evaluationEndDate != '') {
      // Evaluation end date & time selected but start date not selected
      this.evaluationEndsAt = this.evaluationEndDate.formatted + 'T' + evalEndTpVal + ':00';
      return true;
    } else {
      // Both evaluation start & end date-time selected
      let evalStartECOP = this.evaluationStartDate.jsdate.getTime() + parseInt(evalStTpVal.split(':')[0], 10) * 60 * 1000 + parseInt(evalStTpVal.split(':')[1], 10) * 1000;
      let evalEndECOP = this.evaluationEndDate.jsdate.getTime() + parseInt(evalEndTpVal.split(':')[0], 10) * 60 * 1000 + parseInt(evalEndTpVal.split(':')[1], 10) * 1000;
      if (evalStartECOP < evalEndECOP) {
        this.evaluationStartsAt = this.evaluationStartDate.formatted + 'T' + evalStTpVal + ':00';
        this.evaluationEndsAt = this.evaluationEndDate.formatted + 'T' + evalEndTpVal + ':00';
        return true;
      } else {
        this.errorMsg = 'Evaluation start timing can not be greater than the end';
        return false;
      }
    }
  }

  setDatePickerValue(dateToSet) {
    let dateParts = dateToSet.split('-');
    let jsDate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[2], 10), 0, 0, 0, 0);
    return {
      date: {
        year: parseInt(dateParts[0], 10),
        month: parseInt(dateParts[1], 10),
        day: parseInt(dateParts[2], 10)
      },
      jsdate: jsDate,
      formatted: dateToSet,
      epoc: jsDate.getTime()
    };
  }
}
