<app-user-header></app-user-header>

<!--Invite Link Breadcrumb Section-->
<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <i *ngIf="!inviteCheck && !editCandidates" class="material-icons green align-middle"
                  style="cursor: pointer" (click)="backClicked()">keyboard_arrow_left</i>
                <i *ngIf="inviteCheck && privateLinkCreationStep2 && !addprivate"
                  class="material-icons green align-middle" style="cursor: pointer"
                  (click)="backClicked()">keyboard_arrow_left</i>
                <a *ngIf="!checkForEdit && !inviteCheck && !editCandidates">
                  Invite Details</a>
                <a *ngIf="
                    inviteCheck &&
                    (privateLinkCreationStep1 || privateLinkCreationStep2)
                  ">
                  Private Invite Details</a>
                <a *ngIf="
                    inviteCheck &&
                    publicLinkCreation &&
                    !privateLinkCreationStep2
                  ">Invite Details</a>
                <a *ngIf="checkForEdit">Edit Assessment</a>
                <a *ngIf="editCandidates">Edit Publish Link</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</nav>
<!--End of Invite Link Breadcrumb Section-->

<!--Public Link Creation Start -->
<div *ngIf="!privateLinkCreationStep2" id="main_Div" class="container-fluid bg-white pb-5">
  <div *ngIf="byLink" id="main_div2"></div>
  <h5 class="mt-5">Invite Details</h5>
  <div class="manageRadio">
    <div *ngIf="!editCandidates">
      <div style="margin-top: 2rem">
         <h5 *ngIf="!onlineCompilerSelected && !hasCodingQuestions">
          Select mode to publish this assessment
        </h5>
      </div>
      <div *ngIf="!onlineCompilerSelected && !hasCodingQuestions" class="row pt-2 ml-2">
        <form [formGroup]="modalLink">
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" id="inlineRadio1" name="link" value="public"
              [checked]="publicPrivatedChecked == 'public'" formControlName="link"
              (click)="LinkCreation('public', emailID)" />
            <label class="form-check-label" for="inlineRadio1">Public Link</label>
          </div>

          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" id="inlineRadio2" name="link" value="private"
              formControlName="link" [checked]="publicPrivatedChecked == 'private'"
              (click)="LinkCreation('private', emailID)" />
            <label class="form-check-label" for="inlineRadio2">Private Link</label>
          </div>
        </form>
      </div>
    </div>

    <div class="row mtop30">
      <div class="col-4" [ngClass]="{'mt-5':onlineCompilerSelected || hasCodingQuestions}">
        <label>Batch/Group Name<span style="color: red">*</span></label>
        <input minlength="3" maxlength="51" (keyup)="spaceValidation($event)" type="text" class="form-control"
          [formControl]="inviteName" placeholder="Invite Name" [(ngModel)]="publicInviteName" name="inviteName" />
        <small *ngIf="isOnlySpace" class="text-danger">
          Only Space is not allowed</small>
        <span *ngIf="inviteName.touched && inviteName.invalid" class="text text-danger">Please provide valid Batch/Group
          name</span>
      </div>
      <div class="col-4" [ngClass]="{'mt-5':onlineCompilerSelected || hasCodingQuestions}">
        <label>Associate With</label>
        <select class="form-control" placeholder="Select Category" name="tags" [(ngModel)]="publicTags">
          <option value="" hidden>Select Association</option>
          <option *ngFor="let list of tagsForLink" [value]="list.name">
            {{ list.name }}
          </option>
        </select>
      </div>
    </div>

    <!--<div class="col-4 ">-->
    <!--<label >Block IP Address</label>-->

    <!--<div class="input-group mb-3">-->
    <!--<input type="text" class="form-control" placeholder="IP Address" aria-label="Recipient's username"-->
    <!--aria-describedby="basic-addon2" name="ipAddress" [(ngModel)]="publicBlockIpAddress">-->
    <!--<div class="input-group-append">-->
    <!--<i class="material-icons align-middle green">add_circle_outline</i>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <div class="row mtop15">
      <div class="col-4">
        <label>Status <span style="color: red">*</span></label>
        <select class="form-control" id="publicinviteStatus" #inviteStatus [(ngModel)]="publicInviteStatus"
          name="status">
          <option *ngFor="let list of linkStatus" [value]="list">
            {{ list }}
          </option>
        </select>
      </div>
      <div *ngIf="linkPublic && !onlineCompilerSelected && !hasCodingQuestions" class="col-4">
        <label>Access Code <span style="color: red">*</span></label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Test Code" aria-label="Recipient's username"
            aria-describedby="basic-addon2" #testCode [(ngModel)]="Testcode" name="test" readonly />
          <div *ngIf="!editCandidates" class="input-group-append">
            <button type="button" (click)="testCodeGeneration()" class="btn btn-link">
              <i class="material-icons align-middle green">refresh</i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-7">
        <ul class="checkboxes-container mb-0">
          <li>
            <input id="checkbox1" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt1"
              [(ngModel)]="publicAuthanticationOTP" />
            <label for="checkbox1" class="styled-checkbox-label text-center">
              <p><img src="../../assets/img/otp.png" height="55" alt="" /></p>
              Authenticate with OTP
            </label>
          </li>

          <li>
            <input id="checkbox2" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]"
              type="checkbox" value="opt2" [(ngModel)]="TrackLocationForLink" />
            <label for="checkbox2" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/geo_tracking.png" height="55" alt="" />
              </p>
              Track Geo Location
            </label>
          </li>

          <li>
            <input id="checkbox3" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt3"
              [(ngModel)]="IDProofForLink" />
            <label for="checkbox3" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/id_proof.png" height="55" alt="" />
              </p>
              ID Proof
            </label>
          </li>

          <li>
            <input id="checkbox4" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]"
              type="checkbox" value="opt4" [(ngModel)]="candidateAttendanceLink" />
            <label for="checkbox4" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/Attendee Snapshot.png" height="55" alt="" />
              </p>
              Attendance Snapshot
            </label>
          </li>
          <li>
            <input id="checkbox5" [disabled]="candidateProctor" class="styled-checkbox" name="checkboxes[]"
              type="checkbox" value="opt5" [(ngModel)]="candidateSnapshotForLink" />
            <label for="checkbox5" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/candidate_snapshot.png" height="55" alt="" />
              </p>
              Candidate Snapshot
            </label>
          </li>
          <li>
            <input id="checkbox6" (click)="proctorActive()" class="styled-checkbox" name="checkboxes[]" type="checkbox"
              value="opt6" [(ngModel)]="candidateProctor" />
            <label for="checkbox6" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/Observer55.png" height="55" alt="" />
              </p>
              Proctor
            </label>
          </li>

          <li *ngIf="
              createdassessmentType != 'Psychometer' &&
              createdassessmentType != 'Online Compiler' &&
              !onlineCompilerSelected
            ">
            <input id="checkbox7" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt8"
              [(ngModel)]="displayScore" />
            <label for="checkbox7" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/score_new.png" height="55" alt="" />
              </p>
              Display Total Score
            </label>
          </li>

          <li>
            <input id="checkbox8" class="styled-checkbox" name="checkboxes[]" type="checkbox" value="opt7"
              [(ngModel)]="EnableLockUnlock" />
            <label for="checkbox8" class="styled-checkbox-label text-center">
              <p>
                <img src="../../assets/img/unlock.png" height="55" alt="" />
              </p>
              Lock/Unlock
            </label>
          </li>
        </ul>
      </div>
      <!--create link added here-->

      <!--<div class="col-lg-7">
        <ul class="checkboxes-container" *ngIf="candidateMandidate && candidateProctor">
          <li>
            <input id="checkbox8"  type="checkbox" name="screenfreezer" value="screenfreezer" (change)="selectScreenFreezer($event)" [checked]="selectScreenFreezeForLink" >
            <label for="checkbox8" >Enable Screen Feezer</label>
            <span title="Enabling this will mandate the candidate to take the assessment through downloadable EXE application. The candidate will not be able to take the assessment through any browser." > <i class="fa fa-info-circle" style="cursor: pointer;color:skyblue;padding-left:5px;"></i></span>
          </li>
        </ul>
      </div>-->
      <div class="col-lg-7" *ngIf="
          (linkTypeSelect == 'private' ||
            onlineCompilerSelected ||
            createdassessmentType === 'Online Compiler') &&
          candidateMandidate &&
          candidateProctor
        ">
        <ul class="checkboxes-container">
          <li>
            <input style="float: left; width: 20px; margin-top: 5px" id="checkbox7" type="checkbox" name="uploadimage"
              value="imageupload" (change)="selectImageUpload($event)" [checked]="imageUploadForLink" value="opt8" />
            <label for="checkbox7" style="float: left; width: 175px">Mandate Candidate Image</label>
            <span
              title="Enabling this will mandate the upload of candidate's profile photo. Link will be active only once the profile photos for all the candidates are uploaded successfully.">
              <i class="fa fa-info-circle" style="cursor: pointer" style="cursor: pointer; color: skyblue"></i></span>
          </li>
          <!--<span><i class="fa fa-info" ></i></span>-->
        </ul>
      </div>
      <br />
      <div *ngIf="EnableLockUnlock" class="col-lg-8">
        <ul class="checkboxes-container" style="width: 100%;">
          <li style="width: 100%;">
            <label>
              <h6>Instructions for Center Manager</h6>
            </label>
            <angular-editor [(ngModel)]="CenterManagerInstruction" name="textArea" [config]="config"></angular-editor>

          </li>
          <!--<span><i class="fa fa-info" ></i></span>-->
        </ul>
      </div>
      <!--<div style="margin-left:-270px;margin-top:-45px;" >-->
      <!--<span title="hello" > <i class="fa fa-info" ></i></span>-->
      <!--</div>-->
      <!--create link added ends here-->
    </div>

    <div class="row mtop15">
      <div class="col-4">
        <h5>Batch Timing</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <h6 class="mtop10">Start Date</h6>
        <!--<input type="date" class="form-control " placeholder="Enter Date" name="startDate" [(ngModel)]="publicStartDate">-->

        <my-date-picker name="mydate" [(ngModel)]="publicSTARTDate" [options]="myDatePickerOptions"
          placeholder="Enter date" (dateChanged)="onDateChanged($event, 'start')" required></my-date-picker>
      </div>

      <div class="col-4">
        <h6 class="mtop10">Start Time</h6>
        <input class="form-control" (blur)="checkTimeValue($event)" id="startTime" placeholder="Select Time" readonly
          (focus)="onClock('startTime')" (keydown)="validateTime($event)"
          [disabled]="publicStartDate == '' || publicStartDate == null" name="startAssTime"
          [(ngModel)]="publicStartTime" />
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <h6 class="mtop15">End Date</h6>

        <my-date-picker name="mydate" [(ngModel)]="publicENDDate" [options]="myDatePickerOptions"
          placeholder="Enter date" (dateChanged)="onDateChanged($event, 'end')" required></my-date-picker>
      </div>

      <div class="col-4">
        <h6 class="mtop15">End Time</h6>
        <input class="form-control" (blur)="checkTimeValue($event)" placeholder="Select Time" id="endTime" readonly
          (keydown)="validateTime($event)" (focus)="onClock('endTime')"
          [disabled]="publicEndDate == '' || publicEndDate == null" name="endAssTime" [(ngModel)]="publicEndTime" />
      </div>
    </div>
    <!-- Evaluation Timing blocks will appear for assessments with descriptive questions -->
    <div class="row mtop50" *ngIf="hasDescriptiveQuestions">
      <div class="col-4">
        <h5>Batch Evaluation Timing</h5>
      </div>
    </div>
    <div class="row" *ngIf="hasDescriptiveQuestions">
      <div class="col-4">
        <h6 class="mtop15">Start Date</h6>

        <my-date-picker name="startEvalDate" [(ngModel)]="evaluationStartDate" [options]="myDatePickerOptions"
          placeholder="Enter date" (dateChanged)="onDateChanged($event, 'evaluationStart')" required></my-date-picker>
      </div>

      <div class="col-4">
        <h6 class="mtop15">Start Time</h6>
        <input class="form-control" (blur)="checkTimeValue($event)" placeholder="Select Time" id="evaluationStartTime"
          readonly (keydown)="validateTime($event)" (focus)="onClock('evaluationStartTime')"
          [disabled]="evaluationStartDate == '' || evaluationStartDate == null" name="startEvalTime"
          [(ngModel)]="evaluationStartTime" />
      </div>
    </div>
    <div class="row" *ngIf="hasDescriptiveQuestions">
      <div class="col-4">
        <h6 class="mtop15">End Date</h6>
        <my-date-picker name="mydate" [(ngModel)]="evaluationEndDate" [options]="myDatePickerOptions"
          placeholder="Enter date" (dateChanged)="onDateChanged($event, 'evaluationEnd')" required></my-date-picker>
      </div>

      <div class="col-4">
        <h6 class="mtop15">End Time</h6>
        <input class="form-control" (blur)="checkTimeValue($event)" placeholder="Select Time" id="evaluationEndTime"
          readonly (keydown)="validateTime($event)" (focus)="onClock('evaluationEndTime')"
          [disabled]="evaluationEndDate == '' || evaluationEndDate == null" name="endEvalTime"
          [(ngModel)]="evaluationEndTime" />
      </div>
    </div>
    <!-- Evaluation Timing block ends -->
    <div *ngIf="linkPrivate && !editCandidates" class="mtop30 mt-15">
      <div class="row">
        <div class="col">
          <p class="dectTo" (click)="downloadFormat()">
            Download Candidate List Format
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="padding-form">
            <input type="file" #myInput class="form-control" name="fileUpload" (click)="resetFile()" id="inputdefault"
              placeholder="Upload file" (change)="fileChangeEvent($event)" accept=".xlsx" />
          </div>
        </div>
        <div *ngIf="isUpload" class="col">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="previewCandidates()">
            Review
          </button>
        </div>
        <div *ngIf="fileUploadMsg" class="col" class="text-danger">
          <small>{{ uploaderrorMsg }}</small>
        </div>
      </div>

      <div class="row mtop30">
        <div class="col">
          <div class="">
            <label>Name<span style="color: red">*</span></label>
          </div>
        </div>
        <div class="col">
          <div class="">
            <label>Email ID<span style="color: red">*</span></label>
          </div>
        </div>
        <div class="col">
          <div class="">
            <label>Mobile No.<span style="color: red">*</span></label>
          </div>
        </div>
        <div class="col">
          <div class="">
            <label>ID</label>
          </div>
        </div>
      </div>

      <div class="row" *ngFor="let customField of candidatesDetails; let x = index">
        <div class="col">
          <div class="form-group">
            <input class="form-control" type="text" attr.id="canname-{{ x }}" placeholder="Name"
              (keydown)="restrictNumbers($event)" (keyup)="validateName($event, x)" (blur)="nameOnBlur(x)"
              name="candidatesName" [(ngModel)]="candidateData[x].candidatesName" />
            <span *ngIf="candidatesADD" class="mb-3">
              <small style="color: red; cursor: pointer" (click)="removeCandidatesDetails(x)">Remove</small>
              <br />
            </span>
            <span class="error-text text-danger" id="canNameErr-{{ x }}"></span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input class="form-control" type="text" attr.id="email-{{ x }}" (keyup)="validateEmail($event, x)" #emailID
              (blur)="emailOnBlur(x)" placeholder="Email ID" name="email" [(ngModel)]="candidateData[x].email" />

            <span class="error-text text-danger" id="emailErr-{{ x }}"></span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input (keydown)="validateMobile($event, x)" (keyup)="validatePhone($event, x)" class="form-control"
              attr.id="phone-{{ x }}" OnlyNumber="true" aria-describedby="emailHelp" placeholder="Mobile No."
              maxlength="14" minlength="5" name="phone" (blur)="phoneOnBlur(x)" [(ngModel)]="candidateData[x].phoneNo"
              required />
            <span class="error-text text-danger" id="phoneErr-{{ x }}"></span>
            <small class="text-danger" attr.id="duplicate-{{ x }}"></small>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input class="form-control" attr.id="cand-id-{{ x }}" OnlyNumber="true" placeholder="ID" maxlength="21"
              name="candidateIdentifier" (blur)="phoneOnBlur(x)" [(ngModel)]="candidateData[x].candidateIdentifier" />
            <span class="error-text text-danger" id="candidateIdErr-{{ x }}"></span>
          </div>
        </div>
      </div>

      <div class="">
        <a type="file" class="btn btn-add ml-0 my-0 blue" style="cursor: pointer" (click)="addCandidateDetails('', x)">
          <i class="material-icons align-middle">add</i>
          ADD MORE
        </a>
        <br />
        <small *ngIf="errorMessage" class="text-danger mb-3 text-center">Enter All Fields.</small>
      </div>

      <div class="row mtop30">
        <div class="col">
          <div class="form-group">
            <label for="formGroupExampleInput">Remarks </label>
            <textarea minlength="0" maxlength="4000" class="form-control" id="formGroupExampleInput"
              placeholder="Remarks" (keyup)="spaceValidation($event)" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="privateSubject"></textarea>
            <small *ngIf="privateSubject === ' '" class="text-danger">
              Only Space is not allowed
            </small>
          </div>
        </div>
      </div>
    </div>

    <small style="color: red">{{ errorMsg }}</small>
    <small style="color: red">{{ publicerrorMsg }}</small>
    <div class="mt-3" *ngIf="!hasCodingQuestions">
      <button *ngIf="!editCandidates" [disabled]="
          !inviteName.value ||
          (inviteName.touched && inviteName.invalid) ||
          !isValidPhone
        " class="btn btn-primary" (click)="publishAssessmentLink(modalLink.value, imageUploadForLink)">
        Publish
      </button>
      <button *ngIf="editCandidates" [disabled]="
          !inviteName.value || (inviteName.touched && inviteName.invalid)
        " class="btn btn-primary" (click)="editedLink()">
        Save
      </button>

      <button type="button" class="btn btn-cancel" (click)="backClicked()">
        Cancel
      </button>
    </div>
    <div class="mt-3" *ngIf="hasCodingQuestions">
      <button [disabled]="fileUploadMsg || !isValidPhone" type="button" class="btn btn-primary"
      (click)="sendInvitation()">
      Invite
    </button>
      <button type="button" class="btn btn-cancel" (click)="backClicked()">
        Cancel
      </button>
    </div>
  </div>
</div>
<!--end public link creation-->

<!--end step2 for private link creation-->
<div *ngIf="privateLinkCreationStep2" class="container-fluid main-div bg-white pb-5">
  <div class="row">
    <div class="col">
      <h5 *ngIf="!inviteCheck" style="margin-top: -53px">
        Private Invite Details
      </h5>
      <p>Invite Candidates</p>
      <p class="dectTo" (click)="downloadFormat()">
        Download Candidate List Format
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <div class="padding-form">
        <input type="file" #myInput class="form-control" name="fileUpload" (click)="resetFile()" id="inputdefault"
          placeholder="Upload file" (change)="fileChangeEvent($event)" accept=".xlsx" />
      </div>
    </div>
    <div *ngIf="isUpload" class="col">
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="previewCandidates()">
        Review
      </button>
    </div>
    <div *ngIf="fileUploadMsg" class="col" class="text-danger">
      <small>{{ uploaderrorMsg }}</small>
    </div>
  </div>

  <div class="row mtop30">
    <div class="col">
      <div class="">
        <label>Name<span style="color: red">*</span></label>
      </div>
    </div>
    <div class="col">
      <div class="">
        <label>Email ID<span style="color: red">*</span></label>
      </div>
    </div>
    <div class="col">
      <div class="">
        <label>Mobile No.<span style="color: red">*</span></label>
      </div>
    </div>
    <div class="col">
      <div class="">
        <label>ID</label>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let customField of candidatesDetails; let i = index">
    <div class="col">
      <div class="form-group">
        <input class="form-control" type="text" attr.id="canname-{{ i }}" (keydown)="restrictNumbers($event)"
          (keyup)="validateName($event, i)" (blur)="nameOnBlur(i)" [(ngModel)]="candidateData[i].candidatesName"
          placeholder="Name" name="candidatesName" [value]="candidatesDetails[i].candidatesName" />
        <span *ngIf="candidatesADD" class="mb-3">
          <small style="color: red; cursor: pointer" (click)="removeCandidatesDetails(i)">Remove</small>
          <br />
        </span>
        <span class="error-text text-danger" id="canNameErr-{{ i }}"></span>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <input class="form-control" type="text" (keyup)="validateEmail($event, i)" #emailID attr.id="email-{{ i }}"
          id="email" (blur)="emailOnBlur(i)" [(ngModel)]="candidateData[i].email" placeholder="Email ID" name="email"
          [value]="candidatesDetails[i].email" />

        <span class="error-text text-danger" id="emailErr-{{ i }}"></span>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <input #phoneValue (keydown)="validateMobile($event, i)" (keyup)="validatePhone($event, i)" class="form-control"
          attr.id="phone-{{ i }}" OnlyNumber="true" aria-describedby="emailHelp" required placeholder="Mobile No."
          maxlength="14" minlength="5" name="phone" (blur)="phoneOnBlur(i)" [(ngModel)]="candidateData[i].phoneNo"
          [value]="candidatesDetails[i].phoneNo" />
        <span class="error-text text-danger" id="phoneErr-{{ i }}"></span>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <input #candidateIdValue class="form-control" attr.id="cand-id-{{ i }}" placeholder="ID" maxlength="21"
          name="candidateIdentifier" [(ngModel)]="candidateData[i].candidateIdentifier"
          [value]="candidatesDetails[i].candidateIdentifier" />
        <span class="error-text text-danger" id="candidateIdErr-{{ i }}"></span>
      </div>
    </div>
  </div>
  <div class="mtop30">
    <a type="file" class="btn btn-add ml-0 my-0 blue" style="cursor: pointer" (click)="addCandidateDetails('', i)">
      <i class="material-icons align-middle">add</i>
      ADD MORE
    </a>
    <br />
    <small *ngIf="errorMessage" class="text-danger mb-3 text-center">Enter All Fields.</small>
  </div>
  <div class="row mtop30">
    <div class="col">
      <div class="form-group">
        <label for="formGroupExampleInput">Instructions For Candidate </label>
        <textarea (keyup)="spaceValidation($event)" class="form-control" id="formGroupExampleInput"
          placeholder="Remarks" [(ngModel)]="privateSubject">
        </textarea>
        <span *ngIf="isRemarkSpace" class="text-danger">
          Only Space is not allowed
        </span>
      </div>
    </div>
  </div>

  <div>
    <small class="text-danger">{{ errorMsg }}</small>
  </div>

  <div>
    <button [disabled]="fileUploadMsg || !isValidPhone" type="button" class="btn btn-primary"
      (click)="sendInvitation()">
      Invite
    </button>

    <button type="button" class="btn btn-cancel" (click)="backClicked()">
      Cancel
    </button>
  </div>
</div>
<!--end step2 for private link creation-->

<!--Modal for proctor-->
<div class="modal fade" id="proctor" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="proctorcancelation()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Enabling Proctor will enable Track-Location, candidate and attendance
        snapshot automatically. You will not be allowed to disable the same.
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="proctorActivate()">
          Ok
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="proctorcancelation()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<!--End of modal for proctor-->

<!--assesment candidate mandidate checkbox popup-->
<div class="modal fade" id="candidatePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button (click)="cancleMandidate()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Enabling this will mandate the upload of candidate's base profile image.
        Link will be active only once all the candidate images are uploaded
        successfully
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="assesmentMadidate()">
          Ok
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="cancleMandidate()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<!--assesment candidate mandidate checkbox popup ends here-->

<!--Preview candidate list modal-->
<div class="modal fade" id="previewCandidtesList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Invite List</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div class="row heading2">
                  <div class="col">Name</div>
                  <div class="col">Email ID</div>
                  <div class="col">Mobile No</div>
                  <!--<div class="col"></div>-->
                  <div class="col-1"></div>
                </div>
              </div>
            </div>
            <div class="card card__two mtop15 border-0" *ngFor="let list of inviteByFileUpload; let i = index">
              <div class="card-body card-bg">
                <div class="row">
                  <div class="col text-truncate qHeading">{{ list.name }}</div>
                  <div class="col text-truncate qRest">{{ list.email }}</div>
                  <div class="col text-truncate qRest">
                    {{ list.mobile
                    }}<span *ngIf="list.isValidInfo == false" class="ml-3 text-danger">{{ list.error.name }}</span>
                  </div>
                  <div class="col-1">
                    <button type="button" class="btn float-right btnMore" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="material-icons">more_vert</i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"
                      x-placement="bottom-end" style="
                        position: absolute;
                        cursor: pointer;
                        transform: translate3d(-155px, 25px, 0px);
                        top: 0px;
                        left: 0px;
                        will-change: transform;
                      ">
                      <a class="dropdown-item" (click)="deleteCandidate(i)">Delete</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
<!--End of preview candidate list modal-->

<!--Start publish popup-->
<div class="modal fade" id="publishPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        The link will be published in inactive status until all the candidate
        image is uploaded sucessfully.<br />Do you wish to proceed ?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="assesmentProceed()">
          Proceed
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="cancleProceed()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<!--End of publish popup-->