<app-user-header (assessmentPage)="RidirectionMessage()" (profileInfo)="dashboardRefresh()"></app-user-header>

<!--starting assessment creation-->
<div class="create-assessment">
  <!--start of common wizard-->
  <nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
    <div class="row">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <i *ngIf="
                      !step1 && !inviteCheck && !editCandidates && !setWizard4
                    " class="material-icons green align-middle" style="cursor: pointer"
                    (click)="backButton()">keyboard_arrow_left</i>
                  <i *ngIf="
                      inviteCheck && privateLinkCreationStep2 && !addprivate
                    " class="material-icons green align-middle" style="cursor: pointer"
                    (click)="backButton()">keyboard_arrow_left</i>
                  <!--<a *ngIf="(inviteCheck && addprivate)">Add Candidates</a>-->
                  <a *ngIf="!checkForEdit && !inviteCheck && !editCandidates">Create New Assessment</a>
                  <a *ngIf="
                      inviteCheck &&
                      (privateLinkCreationStep1 || privateLinkCreationStep2)
                    ">Private Invite Details</a>
                  <a *ngIf="inviteCheck && publicLinkCreation">Invite Details</a>
                  <a *ngIf="checkForEdit">Edit Assessment</a>
                  <a *ngIf="editCandidates">Edit Publish Link</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div *ngIf="byLink == false" class="main-div-wizard wizard-block" id="container">
    <div class="step-wizard" *ngIf="!checkForPsychometric && !forOnlineCompiler">
      <!--<div [ngClass]="[checkForEdit||checkForPsychometric ? 'progress-edit' : 'progress', checkForEdit && checkForPsychometric ? 'psycho-edit' : 'progress']" >-->
      <div [ngClass]="[checkForEdit ? 'progress-edit' : 'progress']">
        <div class="progressbar empty"></div>
        <div id="prog" class="progressbar"></div>
      </div>
      <ul>
        <li *ngIf="setWizard1" class="active">
          <a id="step1" (click)="stepWizard1()" style="cursor: pointer">
            <span class="step">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>
        <li *ngIf="!setWizard1">
          <a id="step1" style="cursor: not-allowed">
            <span class="stepCom">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>
        <li *ngIf="!setWizard2" class="">
          <a id="step2" style="cursor: not-allowed">
            <span class="stepCom">2</span>
            <span class="title">Select Questions</span>
          </a>
        </li>
        <li *ngIf="setWizard2" class="active">
          <a id="step2" (click)="stepWizard2()" style="cursor: pointer">
            <span class="step">2</span>
            <span class="title">Select Questions</span>
          </a>
        </li>
        <li *ngIf="!setWizard3" class="">
          <a id="step3" style="cursor: not-allowed">
            <span class="stepCom">3</span>
            <span class="title">Configure & Preview</span>
          </a>
        </li>
        <li *ngIf="setWizard3" class="active">
          <a id="step3" (click)="stepWizard3()" style="cursor: pointer">
            <span class="step">3</span>
            <span class="title">Configure & Preview</span>
          </a>
        </li>
        <li *ngIf="!setWizard4 && !checkForEdit" class="">
          <a id="step4" style="cursor: not-allowed">
            <span class="stepCom">4</span>
            <span class="title">Publish</span>
          </a>
        </li>
        <li *ngIf="setWizard4 && !checkForEdit" class="active">
          <a id="step4" style="cursor: pointer">
            <span class="step">4</span>
            <span class="title">Publish</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="step-wizard" *ngIf="checkForPsychometric || forOnlineCompiler">
      <div [ngClass]="[checkForEdit ? 'psycho-edit' : 'progress-edit']">
        <div class="progressbar empty"></div>
        <div id="prog" class="progressbar"></div>
      </div>
      <ul>
        <li *ngIf="setWizard1" class="active">
          <a id="step1" (click)="stepWizard1()" style="cursor: pointer">
            <span class="step">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>
        <li *ngIf="!setWizard1">
          <a id="step1" style="cursor: not-allowed">
            <span class="stepCom">1</span>
            <span class="title">Basic Info</span>
          </a>
        </li>

        <li *ngIf="!setWizard3" class="">
          <a id="step3" style="cursor: not-allowed">
            <span class="stepCom">2</span>
            <span class="title">Configure & Preview</span>
          </a>
        </li>
        <li *ngIf="setWizard3" class="active" (click)="stepWizard3()">
          <a id="step3" style="cursor: pointer">
            <span class="step">2</span>
            <span class="title">Configure & Preview</span>
          </a>
        </li>
        <li *ngIf="!setWizard4 && !checkForEdit" class="">
          <a id="step4" style="cursor: not-allowed">
            <span class="stepCom">3</span>
            <span class="title">Publish</span>
          </a>
        </li>
        <li *ngIf="setWizard4 && !checkForEdit" class="active">
          <a id="step4" style="cursor: pointer">
            <span class="step">3</span>
            <span class="title">Publish</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!--end of common wizard-->

  <!--start step1 for assessment creation-->
  <div *ngIf="step1" class="main-div-assessment container-fluid bg-white pt-3 pb-5">
    <div class="row">
      <div class="col">
        <form class="mt-3" #form1="ngForm" (ngSubmit)="onSubmit(form1)" autocomplete="off">
          <h5>Fill Assessment Details</h5>

          <div class="row mtop30">
            <div class="col">
              <label>Assessment Name <span style="color: red">*</span></label>
              <input type="text" (keyup)="checkAssessmentName()" minlength="3" maxlength="51"
                class="form-control placehold" id="assessmentName" #AssessmentName="ngModel"
                [(ngModel)]="assessmentName" pattern="^[ A-Za-z0-9_@.+-]*$" name="AssessmentName"
                placeholder="Enter Assessment Name" required />

              <small *ngIf="assesmentNameError" class="text-danger">{{
                assesmentNameErr
                }}</small>
              <small *ngIf="AssessmentName.touched && AssessmentName.invalid" class="text-danger">Please enter valid
                assessment name</small>
            </div>
            <!-- when user directly click on create assessment from type of assesment screen-->
            <div class="col-md-6" *ngIf="!checkForType">
              <!-- Master Question Bank access is true-->
              <div *ngIf="masterQBAccess">
                <label>Assessment Type <span style="color: red">*</span></label>
                <select class="form-control" id="Assessment" #AssessmentType="ngModel" [(ngModel)]="assessmentType"
                  name="Assessment" (change)="checkAssessmentType($event)" required>
                  <option value="" class="placehold" hidden>
                    Select Assessment Type
                  </option>
                  <option *ngFor="let list of assessmentTypeList" [value]="list.name">
                    {{ list.name }}
                  </option>
                </select>
              </div>
              <!-- Master Question Bank access is false-->
              <div *ngIf="!masterQBAccess">
                <label>Assessment Type <span style="color: red">*</span></label>
                <select class="form-control" id="Assessment" #AssessmentType="ngModel" [(ngModel)]="assessmentType"
                  name="Assessment" (change)="checkAssessmentType($event)" required>
                  <option value="" class="placehold" hidden>
                    Select Assessment Type
                  </option>
                  <option *ngFor="let list of assessmentTypeList" [value]="list.name"
                    [disabled]="list.name=='Aptitude'||list.name=='Psychometer' || list.name=='Online Compiler'">
                    {{ list.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- when user clicks on create assessment from  particular type of assesment screen-->
            <div class="col-md-6" *ngIf="checkForType">
              <!-- Master Question Bank access is true-->
              <div *ngIf="masterQBAccess">
                <label>Assessment Type <span style="color: red">*</span></label>
                <select [disabled]="checkForEdit" class="form-control" id="Assessment" #AssessmentType="ngModel"
                  [(ngModel)]="assessmentType" name="Assessment" (change)="checkAssessmentType($event)" required>
                  <option [value]="assessmentType" hidden>
                    {{ assessmentType }}
                  </option>
                  <option *ngFor="let list of assessmentTypeList" [value]="list.name" [ngStyle]="{
                      'background-color':
                        list.name === assessmentType ? '#80bdff' : ''
                    }">
                    {{ list.name }}
                  </option>
                </select>
              </div>
              <div *ngIf="!masterQBAccess">
                <label>Assessment Type <span style="color: red">*</span></label>
                <select [disabled]="checkForEdit" class="form-control" id="Assessment" #AssessmentType="ngModel"
                  [(ngModel)]="assessmentType" name="Assessment" (change)="checkAssessmentType($event)" required>
                  <option [value]="assessmentType" hidden>
                    {{ assessmentType }}
                  </option>
                  <option *ngFor="let list of assessmentTypeList" [value]="list.name" [ngStyle]="{
                      'background-color':
                        list.name === assessmentType ? '#80bdff' : ''
                    }" [disabled]="list.name=='Aptitude'||list.name=='Psychometer'||list.name=='Online Compiler'">
                    {{ list.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mtop30">
            <div class="col-md-6" *ngIf="!forOnlineCompiler">
              <label>Duration <span style="color: red">*</span></label>
              <select class="form-control" id="duration" #assessmentDuration="ngModel" [(ngModel)]="durations"
                name="duration" required>
                <option value="" class="placehold" disabled>
                  Select Duration
                </option>
                <option *ngFor="let duration of durationList" [value]="duration.name">
                  {{ duration.name }}
                </option>
              </select>
            </div>

            <div class="col-md-6" *ngIf="forOnlineCompiler">
              <label>Select Test <span style="color: red">*</span></label>
              <select class="form-control" id="Test" #assessmentTest="ngModel" [(ngModel)]="onlinecomp"
                (ngModelChange)="selectedvalue()" [disabled]="checkForEdit" name="test" required>
                <option *ngIf="!checkForEdit" value="" class="placehold" selected hidden>
                  Select Test
                </option>
                <option *ngIf="checkForEdit" [value]="onlinecomp" class="placehold">
                  {{ onlinecomp }}
                </option>
                <option *ngFor="let testname of getDoSelectTest" [ngValue]="testname">
                  {{ testname.name }}
                </option>
              </select>
            </div>

            <div class="col" *ngIf="!checkForPsychometric && !forOnlineCompiler">
              <label>Passing Score(%) <span style="color: red">*</span></label>
              <input (keyup)="checkForZeroValue($event)" (keydown)="checkPassingScore($event)" type="text"
                class="form-control height50 placehold" maxlength="3" OnlyNumber="true" id="Passingpercentage"
                [(ngModel)]="percentageMarks" name="Passingpercentage" placeholder="Enter Passing Percentage" />

              <small *ngIf="showPassingScoreErr" class="text-danger">
                Choose value from 0 to 100.
              </small>
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Assessment Description</label>

            <angular-editor *ngIf="checkForPsychometric" [(ngModel)]="assessmentDescription" name="textArea"
              placeholder="Enter Description" readonly="true" [config]="config"></angular-editor>

            <angular-editor *ngIf="!checkForPsychometric" [(ngModel)]="assessmentDescription" name="textArea"
              placeholder="Enter Description" [config]="config"></angular-editor>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-4 pb-5">
      <div class="col">
        <!--<button  class="btn btn-primary" id="next" [disabled]="AssessmentName.invalid || AssessmentType.invalid ||  assesmentNameError || assessmentDuration.invalid || Passingpercentage.value > 100 || Passingpercentage.value == ''"-->
        <!--(click)="step1Method();">-->
        <!--Next-->
        <!--</button>-->
        <button *ngIf="forOnlineCompiler" class="btn btn-primary" id="next" [disabled]="
            AssessmentName.invalid ||
            !assessmentType ||
            assesmentNameError ||
            !onlinecomp
          " (click)="step1Method()">
          Next
        </button>

        <button *ngIf="!checkForPsychometric && !forOnlineCompiler" class="btn btn-primary" id="next" [disabled]="
            AssessmentName.invalid ||
            !assessmentType ||
            assesmentNameError ||
            !durations ||
            !percentageMarks
          " (click)="step1Method()">
          Next
        </button>

        <button *ngIf="checkForPsychometric && !forOnlineCompiler" class="btn btn-primary" id="next" [disabled]="
            AssessmentName.invalid || !assessmentType || assesmentNameError
          " (click)="step1Method()">
          Next
        </button>
        <!--<button class="btn btn-cancel" (click)="privateCandidateinvitationList(false);">Cancel</button>-->
        <button class="btn btn-cancel" (click)="backClicked()">Cancel</button>
      </div>
    </div>
  </div>
  <!--end step1 for assessment creation-->

  <!--start step2 for assessment creation-->
  <div *ngIf="step2" class="main-div-assessment container-fluid bg-white pt-3 pb-5">
    <div class="row">
      <div class="col">
        <h5>Question Details</h5>
      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col" (change)="inBuildQuestion($event.target.value)">
        <h6>Select Question</h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio1" name="inBuild" [checked]="true"
            [value]="inBuiltQuestion" />
          <label class="form-check-label" for="inlineRadio1">In Built Questions</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="" id="draggablePanelList" class="list-unstyled">
          <div class="panel panel-success mb-3" *ngFor="let box of boxes; let j = index">
            <div class="strip">
              <i class="material-icons align-middle md-12"></i>
            </div>
            <div class="panel-heading pt-3 pl-4">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div *ngIf="sectionGridName[j] && sectionGridName[j].data == false">
                    <div class="row pl-4" attr.id="sectionName-{{ j }}" name="secBox">
                      <p (click)="editSectionname(j)" class="GR-14">
                        {{ boxes[j].sectionName }}
                        <img class="pl-2" src="/assets/img/Edit.png" alt="" />
                      </p>
                    </div>
                  </div>
                  <div *ngIf="sectionGridName[j] && sectionGridName[j].data == true" class="mb-3"
                    style="margin-left: 12px">
                    <input autofocus type="text" minlength="3" maxlength="51" class="edit-section"
                      attr.id="editTestBox-{{ j }}" name="editTestBox" [(ngModel)]="boxes[j].sectionName"
                      style="padding: 5px; border: none; outline: none" (keyup)="spaceValidation($event, j)"
                      (focusout)="updateSectionName($event, j)" />
                  </div>
                  <small id="sectionNameErr-{{ j }}" class="text-danger"> </small>
                </div>
                <div class="col-md-3" style="max-width: 300px;  ">

                  <input type="checkbox" id="allowoptionalQue" class="mr-1 ml-4"
                    [(ngModel)]="boxes[j].hasOptionalQuestions" (ngModelChange)="updateOptionalQueFlag($event,j)">Allow
                  Optional Questions

                </div>
                <div class="col-md-3" style="max-width: 300px; padding-left: 50px;">
                  <mat-slide-toggle [checked]="toggleChecked[j]" (change)="handleQuestionViewToggleChange($event, j)"
                    [(ngModel)]="toggleChecked[j]">
                    Question View Type: {{ configQuestionViewPreference[j].data === 'all' ? 'All' : 'Default' }}
                  </mat-slide-toggle>
                </div>
              </div>
              <hr>
              <div *ngIf="deleteGrid" class="box-header-btns pull-right">
                <div class="float-right">
                  <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-end" style="
                      position: absolute;
                      transform: translate3d(-155px, 25px, 0px);
                      top: 0px;
                      left: 0px;
                      will-change: transform;
                    ">
                    <a class="dropdown-item" title="Remove widget" (click)="removeWidget(j)">Delete</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="panel-body" *ngFor="let customField of box.data; let i = index">
                <div class="">
                  <div class="row">
                    <div class="col">
                      <div class="form-group mb-0">
                        <label>Category</label>
                        <select class="form-control" attr.id="name1-{{ j }}-{{ i }}" placeholder="Select Category"
                          [ngModel]="boxes[j].data[i].parentCategoryId" (change)="
                            changeCategory($event.target.value, false, j, i)
                          " name="category">
                          <option value="" selected hidden>
                            Select Category
                          </option>
                          <option *ngFor="let list of categoryListData" [disabled]="
                              masterQBAccess == false &&
                              list.isInbuiltCategory == true
                            " [value]="list.parentCategoryId">
                            {{ list.parentCategoryName }}
                          </option>
                        </select>
                      </div>
                      <small *ngIf="
                          boxes[j].data.length > 1 && removeSecData[j] == true
                        " class="text-danger" aria-hidden="true" style="cursor: pointer"
                        (click)="removeSectionData(j, i)">Remove
                      </small>
                    </div>

                    <div class="col">
                      <div class="form-group mb-0">
                        <label>Sub Category </label>
                        <select class="form-control" [disabled]="subCatDetails[j].categories[i] == 0"
                          attr.id="subCat-{{ j }}-{{ i }}" placeholder="Select SubCategory"
                          [ngModel]="boxes[j].data[i].categoryId" (change)="optionParentId('subCat', i, j)"
                          name="subCategory">
                          <option value="" selected hidden>
                            Select Sub Category
                          </option>
                          <option *ngFor="let list of subCatDetails[j].categories[i]" [value]="list._id">
                            {{ list.categoryName }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group mb-0">
                        <label>Question Type</label>
                        <select class="form-control" attr.id="value-{{ j }}-{{ i }}"
                          (change)="optionParentId('value', i, j)" [ngModel]="boxes[j].data[i].questionType"
                          name="value" ng-bind-html="attr.id">
                          <option value="" selected hidden>
                            Select Question Type
                          </option>
                          <option *ngFor="let list of typeOfQuestion" [value]="list.name">
                            {{ list.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group mb-0">
                        <label>Difficulty Level</label>
                        <select class="form-control" attr.id="level-{{ j }}-{{ i }}"
                          (change)="optionParentId('level', i, j)" [ngModel]="boxes[j].data[i].difficulty" name="level"
                          ng-bind-html="attr.id">
                          <option value="" selected hidden>
                            Select Question Level
                          </option>
                          <option *ngFor="let list of levelOfQuestion" [value]="list.name">
                            {{ list.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      <label>No. of Questions</label>
                      <input type="text" attr.id="question-{{ j }}-{{ i }}" (keydown)="checkPassingScore($event)"
                        (keyup)="validateQuestionCount('question', i, j)" OnlyNumberPaste="true"
                        [ngModel]="boxes[j].data[i].numberOfQuestions" value=""
                        ng-selected="customField.numberOfQuestions" name="question" placeholder="No. of Questions"
                        class="form-control height50" />
                      <div class="col" id="countDiv-{{ j }}-{{ i }}" [ngStyle]="{
                          display:
                            boxes[j].data[i].maxNumberOfQuestions != null &&
                            boxes[j].data[i].maxNumberOfQuestions !=
                              undefined &&
                            boxes[j].data[i].maxNumberOfQuestions != ''
                              ? 'block'
                              : 'none'
                        }">
                        <input type="hidden" attr.id="maxQuestionCount-{{ j }}-{{ i }}"
                          [value]="boxes[j].data[i].maxNumberOfQuestions" />
                        <span class="form-text text-muted" name="muteedText"
                          style="margin-left: -16px; font-size: small">
                          Maximum No. of Question:
                          <p name="duration" attr.id="questioncount-{{ j }}-{{ i }}" name="count" attr.value="{{
                              boxes[j].data[i].maxNumberOfQuestions
                            }}" style="
                              pointer-events: none;
                              margin-left: 175px;
                              margin-top: -19px;
                            ">
                            {{ boxes[j].data[i].maxNumberOfQuestions }}
                          </p>
                        </span>
                      </div>
                      <small class="mb-0" id="error-{{ j }}-{{ i }}" class="text-danger mb-3"></small>
                    </div>
                  </div>

                  <hr class="mt-3 mb-3" />
                </div>
              </div>
              <div class="text-center">
                <small attr.id="sectionErr-{{ j }}" class="text-danger mb-3 errorMsgContainer"
                  style="text-align: center"></small>
                <!--<small *ngIf="questionErr && !customFieldErr"  class="text-danger mb-3">Select question(s).</small>-->
              </div>
              <div class="panel-body pb-2">

                <a [class.disabled]="dupQstSetSelectionErr" type="file" class="btn btn-add ml-0 mb-3 blue"
                  (click)="addMoreField(j)" style="cursor: pointer"><i class="material-icons align-middle">add</i> ADD
                  MORE</a>


                <div class="ml-3 optionalQue pull-right flex-column" *ngIf="boxes[j].hasOptionalQuestions">
                  <div style="display: flex;">
                    <label class="mr-2 mt-2">Answer:</label>
                    <input type="text" class="form-control" OnlyNumberPaste="true" id="questionToBeAnswered-{{j}}"
                      (keydown)="checkPassingScore($event)" style="width: 50px;"
                      (change)="validateQueToBeAnsCount(boxes[j],j)" [(ngModel)]="boxes[j].questionsToBeAnswered">
                    <span class="ml-1 mt-2 RR-14"> / {{boxes[j].totalNumberOfQuestions}} Questions</span>
                  </div>
                  <small id="error-{{ j }}" class="text-danger mb-3 mr-0"></small>
                </div>

                <div class="optionalQue pull-right mr-4" *ngIf="boxes[j].hasOptionalQuestions">
                  <div style="display: flex;">
                    <label class="mr-2 mt-2">Score Per Question:</label>
                    <input type="text" class="form-control" OnlyNumberPaste="true" (keydown)="checkPassingScore($event)"
                      (change)="validateScore(boxes[j].scorePerQuestion)" [(ngModel)]="boxes[j].scorePerQuestion"
                      style="width: 50px;">
                  </div>
                  <small id="error1-{{ j }}" class="text-danger " style="max-width: 400px;"></small>
                  <small *ngIf="scoreErrorFlag" class="text-danger ">Score must be greater than 0 and less than
                    100.</small>
                </div>
              </div>

            </div>
          </div>
        </div>
        <button [disabled]="dupQstSetSelectionErr" (click)="addSections()" class="btn btn-add ml-0 mb-3 mt-3 blue">
          <i class="material-icons align-middle">add</i>
          Add Section
        </button>

        <div>
          <div class="text-center">
            <small attr.id="gridERR-{{ index }}" class="text-danger mb-3 errorMsgContainer"></small>
          </div>

          <div class="row mt-3">
            <div class="col">
              <button [disabled]="dupQstSetSelectionErr " class="btn btn-primary" id="next" (click)="step2Method()">
                Next
              </button>
              <!--<button type="submit" class="btn btn-cancel " (click)="assessmentDashboard();clearcreateAssementData();">-->
              <!--Cancel-->
              <!--</button>-->
              <button type="submit" class="btn btn-cancel" (click)="clearcreateAssementData(); backClicked()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end step2 for assessment creation-->

  <!--start step3 for assessment creation-->
  <div *ngIf="step3" class="main-div bg-white pt-3">
    <div *ngIf="!checkForPsychometric && !forOnlineCompiler" class="row mt-3">
      <div class="col">
        <h6 class="pt-3">Test Question Configuration</h6>
        <div class="form-check form-check-inline ml-0">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" name="shuffleQuestions"
            [(ngModel)]="shuffleQuestions" />
          <label class="form-check-label" for="inlineCheckbox1">Shuffle questions</label>
        </div>
        <div class="form-check form-check-inline ml-3">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" name="shuffleOptions"
            [(ngModel)]="shuffleOptions" />
          <label class="form-check-label" for="inlineCheckbox2">Shuffle options</label>
        </div>
        <div class="form-check form-check-inline ml-3">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox3" name="attemtAll"
            [(ngModel)]="attemptAll" />
          <label class="form-check-label" for="inlineCheckbox3">All question compulsory</label>
        </div>

        <div class="form-check form-check-inline ml-3">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox4" name="moveForwardOnly"
            [(ngModel)]="moveForwardOnly" />
          <label class="form-check-label" for="inlineCheckbox4">Move forward only</label>
        </div>
      </div>
    </div>
    <hr *ngIf="!checkForPsychometric && !forOnlineCompiler" />
    <div class="row mt-3" *ngIf="!checkForPsychometric && !forOnlineCompiler">
      <div class="col">
        <h6 class="pt-3">Display Maximum Marks</h6>
        <div class="form-check form-check-inline ml-0">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox14" name="displayMaxMarks"
            [(ngModel)]="disMaxMarks" />
          <label class="form-check-label" for="inlineCheckbox14">Display maximum marks for each question</label>
        </div>
      </div>
    </div>
    <hr *ngIf="!checkForPsychometric && !forOnlineCompiler" />
    <div class="row mt-3" *ngIf="!checkForPsychometric && !forOnlineCompiler">
      <div class="col">
        <h6 class="pt-3">Display Question Category Information</h6>
        <div class="form-check form-check-inline ml-0">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox15" name="displayCatSubCat"
            [(ngModel)]="displayCatSubCat" />
          <label class="form-check-label" for="inlineCheckbox15">Display Category & Sub-category information of each
            question to candidate</label>
        </div>
      </div>
    </div>
    <hr *ngIf="!checkForPsychometric && !forOnlineCompiler" />
    <div class="row mt-3" *ngIf="!checkForPsychometric && !forOnlineCompiler">
      <div class="col">
        <h6 class="pt-3">Auto Resume Incomplete Attempt</h6>
        <div class="row">
          <div class="col-5">
            <div class="form-check form-check-inline ml-0">
              <input class="form-check-input" type="checkbox" id="chkEnableAutoResumeAssessmentAttempt"
                name="chkEnableAutoResumeAssessmentAttempt" [(ngModel)]="enableAutoResume" />
              <label class="form-check-label" for="chkEnableAutoResumeAssessmentAttempt">Allow user to auto resume
                his/her incomplete assessment attempt</label>
            </div>
          </div>
          <div class="col-5">
            <form class="form-group" autocomplete="off">
              <label for="exampleFormControlSelect1">Enter count</label>
              <input class="form-control" maxlength="1" [disabled]="!enableAutoResume" OnlyNumber="true"
                (keyup)="checkForZero($event)" (keydown)="validateNumber($event)" (keypress)="keyPress($event)"
                id="autoResumeAttemptCount" (change)="checkForZero($event)" name="autoResumeAttemptCount"
                [(ngModel)]="autoResumeAttemptCount" style="width: 50%" />
            </form>
            <span id="autoResumeErr" style="color: red; text-align: center"></span>
          </div>
        </div>
      </div>
    </div>
    <hr *ngIf="!checkForPsychometric && !forOnlineCompiler" />
    <div class="row mt-4" *ngIf="!checkForPsychometric && !forOnlineCompiler">
      <div class="col">
        <h6>Negative Marking</h6>

        <div class="row">
          <div class="col-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" value="option1" id="chkbEnblNegativeMarking"
                name="negativeMarking" [(ngModel)]="negativeMarking" (change)="checkConfigEnableOption()" />
              <label class="form-check-label" for="chkbEnblNegativeMarking">Enable</label>
            </div>
          </div>
          <div class="col-3">
            <form class="form-group" autocomplete="off">
              <label for="exampleFormControlSelect1">Select Penalty (%)</label>
              <select class="form-control" (change)="checkForNegativeValue($event)" [disabled]="!negativeMarking"
                name="duration" [(ngModel)]="Penalty" style="width: 50%">
                <option value="" hidden></option>
                <option *ngFor="let penality of penalityList" [value]="penality.name">
                  {{ penality.name }}
                </option>
              </select>
            </form>
            <span id="penality" style="color: red; text-align: center"></span>
          </div>
        </div>
      </div>
    </div>
    <h6 class="pt-3" *ngIf="checkForPsychometric">
      Test Question Configuration
    </h6>
    <hr *ngIf="!forOnlineCompiler" />
    <div *ngIf="!forOnlineCompiler" class="row mt-4">
      <div class="col">
        <h6>Violation</h6>
        <div class="row">
          <div class="col-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" value="option1" id="chkbEnblSuspiciosCount"
                name="allowsuspiciosEnable" (change)="checkConfigEnableOption()" [(ngModel)]="allowSuspiciosEnable" />
              <label class="form-check-label" for="chkbEnblSuspiciosCount">Enable</label>
            </div>
          </div>

          <div class="col-3">
            <form class="form-group" autocomplete="off">
              <label for="exampleFormControlSelect1">Enter count</label>
              <input class="form-control" maxlength="2" [disabled]="!allowSuspiciosEnable" OnlyNumber="true"
                (keyup)="checkForZero($event)" (keydown)="validateNumber($event)" (keypress)="keyPress($event)"
                id="exampleFormControlSelect1" (change)="checkForZero($event)" name="allowsuspiciosCount"
                [(ngModel)]="allowsuspiciosCount" style="width: 50%" />
              <small *ngIf="zeroentered" style="color: red">You can't select 0</small>
            </form>
            <span id="suspicious" style="color: red; text-align: center"></span>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row mt-4">
      <div class="col">
        <h6>Additional Information</h6>

        <div class="form-check">
          <input class="form-check-input" [disabled]="checkForPsychometric" type="checkbox" value="option1"
            id="chkbTestInst" name="instruction" (change)="checkConfigEnableOption()" [(ngModel)]="instructForTest" />
          <label class="form-check-label" for="chkbTestInst">Instructions for test</label>
        </div>

        <div *ngIf="instructForTest" class="form-check">

          <angular-editor *ngIf="checkForPsychometric" [(ngModel)]="instructForTestValue" name="textArea"
            readonly="true" [config]="config"></angular-editor>

          <angular-editor *ngIf="!checkForPsychometric" [(ngModel)]="instructForTestValue" name="textArea"
            placeholder="Enter Description" readonly="true" [config]="config"></angular-editor>

        </div>
        <p id="instruction" style="color: red; text-align: center"></p>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="option1" id="chkbTestMessage" name="afterMesage"
            (change)="checkConfigEnableOption()" [(ngModel)]="afterTestMessage" />
          <label class="form-check-label" for="chkbTestMessage">After test message</label>
        </div>

        <div *ngIf="afterTestMessage" class="form-check">
          <angular-editor [(ngModel)]="afterTestMessageValue" name="textArea" [config]="config"></angular-editor>

        </div>
        <p id="message" style="color: red; text-align: center"></p>
      </div>
    </div>
    <div>
      <div class="row mt-3">
        <div class="col">
          <button type="submit" id="next" class="btn btn-primary" (click)="configurationMarking()">
            Next
          </button>
          <!--<button type="submit" class="btn btn-cancel " id="submit " data-toggle="modal" data-target="#cancelDone">-->
          <!--Cancel-->
          <!--</button>-->
          <button type="submit" class="btn btn-cancel" id="submit" (click)="backClicked()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--end step3 for Assesssment creation-->

  <!--start step4 for preview Assessment-->
  <div *ngIf="showPreviewAssessment" class="main-div-assessment container-fluid bg-white">
    <div class="row pt-3">
      <div class="col-12">
        <p class="GR-18 mb-2">Assessment Preview</p>
        <p class="GR-22 mb-2">{{ assessmentName }}</p>
        <div class="row">
          <div *ngIf="!checkForPsychometric && !forOnlineCompiler" class="col-4">
            <p class="GR-12 mb-0 grey">Total Question(s) :</p>
            <p class="GR-22 mb-0 text-success">{{ this.totalNumQuestion }}</p>
          </div>
          <div *ngIf="checkForPsychometric && !forOnlineCompiler" class="col-4">
            <p class="GR-12 mb-0 grey">Total Question(s) :</p>
            <p class="GR-22 mb-0 text-success">168</p>
          </div>

          <div *ngIf="forOnlineCompiler" class="col-4">
            <p class="GR-12 mb-0 grey">Total Question(s) :</p>
            <p class="GR-22 mb-0 text-success">{{ onlineTotalQues }}</p>
          </div>

          <div *ngIf="!forOnlineCompiler" class="col-3">
            <p class="GR-12 mb-0 grey">Test Duration :</p>
            <p class="GR-22 mb-0 text-success">{{ durations }}</p>
          </div>

          <div *ngIf="forOnlineCompiler" class="col-3">
            <p class="GR-12 mb-0 grey">Test Duration :</p>
            <p class="GR-22 mb-0 text-success">{{ onlineDuration }}</p>
          </div>

          <div *ngIf="!checkForPsychometric && !forOnlineCompiler" class="col-4">
            <p class="GR-12 mb-0 grey">Passing Score(%) :</p>
            <p class="GR-22 mb-0 text-success">{{ percentageMarks }}%</p>
          </div>

          <div *ngIf="forOnlineCompiler" class="col-4">
            <p class="GR-12 mb-0 grey">Passing Score(%) :</p>
            <p class="GR-22 mb-0 text-success">{{ onlineCutoff }}%</p>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col" *ngIf="psychometerPreview && !forOnlineCompiler">
        <div class="col-12">
          <h6 id="msg">
            Answer the questions by carefully clicking on the option (one option
            amongst the range from Strongly Agree to Strongly Disagree) that you
            think most suits you.
          </h6>
          <div class="row mt-3 mb-3">
            <div class="col">
              <span class="GR-16">1. </span>
              <span class="GR-16">I feel confident in my ability to deal with other
                people.?</span>
            </div>
          </div>
          <div style="color: green">
            <p class="GR-14">
              Strongly Disagree
              <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
            </p>
          </div>

          <div>
            <p class="GR-14">Disagree</p>
          </div>

          <div>
            <p class="GR-14">Neutral</p>
          </div>

          <div>
            <p class="GR-14">Agree</p>
          </div>

          <div>
            <p class="GR-14">Strongly Agree</p>
          </div>
        </div>
      </div>

      <div class="col" *ngIf="!checkForPsychometric && !forOnlineCompiler">
        <!--<h5>{{section}}</h5>-->
        <div class="row" *ngFor="let listOfQuestion of previewOfAssessmentList; let i = index">
          <div class="col">
            <strong> {{ listOfQuestion.sectionName }}</strong>
            <div class="row">
              <div class="col-12" *ngFor="let list of listOfQuestion.questions; let j = index">
                <div class="row mt-3 mb-3" *ngIf="list.questionType!=='Reading Comprehension'">
                  <div class="col">
                    <span class="GR-16">{{ j + 1 }}. </span>
                    <app-mathjax class="GR-16" [content]="list.question">
                      ?
                    </app-mathjax>
                  </div>
                </div>
                <div class="row mt-3 mb-3" *ngIf="list.questionType =='Reading Comprehension'">
                  <div class="col">
                    <span class="GR-16">{{ j + 1 }}. </span>
                    <div class="GR-16" [innerHTML]="list.question">

                    </div>
                  </div>
                </div>
                <!-- displaying uploaded media of each question -->
                <div class="mt-3 mb-1">
                  <ng-container *ngIf="list.attachedMedias && list.attachedMedias.length > 0">
                    <div class="row mb-2 ">
                      <div *ngFor="let media of list.attachedMedias; let k = index" class="media-item" [ngClass]="{                        
                             'col-12': list.renderedOptions === '1',
                             'col-6 ': list.renderedOptions === '2',
                             'col-4 ': list.renderedOptions === '3',
                             'col-3 ': list.renderedOptions === '4',
                             'col-2': list.renderedOptions === '5',
                             'media-col-1':  list.renderedOptions === 'all'
                           }">
                        <div *ngIf="media.fileType.startsWith('images')">
                          <img [src]="media.location" alt="Image Preview"
                            (click)="openMedia(media.location,media.fileType)" style="width: 200px; height: 130px;"
                            class="mt-3">
                        </div>
                        <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                          <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                            disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                            style="width: 200px; height: 130px;" class="mt-3"></video>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <div class="mt-3 mb-3">
                  <div *ngIf="list.difficultyLevel == easy">
                    <span class="badge badge-success mr-1">{{
                      list.difficultyLevel
                      }}</span>
                    <span class="badge badge-single">{{
                      list.questionType
                      }}</span>
                  </div>
                  <div *ngIf="list.difficultyLevel == medium">
                    <span class="badge badge-warning mr-1">{{
                      list.difficultyLevel
                      }}</span>
                    <span class="badge badge-single">{{
                      list.questionType
                      }}</span>
                  </div>
                  <div *ngIf="list.difficultyLevel == hard">
                    <span class="badge badge-danger mr-1">{{
                      list.difficultyLevel
                      }}</span>
                    <span class="badge badge-single">{{
                      list.questionType
                      }}</span>
                  </div>
                </div>
                <div *ngIf="list.questionType !=='Reading Comprehension' && list.questionType !== 'Coding'">
                  <div *ngIf="
                    list.questionType !== 'Fill In The Blanks' &&
                    list.questionType !== 'Descriptive' &&
                    list.questionType !== 'Match The Following'
                  ">
                    <div *ngIf="
                      list.answer.a == option1 ||
                      list.answer.b == option1 ||
                      list.answer.c == option1 ||
                      list.answer.d == option1
                    " style="color: green">
                      <p class="GR-14 mb-1">
                        Option A :
                        <span [innerHTML]="list.options.option1"></span> &nbsp;
                        <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option1 && list.optionsFiles.option1.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option1 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="
                      list.answer.a != option1 &&
                      list.answer.b != option1 &&
                      list.answer.c != option1 &&
                      list.answer.d != option1
                    ">
                      <p class="GR-14 mb-1">
                        Option A :
                        <span [innerHTML]="list.options.option1"></span>
                      </p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option1 && list.optionsFiles.option1.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option1 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                    <div *ngIf="
                      list.answer.a == option2 ||
                      list.answer.b == option2 ||
                      list.answer.c == option2 ||
                      list.answer.d == option2
                    " style="color: green">
                      <p class="GR-14 mb-1">
                        Option B :
                        <span [innerHTML]="list.options.option2"></span> &nbsp;
                        <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option2 && list.optionsFiles.option2.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option2 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="
                      list.answer.a != option2 &&
                      list.answer.b != option2 &&
                      list.answer.c != option2 &&
                      list.answer.d != option2
                    ">
                      <p class="GR-14 mb-1">
                        Option B :
                        <span [innerHTML]="list.options.option2"></span>
                      </p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option2 && list.optionsFiles.option2.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option2 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                    <div *ngIf="
                      list.answer.a == option3 ||
                      list.answer.b == option3 ||
                      list.answer.c == option3 ||
                      list.answer.d == option3
                    " style="color: green">
                      <p class="GR-14 mb-1" *ngIf="list.options.option3 != ''">
                        Option C :
                        <span [innerHTML]="list.options.option3"></span> &nbsp;
                        <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option3 && list.optionsFiles.option3.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option3 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="
                      list.answer.a != option3 &&
                      list.answer.b != option3 &&
                      list.answer.c != option3 &&
                      list.answer.d != option3
                    ">
                      <p class="GR-14 mb-1" *ngIf="list.options.option3 != ''">
                        Option C :
                        <span [innerHTML]="list.options.option3"></span>
                      </p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option3 && list.optionsFiles.option3.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option3 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                alt="Image Preview" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                    <div *ngIf="
                      list.answer.a == option4 ||
                      list.answer.b == option4 ||
                      list.answer.c == option4 ||
                      list.answer.d == option4
                    " style="color: green">
                      <p class="GR-14 mb-1" *ngIf="list.options.option4 != ''">
                        Option D :
                        <span [innerHTML]="list.options.option4"></span> &nbsp;
                        <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                      </p>
                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option4 && list.optionsFiles.option4.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option4 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="
                      list.answer.a != option4 &&
                      list.answer.b != option4 &&
                      list.answer.c != option4 &&
                      list.answer.d != option4
                    ">
                      <p class="GR-14 mb-1" *ngIf="list.options.option4 != ''">
                        Option D :
                        <span [innerHTML]="list.options.option4"></span>
                      </p>

                      <div class="mb-2">
                        <ng-container *ngIf="list.optionsFiles.option4 && list.optionsFiles.option4.length > 0">
                          <div *ngFor="let media of list.optionsFiles.option4 let i=index">
                            <div *ngIf="media.fileType.startsWith('images')">
                              <img [src]="media.location" alt="Image Preview"
                                (click)="openMedia(media.location,media.fileType)" style="width: 170px; height: 100px;">
                            </div>
                            <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                              <video [src]="media.location" (click)="openMedia(media.location,media.fileType)" controls
                                disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                style="width: 170px; height: 100px;"></video>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                  </div>
                </div>

                <!-- Changes for Comprehension Question -->
                <div *ngIf="list.questionType =='Reading Comprehension'">
                  <div *ngFor="let subQue of list.subQuestions let qSlNo = index;"
                    class="card card__two mtop15 border-0">
                    <div class="card-body card-thin">
                      <span class="GR-16">{{ j + 1 }}.{{ qSlNo + 1 }} </span>
                      <app-mathjax *ngIf="subQue.questionType!=='Fill In The Blanks'" class="GR-16"
                        [content]="subQue.question">
                        ?
                      </app-mathjax>
                      <p *ngIf="subQue.questionType=='Fill In The Blanks'" class="GR-16"
                        [innerHTML]="fillInTheBlanksQuesn">
                      </p>

                      <div class="mt-3 mb-1">
                        <ng-container *ngIf="subQue.attachedMedias && subQue.attachedMedias.length > 0">
                          <div class="row mb-2 ml-1 ">
                            <div *ngFor="let media of subQue.attachedMedias; let k = index" class="media-item"
                              [ngClass]="{                        
                                   'col-12': subQue.renderedOptions === '1',
                                   'col-6 ': subQue.renderedOptions === '2',
                                   'col-4 ': subQue.renderedOptions === '3',
                                   'col-3 ':subQue.renderedOptions === '4',
                                   'col-2 ': subQue.renderedOptions === '5',
                                   'media-col-1': subQue.renderedOptions === 'all'
                                 }">
                              <div *ngIf="media.fileType.startsWith('images')">
                                <img [src]="media.location" alt="Image Preview"
                                  (click)="openMedia(media.location,media.fileType)"
                                  style="width: 190px; height: 130px;" class="mt-3">
                              </div>
                              <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                                <video [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                  controls disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen"
                                  style="width: 190px; height: 130px;" class="mt-3"></video>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>

                      <div *ngIf="
                      subQue.questionType !== 'Fill In The Blanks' &&
                      subQue.questionType !== 'Descriptive' &&
                      subQue.questionType !== 'Match The Following'
                    ">
                        <div *ngIf="
                        subQue.answer.a == option1 ||
                        subQue.answer.b == option1 ||
                        subQue.answer.c == option1 ||
                        subQue.answer.d == option1
                      " style="color: green">
                          <p class="GR-14">
                            Option A :
                            <span [innerHTML]="subQue.options.option1"></span> &nbsp;
                            <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a != option1 &&
                        subQue.answer.b != option1 &&
                        subQue.answer.c != option1 &&
                        subQue.answer.d != option1
                      ">
                          <p class="GR-14">
                            Option A :
                            <span [innerHTML]="subQue.options.option1"></span>
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a == option2 ||
                        subQue.answer.b == option2 ||
                        subQue.answer.c == option2 ||
                        subQue.answer.d == option2
                      " style="color: green">
                          <p class="GR-14">
                            Option B :
                            <span [innerHTML]="subQue.options.option2"></span> &nbsp;
                            <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a != option2 &&
                        subQue.answer.b != option2 &&
                        subQue.answer.c != option2 &&
                        subQue.answer.d != option2
                      ">
                          <p class="GR-14">
                            Option B :
                            <span [innerHTML]="subQue.options.option2"></span>
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a == option3 ||
                        subQue.answer.b == option3 ||
                        subQue.answer.c == option3 ||
                        subQue.answer.d == option3
                      " style="color: green">
                          <p class="GR-14" *ngIf="subQue.options.option3 != ''">
                            Option C :
                            <span [innerHTML]="subQue.options.option3"></span> &nbsp;
                            <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a != option3 &&
                        subQue.answer.b != option3 &&
                        subQue.answer.c != option3 &&
                        subQue.answer.d != option3
                      ">
                          <p class="GR-14" *ngIf="subQue.options.option3 != ''">
                            Option C :
                            <span [innerHTML]="subQue.options.option3"></span>
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a == option4 ||
                        subQue.answer.b == option4 ||
                        subQue.answer.c == option4 ||
                        subQue.answer.d == option4
                      " style="color: green">
                          <p class="GR-14" *ngIf="subQue.options.option4 != ''">
                            Option D :
                            <span [innerHTML]="subQue.options.option4"></span> &nbsp;
                            <img class="correctAnsIcon" src="/assets/img/Upload completed.png" alt="" />
                          </p>
                        </div>
                        <div *ngIf="
                        subQue.answer.a != option4 &&
                        subQue.answer.b != option4 &&
                        subQue.answer.c != option4 &&
                        subQue.answer.d != option4
                      ">
                          <p class="GR-14" *ngIf="subQue.options.option4 != ''">
                            Option D :
                            <span [innerHTML]="subQue.options.option4"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- match the following -->
                <div *ngIf="list.questionType == 'Match The Following'" class="mt-3">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 8%">#</th>
                        <th scope="col" style="width: 46%">Option</th>
                        <th scope="col">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lhsOption of list.LHSOptions; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          <div [innerHTML]="getOptionText(lhsOption, 'LHSOption')"></div>
                          <div class="mb-2">
                            <ng-container *ngIf="list.LHSOptionsFiles['option' + (i + 1)]?.length > 0">
                              <div *ngFor="let media of list.LHSOptionsFiles['option' + (i + 1)]">
                                <div *ngIf="media.fileType.startsWith('images')">
                                  <img [src]="media.location" alt="Image Preview"
                                    (click)="openMedia(media.location,media.fileType)"
                                    style="width: 170px; height: 100px;">
                                </div>
                                <div *ngIf="media.fileType.startsWith('video')" #myVideo>
                                  <video [src]="media.location" (click)="openMedia(media.location,media.fileType)"
                                    controls disablePictureInPicture
                                    controlslist="nodownload noplaybackrate nofullscreen"
                                    style="width: 170px; height: 100px;"></video>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </td>
                        <td>
                          <select class="form-control">
                            <option disabled *ngFor="let rhsOption of list.RHSOptions" [selected]="
                                list.answer[i] ==
                                getOptionKey(rhsOption, 'RHSOption')
                              ">
                              {{ getOptionText(rhsOption, "RHSOption") }}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- match the following -->
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <!-- online compiler -->

      <div class="col" *ngIf="forOnlineCompiler">
        <!--<h5>{{section}}</h5>-->
        <div class="row" *ngFor="let sectionname of onlineSection; let i = index">
          <div class="col">
            <strong> {{ sectionname.name }}</strong>
            <div class="row">
              <div class="col-12" *ngFor="let list of sectionname.problems; let j = index">
                <div class="row mt-3 mb-3">
                  <div class="col">
                    <span class="GR-16">{{ j + 1 }}. </span>
                    <span class="GR-16" [innerHTML]="list.name"> ?</span>
                  </div>
                </div>

                <div class="mt-3 mb-3">
                  <div *ngIf="list.level == 'EAS'">
                    <span class="badge badge-success mr-1">{{ list.level }}Y</span>
                    <span class="badge badge-single">{{
                      list.problem_type
                      }}</span>
                  </div>
                  <div *ngIf="list.level == 'MED'">
                    <span class="badge badge-warning mr-1">{{ list.level }}IUM</span>
                    <span class="badge badge-single">{{
                      list.problem_type
                      }}</span>
                  </div>
                  <div *ngIf="list.level == 'HAR'">
                    <span class="badge badge-danger mr-1">{{ list.level }}D</span>
                    <span class="badge badge-single">{{
                      list.problem_type
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 pect">
      <button type="button" [disabled]="disableSaveExit" class="btn btn-primary" data-dismiss="modal"
        (click)="createAssessmentTest(true)">
        Save & Exit
      </button>
      <button *ngIf="!checkJobValue" type="button" [disabled]="disableSaveExit" class="btn btn-primary"
        data-dismiss="modal" (click)="publishLink()">
        Save & Publish
      </button>
      <!--<button type="button" class="btn btn-cancel" data-dismiss="modal" data-toggle="modal" data-target="#cancelDone">-->
      <!--Cancel-->
      <!--</button>-->
      <button type="button" class="btn btn-cancel" [disabled]="disableSaveExit" (click)="backClicked()">
        Cancel
      </button>
    </div>
  </div>
  <!--end step1 for preview Assessment-->

  <!--Public Link Creation Start -->
  <div *ngIf="publicLinkCreation">
    <app-publish></app-publish>
  </div>
  <!--end public link creation-->
</div>
<!--End Assessment Creation -->

<!--No internet connection modal starts-->
<div class="modal fade" id="internetConnection" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
      </div>
      <div class="modal-body text-center">
        Internet Connection lost.Check your internet connection and try again.
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
<!--End of no internet connection-->

<!-- start of upload media preview modal -->
<div class="modal" id="mediaPreview" tabindex="-1" role="dialog" *ngIf="selectedMediaUrl">
  <div class="modal-dialog" id="media-dialog" role="document">
    <div class="modal-content" id="content">
      <div class="modal-header" id="header">
        <h5 class="modal-title">Media Preview</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="media-body">
        <img *ngIf="selectedMediaType.startsWith('images')" [src]="selectedMediaUrl" alt="Image Preview"
          style="width: 400px; height: 400px;">
        <video *ngIf="selectedMediaType.startsWith('video')" [src]="selectedMediaUrl" controls autoplay
          disablePictureInPicture controlslist="nodownload noplaybackrate nofullscreen" class="mb-0"
          style="width: 400px; height: 270px;"></video>
      </div>
    </div>
  </div>
</div>
<!-- end of upload media preview modal -->