import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AssessmentService } from "../assessment.service";
import { SharedserviceService } from '../../sharedservice.service';

@Component({
  selector: "app-create-quick-assessment",
  templateUrl: "./create-quick-assessment.component.html",
  styleUrls: ["./create-quick-assessment.component.css"],
})
export class CreateQuickAssessmentComponent implements OnInit {
  templateSlug: string = "";
  templateDescription: string = "";
  templateId: string = "";
  showBasicInfo: boolean = true;
  showConfigurations: boolean = false;
  afterTestMessage: Boolean = true;
  instructForTestValue: string = "";
  afterTestMessageValue: string = "";
  templateDetails: any;
  assessmentNameError = false;
  assessmentName: string = "";
  assessmentType: string = "";
  duration: string = "";
  percentageMarks = "";
  isValidPercentageMarks = false; // Used to enable or disable next button for 'Basic Info' tab
  showPassingScoreErr = false; // Used in template to show error message below 'Passing Score' field
  shuffleQuestions: boolean = false;
  shuffleOptions: boolean = false;
  attemptAll: boolean = false;
  moveForwardOnly: boolean = false;
  allowSuspiciosEnable: Boolean = false;
  allowsuspiciosCount = "";
  allowresetEnable: any;
  allowresetCount: any;
  mainHistoryEnable: any;
  mainHistoryCount: any;
  zeroentered: Boolean;
  penalityList: any = [];
  disMaxMarks: boolean = false;
  disCatSubCat: boolean = false;
  negativeMarking: boolean = false;
  Penalty = "";
  accessToken: string;
  companyId: string;
  instructForTest: Boolean = true;
  publicLinkCreation: boolean = false;
  setWizard1: boolean = true;
  setWizard2: boolean = false;
  setWizard3: boolean = false;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  quickAssessmentTemplateId: string;
  disableSave: boolean = false;
  editFlag: boolean = false;
  config:any;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private assessmentServiceObj: AssessmentService,
    private sharedService:SharedserviceService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.config=this.sharedService.config;
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.penality();

    this.getRouteParams();
  }

  getRouteParams() {
    this.router.params.subscribe(
      (params: any) => {
        if (params.id) {
          this.templateId = params.id;
          this.editFlag = false;
          this.getTemplateDetails();
        }
        if (params.id) {
          this.editFlag = true;

          this.getAssessmentDetails(params.id);

        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getTemplateDetails() {
    const payLoad = {
      assessmentId: this.templateId,
    };
    this.assessmentServiceObj
      .getTemplateDetails(payLoad, this.companyId, this.accessToken)
      .toPromise()
      .then((response: any) => {
        this.templateDetails = response.message;
        this.templateId = this.templateDetails._id;
        this.templateSlug = this.templateDetails.urlSlug;
        this.assessmentName = this.templateDetails.templateTitle;
        this.assessmentType = this.templateDetails.forAssessmentType;
        this.setValuesForCreateEditAssessmentUI();
      });
  }

  getAssessmentDetails(id: any) {
    const payload = {
      assessmentId: id,
    };
    this.assessmentServiceObj
      .editAssessment(payload, this.companyId, this.accessToken)
      .subscribe(
        (response: any) => {
          if (!response.message[0].quickAssessmentTemplateId) {
            this.route.navigate([
              "assessment/list/" +
              response.message[0].assessmentType.toLowerCase(),
            ]);
            this.toastrService.error("Invalid process requested");
          }
          this.templateDetails = response.message[0];
          this.templateId = this.templateDetails._id;
          this.quickAssessmentTemplateId =
            this.templateDetails.quickAssessmentTemplateId;
          this.assessmentName = this.templateDetails.assessmentName;
          this.assessmentType = this.templateDetails.assessmentType;

          this.setValuesForCreateEditAssessmentUI();
        },

        (err) => {
          console.log(err);
        }
      );
  }

  setValuesForCreateEditAssessmentUI() {
    this.duration = this.templateDetails.duration;
    this.percentageMarks = this.templateDetails.cutOff;
    this.isValidPercentageMarks = true;
    this.showPassingScoreErr = false;
    this.templateDescription = this.templateDetails.description;

    this.shuffleQuestions =
      this.templateDetails.configuration.shuffleQuestions;
    this.shuffleOptions =
      this.templateDetails.configuration.shuffleOptions;
    this.attemptAll = this.templateDetails.configuration.attemptAll;
    this.moveForwardOnly =
      this.templateDetails.configuration.moveForwardOnly;
    this.disMaxMarks =
      this.templateDetails.configuration.showQuestionScore;
    this.disCatSubCat = this.templateDetails.configuration.showCategoryInfo;
    this.negativeMarking =
      this.templateDetails.configuration.negativeMarking;
    this.Penalty = this.templateDetails.configuration.selectPenalty;
    this.allowSuspiciosEnable =
      this.templateDetails.configuration.allowedSuspiciousCount;
    this.allowsuspiciosCount =
      this.templateDetails.configuration.suspiciousCountValue;
    this.instructForTestValue =
      this.templateDetails.configuration.instructionForTestValue;
    this.instructForTest =
      this.templateDetails.configuration.instructionForTest;
    this.afterTestMessage = this.templateDetails.configuration.addMessage;
    this.afterTestMessageValue =
      this.templateDetails.configuration.addMessageValue;

    if (this.instructForTest) {
      this.disableSave = this.instructForTestValue.length == 0;
    }
    if (this.afterTestMessage) {
      this.disableSave = this.afterTestMessageValue.length == 0;
    }
  }

  goToListPage() {
    // this.route.navigate(["/assessment/list/" + this.assessmentType]);
    this.route.navigate([ "assessment/list/" ]);

  }

  editQuickAssessment() {
    const requestBody = {
      assessmentId: this.templateId,
      passingScore: this.percentageMarks,
      instructionForTest: this.instructForTestValue,
      afterTestMessage: this.afterTestMessageValue,
      description: this.templateDescription,
      assessmentName: this.assessmentName,
    };
    this.assessmentServiceObj
      .editQuickAssessment(requestBody, this.companyId, this.accessToken)
      .toPromise()
      .then(
        (response: any) => {
          this.toastrService.success(response.response);
          this.goToListPage();
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  penality() {
    const payload = {
      type: "penalty",
      assessmentType: "",
    };
    this.assessmentServiceObj
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.penalityList = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  checkConfigEnableOption() {
    if (!this.negativeMarking) {
      this.Penalty = "";
      $("#penality").html("");
    }
    if (!this.allowSuspiciosEnable) {
      this.allowsuspiciosCount = "";
      $("#suspicious").html("");
      this.zeroentered = false;
      ($("#next") as any).attr("disabled", false);
    }
    if (!this.allowresetEnable) {
      this.allowresetCount = "";
      $("#reset").html("");
    }
    if (!this.mainHistoryEnable) {
      this.mainHistoryCount = "";
      $("#history").html("");
    }

    if (this.instructForTest) {
      if (this.instructForTestValue.length == 0) {
        this.disableSave = true;
        return;
      } else {
        this.disableSave = false;
      }
    } else {
      this.disableSave = false
    }
    if (this.afterTestMessage) {
      if (this.afterTestMessageValue.length == 0) {
        this.disableSave = true;
        return;
      } else {
        this.disableSave = false;
      }
    } else {
      this.disableSave = false
    }
  }

  showConfigurationsSection() {
    this.setWizard1 = true;
    this.setWizard2 = true;
    this.setWizard3 = false;
    this.showBasicInfo = false;
    this.showConfigurations = true;
    if (this.editFlag) {
      document.getElementById("prog").style.width = "100%";
    } else {
      document.getElementById("prog").style.width = "50%";
    }
    window.scrollTo(0, 0);
  }

  checkForZeroValue(e) {
    /*
     * On key input, checks 'Passing Score' field value,
     * updated 'isValidPercentageMarks' value, which controls enabled/disabled state of 'Basic Info' tab next button
     */
    if (e.keyCode === 48 && Number(e.target.value.charAt(0)) === 0) {
      // Multiple 0s provided
      e.target.value = 0;
      this.isValidPercentageMarks = true;
    } else if (Number(e.target.value.charAt(0)) === 0) {
      if (e.target.value !== "") {
        // If the value has 0 as the first digit, remove it and update the value
        e.target.value = Math.round(e.target.value);
        this.isValidPercentageMarks = true;
      } else {
        // OK
        this.isValidPercentageMarks = false;
      }
    }
    if (e.target.value >= 0 && e.target.value <= 100) {
      // Valid value is between 0 to 100 range
      this.isValidPercentageMarks = this.percentageMarks !== "";
    } else {
      // Value out of range
      this.isValidPercentageMarks = false;
    }

    this.showPassingScoreErr = !this.isValidPercentageMarks && this.percentageMarks !== "";
  }

  checkPassingScore(e) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  createQuickAssessment(routeToAssessmentListScreen: boolean) {
    this.disableSave = true;
    const requestBody = {
      templateId: this.templateId,
      passingScore: this.percentageMarks,
      instructionForTest: this.instructForTestValue,
      afterTestMessage: this.afterTestMessageValue,
      description: this.templateDescription,
      assessmentName: this.assessmentName,
    };

    this.assessmentServiceObj
      .createQuickAssessment(requestBody, this.companyId, this.accessToken)
      .toPromise()
      .then(
        (response: any) => {
          localStorage.setItem("_createdassessmentId", response.assessmentId);
          if (routeToAssessmentListScreen) {
            this.route.navigate([
              "assessment/list/" + this.assessmentType.toLocaleLowerCase(),
            ]);
            this.toastrService.success(response.response);
          } else {
            this.renderPublishLinkScreen();
          }
          this.disableSave = false;
        },
        (error: any) => {
          console.log(error);
          this.disableSave = false;
          this.toastrService.error("Something went wrong! Could not process your request at this moment. " +
            "Please contact administrator for assistance at support@talentbridge.co.in.");
        }
      );
  }

  goToDetailsPage() {
    this.route.navigate([
      "/skill-based-assessment-template-detail/" + this.templateSlug,
    ]);
  }

  publishLink() {
    this.createQuickAssessment(false);
  }

  renderPublishLinkScreen() {
    this.showBasicInfo = false;
    this.showConfigurations = false;
    this.setWizard1 = false;
    this.setWizard2 = false;
    document.getElementById("prog").style.width = "100%";
    this.setWizard3 = true;
    this.publicLinkCreate();
  }

  publicLinkCreate() {
    this.publicLinkCreation = true;
    $("#main_Div").css("padding-top", "");
  }

  stepWizard1() {
    this.setWizard2 = false;
    this.setWizard3 = false;
    if (!this.setWizard1) {
      return;
    }
    document.getElementById("prog").style.width = 0 + "%";
    this.showBasicInfo = true;
    this.showConfigurations = false;
    this.publicLinkCreation = false;
  }

  stepWizard2() {
    this.setWizard1 = true;
    this.setWizard2 = true;
    this.setWizard3 = false;
    this.showBasicInfo = false;
    this.showConfigurations = true;
    this.publicLinkCreation = false;
    if (!this.setWizard2) {
      return;
    }

    document.getElementById("prog").style.width = "50%";
    console.log();
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }
}
