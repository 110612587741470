import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgProgressModule} from 'ng2-progressbar';
import { MyDatePickerModule } from 'mydatepicker';
import { RouterModule } from '@angular/router';
import { RlTagInputModule } from 'angular2-tag-input';
import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
import { TextMaskModule } from 'angular2-text-mask';
import { HeaderStaticComponent } from '../header-static/header-static.component';
import { FooterStaticComponent } from '../footer-static/footer-static.component';
import { FilterPipe } from '../filter.pipe';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    RlTagInputModule,
    InternationalPhoneNumber2Module,
    TextMaskModule,
    ModalModule.forRoot(),
    
  ],
  exports: [
    HeaderComponent,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    ModalModule,
    RlTagInputModule,
    NgProgressModule,
    MyDatePickerModule,
    HeaderStaticComponent,
    FooterStaticComponent,
    FilterPipe
  ],
  declarations: [
    HeaderComponent,
    HeaderStaticComponent,
    FooterStaticComponent,
    FilterPipe
  ],
})
export class SharedModule { }
