import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment'
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Injectable({
  providedIn: 'root'
})
export class SharedserviceService {
  private url = '';
  globalUrl = environment.serverUrl;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  public config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      ['fontSize', 'textColor', 'backgroundColor', 'insertImage', 'insertVideo', 'removeFormat', 'insertHorizontalRule'],
    ]
  };

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '75px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      ['fontSize', 'textColor', 'backgroundColor', 'insertImage', 'insertVideo', 'removeFormat', 'insertHorizontalRule', 'heading'],
    ]
  };

  constructor(private http: HttpClient) { }


  getSkillBasedTemplateList(payLoad, skip) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/quick-assessment/public/list?skip=' + skip;
    return this.http.post(this.url, payLoad, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }
  getMasterValuesForMetaData(payload): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.url = this.globalUrl + '/business/services/get/meta-master-list';
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  getPublicTemplateDetails(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.url = this.globalUrl + '/business/services/quick-assessment/published-template/details';
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  contactToUse(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.url = this.globalUrl + '/business/services/quick-assessment/request-to-use-template';
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  _errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server Error');
  }
}
