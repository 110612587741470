import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DashBoardService } from '../../dashboard/dashboard.service';
import { Router } from '@angular/router';
import { VerifyService } from '../../verify-comp/verify-comp.service';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { SharedserviceService } from '../../sharedservice.service'

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  providers: [VerifyService, DashBoardService, CommonService]
})
export class EditProfileComponent implements OnInit {

  editForm: FormGroup;
  showMinimumError: boolean;
  logoMessage = '';
  base64textString = '';
  companyLogo: FileList;
  isLoading: boolean;
  companyId: string;
  accessToken: string;
  uploadCompanyLogo: string;
  userDetails: any;
  compURL = 'http://';
  dashboardErrorMsg = '';
  config:any;

  constructor(
    private router: Router,
    public verifyservice: VerifyService,
    public dashBoardService: DashBoardService,
    public commonService: CommonService,
    private toastrService: ToastrService,
    private sharedService: SharedserviceService
  ) {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
  }

  ngOnInit() {
    this.config=this.sharedService.config;
    this.showMinimumError = false;
    this.isLoading = false;
    this.dashboardErrorMsg = '';
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    this.userDetails = userDetail;
    this.uploadCompanyLogo = this.userDetails.Companies[0].CompanyLogo;
    this.editForm = new FormGroup({
      firstname : new FormControl('',
        [Validators.required, Validators.minLength(1), Validators.maxLength(101),
          Validators.pattern("^[a-zA-Z'.-]+[a-zA-Z'.0-9 ]*$")]),
      lastname : new FormControl( '', [Validators.minLength(1), Validators.maxLength(101),
        Validators.pattern("^[a-zA-Z'.-]+[a-zA-Z'.0-9 ]*$")]),
      companyname : new FormControl('', [Validators.required, Validators.minLength(3),
        Validators.maxLength(265), Validators.pattern('^.*[a-zA-Z0-9].*$')]),
      companyurl : new FormControl('', [Validators.pattern('(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]),
      companyinfo: new FormControl(''),
      fileupload: new FormControl(),
      supportemail: new FormControl('', [Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      supportphoneno : new FormControl('', [Validators.minLength(7), Validators.maxLength(14)]),
    });
  }

  get firstname() { return this.editForm.get('firstname'); }
  get lastname() { return this.editForm.get('lastname'); }
  get companyname() { return this.editForm.get('companyname'); }
  get companyurl() { return this.editForm.get('companyurl'); }
  get companyinfo() { return this.editForm.get('companyinfo'); }
  get fileupload() { return this.editForm.get('fileupload'); }
  get supportemail() { return this.editForm.get('supportemail'); }
  get supportphoneno() { return this.editForm.get('supportphoneno'); }

  checkForValue(event) {
    if (event.target.value.trim() === '' && event.target.value.length !== 0) {
      this.showMinimumError = false;
    }
    if (event.target.value.trim() !== '' && event.target.value.length < 3) {
      this.showMinimumError = true;
    } else {
      this.showMinimumError = false;
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.uploadNewCompanyLogo();
  }

  fileChangeEvent(event) {
    this.companyLogo = event.target.files;
    const filenameArray = this.companyLogo[0].name.split('.');
    const fileType = filenameArray[filenameArray.length - 1];
    if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
      const file = this.companyLogo[0];
      if (this.companyLogo[0].size > 2000000) {
        this.logoMessage = 'File size exceeds 2MB.';
        return;
      }
      this.logoMessage = '';
      this.base64textString = '';
      if (this.companyLogo && file) {
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      } else {
        this.logoMessage = 'Please select a file first';
        return;
      }
    } else {
      this.logoMessage = 'Invalid file format.';
      return;
    }

  }

  uploadNewCompanyLogo() {
    if (this.base64textString === '') {
      this.toastrService.error('Could not process the uploaded image', '', {
        timeOut : 5000,
      });
      return;
    }
    const payload = {
      'baseString': this.base64textString,
      'fileId': UUID.UUID() + '.jpg'
    };
    this.isLoading = true;
    this.dashBoardService.uploadDocumentsService(this.companyId, this.accessToken, payload, this.companyLogo)
      .subscribe(
        response => {
          this.uploadCompanyLogo = response.location;
          this.isLoading = false;
        },
        error => {
          console.log(error);
          if (error.status === 412) {
            console.log(error.error.message);
            this.toastrService.error(error.error.message, '', {
              timeOut : 5000,
            });
          } else {
            alert('Something went wrong');
          }
        });
  }

  submitForm(form: any) {
    if (form.companyurl.substring(0, 7) === 'http://' || form.companyurl.substring(0, 8) === 'https://') {
      this.userDetails.Companies[0].CompanyUrl = form.companyurl;
    } else {
      if (form.companyurl.trim() !== '') {
        this.userDetails.Companies[0].CompanyUrl = this.compURL + form.companyurl;
      } else {
        this.userDetails.Companies[0].CompanyUrl = form.companyurl.trim();
      }
    }
    if (this.logoMessage !== '') {
      return;
    }
    const payload = {
      'companyName': (form.companyname === '') ? this.userDetails.Companies[0].CompanyName : form.companyname,
      'logo': this.uploadCompanyLogo,
      'companyurl' : this.userDetails.Companies[0].CompanyUrl,
      'firstName': (form.firstname === '') ? this.userDetails.FirstName : form.firstname,
      'lastName': (form.lastname === '') ? this.userDetails.LastName : form.lastname,
      'supportEmail': (form.supportemail === '') ? this.userDetails.Companies[0].SupportEmail : form.supportemail,
      'supportMobile': (form.supportphoneno === '') ? this.userDetails.Companies[0].SupportMobile : form.supportphoneno,
      'companyDescription': this.userDetails.Companies[0].CompanyDescription,
      'supportCountryCode': '+91',
    };
    this.dashBoardService.changeProfileDetails(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.userDetails = response.companyDetails;
          this.verifyservice.getUserInfo(this.companyId, this.accessToken)
            .subscribe(
              userInfoResponse => {
                localStorage.setItem('userInfo', JSON.stringify(userInfoResponse.userDetails));
                localStorage.setItem('userRole', userInfoResponse.userDetails.Companies[0].RoleId);
                this.commonService.storeAPIKeyWebhookDetails(userInfoResponse);
                this.toastrService.success('Account edited successfully', '', {
                  timeOut : 4000,
                });
                this.router.navigate(['/profile']);
              },
              error => {

              });
        },
        error => {

          this.dashboardErrorMsg = error.error.message;
        });
  }

  cancelProfileEditProcess() {
    this.router.navigate(['/profile']);
  }
}
